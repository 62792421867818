import { useFormik } from "formik";
import { NuInput } from "../../../../Components/Utils/Input";
import { useToast } from "../../../../Components/Others/ToastServices";
import { useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { TextMediumXL } from "../../../../Components/Text/MediumText";
import { CancelButton, NextButton, Submit, SubmitButton } from "../../../../Components/Utils/Buttons";
import * as yup from 'yup';


const ChangePassword = ({onClose}) =>{
  const [nextStep, setNextStep] = useState(false);
  const [finalStep, setFinalStep] = useState(false);
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    email: " ",
    OTP:'',
    retype_password: "",
    type_password: "",
  });
  const validationSchema = yup.object({
    email: yup.string().email("Invalid email address").required("Email is required"),
    OTP: yup.string().when('nextStep', {
      is: true,
      then: yup.string().required("OTP is required"),
    }),
    type_password: yup.string().when('finalStep', {
      is: true,
      then: yup.string().required("Password is required"),
    }),
    retype_password: yup.string().when('finalStep', {
      is: true,
      then: yup.string().oneOf([yup.ref('type_password')], "Passwords must match").required("Re-type password is required"),
    }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z]+$/, "Enter Valid Name"),
    //   dob: yup
    //     .string()
    //     .max(new Date(Date.now() - 567648000000), "Must be at least 18 years")
    //     .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),

    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   joinDate: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value,{resetForm}) => {
      let obj = {
        'email':value.email,
        'retype_password': value.retype_password,
        'type_password': value.type_password,
      }
      if(value.type_password != "" && value.retype_password !=""){
        if(value.retype_password == value.type_password){
          axiosInstance.put(`/auth/reset-password`,obj).then(res=>{
            if(res.data.status){
              resetForm();
              toast.open({
                type: "success",
                message: `Password Reset successfully`,
                description:res.data?.msg||"Password Reset successfully",
                
            });
              onClose();
            }
          }).catch(err=>{
            toast.open({
              type: "error",
              message: `Password Reset Failed`,
              description: err.response?.data?.msg ||'Network Error',
              
          });
          })
        }else{
          toast.open({
            type: "error",
            message: `Password Mismatch`,
            description: 'Please Enter same password in Re-type Password Field',
            
        });
        }
      }else{
        toast.open({
          message: `Enter Password`,
          description: 'Please Enter Password in The Password Fields',
          
      });
      }
    },
  });
  function GotoNextStep(){
    if(formik.values.email !=" "){
      let obj = {
        'email':formik.values.email
      }
      axiosInstance.post(`/auth/email-otp`,obj).then(res=>{
        if(res.data?.status){
          setNextStep(true)
          toast.open({
            type: "success",
            message: `OTP Send successfully`,
            description:res.data?.msg||"OTP Has sent the email",
            
        });
        }else{
          toast.open({
            type: "error",
            message: `OTP Sending failed`,
            description: res.data.msg || 'Network error',
            
        });
        }
      }).catch(err=>{
        toast.open({
          type: "error",
          message: `OTP Sending failed`,
          description: err.response?.data?.msg || 'Network error',
          
      });
      })
    }else{
      toast.open({
        type: "error",
        message: `Enter Valid Email `,
        description:  'Please Enter valid Email Address',
        
    });
    }
  }


  function GotoFinalStep(){
    if(formik.values.OTP!=" "){
      let obj = {
        'email':formik.values.email,
        'OTP':`${formik.values.OTP}`
      }
      axiosInstance.post(`/auth/verify-otp`,obj).then(res=>{
        if(res.data?.status){
          setNextStep(false);
          setFinalStep(true);
          toast.open({
            type: "success",
            message: `OTP Verified successfully`,
            description:res.data?.msg||"OTP Has Verified",
            
        });
        }else{
          toast.open({
            type: "error",
            message: `OTP Verified failed`,
            description: res.data.msg || 'Network error',
            
        });
        }
      }).catch(err=>{
        toast.open({
          type: "error",
          message: `OTP Verified failed`,
          description: err.response?.data?.msg || 'Network error',
          
      });
        // console.log(err);
      })
    }else{
      toast.open({
        type: "error",
        message: `Invalid OTP `,
        description: 'Please Enter Valid OTP',
        
    });
    }
  }
  return(
    <>
      <div className=" fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-md z-1">
            <div  className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <form onSubmit={formik.handleSubmit}>
            {nextStep == false && finalStep == false && (
              <div>
                <div className=" w-full flex flex-wrap">
                  <TextMediumXL content={"Forget Password"} otherStyles=' mb-2' align='start' />
                  <NuInput
                    type="email"
                    label="Email"
                    placeholder="example@email.com"
                    formik={formik}
                    name="email"
                    width="w-full"
                  />
                </div>
                <div className=" w-full flex justify-end items-center gap-2">
                  <CancelButton handleClick={onClose}/>
                  <NextButton handleClick={GotoNextStep}/>
                </div>
              </div>
            )}
            {
              nextStep == true && (
                <div>
                  <div className=" w-full flex flex-wrap">
                  <TextMediumXL content={"Enter OTP"} otherStyles=' mb-2' align='start' />
                    <NuInput
                      type="number"
                      label="OTP"
                      placeholder="Enter OTP"
                      formik={formik}
                      name="OTP"
                      width="w-full"
                    />
                  </div>
                  <div className=" w-full flex justify-end items-center gap-2">
                  <CancelButton handleClick={onClose}/>
                  <Submit handleClick={GotoFinalStep}/>
                  </div>
                </div>
              )
            }
            {finalStep == true && (
              <div>
                <div className=" w-full flex flex-wrap">
                <TextMediumXL content={"Enter New Password"} otherStyles=' mb-2' align='start' />
                  <NuInput
                    type="password"
                    label="New Password"
                    placeholder="******"
                    formik={formik}
                    name="type_password"
                    width="w-full"
                  />
                <NuInput
                    type="password"
                    label="Re-type Password"
                    placeholder="******"
                    formik={formik}
                    name="retype_password"
                    width="w-full"
                  />
                </div>
                <div className=" w-full flex justify-end items-center gap-2">
                  <CancelButton handleClick={onClose}/>
                  <SubmitButton/>
                </div>
              </div>
            )}
          </form>
          </div>
        </div>
    </>
  )
}

export default ChangePassword;