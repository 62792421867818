import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

const ViewMyTask = () => {
  // const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location?.state?.data||{};
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <Breadcrumb
         items={
          [{
            name: "All Tasks",
            link: "/task/my-task"
          },
          {
            name: "View All Task"
          }]
         }
        />
        <div className=" flex gap-2">
          <BackButton />
          {/* <Link to={`/store/returns/edit/${data?.id||""}`} state={{data: data, from:`/store/returns/view/${data?.id||""}`}}><EditButton height='8'/></Link> */}
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-120px)] bg-white rounded-xl overflow-y-scroll">
              <h2 className='text-base font-normal text-[#111537] p-3'>All Task Details</h2>
              <div className=" w-full flex flex-wrap ">
                 <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>AllTask ID</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                   <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>taskName</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.taskName || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>taskDesc</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.taskDesc || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>taskStatus</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.taskStatus || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>assignedTo</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.assignedTo?.map((name)=>{
                      return name?.name
                    }) || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>copyTo</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.copyTo?.map((name)=>{
                      return name?.name
                    })  || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>startDate</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.startDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>reminderDate</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.reminderDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>createdByName</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.createdByName || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>endDate</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.endDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>noOfDays</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.noOfDays || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>noOfWeeks</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.noOfWeeks || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>priority</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.priority || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>pubId</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.pubId || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>refNo</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>reminderDate</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>reminderTime</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>revisedEndDate</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.revisedEndDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>taskCompletion</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.taskCompletion || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>taskCompletionD</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.id || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>updatedByName</p>
                    <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data?.updatedByName || '-'}</p>
                  </div>
                  </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewMyTask;