import React from "react";
import Select from "react-select";
import { CountryMIN } from "../../assets/CountryMini";

function FormikSelectTwo({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled = false,
  customClass,
  px = "6",
  isRequired,
  width,
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      fontWeight: "500",
      width: "100%",
      zIndex: 0,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,  // Ensure it's above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",  // Default padding
      fontSize: "12px",  // Default font size for small screens
      [`@media (min-width: 640px)`]: {
        fontSize: "12px",  // sm breakpoint (Tailwind sm: text-sm)
      },
      [`@media (min-width: 768px)`]: {
        fontSize: "12px",  // md breakpoint (Tailwind md: text-base)
      },
      [`@media (min-width: 1024px)`]: {
        fontSize: "12px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      [`@media (min-width: 1536px)`]: {
        fontSize: "14px",  // lg breakpoint (Tailwind lg: text-lg)
      },
      color: state.isSelected ? '#fff' : '#000',  // Custom colors for selected options
      backgroundColor: state.isSelected ? '#2563EB' : '#fff',  // Custom background color for selected option
      ':hover': {
        color:'#fff',
        backgroundColor: '#60A5FA',  // Hover state color
      },
    }),
  };

  return (
    <div className={`${width} relative px-${px} py-2`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <div className="mt-2">
        <Select
          isDisabled={disabled}
          options={options}
          value={options.find((i) => i.value === formik.values[name]) || ""}
          styles={styles}
          menuPortalTarget={document.body} // Render the dropdown in the body
          className={`${classname} text-xs 2xl:text-sm mt-2 font-medium ${disabled ? "bg-slate-100 cursor-not-allowed" : ""}`}
          name={name}
          onChange={onChange || handleChange}
          placeholder={placeholder}
        />
      </div>
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}

export default FormikSelectTwo;






export function SelectCountry({
  classname,
  labelclassname,
  name,
  onChange,
  formik,
  isRequired,
  width
}) {


  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-6 py-2`}>
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          Country
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <div className="mt-2">
          <Select
            options={CountryMIN}
            value={CountryMIN.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium `}
            name={name}
            onChange={onChange || handleChange}
            placeholder={"Choose Country"}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}

export function SelectWithError({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  disabled=false,
  customClass,
  px="6",
  isRequired,
  width,
  ErrMessage
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };
  return (
    <>
      <div className={`${width} relative px-${px} py-2`}>
        <div className="flex">
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <p className="pl-2 text-xs font-semibold text-red-500 ">{ErrMessage}</p>
        </div>
        <div className="mt-2">
          <Select 
          isDisabled={disabled ? true : false}
            options={options}
            value={options.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm mt-2 font-medium ${disabled === true? "bg-slate-100 cursor-not-allowed":""}`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}



