import React from "react";
import Pagination from "../Utils/Pagenation";
import { useTranslation } from "react-i18next";
import NuDate from "../Utils/NuDate";
import NuAmount from "../Utils/NuAmount";
// import Pagination from '../Utils/Pagenation';

function ReportTable({
  headers,
  data,
  height = "h-[calc(100vh-130px)]",
  bodyHeight = "max-h-[calc(100vh-200px)]",
}) {
  const { t, i18n } = useTranslation();


  function getField(row,field,typeValue){
    let splitValue = field.split(".");
    let value = ""
    if(splitValue.length >= 2){
      if(splitValue.length == 3){
        value = row[splitValue[0]][splitValue[1]][splitValue[2]]
      }else {
        value = row[splitValue[0]][splitValue[1]]
      }
    }else{
        value = row[splitValue[0]]
    }

    if(typeValue === "date-time"){
      return  (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY hh:mm a" />  )||""
    }else if(typeValue === "date"){
      console.log("date format");
      return (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY" />  )||""
    }else if(typeValue === "time"){
      return (value !=="" && value !=="None" &&<NuDate value={value} format="hh:mm a" />  )||""
    }else if(typeValue === "amount"){
      return <NuAmount value={value}/> ||""
    }else{
      return value
    }
  }

  return (
    <div className={`w-full ${height} mt-2 `}>
      <div className="w-full h-full overflow-hidden">
        <div className="w-full">
          <div className=" w-full " style={{ borderBottom: "1px solid black" }}>
            <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
              <thead>
                <tr className="text-xs font-semibold">
                  {headers &&
                    headers.map((header, i) => (
                      <th
                        className={`p-2 font-semibold truncate`}
                        style={{
                          width:header?.width,
                          textAlign:header?.rowAlign||"center"
                        }}
                        key={i}
                      >
                        {header.title || "-"}
                      </th>
                    ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className={`${bodyHeight} z-10 pt-1 pb-4 overflow-y-auto`}>
            <table
              className="w-full table-auto border-collapse"
              dir={i18n.language == "ar" ? "rtl" : ""}
            >
              <tbody className="text-xs w-full">
                {data.map((row, rowIndex) => (
                  <tr
                    className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105"
                    key={rowIndex}
                  >
                    {headers.map((header, colIndex) => {
                      return (
                        <td
                          className={`py-3 px-[1px]`}
                          key={colIndex}
                          style={{
                            width:header?.width,
                            textAlign:"center",
                          }}
                        >
                          {
                          header.isCondition ? (row[header.field] ? 'Yes' : 'No') 
                          : getField(row,header?.field,header?.type)
                          }
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportTable;
