import React from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

function ViewCustomers() {
    const { customersViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
        items={[
          // { name: "Sales" },
  
          { name: "Customers",
            link:"/sales/customers"},
            
          { name: "View"},
        
    ]} />
      <div className=" flex gap-2">
        <Link to={'/sales/customers'}><BackButton link={true}/></Link>
        <Link to={`/sales/customers/edit/${customersViewId}`} 
        state={{data: data, from:`/sales/customers/view/${customersViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <NormalHeading title={data.companyName} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
             <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer Type</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.customerType}</p>
              </div>
               {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Name</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.companyName}</p>
              </div>  */}
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.email}</p>
              </div>
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.phoneWithCC}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Desigation</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.designation}</p>
              </div>
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Website</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.website}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.category}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Industry</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.industry}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Name</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.cpName}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Email</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.cpEmail}</p>
              </div> 
             
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Contact Person Phone</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.cpPhoneWithCC}</p>
              </div>

               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Billing Address</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.billingAddress}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping Address</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.shippingAddress}</p>
              </div>
              
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>CR Num</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.crNum}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vat Num</p>
                <p className='w-1/2 py-2 pl-5 text-xs text-[#606279]'>{data.vatNum}</p>
              </div>
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Ratings</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.ratings}</p>
              </div> 
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Payment Term</p>
                <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data.paymentTerm}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Credit Limit</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.creditLimit}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                <p className='w-1/2 py-2 pl-5 text-xs text-[#606279]'>{data.comments}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.country}</p>
              </div> 
             
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.state}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.city}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>District</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.district}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Postal Code</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.postalCode}</p>
              </div> 
             
          </div>
        </div>
      </div>
       
    </div>
  </div>
)
};
export default ViewCustomers