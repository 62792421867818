import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import { NuInputReffrence } from "../../../../Components/Utils/Input";
import * as yup from "yup";
import Save from "../../../../Components/Icons/Save";


const PurchaseRequest = () =>{
  const toast = useToast();
  const [data, setData] = useState();
  console.log("iiii",data);
  const [createNew, setCreateNew] = useState({
    prefix: "",
    displayZero: false,
    sufix: ""
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
       axiosInstance
       .put(`/ref-format/edit-ref-format/purchase_request`,value)
       .then((res)=>{
        console.log(res);
        getData();
        if (res.data?.status === true) {
            // res.data?.data && setData();
            toast.open({
              type:"success",
              message:"Success",
              description: res.data?.msg || "Created Successfully."
            })
          }
          else if(res.data?.status === false && res.data?.error){
            toast.open({
              type:"error",
              message:"Error",
              description: res.data.error || "Network error."
            })
          } 
           else {
            toast.open({
              type:"warning",
              message:"Warning",
              description: res.data?.msg || "Warning."
            })
          }
       })
       .catch((err) => {
        console.log(err);
        toast.open({
          type:"error",
          message:"Error",
          description:  "Network error."
        })
      })
    },
  });
  function getData() {
    axiosInstance.get("/ref-format/purchase_request").then((res) => {
      console.log("yyoo", res?.data?.data);
      setData(res?.data?.data);
      setCreateNew({
        prefix: res?.data?.data?.prefix || "",
        displayZero: res?.data?.data?.displayZero || false,
        sufix: res?.data?.data?.sufix.length || ""
      });
    });
  }
  useEffect(() => {
    getData();
  }, []);
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setCreateNew(() => ({
      ...createNew,
        prefix: formik.values.prefix,
        sufix: formik.values.sufix,
      displayZero: checked
    }));
  };
    return(
      <form onSubmit={formik?.handleSubmit}>
      <div className="flex flex-row bg-slate-50">
      <div className="w-1/5 py-4 text-left text-sm px-4">{"Purchase Request"}</div>
      <div className="w-1/5 py-1 text-center text-sm px-4">
        <NuInputReffrence type="text" placeholder="Prefix" formik={formik} name="prefix" />
      </div>
      <div className="w-1/5 py-1 text-center text-sm px-4">
        <NuInputReffrence type="number" placeholder="Suffix" formik={formik} name="sufix" />
      </div>
      <div className="w-1/5 py-4 text-center text-sm px-4">
        <input type="checkbox" name="displayZero" checked={createNew.displayZero} onChange={handleCheckboxChange}/>
      </div>
      <div className="w-1/5 py-3 text-center text-sm px-4">
      <button className="h-7 w-7 rounded-md bg-baseColor-primary cursor-pointer px-1" type="submit"> <Save/> </button>
      </div>
    </div>
    </form>
    )
}

export default PurchaseRequest;