import React from "react";
import UseGetDashBoardData from "../../../../Components/Hooks/useDashboardData";
import { FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';
import PrFromCustomer from "./PrFromCustomer";
import QuotationToCustomer from "./QuotationToCustomer";
import SalesOrderToCustomer from "./SalesOrderToCustomer";
import PuschaseOrderToSupplier from "./PuschaseOrderToSupplier";
import GRNApproval from "./GRNApproval";
import DeliveryNotesApproval from "./DeliveryNotesApproval";
import InvoiceApproval from "./InvoiceApproval";

function Approvals() {
  const { data, loading } = UseGetDashBoardData('');

  return (
    <div className="col-span-4 bg-white max-h-[55vh] p-2 mb-5  rounded-lg shadow-lg overflow-y-auto">
      <p className="text-xs 2xl:text-sm font-medium pl-2 text-[#4F4768]">Approvals</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2 mb-4"></div>

      <div className="flex flex-wrap gap-4 overflow-y-auto">
       <PrFromCustomer/>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <QuotationToCustomer
        />
      {/* <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <SalesOrderToCustomer

        /> */}
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <PuschaseOrderToSupplier
        />
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

      {/* </div> */}

      {/* <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-4 mb-4"></div> */}

      {/* <div className="flex flex-wrap gap-4 overflow-y-auto"> */}
        <GRNApproval
        />
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

        <DeliveryNotesApproval
        />
        {/* <InvoiceApproval
        /> */}
      </div>
    </div>
  );
}

// function ApprovalCard({ title, totalPrice, approved, pending }) {
//   return (
//     <div className="flex-1 flex-wrap bg-gray-100 p-4 max-h-40 md:max-h-48 lg:max-h-60 overflow-y-scroll rounded-lg shadow-md">
//       <div className="flex items-center justify-between mb-2">
//         <p className="text-[#ACACAC] text-sm font-normal">{title}</p>
//         <p className="text-[#333333] text-[25px] 2xl:text-[32px] font-semibold">{totalPrice}</p>
//       </div>
//       <div className="flex justify-between items-center">
//         <div className="flex items-center text-sm">
//           <FaCheckCircle className="text-green-500 mr-1" />
//           <span>Approved {approved}</span>
//         </div>
//         <div className="flex items-center text-sm">
//           <FaHourglassHalf className="text-yellow-500 mr-1" />
//           <span>Pending {pending}</span>
//         </div>
//       </div>
//     </div>
//   );
// }

export default Approvals;
