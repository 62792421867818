import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import { NuUpload } from '../../../../Components/Utils/Input';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';
import { CancelButton } from '../../../../Components/Utils/Buttons';

const Importdoc = ({ show, onClose, getData }) => {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const { addFormData, contextHolder, isLoading } = useAddFormData();

  const formik = useFormik({
    initialValues: {},
    validationSchema: yup.object({}),
    onSubmit: async (values, { resetForm }) => {
      let formData = new FormData();
      console.log("sss",file);
      if (file) {
        formData.append('file', file);
      }
      // await addFormData('/customers/import/customers', formData, resetForm);
      await axiosInstance.post('/items/import/products',formData)
      .then(res => {               
        if (res.data) {
            console.log("Created", resetForm)
            resetForm();
            onClose();
            getData();
            toast.open({
              type:"success",
              message: `Imported File Added successfully`,
              // description:'',
              // placement: 'bottomRight',
          });
        }
        else {
            console.log("Failed")
            toast.open({
              type:"error",
              message: `Imported File Added failed`,
              // description: response.data.msg || 'Network error',
              // placement: 'bottomRight',
          });
        }
    }) 
    },
  });

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setFile(e.target.files[0]);
    }
  };

  if (!show) return null;

  return (
    <>
    
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[70%] max-h-[80vh] bg-white py-6 px-6 rounded-md shadow-lg overflow-y-scroll">
            <form onSubmit={formik.handleSubmit}>
              <div className="w-full">
              <NuUpload
              label="Attachment"
              width="w-full "
              onChange={handleFileChange}
            /> 
            <div className='flex gap-4 justify-center items-baseline'>
                <button
                  type="submit"
                  className={ `px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm w-[20%] rounded-lg mt-4 text-center ${isLoading ? 'bg-red-300 cursor-wait' : 'bg-baseColor-primary'} text-white`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Importing...' : 'Import'}
                </button>
                <button
  type="button"
  onClick={() => { console.log("Cancel button clicked"); onClose(); }}
  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm  text-textColor-main bg-[#E6F2F2] hover:bg-gray-300
      rounded-md flex items-center`"
>
  Cancel
</button>  </div>
              </div>
            </form>
          </div>
        </div>
     
    </>
  );
};

export default Importdoc;
    