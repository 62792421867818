import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatus from "./ApprovalStatus";
import Division from "./Division";
import ModeOfPayment from "./ModeOfPayment";
import Status from "./Status";
import Tax from "./Tax";

const InvoicesIncoming = () =>{
    return(
        <>
        <TextMediumSM content="Invoices Incoming" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Division/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ModeOfPayment/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Tax/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div> */}
         </div>
         </>
    )
}

export default InvoicesIncoming;