
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const PaymentStatus = () => {
  return (
    <SettingsMetaData propertyName={"PaymentStatus_SalesOrder"} viewPropertyName={"Payment Status"} />
  );
};

export default PaymentStatus;
