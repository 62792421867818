import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';

// Specify worker source
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ pdfFile }) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [error, setError] = useState(null); // Handle errors

//   // Callback to set number of pages once the PDF is loaded
//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//     setPageNumber(1); // Reset to the first page when a new document is loaded
//     setError(null); // Clear any previous error
//   }

//   // Callback for load error
//   function onDocumentLoadError(err) {
//     console.error("Failed to load PDF:", err);
//     setError('Failed to load PDF. Please try again.');
//   }

  return (
    <div className=' w-full h-full flex items-end'>
    {pdfFile ? (
      <iframe
        src={pdfFile} // PDF URL or Blob URL
        style={{ width: '100%', height: '96%' }}
        frameBorder="0"
      />
    ) : (
      <p>No PDF file specified.</p>
    )}
  </div>
  );
};

export default PDFViewer;
