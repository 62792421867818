import React from 'react'
import PageNotFoundImg from "../../assets/images/pageNotFound.svg";
import { BackButton, CreateButton } from './Buttons';
import { Link } from 'react-router-dom';

function PageNotFound({backbutton=true}) {
  return (
    <div className=' w-full flex justify-center items-center h-screen bg-white'>
        <div className=' w-[55%] relative'>
        <img src={PageNotFoundImg} width={"100%"}  alt='#'/>
        <p className=' absolute  bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2  text-2xl mb-6 font-medium text-gray-600   mx-auto text-center'>Page Not Found</p>
         <div className=' mx-auto flex justify-center gap-4 items-center'>
             {backbutton && <BackButton />}
            <Link 
             to={"/dashboard"}>
            <CreateButton svg={false} label='Dashboard'/>

            </Link>
         </div>
        </div>
    </div>
  )
}

export default PageNotFound