export const currencySymbol = [
    {
        label: "EUR",
        value: "€"
    },
    {
        label: "GBP",
        value: "£"
    },
    {
        label: "USD",
        value: "$"
    },
    {
        label: "JOD",
        value: "JOD"
    },
    {
        label: "KWD",
        value: "KWD"
    },
    {
        label: "LBP",
        value: "LBP"
    },
    {
        label: "AED",
        value: "AED"
    },
    {
        label: "QAR",
        value: "QAR"
    },
    {
        label: "SAR",
        value: "SAR"
    },
    {
        label: "YER",
        value: "YER"
    },
    {
        label: "INR",
        value: "₹"
    },
    {
        label: "JPY",
        value: "¥"
    },
]