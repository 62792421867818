import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MaintenceType = () => {
  return (
    <SettingsMetaData propertyName={"vehicle_mainType"} viewPropertyName={"Maintenance Type"}/>

  )
}

export default MaintenceType