import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const LeadStatus = () => {
  return (
    <SettingsMetaData propertyName={"lead_Status"} viewPropertyName={"lead Status "} />
  );
};

export default LeadStatus;
