
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const CustomerRating = () => {
  return (
    <SettingsMetaData propertyName={"customerRating_Customer"} viewPropertyName={"Customer Rating"} />
  );
};

export default CustomerRating;
