import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Damage from "./Damage";
import InspectionStatus from "./InspectionStatus";
import StorageLocation from "./StorageLocation";
import Condition from "./AssertCondition";
import Manufacturer from "./Manufacturer";
import Band from "./Band";




const Asserts = () =>{
    return(
        <>
        <TextMediumSM content="Asserts" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Condition/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Damage/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <InspectionStatus/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <StorageLocation/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Manufacturer/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Band/>
         </div>
         </div>
         </>
    )
}

export default Asserts;