// import React from 'react'
// import { TextMediumSM } from '../../../../../Components/Text/MediumText';
// import { useState } from 'react';
// import BarChartView from '../../../../../Components/Charts/BarChart';
// import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// function OrdersRecordProDash() {
//   const { data, loading } = UseGetDashBoardData('');
//         const currentYear = new Date().getFullYear();
//     const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
//     const [selectedMonth, setSelectedMonth] = useState(currentMonth);     
//         const handleMonthChange = (event) => {
//           setSelectedMonth(event.target.value);
//         };
//         const dataDummy = [
//           { name: 'Bala', amount: 400 },
//           { name: 'Clinton', amount: 300 },
//           { name: 'Shiko', amount: 500 },
//           { name: 'Alen', amount: 200 },
//           { name: 'Jojo', amount: 278 },
//         ];
//   return (
//     <div className='w-[49%] bg-white p-4 rounded-lg drop-shadow-lg h-[40vh] overflow-y-scroll'>
//         <div className="w-full rounded-md flex justify-between">
//          <TextMediumSM content={"Order Records"} color='text-[#4F4768]' />
//          <div className='w-1/6'>
//          <select id="month-select" value={selectedMonth} onChange={handleMonthChange} className='min-w-6 max-w-20 h-5 text-xs border border-slate-200 rounded-sm py-[1px] outline-none'>
//         <option value="">--Select Month--</option>
//         <option value="01">January</option>
//         <option value="02">February</option>
//         <option value="03">March</option>
//         <option value="04">April</option>
//         <option value="05">May</option>
//         <option value="06">June</option>
//         <option value="07">July</option>
//         <option value="08">August</option>
//         <option value="09">September</option>
//         <option value="10">October</option>
//         <option value="11">November</option>
//         <option value="12">December</option>
//       </select>
//          </div>
//       </div>
//       {loading ? (
//         <div className="flex justify-center items-center h-full">
//           <div className="animate-pulse space-y-4 w-full">
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//           </div>
//         </div>
//       ) : (
//         <ResponsiveContainer width="100%" height={200}>
//       <BarChart
//         layout="vertical"
//         data={dataDummy}
//         margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//         barSize={15}
//       >
//         <CartesianGrid strokeDasharray="1 1" />
//         <XAxis type="number" />
//         <YAxis type="category" dataKey="name" tick={{ fontSize: 12 }} />
//         <Tooltip />
//         <Legend />
//         <Bar dataKey="amount" fill="#8884d8" />
//       </BarChart>
//     </ResponsiveContainer>
//       )}
//     </div>
//   )
// }

// export default OrdersRecordProDash;


import React, { useState, useEffect } from 'react';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function OrdersRecordProDash() {
  const [params, setParams] = useState({ period: 'monthly' });
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/QuotesSubmittedByProcurementRecords`, params);

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }

  const dataDummy = (data?.customer_request_salesman_wise || []).map(item => ({
    name: item.procurementMember,
    amount: item.price,
  }));

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
   <div className="flex justify-between items-center mb-2">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Order Records</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-sm text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            layout="vertical"
            data={dataDummy}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            barSize={15}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" tick={{ fontSize: 12 }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default OrdersRecordProDash;
