import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Availability from './availability'
import Validity from './validity'
import LeadTime from './leadTime'
import DeliveryTerms from './deliveryTrems'
import PaymentTerms from './paymentTerms'
import PaymentMethod from './paymentMethod'
import ApprovalStatus from './ApprovalStatus'
import Status from './Status'

const SupplierQuote = () => {
    return(
        <>
        <TextMediumSM content="Supplier Quotation" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Availability/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Validity/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadTime/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentTerms/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliveryTerms/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentMethod/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default SupplierQuote