import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useParams } from 'react-router-dom';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

const RequestFromCustomer = () => {
  const [params, setParams] = useState({
    period:"monthly",

  });

  const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/purchaseRequestBySalesMen`, params);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data?.customer_request_salesman_wise) {
      const formattedData = data.customer_request_salesman_wise.map(item => ({
        name: item.salesman,
        value: item.count,
      }));
      formattedData.sort((a, b) => b.value - a.value);
      setChartData(formattedData);
    }
  }, [data]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28F88'];

  // const handlePeriodChange = (event) => {
  //   setSelectedPeriod(event.target.value);
  //   // Trigger data fetch or manipulation based on the selected period if necessary
  // };

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 pb-10 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center mb-2">
        <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Request From Customer Salesmen-wise</p>
        <div className="flex items-center">
          <select
            value={selectedPeriod}
            onChange={hanChange}
            className="border rounded-md p-1 text-sm text-[#ACACAC] font-normal"
          >
            {periods.map((period) => (
              <option key={period.value} value={period.value}>
                {period.option}
              </option>
            ))}
          </select>
        </div>
      </div>
      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-[96%]">
          <div className="w-1/2 flex justify-center items-center">
            <ResponsiveContainer width="100%" height={150}>
              <PieChart>
                <Pie
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="w-1/2 flex flex-col justify-center">
            {chartData.map((item, index) => (
              <div key={index} className="flex flex-wrap items-center text-xs mb-1">
                <span
                  className="inline-block w-2 h-2 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                ></span>
                <p className='w-1/4'>{item.name}</p>
                <p className='w-1/4'>: {item.value}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestFromCustomer;
