import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Status = () => {
  return (
    <SettingsMetaData propertyName={"Status_Supplierqoutes"} viewPropertyName={"Status"} />

  )
}

export default Status