import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Industry from "./Industry";
import Status from "./Status";
import VisitPurpose from "./VisitPurpose";


const Appoinments = () =>{
    return(
        <>
        <TextMediumSM content="Appoinments" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Industry/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VisitPurpose/>
         </div>
         </div>
         </>
    )
}

export default Appoinments;