
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const InsuranceProvider = () => {
return(
  <SettingsMetaData propertyName={"InsuranceProvider"} viewPropertyName={"Insurance Provider"} />
)
};

export default InsuranceProvider;
