import { useEffect, useState } from "react";
import { TextMediumBase, TextMediumXL } from "../../../../Components/Text/MediumText";
import axiosInstance from "../../../../Services/axiosCommon";
import EditIcon from "../../../../Components/Icons/Edit";
import { NuInput, NuInputCurrency } from "../../../../Components/Utils/Input";
import { useFormik } from "formik";
import { useToast } from "../../../../Components/Others/ToastServices";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";


const VatRate = () =>{
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const toast = useToast();
    const [vatRate, setData] = useState(0.00);
    console.log("qqqqq",vatRate);
    const GetData = async () => {
      await axiosInstance
        .get(`/vat/`)
        .then((res) => {
          console.log("data", res.data?.data);
          setCreateNew({
            vatRate: res.data?.data?.vatRate,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };  
    useEffect(() => {
      GetData();
    },[]);
    const [createNew, setCreateNew] = useState({
        vatRate: vatRate,
      });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: yup.object({
        // category: yup.string().required('Status is Required'),
        // }),
        onSubmit: async (value, { resetForm }) => {
          axiosInstance.put(`/vat/edit-vat`, value).then((res) => {
            if (res.data) {
              console.log("Created", resetForm);
              resetForm();
              closeFilterDialog();
              GetData();
              toast.open({
                type: "success",
                message: `VatRate  Added successfully`,
                // description:'',
                // placement: 'bottomRight',
              });
            } else {
              console.log("Failed");
              toast.open({
                type: "error",
                message: `VatRate Added failed`,
                // description: response.data.msg || 'Network error',
                // placement: 'bottomRight',
              });
            }
          });
        },
      });
      const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
    return(
        <>
        <div className="w-full p-3">
           <TextMediumBase content={"Vat Rate"} />
           <div className="flex justify-start gap-2">
                <form onSubmit={formik.handleSubmit}> 
                    <div className="flex mb-4">
                    <NuInputCurrency
              type="number"
              placeholder=""
              formik={formik}
              name="vatRate"
              width="w-full md:-mt-2"
            />
                 <div className="h-8 ml-2 mt-2">
                     <SubmitButton name='Save' />
                   </div>
                    </div>
                  </form>
           </div>
        </div>
        </>
    )
}

export default VatRate;

// {showFilterDialog && (            
//     <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
//       <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
//         <h2 className="text-xl mb-4">Edit Vat Rate</h2>
//         <form onSubmit={formik.handleSubmit}> 
//            <div className="flex flex-col mb-4">
//            <NuInput
//      type="number"
//      placeholder=""
//      formik={formik}
//      name="vatRate"
//      width="w-full md:-mt-2"
//    />
//            </div>
//           <div className="flex justify-end gap-2">
//             {/* <CancelButton handleClick={closeFilterDialog} /> */}
//             <SubmitButton name='Save' />
//           </div>
//          </form> 
//       </div>
//     </div>
//   )}