
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const PaymentStatus = () => {
  return (
    <SettingsMetaData propertyName={"paymentStatus_po"} viewPropertyName={"Payment Status"} />
  );
}

export default PaymentStatus;
