
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Division = () => {
  return (
    <SettingsMetaData propertyName={"Division_invoicesIncoming"} viewPropertyName={"Division"} />
  );
};

export default Division;
