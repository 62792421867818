import React, { useRef, useEffect, useState } from 'react';
import CompanyLogo from '../../../assets/images/companyLogo.png';
import CompanyName from '../../../assets/images/companyName.png';
import Seal from '../../../assets/images/seal.png';
import NuDate from '../../../Components/Utils/NuDate';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const PRPdfOther = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);

  return (
    <div
    ref={containerRef}
    className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border">
     <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className='relative w-full h-full '>
          <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
            <button onClick={() => zoomIn()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">+</button>
            <button onClick={() => zoomOut()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">-</button>
            <button onClick={() => resetTransform()} className='text-left'>Reset</button>
          </div>
      <div className="flex justify-center items-center w-full h-full">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <div className='min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black'>
                <div className='border border-black'>
                    <div className="flex justify-between items-center border-b border-black">
                      <div className='w-full'>
                        <div className='w-full flex justify-between items-center p-4'>
                          <img src={CompanyName} alt="Company Name" className='h-28' />
                          <img src={CompanyLogo} alt="Company Logo" className='h-28 w-28' />
                        </div>
                        {data?.isDraft === true ? (
                          <p className="text-center text-blue-400 pb-2 text-sm">Draft</p>
                        ) : data?.isDraft === false ? null : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="grid grid-cols-3 border-b border-black">
                      <div className='w-full p-3 flex items-center border-r border-black'>Your Ref. :</div>
                      <div className='w-full p-3 text-center text-lg font-semibold border-r border-black'>PURCHASE REQUEST</div>
                      <div className='w-full'>
                        <div className='w-full h-1/2 border-b border-black px-2 pt-1 flex'>
                          <p className='w-8'>Date </p>
                          <p className="text-xs">: {data?.date ? (
                            <NuDate value={data?.date} format="DD-MMM-YYYY" />
                          ) : (
                            "N/A"
                          )}</p>
                        </div>
                        <div className='w-full h-1/2 px-2 pt-1 flex'>
                          <p className='w-8'>PR# </p>
                          <p className="text-xs"> : {data.ref || 'N/A'}</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between px-3 py-2 border-b border-black">
                      <div className='w-[60%]'>
                        <h4 className="font-semibold text-sm">Customer</h4>
                      </div>
                      <div className='w-[40%]'>
                        <h4 className="font-semibold text-sm pl-3">Assignee</h4>
                      </div>
                    </div>
                    <div className="flex justify-between border-b border-black">
                      <div className='w-[60%] border-r border-black px-3 py-1'>
                        <table className='w-full'>
                          <tbody>
                            <tr className='w-full'>
                              <td className='w-[30%] py-1'>Name </td>
                              <td className='w-[70%] py-1'>: {data.customerName || 'N/A'}</td>
                            </tr>
                            <tr className='w-full'>
                              <td className='w-[30%] py-1'>Reference </td>
                              <td className='w-[70%] py-1'>: {data.customerRfqRef || 'N/A'}</td>
                            </tr>
                            <tr className='w-full'>
                              <td className='w-[30%] py-1'>Category </td>
                              <td className='w-[70%] py-1'>: {data.customerCat || 'N/A'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='w-[40%] px-3 py-1'>
                        <p>{data.rfqAssigneeName || 'N/A'}</p>
                      </div>
                    </div>

                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-200 text-[12px]">
                          {['S.N', 'Item Code', 'Product Name', 'Product Unit', 'Quantity'].map((header, index) => (
                            <th key={index} className="border-b border-r last:border-r-0 border-black p-2 text-left font-bold">{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.productList?.map((product, index) => (
                          <tr key={index} className="border-y border-black text-[12px]">
                            <td className="p-2">{index + 1}</td>
                            <td className="border border-black p-2">{product.item_code || 'N/A'}</td>
                            <td className="border border-black p-2">{product.product_name || 'N/A'}</td>
                            <td className="border border-black p-2">{product.product_unit || 0}</td>
                            <td className="border border-r-0 border-black p-2">{product.quantity || 0}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="mt-4 flex justify-between items-center text-[12px] px-2">
                      <div>
                        <p className="text-xs">Note:</p>
                        <p>{data.notesFromCustomer || 'N/A'}</p>
                      </div>
                      <div className="text-center">
                        <p className="text-xs">Authorized By</p>
                        {data?.authorizedBy ?
                          <img
                            src={data?.authorizedBy}
                            alt="Company Seat"
                            className="h-24 mb-2"
                          />
                          :
                          <p className='text-xs mt-3 text-red-400'>Not Authorized</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </TransformComponent>
            </div>
          </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default PRPdfOther;
