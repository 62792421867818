import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useState } from "react";
import Input, { NuInput, NuMobileNumber, NuSelect,NuDate, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import { useEffect } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];


const EditShipmentTracking = () => {
  const {value:Consignee} = useFetchMataData(`/dropdown/dropdown-details/`,'Consignee')
  const {value:CustomerClearanceStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'CustomerClearanceStatus')
  const {value:FinalDestination} = useFetchMataData(`/dropdown/dropdown-details/`,'FinalDestination')
  const {value:InsurancePremium} = useFetchMataData(`/dropdown/dropdown-details/`,'InsurancePremium')
  const {value:InsuranceProvider} = useFetchMataData(`/dropdown/dropdown-details/`,'InsuranceProvider')
  const {value:IssuedCarrier} = useFetchMataData(`/dropdown/dropdown-details/`,'IssuedCarrier')
  const {value:PortOfDischarge} = useFetchMataData(`/dropdown/dropdown-details/`,'PortOfDischarge')
  const {value:PortOfLoading} = useFetchMataData(`/dropdown/dropdown-details/`,'PortOfLoading')
  const {value:Shipper} = useFetchMataData(`/dropdown/dropdown-details/`,'Shipper')
  const {value:ShippingMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'ShippingMethod')
  const {value:VesselName} = useFetchMataData(`/dropdown/dropdown-details/`,'VesselName')
  const {value:ShippingStatus, getData: refShippingStatus } = useFetchMataData(`/dropdown/dropdown-details/`,'ShippingStatus')
  const {value:IdentityType} = useFetchMataData(`/dropdown/dropdown-details/`,'ShipIdentityType')
  const {value:VehicleType} = useFetchMataData(`/dropdown/dropdown-details/`,'ShipVehicleType')
  const { data: Agents } = UseDropDown('/agents/drop-down');
  
   const toast = useToast();
  const { data: PR } = UseDropDown('/PR/drop-down');
  // const { data: Agents } = UseDropDown("/agents/drop-down");
  // const { data: SO } = UseDropDown('/SO/drop-down');
  // const { data: DN } = UseDropDown('/DN/drop-down');


  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [image, setImage] = useState({ preview: "", raw: "" });

  const [createNew, setCreateNew] = useState({
    prId: data?.prId || "",
    agent: data?.agent || "",
    poId: data?.poId || "",
    ETD: data?.ETD || "",
    ETA: data?.ETA || "",
    permitReq: data?.permitReq || false,
    modeOfShipment: data?.modeOfShipment || "",
    bookingDate: data?.bookingDate || "",
    deliveryDatebyPO: data?.deliveryDatebyPO || "",
    shippedOn: data?.shippedOn || "",
    // estimatedArrivalDate: "",
    actualArrivalDate: data?.actualArrivalDate || "",
    shipmentBill: data?.shipmentBill || "",
    shippingMethod: data?.shippingMethod || "",
    deliveryPersonName: data?.deliveryPersonName || "",
    identityType: data?.identityType || "",
    identityNumber: data?.identityNumber || "",
    vehicleType: data?.vehicleType || "",
    vehicleNumber: data?.vehicleNumber || "",
    shipmentStatus: data?.shipmentStatus || ""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("Purchase Request is required!"),
      poId: yup.string().required("Sales Order is required!"),
      agent: yup.string().required("Agent is required!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr && finalArr.map(fileName=>{
          tempArr.push(fileName.file)
        })
        // console.log(',..df.,..',tempArr);
        value.filesToRemove = tempArr;
      } else {
        value.filesToRemove = [];
      }
      formData.append("shippingDetails", JSON.stringify(value));
      // formData.append('proofOfDelivery', image.raw);
      formData.append('insuranceCertificate', image.raw);
      // formData.append('attachments', image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      }

      editData(`/shipping/edit-shipping/${data.shippingId}`, formData, resetForm, from)

    },
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [multiImage, setMultiImage] = useState([]);


  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        toast.open({
          type:"warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setMultiImage(tempArr)
  }

  useEffect(()=>{
      if (
        data?.attachments !== null &&
        data?.attachments !== "None" &&
        data?.attachments !== "[]"
      ) {
        setFilePathList(data?.attachments);
        // console.log('image',createNew?.attachments);
        setRemoveFilePathList(data?.attachments);
        // console.log('images',filePathList);
      }
 },[data])

 function UpdateAttachmentList(item) {
  let filterList = filePathList.filter((itemList) => itemList.file !== item);
  console.log('llll',filterList);
  setFilePathList(filterList);
}

function handleImageClick(img) {
  setSelectedImage(img);
  setIsModalOpen(true);
}

  // const [SO,setSO] = useState([]);
  // const [DN,setDN] = useState([]);
  // const getSOByPR = () =>{
  //   axiosInstance
  //   .get(`/sales-order/drop-down/by-PR/${formik?.values?.prId}`)
  //   .then(res => {
  //        console.log("v",res?.data);
  //        let data = res.data;
  //           console.log(data,'dddd');
  //           let tempData = [];
  //           data.forEach((item) => {
  //               let tempObject = { value: item.value, label: item.label };
  //               tempData.push(tempObject);
  //           });
  //           res.data && setSO(tempData);
  //   }).catch((err)=>{
  //     console.log(err);
  //   })
  // }

  // const getDNBySO = () =>{
  //   axiosInstance
  //   .get(`/delivery-notes/drop-down/by-SO/${formik?.values?.prId}`)
  //   .then(res => {
  //        console.log("v",res?.data);
  //        let data = res.data;
  //           console.log(data,'dddd');
  //           let tempData = [];
  //           data.forEach((item) => {
  //               let tempObject = { value: item.id, label: item.name };
  //               tempData.push(tempObject);
  //           });
  //           res.data && setDN(tempData);
  //   }).catch((err)=>{
  //     console.log(err);
  //   })
  // }

  const [PO,setPO] = useState([]);
  const getPOByPR = () =>{
    axiosInstance
    .get(`/grn/PO/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setPO(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    if(formik?.values?.prId){
      getPOByPR();
    }
    // if(formik?.values?.poId){
    //   getDNBySO();
    // }
  },[formik?.values?.prId,formik?.values?.poId])
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
          items={
            [{
              name: "Shipment Tracking",
              link: "/shipping/shipment-tracking"
            },
            {
              name: "Edit Shipment Tracking"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          <p className="w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
              Booking Details
            </p>
            <div className="border-t border-[1px] border-slate-200/60 border-solid w-full px-4"></div>
            {/* <NuInput
              type="text"
              label="Reference No"
              placeholder="Reference No"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Booking Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="bookingDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Purchase Requests"
              placeholder="Purchase Requests"
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />

            <FormikSelectTwo
              type="text"
              label="Purchase Order"
              placeholder="Purchase Order"
              Options={PO}
              formik={formik}
              name="poId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />
            <NuDate
              label="Delivery Date By PO"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deliveryDatebyPO"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Agent"
              placeholder="Agent"
              Options={Agents}
              formik={formik}
              name="agent"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              disabled={true}
            />
            {/* <div className="relative px-6 py-2 w-full md:w-1/2 lg:w-1/3">
            <div className=" flex justify-between items-center w-full">
              <FormikSelectTwo
                type="text"
                label="Agents "
                placeholder="Agents  "
                Options={Agents}
                formik={formik}
                name="agent"
                width="w-[84%]  "
                px="0"
                isRequired={true}
              />
              <button
                onClick={togglePopup}
                type="button"
                className="w-[13%] p-1 text-center h-10 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
              >
                {" "}
                +{" "}
              </button>
            </div>
            </div> */}
            <NuDate
              label="Shipped On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shippedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Shipment Bill Number"
              placeholder="Claim Contact Info"
              formik={formik}
              name="shipmentBill"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              label="Mode Of Shipment"
              placeholder="Choose"
              Options={ShippingMethod}
              formik={formik}
              name="modeOfShipment"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              label="Permit Requred"
              placeholder="Choose"
              Options={[
                {value: true, label:"Yes"},
                {value: false, label:"No"}
              ]}
              formik={formik}
              name="permitReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <p className="w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
              Tracking Details
            </p>
            <div className="border-t border-[1px] border-slate-200/60 border-solid w-full px-4"></div>
            <NuDate
              label="Estimated Time Of Departure(ETD)"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETD"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Estimated Time Of Arrival(ETA)"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETA"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Actual Arrival Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name=""
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Shipment Status"
              placeholder="Choose"
              Options={ShippingStatus}
              formik={formik}
              name="shipmentStatus"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Delivery Person Name"
              placeholder="Delivery Person Name"
              formik={formik}
              name="deliveryPersonName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Identity Type"
              placeholder="Choose"
              Options={IdentityType}
              formik={formik}
              name="identityType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Identity Number"
              placeholder="Identity Number"
              formik={formik}
              name="identityNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Vehicle Type"
              placeholder="Choose"
              Options={VehicleType}
              formik={formik}
              name="vehicleType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Vehicle Number"
              placeholder="Vehicle Number"
              formik={formik}
              name="vehicleNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full ">
              {/* <div className=" w-[15%] "> */}
              <p className="w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]">
                Attachment
              </p>
              <div className="border-t border-[1px] border-slate-200/60 border-solid w-full px-4"></div>
              {/* <Attachement
                handleChange={handleImageChange}
                preview={multiImage || ""}
                width="w-full"
                label="Attach"
                multiple={true}
              /> */}
              {/* </div> */}
            {/* </div> */}

            {/* <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Insurance Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <NuInput
              type="text"
              label="Insurace Policy Number"
              placeholder="Insurace Policy Number"
              formik={formik}
              name="insuracePolicyNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Insurance Provider"
                placeholder="Choose"
                Options={InsuranceProvider}
                formik={formik}
                name="insuranceProvider"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refInsuranceProvider}
                displayProperty={"Insurance Provider"}
                propertyName={"InsuranceProvider"}
              />           
              <FormikSelectTwo
              label="Insurance Type"
              placeholder="Insurance Type"
              Options={[
                { value: "Personal", label: "Personal" },
                { value: "Mutual", label: "Mutual" },
              ]}
              formik={formik}
              name="insuranceType"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Coverage Start Date"
              placeholder="Coverage Start Date"
              formik={formik}
              name="coverageStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Coverage End Date"
              placeholder="Coverage End Date"
              formik={formik}
              name="coverageEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Coverage Amount"
              placeholder="Coverage Amount"
              formik={formik}
              name="coverageAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Insurance Premium"
                placeholder="Choose"
                Options={InsurancePremium}
                formik={formik}
                name="insurancePremium"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refInsurancePremium}
                displayProperty={"Insurance Premium"}
                propertyName={"InsurancePremium"}
              />
            
            <NuInput
              type="text"
              label="Claim Contact Info"
              placeholder="Claim Contact Info"
              formik={formik}
              name="claimContactInfo"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuUpload
              label="Proof Of Delivery"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            {/* <NuUpload
              label="Insurance Certificate"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
            <Attachement
                                    handleChange={handleImageChange}
                                    preview={multiImage || ""}
                                    width="w-full md:w-1/2"
                                    label="Attach"
                                    multiple={true}
                                />
            <div className="md:flex justify-between mt-5 px-3 w-full">
                            <div className="w-full flex gap-2 items-center flex-wrap pl-3">
                                {data?.attachments &&
                                    filePathList.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div
                                                    className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                                                    onClick={() => UpdateAttachmentList(imgs?.file)}
                                                >
                                                    x
                                                </div>
                                                <div className=" w-32 h-14 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} 
                                                    className="w-full h-full object-fill"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        </div>


            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditShipmentTracking;
