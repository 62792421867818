import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetDataSearch = (url, params=null) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [credit, setCredit] = useState(0);
  const [advance, setAdvance] = useState(0);

  async function getData() {
    try {
      const res = await axiosInstance.get(url, { params });
      params?.page!=="" && params?.pageCount!==""? setData(res?.data?.data?.data || []) : setData(res?.data?.data || []) ;
      params?.page!=="" && params?.pageCount!=="" ? setTotalCount(res?.data?.data?.total || 0) : setTotalCount(0)
      res.data?.credit && setCredit(res.data?.credit)
      res.data?.advance && setAdvance(res.data?.advance)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [url, params]);

  return { data, totalCount , credit, advance, getData };
};

export default UseGetDataSearch;