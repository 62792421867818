import { useState } from "react";
import ToastContext from "./ToastServices";
import CloseIcon from "../../assets/icons/Close";
import { TextMediumBase, TextMediumSM } from "../Text/MediumText";
import { TextSM } from "../Text/NormalText";
import Success from "../../assets/icons/Success";
import Warning from "../../assets/icons/Warning";
import Error from "../../assets/icons/Error";

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const open = (component, timeout = 5000) => {
    const id = Date.now();

    setToasts((toasts) => [...toasts, { id, component }]);

    setTimeout(() => close(id), timeout);
  };

  const close = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ open, close }}>
      {children}
      <div className="space-y-2 absolute bottom-4 right-4">
        {toasts.map(({ id, component }) => {
          // console.log(component);
          return (
            <div
              key={id}
              className="relative min-w-80 max-w-96 bg-white rounded-lg shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] px-4 py-3" 
            >
              <div className=" w-full flex justify-between items-center gap-3">
                <div className=" w-12 h-12  rounded-full overflow-hidden">
                    {
                        (component?.type =="success" || component?.type =="Success") && <Success/>
                    }
                    {
                        (component?.type =="error" || component?.type =="Error") && <Error/>
                    }
                    {
                        (component?.type =="warning" || component?.type =="Warning") && <Warning/>
                    }
                </div>
                <div className=" min-w-[80%] ">
                  <div className=" flex justify-between items-start">
                    <div className=" w-[85%]">
                      <TextMediumBase
                        content={component?.message || ""}
                        truncate={true}
                      />
                    </div>
                    <button
                      onClick={() => close(id)}
                      className=" p-2 rounded-lg bg-gray-600/20 text-gray-800/60"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div>
                    <TextSM content={component?.description||""} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ToastContext.Provider>
  );
}
