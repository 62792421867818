import React from 'react'

function Pdf({color="#FF4040"}) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="30" height="30" rx="5" fill={color} fill-opacity="0.1"/>
    <path d="M9 25H6.33333C6.15824 25 5.98486 24.9655 5.82309 24.8985C5.66132 24.8315 5.51434 24.7333 5.39052 24.6095C5.14048 24.3594 5 24.0203 5 23.6667V6.33333C5 5.97971 5.14048 5.64057 5.39052 5.39052C5.64057 5.14048 5.97971 5 6.33333 5H15.7813C15.9565 5.00004 16.13 5.0346 16.2918 5.10171C16.4536 5.16882 16.6006 5.26716 16.7244 5.39111L21.9431 10.6098C22.0669 10.7336 22.1651 10.8807 22.232 11.0425C22.299 11.2043 22.3334 11.3778 22.3333 11.5529V15.6667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3333 11.6667H17C16.6463 11.6667 16.3072 11.5262 16.0572 11.2761C15.8071 11.0261 15.6666 10.687 15.6666 10.3333V5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6666 24.9997V18.333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6666 18.333H12.3368C12.8673 18.333 13.376 18.5437 13.7511 18.9188C14.1261 19.2939 14.3368 19.8026 14.3368 20.333C14.3368 20.8634 14.1261 21.3721 13.7511 21.7472C13.376 22.1223 12.8673 22.333 12.3368 22.333H11.6702" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17 24.9997C17.7072 24.9997 18.3855 24.7187 18.8856 24.2186C19.3857 23.7185 19.6667 23.0403 19.6667 22.333V20.9997C19.6667 20.2924 19.3857 19.6142 18.8856 19.1141C18.3855 18.614 17.7072 18.333 17 18.333V24.9997Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3333 24.9997V19.6663C22.3333 19.3127 22.4737 18.9736 22.7238 18.7235C22.9738 18.4735 23.313 18.333 23.6666 18.333H24.9999" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3333 22.333H24.3333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default Pdf