import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Desigination from "./Desigination";
import Industry from "./Industry";
import Status from "./Status";
import SupplierCategory from "./SupplierCategory";
import SupplierType from "./SupplierType";
import VendorRating from "./VendorRating";
import VendorType from "./VendorType";


const Supplier = () =>{
    return(
        <>
        <TextMediumSM content="Supplier" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Industry/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Desigination/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VendorType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VendorRating/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <SupplierType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <SupplierCategory/>
         </div>
         </div>
         </>
    )
}

export default Supplier;