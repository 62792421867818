import React from 'react'
import { Route } from 'react-router-dom';
import DashboardLayout from '../Layouts/MainPage/Dashboard';
import Dashboard from '../Views/Web/Dashboard';
import Salesindex from '../Views/Web/Dashboard/Sales';
import ProcurmentDashboard from '../Views/Web/Dashboard/Procurments';
import StoreIndex from '../Views/Web/Dashboard/Store';
import MaintanaceIndex from '../Views/Web/Dashboard/Maintenance/MaintanaceIndex';

function DashboardRoutes() {
  return (
    <Route path="dashboard" element={<DashboardLayout />}>
        <Route index element={<Dashboard/>} />

        <Route path='sales' element={<Salesindex/>} />
        <Route path='procurement' element={<ProcurmentDashboard/>} />
        <Route path='stores' element={<StoreIndex/>} />
        <Route path='maintenance' element={<MaintanaceIndex/>} />

    </Route>
  )
}

export default DashboardRoutes;