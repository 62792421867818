
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Status = () => {
  return (
    <SettingsMetaData propertyName={"status_RFQ"} viewPropertyName={"Status"} />
  );
}

export default Status;
