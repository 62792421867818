import React, { useState } from "react";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import Templates from "./Templates";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import DataTable from "../../../../Components/Others/DataTable";
import ViewMore from "./ViewMore";

const MainTemplate = () => {
  const [page, setPage] = useState("");
  const [pageCount, setPageCount] = useState("");
  const { data, totalCount, getData } = UseGetData(
    page,
    pageCount,
    "/email_templates/"
  );

  let header = [
    {
      label: "ID",
      width: "w-[5%]",
    },
    {
      label: "Subject",
      width: "w-[27%]",
    },
    {
      label: "Message",
      width: "w-[39%]",
    },
    {
      label: "Thank Message",
      width: "w-[21%]",
    },
    {
      label: "Action",
      width: "w-[8%]",
    },
  ];


  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Template",
            },
          ]}
        />
        <div className=" flex gap-3 items-center">
        {/* <CreateButton label="Template" /> */}
        <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-hidden">
        <div className=" w-full flex justify-between items-center">
          <p className=" font-medium text-sm">Email Templates</p>
        </div>
        <div className=" w-full h-[calc(100vh-195px)] ">
        <DataTable
          headers={header}
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
          height="h-[calc(100vh-196px)]"
          bodyHeight="max-h-[calc(100vh-240px)]"
        >
          {data &&
            data.map((item, index) => (
              <tr
                className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105"
                key={index}
              >
                <td className="py-3 px-[8px] text-center w-[5%]">
                  {item?.id || "#"}
                </td>
                <td className="py-3 px-[8px] text-center w-[27%]">
                {/* <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/settings/user/view/${id}`} state={{data:item}}>               */}
                <p className=" line-clamp-2">{item?.subject || "-"}</p>
                 {/* </Link> */}
 
                </td>
                <td className="py-3 px-[8px]  text-center w-[39%]">
                  <p className=" line-clamp-2">{item?.message || "-"}</p>
                </td>
                <td className="py-3 px-[8px] text-center w-[21%]">
                <p className=" line-clamp-2">{item?.thanksMessage || "-"}</p>
                </td>
                <td className="py-3 px-[8px] text-center w-[8%]">
                  {/* <MoreOptions
                    data={item}
                    fetcher={getData}
                    id={item?.userId|| ""}
                    removevalue={item?.name || "undefined"}
                    url={"/user/remove-user/"}
                    moduleName="User"
                    from={"/settings/user/"}
                    editLink={"/settings/user/edit/"}
                    viewLink={"/settings/user/view/"}
                   /> */}
                   <ViewMore  getData={getData}  data={item} />
                </td>
              </tr>
            ))}
        </DataTable>
        </div>
      </div>
    </div>
  );
};

export default MainTemplate;
