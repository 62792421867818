import Moment from 'moment';

export default function NuDate(props) {
  //add string
  // d=>days
  // Y=>year
  // M=>months
  // w=>weeks
  // h=>hours
  // m=>minuites
  // s=>seconds

  const { format, add, addString } = props;
  if (!props.value) {
    return '';
  }

  return Moment(props.value)
    .add(add ? add : 0, addString ? addString : 'd')
    .format(`${format ? format : 'DD-MM-YYYY'}`);
}
