
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Manufacture = () => {
  return (
    <SettingsMetaData propertyName={"Manufacture_item"} viewPropertyName={"Manufacture"} />
  );
};

export default Manufacture;
