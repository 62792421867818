
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const VendorType = () => {
  return (
    <SettingsMetaData propertyName={"vendorType_supplier"} viewPropertyName={"Vendor Type"} />
  );
};

export default VendorType;
