import React from 'react'
import { BackButton } from '../../../../Components/Utils/Buttons'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import EmailServer from './EmailServer'

const MainEmailServerdetails = () => {
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Email Server",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-scroll">
        <div className=" w-full flex justify-between items-center">
<EmailServer/>
          </div>
          </div>
          </div>
   )
}

export default MainEmailServerdetails