import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuDate,
  NuInput,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const CreateRefunds = () => {
  const {value:Method ,getData:refMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'Refund_Method')
  const {value:Reason ,getData:refReason} = useFetchMataData(`/dropdown/dropdown-details/`,'Refund_Reason')


  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    invoiceId: "",
    refundMethod: "",
    refundAmount: "",
    date: "",
    customerId: "",
    comments: "",
    trackingNo: "",
    reason: "",
    reviewedBy: "",
    approvedBy: "",
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
        });
    }
};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("refundDetails", JSON.stringify(value));
      formData.append('attachments', image.raw);
      addFormData("/refunds/new-refund", formData, resetForm);
    },
  });

  const { data: invoiceIncomming } = UseDropDown('/invoices-incoming/drop-down');
  const { data: customer } = UseDropDown('/customers/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
   
        <Breadcrumb
         items={
          [
          { 
            name: "Payment",
            link: "/payments/refunds"
         },
          {
            name: "Refunds",
            link: "/payments/refunds"
          },
          {
            name: "Create Refunds"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              label="Invoice"
              placeholder="Choose"
              Options={invoiceIncomming}
              formik={formik}
              name="invoiceId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Customer"
              placeholder="Choose"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Refund Method"
                placeholder="Choose"
                Options={Method}
                formik={formik}
                name="refundMethod"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refMethod}
                displayProperty={"Refund Method"}
                propertyName={"Refund_Method"}
              />
           
            <NuInput
              type="number"
              label="Refund Amount"
              placeholder="Refund Amount"
              formik={formik}
              name="refundAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Tracking No"
              placeholder="trackingNo"
              formik={formik}
              name="trackingNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Reason"
                placeholder="Choose"
                Options={Reason}
                formik={formik}
                name="reason"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refReason}
                displayProperty={"Reason"}
                propertyName={"Refund_Reason"}
              />
            
            {/* <FormikSelectTwo
              label="Approved By"
              placeholder="Choose"
              Options={user}
              formik={formik}
              name="approvedBy"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Reviewed By"
              placeholder="Choose"
              Options={user}
              formik={formik}
              name="reviewedBy"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateRefunds;
