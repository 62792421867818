import React, { useEffect, useState } from 'react';
import { CancelButton, CreateButton, FilterButton, Submit, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { NuInput, NuSelect } from '../../../../Components/Utils/Input';
import { useFormik } from 'formik';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';


function CatalogueIndex() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    category:"",
    subCategory:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    category:"",
    subCategory:""
  });

  const clearFilter = ()=> {
    setParams({...params,category:"", subCategory:""})
      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category: "",
        subCategory: ""
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch('/catalogues/', params);

  const availableHeaders = [
    {
            title: "ID",
            width: "25%",
            field: "catalogueId",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Category",
            width: "25%",
            field: "category",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Sub-Category",
            width: "25%",
            isLink: true,
            id: "catalogueId",
            link: '/products/catalogues/view/',
            field: "subCategory",
            textAlign: "left",
            rowAlign: "left",
          },
          {
            title: "Actions",
            width: "25%",
            field: "actions",
            textAlign: "left",
            rowAlign: "left",
            contents:{
              // fetcher:getData,
              id:"catalogueId",
              removeValue:"subCategory",
              url:`/catalogues/`,
              moduleName:"Catalogues",
              from:`/products/catalogues`,
              editLink:"/products/catalogues/edit/",
              viewLink:"/products/catalogues/view/"
      
            }
          },
  ];

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const { category: Category, subCategory: SubCategory } = UseCatSubCatDropDown('/category/Category', "Category", formik?.values?.category);

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Products" },
            { name: "Catalogues" },
          ]} />
        <div className='flex gap-2 items-center'>
          <SearchBar handleChange={(e) => setParams({...params,search:e.target.value})} />
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
          <CreateButton label='Import' />
          <Link to="/products/catalogues/create"><CreateButton label='Catalogues' /></Link>
        </div>
      </div>
      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={availableHeaders}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Catalogues List'
        storageName="CataloguesTable"
      />
     
      {showFilterDialog && (
             
             
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Filter Catalogues</h2>
            <form onSubmit={formik.handleSubmit}> 
               <div className="flex flex-col mb-4">
                 <FormikSelectTwo 
                   label="Category"
                   placeholder="Choose"
                   Options={Category}
                   formik={formik}
                   name="category"
                   width="w-full"
                 />
                 <FormikSelectTwo 
                   label="Sub Category"
                   placeholder="Choose"
                   Options={SubCategory}
                   formik={formik}
                   name="subCategory"
                   width="w-full"
                 />
               </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name='Apply' />
              </div>
             </form> 
          </div>
        </div>
      )}

    </div>
  )
}

export default CatalogueIndex;


