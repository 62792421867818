import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const AccountType = () => {
  return (
    <SettingsMetaData propertyName={"AccountType__Recevable"} viewPropertyName={"AccountType"} />

  )
}

export default AccountType