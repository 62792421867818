import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Category from "./Category";
import CustomerRating from "./CustomerRating";
import CustomerType from "./CustomerType";
import Desigination from "./Desigination";
import Industry from "./Industry";
import Status from "./Status";


const Customer = () =>{
    return(
        <>
        <TextMediumSM content="Customer" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Industry/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Desigination/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CustomerRating/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CustomerType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Category/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
        
       
         </div>
         </>
    )
}

export default Customer;