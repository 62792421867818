
const CircleXIcon = ({ color = "red", size = 13 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" fill="none" />
      <path
        d="M9 9l6 6M15 9l-6 6"
        stroke={color}
        strokeWidth="2"
        fill="none"
      />
    </svg>
  );
  
  export default CircleXIcon;
  