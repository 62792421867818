import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
// import { Navbar, SideBar } from "./Layouts/MainPage";
// import { SidebarItem } from "./Layouts/MainPage/SideBar";
import { useTranslation } from "react-i18next";
import SettingsSidebar, { SettingsSidebarItem } from "../../Layouts/MainPage/SettigsSideBar";
import { Navbar } from "../../Layouts/MainPage";
import Maintanance from "../../Components/Icons/Maintanance";
import Shipping from "../../Components/Icons/Shipping";
import Store from "../../Components/Icons/Store";
import Task from "../../Components/Icons/Task";
import Payments from "../../Components/Icons/Payments";
import Sales from "../../Components/Icons/Sales";
import Procurement from "../../Components/Icons/Procurement";
import Marketing from "../../Components/Icons/Marketing";
import Products from "../../Components/Icons/Products";
import Staff from "../../Components/Icons/Staff";
import UserRole from "../../Components/Icons/UserRole";
import Role from "../../Components/Icons/Role";
import BellIcon from "../../Components/Icons/BellIcon";
import { GetAccess } from "../../Components/Utils/roles";
import Email from "../../Components/Icons/Email";
import Template from "../../Components/Icons/Template";

const MainSettings = () => {
  const location = useLocation();

  const {t} = useTranslation();
  const navItem = [
    {
      icon: <Products/>,
      title: "Company Info",
      link:'/settings',
      hasAccess:"company_info_view"

    },
    {
      icon: <Products/>,
      title: "Products",
      link:'/settings/products',
      hasAccess:"product_view"

    },
    {
      icon: <Marketing/>,
      title: "Marketing",
      link:'/settings/marketing',
      hasAccess:"marketing_view"

    },
    {
      icon: <Procurement/>,
      title: "Procurement",
      link:'/settings/procurement',
      hasAccess:"procurement_view"

    },
    {
      icon: <Sales/>,
      title: "Sales",
      link:'/settings/sales',
      hasAccess:"sales_view"

    },
    {
      icon: <Payments/>,
      title: "Payments",
      link:'/settings/payments',
      hasAccess:"payment_view"

    },
    // {
    //   icon: <Task/>,
    //   title: "Tasks",
    //   link:'/settings/tasks',
    //   hasAccess:"company_info_view"

    // },
    {
      icon: <Store/>,
      title: "Store",
      link:'/settings/store',
      hasAccess:"store_view"
      
    },
    {
      icon: <Shipping/>,
      title: "Shipping",
      link:'/settings/shipping',
      hasAccess:"shipping_view"

    },
    {
      icon: <Maintanance/>,
      title: "Maintenance",
      link:'/settings/maintenance',
      hasAccess:"maintenance_view"
    },
    {
      icon: <Staff/>,
      title: "User",
      link:'/settings/user',
      hasAccess:"user_view"
    },
    {
      icon: <Role/>,
      title: "Role",
      link:'/settings/role',
      hasAccess:"role_view"
    },
    {
      icon: <UserRole/>,
      title: "User Role",
      link:'/settings/user-role',
      hasAccess:"user_role_view"
    },
    {
      icon: <BellIcon color = "#999999"/>,
      title: "Notifications",
      link:'/settings/notifications',
      hasAccess:"notification_view"
    },
    {
      icon: <UserRole/>,
      title: "Reference",
      link:'/settings/reffrence',
      hasAccess:"reference_view"
    },
    {
      icon: <UserRole/>,
      title: "Currency And Vat",
      link:'/settings/currency-vat',
      hasAccess:"reference_view"
    },
    {
      icon: <UserRole/>,
      title: "Seal And Signature",
      link:'/settings/sealandsignature',
      hasAccess:"reference_view"
    },
    {
      icon: <UserRole/>,
      title: "Status Permission",
      link:'/settings/statuspermission',
      hasAccess:"reference_view"
    },
    {
      icon: <Email />,
      title: "Email Server",
      link:'/settings/emailserverdetails',
      hasAccess:"reference_view"
    },
    {
      icon: <Template />,

      title: "Template ",
      link:'/settings/template',
      hasAccess:"reference_view"
    }
  ];



  function isActiveFun(x){
    let actualPath = location.pathname
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         linkPath = y
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }

  return (
    <div className=" w-full h-screen bg-slate-100 flex justify-start items-center overflow-hidden">
      <SettingsSidebar>
        {navItem &&
          navItem.map((item) => {
            return (
              <>
              {
                GetAccess(item?.hasAccess) && (
                  <SettingsSidebarItem
                  icon={item?.icon}
                  text={t(item?.title)}
                  hasSubMenu={item?.hasSubMenu}
                  subItem={item?.subMenu}
                  link={item?.link}
                  active={isActiveFun(item)}
                  //  alert = "test"
                />
                )
              }
              </>
            );
          })}
      </SettingsSidebar>

      <div className=" w-full h-[100vh]  ">
        <div className=" w-full h-[59px] bg-white z-50 shadow-lg">
          <Navbar />
        </div>
        <div className=" w-full h-[calc(100vh-87px)]  overflow-y-auto">
          <Outlet />
        </div>
        <div className=" w-full h-7 ">
          <p className=' font-semibold text-center text-s text-slate-400'>
            <a
             href="https://numota.in"
             target="_blank"
            >
              &#169;  numota
            </a>
            </p>
        </div>
      </div>
    </div>
  );
};

export default MainSettings;
