import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const DeliveryTerms = () => {
  return (
    <SettingsMetaData propertyName={"DeliveryTerms_Supplierqoutes"} viewPropertyName={"Delivery Terms"} />

  )
}

export default DeliveryTerms