import React, { useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import MainVehicle from "../Views/Web/Maintenance/Vehicle/MainVehicle";
import VehicleIndex from "../Views/Web/Maintenance/Vehicle";
import CreateVehicle from "../Views/Web/Maintenance/Vehicle/CreateVehicle";
import EditVehicle from "../Views/Web/Maintenance/Vehicle/EditVehicle";
import ViewVehicle from "../Views/Web/Maintenance/Vehicle/ViewVehicle";
import MainMaintenanace from "../Views/Web/Maintenance/MainMaintenanace";
import MainEquipments from "../Views/Web/Maintenance/Equipment/MainEquipments";
import EquipmentIndex from "../Views/Web/Maintenance/Equipment";
import CreateEquipments from "../Views/Web/Maintenance/Equipment/CreateEquipments";
import EditEquipments from "../Views/Web/Maintenance/Equipment/EditEquipments";
import ViewEquipments from "../Views/Web/Maintenance/Equipment/ViewEquipments";
import { Wrapper } from "../Components/Utils/roles";
import CreateVehicleMaintence from "../Views/Web/Maintenance/Vehicle/CreateVehicleMaintence";
import EditVehicleMaintence from "../Views/Web/Maintenance/Vehicle/EditVehicleMaintence";
import EditEquipmentMaintenance from "../Views/Web/Maintenance/Equipment/EditEquipmentMaintenance";
import CreateEquipmentMaintenance from "../Views/Web/Maintenance/Equipment/CreateEquipmentMaintenance";

const MaintenanceRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/maintenance" ||
      location.pathname === "/maintenance/") {
      navigate("/page-not-found")
    }
  },[location])
  return (
    <Route path="maintenance" element={<MainMaintenanace />}>
      <Route path="vehicle" element={<MainVehicle />}>
        <Route index element={< Wrapper accessKey={"vehicle_view"}  Component={VehicleIndex} /> } />
        <Route path="create" element={< Wrapper accessKey={"vehicle_create"}  Component={CreateVehicle} />} />
        <Route path="edit/:vehicleEditId" element={< Wrapper accessKey={"vehicle_edit"}  Component={EditVehicle} />} />
        <Route path="view/:vehicleViewId" element={< Wrapper accessKey={"vehicle_view"}  Component={ViewVehicle} />} />
        <Route path="add/:vehicleId" element={< Wrapper accessKey={"vehicle_create"}  Component={CreateVehicleMaintence} />}/>
       <Route path="edit-maintenance/:maintenceId" element={< Wrapper accessKey={"vehicle_edit"}  Component={EditVehicleMaintence} />}/>

      </Route>
      <Route path="equipment" element={<MainEquipments />}>
        <Route index element={< Wrapper accessKey={"equipment_view"}  Component={EquipmentIndex} />} />
        <Route path="create" element={< Wrapper accessKey={"equipment_create"}  Component={CreateEquipments} />} />
        <Route path="edit/:equipmentEditId" element={< Wrapper accessKey={"equipment_edit"}  Component={EditEquipments} />} />
        <Route path="view/:equipmentViewId" element={ < Wrapper accessKey={"equipment_view"}  Component={ViewEquipments} />} />
        <Route path="add/:equipmentId" element={< Wrapper accessKey={"equipment_create"}  Component={CreateEquipmentMaintenance} />}/>
       <Route path="edit-equipment/:equipmentMaintenanceId" element={< Wrapper accessKey={"equipment_edit"}  Component={EditEquipmentMaintenance} />}/>

      </Route>
    </Route>
  );
};

export default MaintenanceRoutes;
