
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Status = () => {
 
  return (
    <SettingsMetaData propertyName={"Status_Customer"} viewPropertyName={"Status"} />
  );
};
export default Status;
