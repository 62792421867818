import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetDashBoardWithParams = (url, params) => {
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    try {
      await axiosInstance.get(url, params).then(res=>{
        setLoading(false);
        setData(res?.data?.data || []) ;
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [params]);

  return { data,loading};
};

export default UseGetDashBoardWithParams;
