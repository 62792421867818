import React from 'react'
import { TextMediumSM } from '../../../../Components/Text/MediumText'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton, CancelButton, CreateButton, SubmitButton } from '../../../../Components/Utils/Buttons'

function MainNotification() {


  function handleChange(e,value){
    console.log("e",e,value)
  }

  const notiValue =[
    {
      name: "Catalogues",
    },
    {
      name: "Items",
    },
    {
      name: "Appointments",
    },
    {
      name: "Leads",
    },
    {
      name: "Purchase Requests",
    },
    {
      name: "RFQ",
    },
    {
      name: "Supplier Quotes",
    },
    {
      name: "Quote Comparison",
    },
    {
      name: "Purchase Order",
    },
    {
      name: "Contracts",
    },
    {
      name: "Suppliers",
    },
    {
      name: "Customers",
    },
    {
      name: "Invoices Incoming",
    },
    {
      name: "Invoices Outgoing",
    },
    {
      name: "Promotions",
    },
    {
      name: "Sales Orders",
    },
    {
      name: "Quotes",
    },
    {
      name: "Payables",
    },
    {
      name: "Receivables",
    },
    {
      name: "Refunds",
    },
    {
      name: "My Tasks",
    },
    {
      name: "All Tasks",
    },
    {
      name: "Assets",
    },
    {
      name: "Inventory",
    },
    {
      name: "GRN",
    },
    {
      name: "Delivery Note",
    },
    {
      name: "Returns",
    },
    {
      name: "Packing List",
    },
    {
      name: "Requests",
    },
    {
      name: "Agents",
    },
    {
      name: "Shipment Tracking",
    },
    {
      name: "Vehicle",
    },
    {
      name: "Equipment",
    },
    {
      name: "Chat",
    },
    {
      name: "Team",
    },
  ]

  return (
    <div className="w-full h-full px-4 py-2">
    <div className="w-full flex justify-between items-center">
      <Breadcrumb
        items={[
          {
            name: "Settings",
          },
          {
            name: "Notifications",
          },
        ]}
      />
      <BackButton />
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-scroll">
      <div className=" w-full flex justify-between items-center">
        <TextMediumSM content="Notifications List" />
      </div>
      <table className=' w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2 '>
       <thead>
       <tr className=''>
          <th className=' w-[33.3%] text-left text-sm font-medium py-2 px-4'>Module Name</th>
          <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Email Notification</th>
          <th className=' w-[33.3%] text-center text-sm font-medium py-2 px-4'>Push Notification</th>
        </tr>
       </thead>
       <tbody>
        {
          notiValue && notiValue.map((value, index) => {
            return (
              <tr className='even:bg-white odd:bg-slate-50 ' key={index}>
              <td className=' w-[33.3%] py-2 text-left text-sm px-4'>{value?.name}</td>
              <td className=' w-[33.3%] py-2 text-center text-sm px-4'>
                <input type='checkbox' onChange={(e)=>handleChange(e,value?.name)} />
              </td>
              <td className=' w-[33.3%] py-2 text-center text-sm px-4'>
              <input type='checkbox' onChange={(e)=>handleChange(e,value?.name)} />
              </td>
            </tr>
            )
          })
        }
       </tbody>
      </table>
      <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => {  }} />
                <CreateButton svg={false} label='Submit' handleClick={()=>{}} />
              </div>
            </div>
    </div>
  </div>
  )
}

export default MainNotification