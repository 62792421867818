import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function RequestFromCustomerProcuDash() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/purchaseRequest`);
 const percentage = parseFloat(data?.selected_month_percentage) || 0;
  // const percentage = parseFloat(-87.6 ||0)
  const isNegative = percentage < 0;

  return (
    <div className=" bg-white p-4 rounded-lg drop-shadow-lg max-h-[25vh] w-full">
    {/* <div className="flex  mb-4"> */}
    <div className="flex ">
    <div className="bg-baseColor-teritary rounded-full p-2 2xl:p-3 h-[18%]">
          {/* <svg width="40" height="40" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="vuesax/linear/profile-2user">
                    <g id="profile-2user">
                        <path id="Vector" d="M16.0302 19.0229C15.8552 19.0054 15.6452 19.0054 15.4527 19.0229C11.2877 18.8829 7.98022 15.4704 7.98022 11.2704C7.98022 6.98287 11.4452 3.50037 15.7502 3.50037C20.0377 3.50037 23.5202 6.98287 23.5202 11.2704C23.5027 15.4704 20.1952 18.8829 16.0302 19.0229Z" stroke="#048178" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
                        <path id="Vector_2" d="M28.7171 6.99963C32.1121 6.99963 34.8421 9.74713 34.8421 13.1246C34.8421 16.4321 32.2171 19.1271 28.9446 19.2496C28.8046 19.2321 28.6471 19.2321 28.4896 19.2496" stroke="#048178" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
                        <path id="Vector_3" d="M7.2805 25.4796C3.0455 28.3146 3.0455 32.9346 7.2805 35.7521C12.093 38.9721 19.9855 38.9721 24.798 35.7521C29.033 32.9171 29.033 28.2971 24.798 25.4796C20.003 22.2771 12.1105 22.2771 7.2805 25.4796Z" stroke="#048178" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
                        <path id="Vector_4" d="M32.0947 35.0004C33.3547 34.7379 34.5447 34.2304 35.5247 33.4779C38.2547 31.4304 38.2547 28.0529 35.5247 26.0054C34.5622 25.2704 33.3897 24.7804 32.1472 24.5004" stroke="#048178" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </g>
            </svg> */}
<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="#048178" viewBox="0 0 512 512"><path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
          </div>
          <div className="pl-2 flex flex-col gap-1 h-[96%]">
          <p className='text-[#4F4768] text-xs 2xl:text-sm font-semibold '>Request From Customer</p>
          <p className='text-[#333333] text-[25px]  font-semibold '>{data?.total_count || 0}</p>
          <div className='flex flex-wrap'>
                  <p className='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 20" fill="none">
                <path d={`M10 ${isNegative ? '17L10 5' : '5L10 17'}`} stroke={isNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d={`M${isNegative ? '15.8337 10.0001L10.0003 15.8334L4.16699 10.0001' : '4.16699 10.0001L10.0003 4.16675L15.8337 10.0001'}`} stroke={isNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className={`text-${isNegative ? 'red-500' : '[#048178]'} text-[10px] 2xl:text-xs`}> {isNegative? percentage * -1 : percentage } %</span>
         
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 20" fill="none">
                      <path d="M10 17L10 5" stroke="#048178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M4.16699 10.0001L10.0003 4.16675L15.8337 10.0001" stroke="#048178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> 
                    <span className='text-[#048178] text-xs'>{data?.selected_month_percentage}</span> */}
                  <p className='pl-[2px] text-xs'>this month</p>
                  
                   </p>
                </div> 
          </div>          
          </div>
          </div>
  )
}

export default RequestFromCustomerProcuDash;