import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import {
  NuInput,
  NuMobileNumber,
  NuTextArea,
  NuUpload,
  SupplierNuInput,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
import { CountryMIN } from "../../../../assets/CountryMini";
import DropDownAddCurrency from "../../../../Components/Utils/selectAddCurrencyDropDown";
import UseFetchCurrency from "../../../../Components/Hooks/useFetchCurrencyData";

function CreateSuppliers() {
  const {value:Industry ,getData:refIndustry} = useFetchMataData(`/dropdown/dropdown-details/`,'industry_Supplier')
  const {value:desigination ,getData:refdesigination} = useFetchMataData(`/dropdown/dropdown-details/`,'desigination_Supplier')
  const {value:vendorType ,getData:refvendorType} = useFetchMataData(`/dropdown/dropdown-details/`,'SupplierType')
  const {value:supplierCat ,getData:refsupplierCat} = useFetchMataData(`/dropdown/dropdown-details/`,'SupplierCategory')
  const { value:vendorRating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'vendorRating_supplier')

  const [image, setImage] = useState({ preview: "", raw: "" });
  const [productList, setProductList] = useState([]);
  const productsString = productList.join(', ');
  const [servicesList, setServicesList] = useState([]);
  const servicesString = servicesList.join(', ');
  const [emailsList,setEmailsList] = useState([]);
  const emailsString = emailsList.join(', ');
  // console.log("find",productsString);
  // const [division, setDivision] = useState([]);
  let { value: currency, getData: refCurrency } = UseFetchCurrency("/currency/drop-down");
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    contactPerson: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    designation: "",
    buildingNo: "",
    contactPersonEmail: "",
    contactPersonMobile: "",
    taxId: "",
    performanceRating: "",
    comments: "",
    streetName: "",
    country: "",
    city: "",
    state: "",
    district: "",
    postalCode: "",
    cpCountryCode: "",
    products: "",
    services: "",
    address1: "",
    address2: "",
    currencyId: "",
    emails: ""
  });
  console.log("list",productList);
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .min(9, "Phone number must be at least 9 digits")
      .max(10, "Phone number must be at most 10 digits")
      .required("Phone number is required"),
      email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      const valueWithProducts = {
        ...value,
        products: productsString,
        services: servicesString,
        emails: emailsString
      };
      formData.append("vendorDetails", JSON.stringify(valueWithProducts));
      formData.append("vendorImage", image.raw);

      addFormData("/vendors/new-vendor", formData, resetForm);
    },
  });

  const handleAddProducts = () => {
    const products = formik.values.products.split(',').map((p) => p.trim()).filter(Boolean);
    setProductList((prevList) => [...prevList, ...products]);
    formik.setFieldValue('products', '');
  };

  const handleDeleteProduct = (index) => {
    setProductList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleAddServices = () => {
    const services = formik.values.services.split(',').map((p) => p.trim()).filter(Boolean);
    setServicesList((prevList) => [...prevList, ...services]);
    formik.setFieldValue('services', '');
  };

  const handleDeleteServices = (index) => {
    setServicesList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleAddEmails = () => {
    const emails = formik.values.emails.split(',').map((p) => p.trim()).filter(Boolean);
    setEmailsList((prevList) => [...prevList, ...emails]);
    formik.setFieldValue('emails', '');
  };

  const handleDeleteEmails = (index) => {
    setEmailsList((prevList) => prevList.filter((_, i) => i !== index));
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
     <Breadcrumb
          items={[
            {
              name: "Suppliers",
              link: "/procurement/suppliers",
            },
            {
              name: "Create-Suppliers",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
            {/* <FormikSelectTwo
                      type="text"
                      label="Customer Type"
                      placeholder="Customer Type"
                      options={[
                        {
                           value: "option" , label: "option"
                        }
                      ]}
                      formik={formik}
                      name="products"
                       width="w-full md:w-1/2 lg:w-1/3"
                    />  */}
                <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Supplier Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

                <NuInput
              type="text"
              label="Company Name"
              placeholder="company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
           <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />         

            <NuInput
              type="text"
              label="website"
              placeholder="website"
              formik={formik}
              name="website"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Designation"
                placeholder="Choose"
                Options={desigination}
                formik={formik}
                name="designation"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refdesigination}
                displayProperty={"Designation"}
                propertyName={"desigination_Supplier"}
              />

              <DropDownAddMetaData
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={formik}
                name="vendorType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refvendorType}
                displayProperty={"Type"}
                propertyName={"SupplierType"}
              />

              <DropDownAddMetaData
                label="Category"
                placeholder="Choose"
                Options={supplierCat}
                formik={formik}
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refsupplierCat}
                displayProperty={"Category"}
                propertyName={"SupplierCategory"}
              />
            
            <NuInput
              type="text"
              label="Contact Person"
              placeholder="contact Person"
              formik={formik}
              name="contactPerson"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuMobileNumber
              nunCode="cpCountryCode"
              label="Contact Person Mobile"
              placeholder="Contact Person Mobile"
              formik={formik}
              name="contactPersonMobile"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuInput
              type="email"
              label="Contact Person Email"
              placeholder="Contact Person Email"
              formik={formik}
              name="contactPersonEmail"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              Options={vendorRating}
              label="Performance Ratings"
              placeholder="Performance Ratings"
              formik={formik}
              name="performanceRating"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <FormikSelectTwo
                  label="Currency"
                  placeholder="Choose"
                  Options={currencySymbol}
                  formik={formik}
                  name="currency"
                  width="w-full md:w-1/2 lg:w-1/3"
                /> */}
             {/* <FormikSelectTwo
             
                Options={[
                  {
                    value: "vendorType",
                    label: "Credit, Advance",
                  },
                  {
                    value: "vendorCategory",
                    label: "IT, Civil, Mech, Electronics, Electrical",
                  },
               
               
              ]}
              label="Category Type"
              placeholder="Category Type"
              formik={formik}
              name="categoryType"
              width="w-full md:w-1/2 lg:w-1/3"
            />       */}
          
          
            <NuInput
              type="number"
              label="Tax Id"
              placeholder="Tax Id"
              formik={formik}
              name="taxId"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <DropDownAddCurrency
              label="Currency"
              placeholder="Choose"
              Options={currency}
              formik={formik}
              name="currencyId"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refCurrency}
            />
           
           <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Address</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
        
            <NuTextArea
              type="text"
              label="Address Line 1"
              placeholder="Address Line 1"
              formik={formik}
              name="address1"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              type="text"
              label="Address Line 2"
              placeholder="Address Line 2"
              formik={formik}
              name="address2"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              Options={CountryMIN}
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="state"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="district"
              formik={formik}
              name="district"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="city"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="text"
              label="Street Name"
              placeholder="Street Name"
              formik={formik}
              name="streetName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
               <NuInput
              type="number"
              label="Building No"
              placeholder="Building No"
              formik={formik}
              name="buildingNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Postal Code"
              placeholder="postalCode"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuUpload
              label="Vendor Image"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />

            {/* <NuInput
                      type="text"
                      label="Contact Person Desigation"
                      placeholder="Contact Person Desigation"
                      formik={formik}
                      name="cpDesigation"
                       width="w-full md:w-1/2 lg:w-1/3"
                    /> */}

            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           <p className='w-full  pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Products , Services & Emails</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

           <div className="flex flex-col md:flex-row justify-start gap-2"> 

            <div className="w-full flex flex-col ">
              <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Products"
                placeholder="seprate products with ','"
                formik={formik}
                name="products"
                width="w-11/12"
              />
              <button
                onClick={handleAddProducts}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div>
              <div className="w-11/12 h-28 ml-[8%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {productList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteProduct(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col ">
              <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Services"
                placeholder="seprate services with ','"
                formik={formik}
                name="services"
                width="w-11/12"
              />
              <button
                onClick={handleAddServices}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div>
              <div className="w-11/12 h-28 ml-[8%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {servicesList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteServices(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col ">
              <div className="flex justify-start">
              <SupplierNuInput
                type="email"
                label="Emails"
                placeholder="seprate emails with ','"
                formik={formik}
                name="emails"
                width="w-11/12"
              />
              <button
                onClick={handleAddEmails}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                >
                +
               </button>
               </div>
              <div className="w-11/12 h-28 ml-[8%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {emailsList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteEmails(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>
            </div>
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateSuppliers;
