import { useState, useMemo } from 'react';
import axiosInstance from '../../../../../Services/axiosCommon';

function useQuotProducts(prId, vendorId) {
  const [quotProducts, setQuotProducts] = useState([]);

  useMemo(() => {
    if (prId !== "" && vendorId !== "") {
      axiosInstance
        .get(`/supplier-quotations/quot-products/drop-down/by-vendor/${prId}/${vendorId}`)
        .then(res => {
          let data = res.data.data;
          setQuotProducts(data);
        })
        .catch(err => {
          console.log(err, "err");
        })
    }
        else {
            setQuotProducts([]);
          }
    }
  , [prId, vendorId]);

  return quotProducts;
}

export default useQuotProducts; 