import { useState, useEffect } from "react";
import { useCurrency } from "../../Context/CurrencyContext";

function NuAmount({ currency = "", value = 0 }) {
    const { currencySymbol } = useCurrency();
    const [locale, setLocale] = useState('en-US'); // You can make locale dynamic if needed

    useEffect(() => {
        // Logic to set locale dynamically if required
        // Example: setLocale(someDynamicLocale);
    }, []);

    const formatValue = (val) => {
        const parsedValue = parseFloat(val);
        if (isNaN(parsedValue)) return '0.00'; // Fallback if parsing fails

        return parsedValue.toLocaleString(locale, { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        });
    };

    return (
        <span>
            {`${currency || currencySymbol || ""} ${formatValue(value)}`}
        </span>
    );
}

export default NuAmount;
