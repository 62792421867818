import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import CurrencyIndex from "./CurrencyIndex";
import VatRate from "./VatRate";



  
  
  const MainCurrencyAndVatSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Currency And Vat"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
        <div className='w-full flex flex-col flex-wrap justify-center md:justify-start  items-center md:items-start overflow-y-scroll'>
          <div className=' w-full md:w-1/2 mt-4'>
            <CurrencyIndex/>
          </div>
          <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VatRate/>
          </div>
        </div>
        </div>
        </div>
    )
  }

  export default MainCurrencyAndVatSettings;