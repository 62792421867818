import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const Manufacture = () => {
  return (
    <SettingsMetaData propertyName={"EquipmentManufacturer"} viewPropertyName={"Equipment Manufacturer"}/>
  );
};

export default Manufacture;
