import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons'
import { NuInput, NuTextArea } from '../../../../Components/Utils/Input'
import { TextMediumBase } from '../../../../Components/Text/MediumText'
import DeleteIcon from '../../../../Components/Icons/Delete'
import FormikSelectTwo from '../../../../Components/Utils/Select'
import NuDate from '../../../../Components/Utils/NuDate'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import axiosInstance from '../../../../Services/axiosCommon'
import UseDropDown from '../../../../Components/Hooks/useDropDown'
import { useFormik } from 'formik'
import useEditFormData from '../../../../Components/Hooks/useEditFormData'
import { useLocation } from 'react-router-dom'
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData'
import * as yup from 'yup';

function ViewCqCreate() {
    const {value:Availablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Availablity_CustomerQuotes')
    const {value:DeliveryTerm} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryTerm_CustomerQuotes')
    const {value:PaymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_CustomerQuotes')
    const {value:PaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_CustomerQuotes')
    const {value:Validity} = useFetchMataData(`/dropdown/dropdown-details/`,'Validity_CustomerQuotes')
    const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_customerQuotes')
    const {value:leadTime} = useFetchMataData(`/dropdown/dropdown-details/`,'leadTime_customerQuotes')
    const { data: customers } = UseDropDown('/customers/drop-down');
  
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
  //   console.log(location.state, 'state');
  //   const [dataa,setData] = useState(data);
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [items, setItems] = useState([]);
    const [remove, setRemove] = useState([]);
    const [dataa,setData] = useState(data);
    console.log(items,"kk");
    console.log(data,"pp");
    const [newItemData, setNewItemData] = useState({
      supQuotProductId: "",
              quantity: "",
              price: "",
              comments: ""
     });
    const [createNew, setCreateNew] = useState({
      date: data?.date || "",
      deliverTerms: data?.deliveryTerms || "", 
      paymentTerms: data?.paymentTerms || "",
      paymentMethod: data?.paymentMethod || "",
      totalPrice: data?.totalPrice || "",
      prId: data?.prId || "",
      ref: data?.ref || "",
      availability: data?.availability || "",
      validity: data?.validity || "",
      specialNotes: data?.specialNotes || "",
      leadTime: data?.leadTime || "",
      preparedBy: data?.preparedBy || "",
      reviewedBy: data?.reviewedBy || "",
      approvedBy: data?.approvedBy || "",
      remarks: data?.remarks || "",
      comments: data?.comments || "",
      status: data?.status || "",
     
    })
    
    const handleDelete = (productId) => {
      const updatedquoteProducts = dataa?.quoteProducts.filter(item => item.productId !== productId);
    
     
      const deletedProduct = dataa?.quoteProducts.find(item => item.productId === productId);
    
      if (deletedProduct && deletedProduct.price && deletedProduct.quantity) {
        const priceToRemove = deletedProduct.price || 0;
        const quantityToRemove = deletedProduct.quantity || 0;
        const reduction = priceToRemove * quantityToRemove;
    
      
        const newTotalPrice = updatedquoteProducts.length > 0 
        ? formik.values.totalPrice - reduction 
        : 0;
  
        setData(prevState => ({
          ...prevState,
          quoteProducts: updatedquoteProducts,
          totalPrice: newTotalPrice
        }));
    
        formik.setFieldValue('totalPrice', newTotalPrice);
    
        setRemove([...remove, productId]);
      }
    };
    const handleAdd = () => {
      const newItem = {
        supQuotProductId: formik.values.supQuotProductId,
        comments: formik.values.comments,
        price: formik.values.price,
        quantity: formik.values.quantity
      };
      setItems([...items, newItem]);
      // Update local storage
      setNewItemData({
      supQuotProductId: "",
      comments: "",
      price: "",
      quantity: ""
      });
      formik.values.supQuotProductId = '';
      formik.values.quantity ='';
      formik.values.price = '';
      formik.values.comments =''
    };
  
    const handleDeleteItem = (indexToDelete) => {
      const updatedItems = items.filter((_, index) => index !== indexToDelete);
      setItems(updatedItems);
    };
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
  
      }),
      onSubmit: async (value, { resetForm }) => {
        const valueWithItems = {
          ...value,
          products: dataa.quoteProducts,
          removedProducts:remove
        };
  
        editFormData(`/customer-quotations/quotation-revision/${data.id}`, valueWithItems, resetForm, from);
      }
    });
  
    const { data: PR } = UseDropDown('/PR/drop-down');
    const { data: user } = UseDropDown('/user/drop-down');
    const [subQuot,setSubQuot] = useState([]);
  const listSubQuotes = useMemo(() => {
    axiosInstance
    .get(`/supplier-quotations/quot-products/drop-down/${formik?.values?.prId}`)
    .then((res) => {
      let list = res.data;
      let array = [];
      list &&
        list.forEach((i) => {
          let num = { value: i?.id, label: i?.productName };
          array.push(num);
        });
      res.data && setSubQuot(array);
    })
    .catch((error) => {
          console.error('Error fetching data:', error);
        });
  }, [formik?.values?.prId]);
  
  const [prlist, setPrlist] = useState([]);
  
  const getPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/supplier-quotations/selected-products/drop-down/${prId}`)
        .then(res => {
          let data = res?.data?.data?.products;
  
          let tPrice=0.00
          for (let i=0;i<data?.length;i++){
           tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
          }
         //  setTotalPrice(tPrice);
         formik.setFieldValue('totalPrice', tPrice);
        
          setPrlist(data);
          setItems(data);
           
          // formik.setFieldValue('paymentTerms', res?.data?.data?.customerId || '');
          // formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
      
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };
  
  // useEffect(() => {
  //   const totalPrice = prlist.reduce((total, item) => {
  //     return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
  //   }, 0);
  
  //   formik.setFieldValue('totalPrice', totalPrice);
  // }, [prlist]);
  
  useEffect(() => {
    if (formik.values.prId) {
      getPr(formik.values.prId);
    }
  }, [formik.values.prId]);
  
  
    const updateTotalPrice = () => {
      const totalPrice = [...dataa.quoteProducts, ...items].reduce((total, item) => {
        return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
      }, 0);
      formik.setFieldValue('totalPrice', totalPrice);
    };
  
    const handlePriceChange = (e, index) => {
      const updatedItems = [...dataa.quoteProducts];
      if (updatedItems[index]) {
        updatedItems[index].price = e.target.value;
        setData({
          ...dataa,
          quoteProducts: updatedItems
        });
        updateTotalPrice();
      }
    };
  
    const handleQuantityChange = (index, newQuantity) => {
      const updatedItems = [...dataa.quoteProducts];
      if (updatedItems[index]) {
        updatedItems[index].quantity = newQuantity;
        setData({
          ...dataa,
          quoteProducts: updatedItems
        });
        updateTotalPrice();
      }
    };
  
    const handleIncrement = (index) => {
      handleQuantityChange(index, dataa.quoteProducts[index].quantity + 1);
    };
  
    const handleDecrement = (index) => {
      handleQuantityChange(index, Math.max(1, dataa.quoteProducts[index].quantity - 1));
    };
  
    const handleInputChange = (event, index) => {
      const value = Math.max(Number(event.target.value));
      handleQuantityChange(index, value);
    };
  
    
    return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
       <Breadcrumb
            items={
              [           
              // { name: "Sales",
              // link: "/sales/quotes" },
              { 
                  name: "Customer Quotation",
                  link: "/sales/quotes/viewcqrevision"
              },
              {
                name: "Edit Revision"
              }]
            }
          />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
            <div className=' w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'>
            <FormikSelectTwo
                label="Purchase Request"
                placeholder="Choose"
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"              
              />
               {/* <FormikSelectTwo
                  type="text"
                  label="Customer"
                  placeholder="Choose"
                  Options={customers}
                  formik={formik}
                  name="customerId"
                  width="w-full md:w-1/2 lg:w-1/3"
                /> */}
              {/* <NuInput
                type="text"
                label="Refrence"
                placeholder="Reffrence"
                formik={formik}
                name="ref"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
            <NuDate
                label="Quote Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="date"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
             
             
               {/* <FormikSelectTwo
                type="text"
                label="Availability"
                placeholder="Choose"
                Options={Availablity}
                formik={formik}
                name="availability"
                width="w-full  md:w-1/2 lg:w-1/3"
              /> */}
              <FormikSelectTwo
                type="text"
                label="Validity"
                placeholder="Choose"
                Options={Validity}
                formik={formik}
                name="validity"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
              {/* <FormikSelectTwo
                type="text"
                label="Lead Time"
                placeholder="Choose"
                Options={leadTime}
                formik={formik}
                name="leadTime"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               <FormikSelectTwo
                  type="text"
                  label="Deliver Terms"
                  placeholder="Choose"
                  Options={DeliveryTerm}
                  formik={formik}
                  name="deliverTerms"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
               <FormikSelectTwo
                  type="text"
                  label="Payment Terms"
                  placeholder="Choose"
                  Options={PaymentTerms}
                  formik={formik}
                  name="paymentTerms"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
              
              {/* <FormikSelectTwo
                label="Payment Method"
                placeholder="Choose"
                Options={PaymentMethod}
                formik={formik}
                name="paymentMethod"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
             
             
               <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={status}
                formik={formik}
                name="status"
                width="w-full  md:w-1/2 lg:w-1/3"
              />
               <NuTextArea
                label="Special Notes"
                placeholder="Special Notes"
                formik={formik}
                name="specialNotes"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
                />
                     <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
  
          
  
            <div className='w-full bg-white overflow-y-scroll mt-5'>
                <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.No </p>
                     
                          <p className=" w-[40%] text-left text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                     Product
                </p>
                {/* <p className=" w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                  Lead Time
                </p> */}
                <p className=" w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                  Quantity
                </p>
                <p className=" w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                  Price
                </p>
                <p className=" w-[10%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                  Action
                </p>
              </div>
             
              {dataa?.quoteProducts?.map((List, index) => {
                return (
                  <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                     <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                    <p className=" w-[40%] text-left text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light py-1">
                      {List?.product_name}
                    </p>
                    {/* <p className=" w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light py-1">
                    {List?.product_name}
                  </p> */}
                    <div className="w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                        <button
                          type="button"
                          className="border rounded-full p-1 "
                          onClick={() => handleDecrement(index)}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-[20%] text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                          name="quantity"
                          value={List.quantity}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                        <button
                          type="button"
                          className="border rounded-full p-1 "
                          onClick={() => handleIncrement(index)}
                        >
                          +
                        </button>
                      </div>
                      <input
                        type="number"
                        className="w-[20%] py-1 text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                        name="price"
                        formik={formik}
                        placeholder="Enter the price"
                        value={List.price || ""}
                        onChange={(e) => handlePriceChange(e, index)}
                      />
                    {/* <p className=" w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light py-1">
                      {List?.quantity}
                    </p>
                    <p className=" w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light py-1">
                      {List?.price}
                    </p> */}
                   <div className='w-[10%] text-center'> 
                   <button
                      onClick={() => handleDelete(List?.productId)}
                      type="button"
                      className="text-red-500 cursor-pointer  ml-4 border-none"
                    >
                      <DeleteIcon
                       color='red'
                       height={4}
                      />
                    </button>
                    </div>
                  
                  </div>
                );
              })}
            </div>
            <div className='w-full my-6 flex justify-end items-center gap-4'>
                <TextMediumBase content={"Total Price : "} />
                <NuInput
                type="number"
                // label="Total Price"
                placeholder="TotalPrice"
                formik={formik}
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                 disabled={true}
                />
              </div>
             <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton  handleClick={()=>{Oncancel(-1)}}/>
              <SubmitButton />
            </div>
          </div>
            </div>
          </div>
          </form>
      </div>
    );
  }
  
  
export default ViewCqCreate