import React, { useEffect, useState } from "react";
import {
  TextMediumBase,
  TextMediumSM,
} from "../../../../Components/Text/MediumText";
import { NuUpload } from "../../../../Components/Utils/Input";
import { SubmitButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useRef } from "react";

function CreateSeal() {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [data,setData] = useState([]);
  const getData = () => {
    axiosInstance.get('/company-seal/get-seal')
      .then((res) => {
        setData(res?.data?.data || []);
      })
      .catch(() => {
        toast.open({
          type: 'error',
          message: 'Error',
          description: 'Failed to fetch data.',
        });
      });
  };
  console.log("zzz",data);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append("seal", image?.raw);
      axiosInstance.post('/company-seal/add-seal',formData)
      .then((res)=>{
        if (res.data?.status === true) {
          toast.open({
            type:"success",
            message:"Success",
            description: res.data?.msg || "Created Successfully."
          }) 
          resetForm();
          setImage({ preview: '', raw: '' });
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          getData();
        } 
        else if(res.data?.status === false && res.data?.error){
          toast.open({
            type:"error",
            message:"Error",
            description: res.data.error || "Network error."
          })
        } 
        else {
          toast.open({
            type:"warning",
            message:"Warning",
            description: res.data?.msg || "Warning."
          })
        }
      })
    },
  });
  useEffect(()=>{
    getData();
  },[])
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  return (
    <div className="">
      <div className="ml-5">
        <TextMediumSM content={"Company Seal"} />
        <div className="mt-2 p-1 w-60 h-40 border border-black rounded-md">
             <div className='w-full h-full'>
                <img src={data?.[0]?.file} alt={data?.[0]?.file} className='w-full h-full ' />
             </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
      <div className="flex justify-start gap-2">
        <NuUpload
          label="Attachment"
          width="w-full md:w-1/2 lg:w-1/3"
          onChange={handleChangeImg}
          ref={fileInputRef}
        />
        <div className="mt-10">
        {image?.preview == "" ? null : (
            <SubmitButton />
        )}
        </div>
      </div>
      </form>
    </div>
  );
}

export default CreateSeal;
