import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import DeleteIcon from '../../../../Components/Icons/Delete';
import { NuDataTime, NuDate, NuInput, NuTextArea, NuUpload } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import NuAmount from '../../../../Components/Utils/NuAmount';

function EditSalesOrders() {
  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_SalesOrder')
  const {value:CustomerDiscount} = useFetchMataData(`/dropdown/dropdown-details/`,'CustomerDiscount_SalesOrder')
  const {value:PaymentStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentStatus_SalesOrder')
  const {value:TermsAndConditions} = useFetchMataData(`/dropdown/dropdown-details/`,'termsAndConditions_SalesOrder')
  const {value:ShippingMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'shippingMethod_SalesOrder')
  const { value: status } = useFetchMataData(`/dropdown/dropdown-details/`, 'Status_SalesOrder');

  const { data: user } = UseDropDown('/user/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  const { data: Customers } = UseDropDown('/customers/drop-down');

  
  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  // console.log(location.state, 'state');
  const [dataa,setData] = useState(data);
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);

  // console.log(items,"kk");
  // console.log(data,"pp");
  const [newItemData, setNewItemData] = useState({
    productId:"",
    quoteQty:"",
    orderedQty: "",
    quantity:"",
    quoteProductId:"" ,
    product_name:"",
      price:"",
   });
  const [createNew, setCreateNew] = useState({
    type: data?.type,
    busySoNo: data?.busySoNo,
    orderReceivedOn: data?.orderReceivedOn || "",
    assignee: data?.assignee,
    salesPerson: data?.salesPerson,
    deadLine: data?.deadLine,
    notesFromCus: data?.notesFromCus,
    prId: data.prId,
    ref: data.ref,
    date: data.date,
    cusOrderRef: data.cusOrderRef,
    customerId: data.customerId,
    comments: data.comments,
    cusDiscount: data.cusDiscount,
    shippingInstruction: data.shippingInstruction,
    shippingMethod: data.shippingMethod,
    billingAddress: data.billingAddress,
    deliveryAddress: data.deliveryAddress,   
    termsAndConditions: data.termsAndConditions,
    status: data.status,
    approvedBy: data.approvedBy,
    subTotal: data?.subTotal,
    paymentStatus: data.paymentStatus,
    requestedDeliveryDate: data.requestedDeliveryDate,
    requestedBy: data.requestedBy,
    products: data.products,
    quoteProductId: data.quoteProductId,
    approvalStatus: data.approvalStatus,
    receivedOn:data.receivedOn,
    productId: data.productId,
    quoteQty: data.quoteQty,
    removedProducts: [],
    filesToRemove:[],
    product_name: "",
    cusQuoteId:data.cusQuoteId

  })
  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.products.filter(item => item.productId !==productId );//quoteProductId
   
    const deletedProduct = dataa?.products.find(item => item.productId === productId);


    if (deletedProduct &&  deletedProduct.quantity) {
      const priceToRemove = deletedProduct.price || 0;
      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = priceToRemove * quantityToRemove;
  
      // Calculate the new total price by subtracting the reduction
      // const newsubTotal = formik.values.subTotal - reduction;
      const newsubTotal = updatedquoteProducts.length > 0 
                            ? formik.values.subTotal - reduction 
                            : 0;
    setData(prevState => ({
      ...prevState,
      products: updatedquoteProducts
      
    }));
    formik.setFieldValue('subTotal', newsubTotal);

    setRemove([...remove,productId]);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, deletedProduct]);

  }
};

useEffect(() => {
  let tempData = [];
  deletedItem.forEach((item) => {
    let tempObject = { value: item.productId, label: item.product_name };
    tempData.push(tempObject);
  });
  setDeletedItemDropDown(tempData);
}, [deletedItem]);

const handleAddDelPro = () => {
  const selectedProductId = formik.values.productId;

  // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
  const selectedProduct = deletedItem.find(
    (item) => item.productId === selectedProductId
  );

  if (selectedProduct) {
    // Add the selected product to prList
    setData((prevState) => ({
      ...prevState,
      products: [...prevState.products, selectedProduct],
    }));

    // Optionally, remove the selected product from the dropdown after adding it to prList
    setDeletedItem((prevDeletedItems) =>
      prevDeletedItems.filter((item) => item.productId !== selectedProductId)
    );
  }
};

  const handleAdd = () => {
    const newItem = {
       price: formik.values.price,
      quoteQty: formik.values.quoteQty,
       productId: formik.values.productId,
       quoteProductId: formik.values.quoteProductId,
      orderedQty: formik.values.orderedQty,
      quantity:formik.values.quantity,
      product_name: formik.values.product_name
    };
    setItems([...items, newItem]);
    const newSubTotal = parseFloat(formik.values.subTotal || 0) + (parseFloat(newItem.price) * parseFloat(newItem.quantity));
    formik.setFieldValue('subTotal', newSubTotal);
  
    setNewItemData({
      price: "",
      product_name:"",
      quoteProductId: "",
      orderedQty: "",
      quantity:"",
      quoteQty:"",
      productId:""
    });
    formik.values.quantity ='';
    formik.values.quoteProductId = '';
    formik.values.price =''
  };

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter((_, index) => index !== indexToDelete);
    setItems(updatedItems);
    // localStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => 
      ({ ...createNew, image: "" }))
    formik.values.image = ""
  }
  const [error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
          prId: yup.string().required('Purchase Request is requred!'),
          cusOrderRef: yup.string().required('Customer Order is requred!')
    }),

    onSubmit: async (value, { resetForm }) => {
      if (dataa.products.length === 0) {
        setError('Product table is empty');
        return;
      }
      let formData = new FormData();

      const valueWithItems = {
        ...value,
        products: dataa.products,
        removedProducts:remove
      };
     formData.append('json_data', JSON.stringify(valueWithItems));
      formData.append('attachments', []);

      editFormData(`/sales-order/edit-sales-order/${data.id}`, formData, resetForm,from);
  
    }
  });

  const [products, setProducts] = useState([])

  useMemo(()=>{
    if (formik.values.quoteProductId!==''){
      let tem_product = products.find((i)=> i.id == formik.values.quoteProductId);
      formik.values.price = tem_product && tem_product.price;
      formik.values.productId = tem_product && tem_product.productId;
      formik.values.quoteQty = tem_product && tem_product.quoteQty
      formik.values.product_name = tem_product && tem_product.name
      // console.log(tem_product,"oo");
    }
  },[formik.values.quoteProductId])
  const [customerQuotation,setCustomerQuotation] = useState([]);

  const getCQByPR = () =>{
    axiosInstance
    .get(`/customer-quotations/drop-down/${formik?.values?.prId}`)
    .then(res => {
         console.log("v",res?.data);
         let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setCustomerQuotation(tempData);
    }).catch((err)=>{
      console.log(err);
    })
  }

function getProduct(num){
  axiosInstance.get(`/sales-order/drop-down/by-PR/${num}`).then((response) => {
    let tempData =[]
    if(response.data){
      let x = response.data
      setProducts(x)

    }
  }).catch((error) => {})
 }

 useMemo(()=>{
  if(formik.values.prId){
    getCQByPR();
    getProduct(formik.values.prId)
  }
 },[formik.values.prId])

 
  const [Colist, setColist] = useState([]);


//  const getCQ = (cusOrderRef) => {
//    if (cusOrderRef !== "") {
//      axiosInstance
//        .get(`/customer-orders/co-products/${cusOrderRef}`)
//        .then(res => {
//          let data = res.data?.data?.products;
//          console.log('product details',data);

//          let tPrice=0.00
//          for (let i=0;i<data?.length;i++){
//           tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
//          }
//         //  setsubTotal(tPrice);
//         formik.setFieldValue('subTotal', tPrice);
//        //  formik.setFieldValue('paymentTerms', res?.data?.data?.paymentTerms || '');
//        //  formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
    
//          setColist(data);
//        })
//        .catch(err => {
//          console.log(err, "err");
//        });
//    }
//  };

//  useEffect(() => {
//    if (formik.values.cusOrderRef) {
//      getCQ(formik.values.cusOrderRef);
//    }
//  }, [formik.values.cusOrderRef]);

useEffect(() => {
  const subTotal = Colist.reduce((total, item) => {
    return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
  }, 0);
  formik.setFieldValue('subTotal', subTotal);
}, [Colist]);


 useEffect(() => {
  if (dataa.products.length > 0) {
    setError('');
  }
}, [dataa.products]);


useEffect(()=>{
 formik.setFieldValue('subTotal', data?.subTotal)
},[data])
   

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
     <Breadcrumb 
      items={[
        {
          name: "Sales Order",
          link: "/sales/sales-orders"
        },
        { name: "Edit" },
      
  ]} />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request "
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> 
                <FormikSelectTwo
                type="text"
                label="Customer Quotation"
                placeholder="Customer Quotation"
                Options={customerQuotation}
                formik={formik}
                name="cusQuoteId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              /> 
              <NuInput
              type="text"
              label="Busy So No"
              placeholder="Busy So No"
              formik={formik}
              name="busySoNo"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
             {formik.values.cusQuoteId && 
              <><FormikSelectTwo
                disabled={true}
                type="text"
                label="Customer "
                placeholder="Customer"
                Options={Customers}
                formik={formik}
                name="customerId"
                width="w-full md:w-1/2 lg:w-1/3" /></>}
               <NuInput
                type="text"
                label="Customer Order Reference"
                placeholder="Customer Order  "
                formik={formik}
                name="cusOrderRef"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              />
               <NuDate
                label="Order Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="orderReceivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <NuDate
                label="Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
                // value={currentDate}
              />
               <FormikSelectTwo
                type="text"
                label="SO Type"
                placeholder="SO Type "
                Options={[
                  {value: "International", label: "International"},
                  {value: "Local", label: "Local"},
                  {value: "Ex-Stock", label: "Ex-Stock"},
                ]}
                formik={formik}
                name="type"
                width="w-full md:w-1/2 lg:w-1/3"
                // isRequired={true}
              />  
               {/* <NuInput
                label="Reference"
                disabled={true}
                type="text"
                formik={formik}
                placeholder="Reference "
                name="ref"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              {/* <NuInput
                label="Customer Order Reference"
                type="text"
                formik={formik}
                placeholder="Customer Order Reference"
                name="cusOrderRef"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              
              {/* <FormikSelectTwo
               disabled={true}
                type="text"
                label="Customer"
                placeholder="Customer "
                Options={Customers}
                formik={formik}
                name="customerId"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
             
              {/* <FormikSelectTwo
              // type="text"
              label="Assignee"
              placeholder="Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="assignee"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={Salesman}
                formik={formik}
                name="salesPerson"
                width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
              />
             
             
              {/* <NuDate
                label="Dead Line"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLine"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              <NuDate
                label="Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="date"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
              {/* <FormikSelectTwo
                type="text"
                label="Customer Discount"
                placeholder="Choose"
                Options={CustomerDiscount}
                formik={formik}
                name="cusDiscount"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                label="Shipping Instruction"
                type="text"
                formik={formik}
                placeholder="Shipping Instruction"
                name="shippingInstruction"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               <FormikSelectTwo
                type="text"
                label="Shipping Method"
                placeholder="Choose"
                Options={ShippingMethod}
                formik={formik}
                name="shippingMethod"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
             
              <FormikSelectTwo
                type="text"
                label="Terms And Conditions"
                placeholder="Terms And Conditions"
                Options={TermsAndConditions}
                formik={formik}
                name="termsAndConditions"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             <FormikSelectTwo
                type="text"
                label="Payment Status"
                placeholder="Payment Status"
                Options={PaymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                <NuDate
                label="Requested Delivery Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="requestedDeliveryDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               
               {/* <FormikSelectTwo
                type="text"
                label="Approval Status"
                placeholder="Approval Status"
                Options={ApprovalStatus}
                formik={formik}
                name="approvalStatus"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               <FormikSelectTwo
            type="text"
            label=" Status"
            placeholder=" Status"
            Options={status}
           formik={formik}
           name="status"
           width="w-full md:w-1/2 lg:w-1/3"
          />
               <NuTextArea
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              type="text"
              label="Delivery Address"
              placeholder="Delivery Address"
              formik={formik}
              name="deliveryAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuTextArea
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             {/* <NuUpload
              label="Attachment"
              width="w-full md:w-1/2"
              onChange={handleChangeImg}
            /> */}
               <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
               {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddDelPro}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
       <div className='w-full bg-white overflow-y-scroll mt-5'>
       {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
            <div className='w-full py-3 bg-[#048178] flex justify-between rounded-lg px-2'>
            <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

            <p className=" w-[50%] text-left text-white text-xs font-medium"> Product</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Unit</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">  Quantity</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Price</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Total</p>

              <p className=" w-[5%] text-center text-white text-xs font-medium">Action</p>
            </div>
           {dataa?.products?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

           {dataa?.products.map((List, index) => {
            return (
              <div key={index} className="w-full py-3 flex justify-between px-2 odd:bg-white even:bg-slate-100">
                <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                <p className=" w-[50%] text-left text-[#606279] text-xs font-light">
                    {List?.product_name}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.unit}
                  </p>

                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.quantity}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.price}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
               <div className='w-[5%] text-center'> 
               <button
                  onClick={() => handleDelete(List?.productId)}
                  type="button"
                  className="text-red-500 cursor-pointer ml-4 border-none"
                >
                  <DeleteIcon
                   color='red'
                   height={4}
                  />
                </button>
                </div>
              </div>
            );
          })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

      </div>
      <div className='w-full my-6 flex justify-end items-center gap-4'>
              <TextMediumBase content={"Total Price : "} />
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount value={formik?.values?.subTotal || 0} />
              </p> 

            </div>
            <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
        </div>
      </form>
    </div>
  );
}
export default EditSalesOrders