import React from 'react'

function Txt(color="#8099AF") {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="30" rx="5" fill={color} fill-opacity="0.1"/>
<path d="M9 25H6.33333C5.97971 25 5.64057 24.8595 5.39052 24.6095C5.14048 24.3594 5 24.0203 5 23.6667V6.33333C5 5.97971 5.14048 5.64057 5.39052 5.39052C5.64057 5.14048 5.97971 5 6.33333 5H15.7813C16.1347 5.00008 16.4736 5.14043 16.7236 5.39022L21.9431 10.6098C22.1929 10.8597 22.3333 11.1986 22.3333 11.552V15.6667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3333 11.6667H17C16.6463 11.6667 16.3072 11.5262 16.0571 11.2761C15.8071 11.0261 15.6666 10.687 15.6666 10.3333V5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M23.6666 24.9997V18.333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3334 18.333H25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 24.9997V18.333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.6666 18.333H14.3333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17 18.333L19.6667 24.9997" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.6667 18.333L17 24.9997" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default Txt;