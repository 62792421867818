import React,{useEffect,useState} from "react";
// import { VerticalRightOutlined,VerticalLeftOutlined,LeftOutlined,RightOutlined } from '@ant-design/icons';

export default function Pagination({
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  total
}) {
  function qwer() {
    let x = document.getElementById('page');
    setDataCount(x[x.selectedIndex].innerHTML)
    setPageIndex(1)
}
const [totalPages, setTotalPages] = useState(0);
useEffect(() => {
  if (total > 0 && dataCount > 0)
      setTotalPages(Math.ceil(total / dataCount));
}, [total, dataCount]);

  return (
    <div className="flex items-center justify-between w-full h-full">
    <div className="w-5/12 md:w-3/12">
      <span className="mx-3 text-xs  font-medium flex items-center gap-3"><span>View</span> 
                    <select className=" text-xs border-none outline-none bg-slate-100 py-[2px] rounded-md" onClick={() => qwer()} id='page'>
                        <option >20</option>
                        <option >40</option>
                        <option >60</option>
                        <option >80</option>
                        <option >100</option>
                    </select>
                    <span>: Per Page</span>
                    
      </span>
      </div>
      <div className="flex justify-between items-center w-7/12 md:w-3/12 xl:w-[20%] md:ml-10 text-center text-xs">
      <p className=" text-xs font-medium">
           Showing
          <span className="border border-pageborder rounded-md py-1 px-2 mx-1">
            {pageIndex||1}
          </span>
           of {totalPages > 1 ? `${totalPages} Pages`:`${1} Page` }
        </p>
        <span className=" text-sm font-bold cursor-pointer" onClick={() => setPageIndex(1)}>
        &#x226A;
        </span>
        <span className=" text-sm font-bold cursor-pointer"  onClick={() => setPageIndex(pageIndex > 1 ? pageIndex - 1 : 1)} >
        &#x3c;
        </span>
        {/* <VerticalRightOutlined onClick={} /> */}
        {/* <LeftOutlined /> */}
        {/* < RightOutlined        /> */}

             <span className=" text-sm font-bold cursor-pointer" onClick={() => setPageIndex( 
              totalPages > 0 ? (pageIndex < totalPages ? 
                pageIndex + 1 
                :  totalPages) :1
                 )}>
&#x3e;
             </span>
             <span className=" text-sm font-bold cursor-pointer" onClick={() => setPageIndex(totalPages > 0 ? totalPages : 1)}>
             &#x226B;
             </span>

        
      </div>
    </div>
  );
}
