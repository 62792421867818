import React, { useState } from 'react'
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, CancelButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import SideModal from '../../../../Components/Others/SideModel';
import PdfLinkSo from './Pdf/PdfLinkSo';
import NuDate from '../../../../Components/Utils/NuDate';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadSo from './Pdf/DownloadSo';
import ViewPdf from './ViewPdf';
import Comments from './CommentsPr';
import CommonComments from '../../../../Components/Utils/commonComments';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import SOPdfOthers from '../../ViewPdfTemplate/SOPdfOthers';
import SOPdfNu from '../../ViewPdfTemplate/SOPdfNu';
import NuAmount from '../../../../Components/Utils/NuAmount';

function ViewSalesOrders() {
    const { salesOrdersViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    console.log('sss',data?.quoteProducts);
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
    const [showPdfComponent, setShowPdfComponent] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };

  const id = salesOrdersViewId; 
  const getUrl = `/sales-order/get-status`;
  const putUrl = `/sales-order/edit-status`; 
  const statusUrl = '/sales-order/all-status';

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
         <Breadcrumb 
         items={[
          
  
          { name: "Sales Order",
            link:"/sales/sales-orders"},
            
          { name: "View"},
        
    ]} />
        <div className=" flex gap-2">
          <Link to={'/sales/sales-orders'}><BackButton link={true}/></Link>
          <PdfLinkSo
            data={data}
            fileName={`Sales_Order_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          />

          <Link to={`/sales/sales-orders/edit/${salesOrdersViewId}`} 
          state={{data: data, from:`/sales/sales-orders/view/${salesOrdersViewId}`}}
          ><EditButton height='8'/>
          </Link>
                 {/* <button className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`} onClick={handleOpenCommentsModal}>Comments</button> */}

        </div>
      </div> 
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
        <div className="grid  items-center ">
        <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div> <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
         </p>
         {/* <p className="text-xs text-[#111537]"> Created By : {data.preparedByName || 'None'}</p> */}
         <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p>

         </div>
         </div>
         </div>
          {/* <div className="flex justify-between items-center">

      <div className='flex gap-3 items-center'>
      <NormalHeading title={"Sales Order"} />  :<p className="text-xs grid pt-5">{data.ref}
      <span className="font-light text-[11px] px-2">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span>
      </p>
      </div>
      <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p>
              </div> */}
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

<div className=" w-full flex flex-wrap  px-2 border border-gary mt-4  rounded-lg ">

              <div className=" w-full grid grid-cols-2 gap-x-8 ">
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer Order </p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data?.cusOrderRef}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer Discount</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.cusDiscount}</p>
                </div>  */}
                 {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchase Requests </p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.prRef}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Date</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.date}</p>
                </div> */}
               
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Busy SO No.</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.ref}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.customerName}</p>
                </div> */}
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Sub Total</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.subTotal}</p>
                </div> */}
               
               
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Requested Delivery Date</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.requestedDeliveryDate? (
                  <NuDate value={data?.requestedDeliveryDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
                </div>
               
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping method</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.shippingMethod}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping Instruction</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.shippingInstruction}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Terms and Conditions</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.termsAndConditions}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Address</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.deliveryAddress}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Billing Address</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.billingAddress}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Payment Status</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.paymentStatus}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.approvalStatus}</p>
                </div> */}
                {data && (
                  <>
                  {data.approvedByName &&
                   <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approval By</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.approvedByName}</p>
                </div>
}
{data.requestedByName && 
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Requested By</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.requestedByName}</p>
                </div>
}
                  </>
                )}
               
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.comments}</p>
                </div> */}
                
              </div>
              </div>
              {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
              <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
            {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
                

            <div className="w-full bg-white  border border-gary  mt-4 rounded-lg ">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg px-3">
            {/* <p className=" w-[15%] text-left text-white text-xs font-medium p-1">Item Code </p> */}
            <p className="w-[5%]  text-left  text-white text-xs font-medium ">S.N. </p>

            <p className=" w-[55%] text-left  text-white text-xs font-medium "> Product </p>
            <p className=" w-[10%] text-center text-white text-xs font-medium "> Unit</p>
            {/* <p className=" w-[15%] text-center text-white text-xs font-medium "> Product Category</p> */}
            <p className=" w-[15%] text-center text-white text-xs font-medium ">  Qty </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium ">  Price</p>
            <p className=" w-[15%] text-center text-white text-xs font-medium ">  Total</p>

            {/* <p className=" w-[20%] text-left text-white text-xs font-medium p-1">  Quotes Quantity</p> */}
          </div>
            {data?.products?.map((List, index) => {
              return (
               
               <div key={index} className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100 px-3">
                 {/* <p className=" w-[15%] text-left text-[#606279] text-xs font-light ">
                  {List?.item_code}
                </p> */}
                 <p className="w-[5%]  text-left  text-[#606279] text-xs font-light ">
                                    {index + 1}
                                </p>
                 <p className=" w-[55%] text-left text-[#606279] text-xs font-light ">
                  {List?.product_name}
                </p>
                <p className=" w-[10%] text-center text-[#606279] text-xs font-light ">
                  {List?.unit}
                </p>
               
               
                {/* <p className=" w-[15%] text-center text-[#606279] text-xs font-light ">
                  {List?.product_category}
                </p> */}
                <p className=" w-[15%] text-center text-[#606279] text-xs font-light ">
                  {List?.quantity}
                </p>
                <p className=" w-[15%] text-center text-[#606279] text-xs font-light ">
                  {List?.price}
                </p>
                <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
             </div>
              );
            })}
             <div className="justify-end flex gap-2 px-4">
            <p className=" py-2 text-xs font-medium text-[#111537]">
              Total Price 
            </p>
            <p className=" py-2 text-xs font-medium text-[#606279]">
             : <NuAmount value= {data?.subTotal || '-'} />
            </p>
          </div>
          </div>
         
          {/* <div className="w-full flex px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              {data.subTotal}
            </p>
          </div>
        </div> */}
        
        </div>
        </div>
        <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">

        {/* <ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl}/> */}

        <div className="w-full grid  border border-gary   rounded-lg">
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Prepared By</p>
            </div>
          {/* <p className="text-xs text-[#111537] font-medium">Customer Detail</p> */}
          <div className='w-full flex flex-col py-2 pl-2'>
                  <p className='w-1/2 pb-1 text-xs font-medium text-[#111537]'>{data.preparedByName}</p>
                  <p className='w-1/2 text-[10px] font-medium text-[#606279]'> {data.createdAt}</p>
                </div>              
           </div>
         
          <div className="w-full grid  border border-gary mt-4  rounded-lg">
          <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Sales Detail</p>

            </div>
          {/* <p className="text-xs text-[#111537] font-medium">Customer Detail</p> */}
          <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchase Requests </p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'> : {data.prRef}</p>
                </div>
              <div className="w-full flex px-2 justify-between">
             
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'> : {data.customerName}</p>
                </div>
           
                <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'> Order </p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'> : {data?.cusOrderRef}</p>
                </div>
                <div className='w-full flex px-2 justify-between'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'> Received On </p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'> : {data?.receivedOn}</p>
                </div>
               
           </div>
           <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className="felx gap-2 flex-wrap overflow-y-scroll h-16">

           <div onClick={handlePdfIconClick} className="cursor-pointer m-2  overflow-y-scroll ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div>
        </div>
        </div>
         <div className="w-full h-auto flex  border border-gary mt-4 overflow-hidden rounded-lg">
         <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={salesOrdersViewId}
            addurl={'/so-comments/new-comment'}
            geturl={`/so-comments/by-SO/${salesOrdersViewId}`}
         />
         </div>
        
          </div>
        </div>
      </div>
      {showPdfComponent && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[95%] h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
               className="absolute top-2 right-2 text-white bg-red-600"
               onClick={() => setShowPdfComponent(false)}
       >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {/* <ViewPdf data={data} /> */}
      <SOPdfOthers data={data} />
      {/* <SOPdfNu/> */}
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div> */}
    </div>
  </div>
)}

    
      {/* {isCommentsModalOpen && (
        <SideModal
          visible={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={salesOrdersViewId}
          addurl={'/so-comments/new-comment'}
          geturl={`/so-comments/by-SO/${salesOrdersViewId}`}
        />
      )} */}
    </div>
  )
  };
  

export default ViewSalesOrders