// import React from 'react'

// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//     page: {
//       padding: 10,
//       fontSize: 12,
//       fontFamily: 'Helvetica',
//     },
//     headingContainer: {
//       flexDirection: 'row',
//       alignItems: 'center', 
//       marginBottom: 10,
//     },
//     headingTitle: {
//       fontSize: 16,
//       fontWeight: 'bold',
//     },
//     headingText: {
//       marginTop: 5,
//       fontSize: 14,
//     },
//     section: {
//       display: 'flex',
//       flexWrap: 'wrap',
//       alignItems: 'center',
//       marginBottom: 10,
//     },
//     label: {
//       width: '50%',
//       padding: 5,
//       fontSize: 12,
//       fontWeight: 'bold',
//     },
//     value: {
//       width: '50%',
//       padding: 5,
//       fontSize: 12,
//     },
//     productRow: {
//       flexDirection: 'row',
//       padding: 5,
//     },
//     productHeader: {
//       backgroundColor: '#1e90ff',
//       color: '#fff',
//       padding: 5,
//       fontSize: 12,
//       fontWeight: 'bold',
//     },
//     productCell: {
//       padding: 5,
//       fontSize: 12,
//     },
//     note: {
//       marginTop: 20,
//       padding: 10,
//       borderWidth: 1,
//       flexDirection: 'row',
//       justifyContent: 'space-between', 
//       borderColor: '#ccc',
//     },
//   });
// const DownloadPdfCq = ({data}) => {
//     return(
//         <Document>
//         <Page size="A4" style={styles.page} orientation="portrait">
//           <View style={styles.headingContainer}>
//             <Text style={styles.headingTitle}>Customer Quotes</Text>
//             <Text style={styles.headingText}>: {data.ref}</Text>
//           </View>
    
//           <View style={styles.section}>
//             {[
//               { label: 'Customer ', value: data.customerName },
//               { label: 'Validity ', value: data.validity || 'N/A' },
//               { label: 'Availability', value: data.availability || 'N/A'},
//               { label: ' Quote Date ', value: data.date || 'N/A'},
//               { label: 'Lead Time', value: data.leadTime || 'N/A'},
//               { label: ' Payment Method ', value: data.paymentMethod || 'N/A'},
//               { label: 'Delivery Terms ', value: data.deliveryTerms || 'N/A'},
//               { label: ' Payment Terms    ', value: data.paymentTerms || 'N/A'},
//               { label: ' Purchase Requests', value: data.prRef || 'N/A'},
//               { label: ' Prepared By ', value: data.preparedByName || 'N/A'},
//               { label: ' Reviewed By ', value: data.reviewedByName || 'N/A'},
//               { label: 'Verified By ', value: data.verifiedByName || 'N/A' },
//               { label: 'Approved By ', value: data.approvedByName || 'N/A' },
//               { label: 'Status ', value: data.status || 'N/A' },
//               { label: 'Comments ', value: data.comments || 'N/A' },
             
//                    ].map((item, index) => (
//               <View key={index} style={{ flexDirection: 'row' }}>
//                 <Text style={styles.label}>{item.label}</Text>
//                 <Text style={styles.value}>{item.value}</Text>
//               </View>
//             ))}
//           </View>
    
//           <View style={{ marginTop: 20, backgroundColor: '#D3D3D3' }}>
//             <View style={{ flexDirection: 'row' }}>
//               {[
//                 'S.N','Item Code','Product Name', ' Quantity','Price'
               
//               ].map((header, index) => {
//                 let width=[5, 20, 40, 15,20 ]
//                 return(
//                 <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
//                   {header}
//                 </Text>
//               )})}
//             </View>
//             {data?.quoteProducts?.map((product, index) => (
//               <View key={index} style={styles.productRow}>
//                 <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
//                 <Text style={[styles.productCell, { width: '20%' }]}>{product.item_code}</Text>
//                 <Text style={[styles.productCell, { width: '40%' }]}>{product.product_name}</Text>
//                 <Text style={[styles.productCell, { width: '15%' }]}>{product.quantity}</Text>
//                 {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
//                 {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
//                 <Text style={[styles.productCell, { width: '20%' }]}>{product.price}</Text>
//                 {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.remainingQty}</Text> */}
//                 {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
//               </View>
//             ))}
//           </View>
    
//           <View style={styles.note}>
//             {/* <Text>Note From Customer: {data.notesFromCus || 'N/A'}</Text> */}
    
//             <Text>Total Price: {data.subTotal}</Text>
//           </View>
//         </Page>
//       </Document>
//     )
// }

// export default DownloadPdfCq

import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
    backgroundColor: '#fff',
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subHeader: {
    fontSize: 12,
  },
  table: {
    display: 'table',
    width: 'full',
    marginBottom: 10,
    
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 5,
    backgroundColor: '#d3d3d3',
  },
  tableCol: {
    fontSize: 12,
    padding: 5,
  },
  flexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  termLabel: {
    width: '50%',
    padding: 2,
    fontSize: 12,
    fontWeight: 'medium',
    color: '#111537',
  },
  termValue: {
    width: '50%',
    padding: 2,
    fontSize: 12,
    color: '#606279',
  },
  note: {
    marginTop: 10,
  },
  sections:{
  display:'flex',
  justifyContent:'flex-end'
  }
});

const DownloadPdfCq = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={[styles.section, styles.flexRow]}>
        <View>
          <Text style={styles.header}>Yanama Trading Company</Text>
          <Text style={styles.subHeader}>C.R. 2051064219</Text>
        </View>
      </View>
      
      <View style={[styles.section, styles.flexRow]}>
        <View>
          <Text>Party: {data.customerName}</Text>
          <Text>Address: {data.customerAddress}</Text>
          <Text>Attn: {data.attn}</Text>
        </View>
        <View>
          <Text>Date: {data.date || 'N/A'}</Text>
          <Text>Your Ref: {data.ref || 'N/A'}</Text>
          <Text>Our Ref: {data.ourRef || 'N/A'}</Text>
          <Text>Project: {data.project || 'N/A'}</Text>
        </View>
      </View>
      
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {['S.N', 'Item Code', 'Product Name', 'Quantity', 'Price'].map((header, index) => (
            <Text key={index} style={styles.tableColHeader}>{header}</Text>
          ))}
        </View>
        {data.quoteProducts.map((product, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCol}>{index + 1}</Text>
            <Text style={styles.tableCol}>{product.item_code}</Text>
            <Text style={styles.tableCol}>{product.product_name}</Text>
            <Text style={styles.tableCol}>{product.quantity}</Text>
            <Text style={styles.tableCol}>{product.price}</Text>
          </View>
        ))}
      </View>

      <View style={[styles.sections, styles.flexRow]}>
        <View style={{ width: '100%' }}>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Total</Text>
            <Text style={styles.termValue}>{data?.subTotal || "-"}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>VAT</Text>
            <Text style={styles.termValue}>{data?.vat || "-"}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Net Total</Text>
            <Text style={styles.termValue}>{data?.netTotal || "-"}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.section, styles.flexRow]}>
        <View style={{ width: '50%' }}>
          <Text style={styles.header}>Terms & Conditions</Text>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Validity</Text>
            <Text style={styles.termValue}>: {data?.validity || "-"}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Availability</Text>
            <Text style={styles.termValue}>: {data?.availability || "-"}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Delivery Term</Text>
            <Text style={styles.termValue}>: {data?.deliveryTerms || "-"}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={styles.termLabel}>Payment Term</Text>
            <Text style={styles.termValue}>: {data?.paymentTerms || "-"}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.section, styles.note]}>
        <Text>Note:</Text>
        <Text>{data.specialNotes || 'N/A'}</Text>
      </View>
    </Page>
  </Document>
);

export default DownloadPdfCq;