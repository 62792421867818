import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { useLocation, useParams } from 'react-router-dom';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';

function EditVehicleMaintence() {
  const {value:mainType } = useFetchMataData(`/dropdown/dropdown-details/`,'vehicle_mainType')
  const { maintenceId } = useParams();

    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(location.state,'state');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
   

    const [createNew, setCreateNew] = useState({
  
        mainDate: data?.mainDate,
       vehicleId: data?.vehicleId,
      mainBy: data?.mainBy,
      mainType:data?.mainType,
      nextMain:data?.nextMain,
      comments:data?.comments,
    

    })
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          

            editFormData(`/vehicles/edit-maintenance/${maintenceId}`, value, resetForm, from);
        }
    })
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
   
    <Breadcrumb
          items={[
            {
              name: "Vehicle",
              link: "/maintenance/vehicle"

            },
            {
              name: " Maintence Vehicle"
            }
          ]}
        />
    <BackButton/>
    </div> 
   <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-2 flex justify-start content-start flex-wrap  overflow-y-auto">
       
          
         <NuDate
                label="Maintenance Date"
                placeholder="Maintenance Date"
                formik={formik}
                name="mainDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <NuInput
                type="test"
                label="Vehicle Id"
                placeholder="Vehicle Id"
                formik={formik}
                name="vehicleId"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              <NuInput
                type="test"
                label="Maintenance By"
                placeholder="Maintenance By"
                formik={formik}
                name="mainBy"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
            <FormikSelectTwo
              label="Maintenance Type"
              placeholder="Choose"
              formik={formik}
              Options={mainType}
              name="mainType"
              width="w-full md:w-1/2 lg:w-1/3"
            />

               <NuDate
                label="Next Maintenance"
                placeholder="Next Maintenance"
                formik={formik}
                name="nextMain"
                width="w-full md:w-1/2 lg:w-1/3"
              />
           
           
              <NuTextArea
                label="Comments"
                placeholder="comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
  
            
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
 </div>
  )
}


export default EditVehicleMaintence