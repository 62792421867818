import { PDFDownloadLink } from '@react-pdf/renderer'
import React from 'react'
import DownloadPDF from './DownloadSQPDF';
import { CreateButton } from '../../../../../Components/Utils/Buttons';

function SQDownloadLink({headers,data,fileName}) {
    // console.log("headerssss",headers);
  return (
    <PDFDownloadLink document={<DownloadPDF header={headers} data={data} />} fileName={fileName}>
    {({ blob, url, loading, error }) =>
      loading ? (
        "..."
      ) : (
        <CreateButton svg={false} label="Download as PDF" />
      )
    }
  </PDFDownloadLink>
  )
}

export default SQDownloadLink