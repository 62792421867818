import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  ImportButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import AgentCard from "./AgentCard";
import List from "../../../../Components/Icons/list";
import Card from "../../../../Components/Icons/card";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import Select from 'react-select'
import Pagination from "../../../../Components/Utils/Pagenation";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import Importdoc from "./Importdoc";
import { GetAccess } from "../../../../Components/Utils/roles";

function AgentIndex() {
  const {value:Type} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentType')
  const { value:Rating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'AgentRating')
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentStatus')

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    type:"",
    status:"",
    ratings:"",
  });
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const [searchBy, setSearchBy] = useState("all");
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    searchBy: "",
    type:"",
    status:"",
    ratings:"",
  });

  useEffect(()=>{
     localStorage.setItem("viewMode", viewMode);
      setParams({...params,page:page,per_page:pageCount})
  },[page,pageCount,viewMode])

  const clearFilter = ()=> {
    setParams({...params, type:"",
      status:"",
      ratings:"",})
      setFilter(false)
      formik.values.type=""
      formik.values.status=""
      formik.values.ratings=""


  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,type:value.type, status:value.status,ratings:value.ratings})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        type:"",
    status:"",
    ratings:"",
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch( "/agents/", params );
  
   const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "agentId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "agentId",
      link: '/shipping/agents/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Phone",
      width: "10%",
      field: "phone.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Billing Address",
      width: "10%",
      field: "billingAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Address",
      width: "10%",
      field: "shippingAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "City",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Country",
      width: "10%",
      field: "country",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Countact Person ",
      width: "10%",
      field: "cpName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Countact Person Email",
      width: "10%",
      field: "cpEmail",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Countact Person Phone",
      width: "10%",
      field: "cpPhoneWithCC",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Countact Person Desigination",
      width: "10%",
      field: "cpDesigination",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "CR Number",
      width: "10%",
      field: "crNum",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vat Number",
      width: "10%",
      field: "vatNum",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Type",
      width: "10%",
      field: "type",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "District",
      width: "10%",
      field: "district",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Street Name",
      width: "10%",
      field: "streetName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "State",
      width: "10%",
      field: "state",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "PostalCode",
      width: "10%",
      field: "postalCode",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Pub Id",
      width: "10%",
      field: "pubId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "ratings",
      width: "10%",
      field: "ratings",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"agentId",
        removeValue:"companyName",
        url:`/agents/`,
        moduleName:"Agent",
        from:`/shipping/agents`,
        editLink:"/shipping/agents/edit/",
        viewLink:"/shipping/agents/view/"
      }
    },
   ];
  const defaultHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "agentId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "agentId",
      link: '/shipping/agents/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Phone",
      width: "10%",
      field: "phone.number",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Billing Address",
      width: "10%",
      field: "billingAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Address",
      width: "10%",
      field: "shippingAddress",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"agentId",
        removeValue:"companyName",
        url:`/agents/`,
        moduleName:"Agent",
        from:`/shipping/agents`,
        editLink:"/shipping/agents/edit/",
        viewLink:"/shipping/agents/view/"
      }
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '33px',
      height: '33px',
      fontSize: '12px',
      minWidth: '100px',
      width: '100px', 
      borderRadius: '8px',
      border: "none"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
  };

  let searchList = [
    { value: "all", label: "All" },
    { value: "services", label: "Services" },
  ];

  function handleSearch(e) {
    setSearchBy(e.value);
    setParams({...params,searchBy:e.value})
  }

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };

  return (
    <>
      <div className="w-full h-full px-4 py-2">
        <div className="w-full flex justify-between items-center">
          <Breadcrumb
            items={[
              {
                name: "Shipping",
              },
              {
                name: "Agents",
              },
            ]}
          />
          <div className=" flex gap-2">
            <Select
              options={searchList}
              value={searchList.find((i) => i.value == searchBy) || ""}
              styles={customStyles}
              className={`text-xs capitalize 2xl:text-sm font-medium `}
              onChange={handleSearch}
            />
            <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />
            <div className="flex gap-2">
              <div
                onClick={() => setViewMode("list")}
                className={
                  viewMode === "list"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <List />
              </div>
              <div
                onClick={() => setViewMode("card")}
                className={
                  viewMode === "card"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <Card />
              </div>
            </div>
            <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {GetAccess("agents_create") &&   <ImportButton handleClick={handleImportClick} />}
          {GetAccess("agents_create") &&   <Link to="/shipping/agents/create">
              <CreateButton label="Agents" />
            </Link>}
          </div>
        </div>
        {viewMode === "list" ? (
          <CustomDataTable
          fetcher={getData}
            data={data}
            availableHeaders={availableHeaders}
            defaultHeader={defaultHeaders}
            bodyHeight="max-h-[calc(100vh-260px)]"
            height="h-[calc(100vh-145px)]"
            total={totalCount}
            pageIndex={params.page}
            setPageIndex={setPage}
            dataCount={params.per_page}
            setDataCount={setPageCount}
            title="Agents List"
            storageName="AgentsTable"
            getById="agentId"
            editAccess="agents_edit"      
            deteleAccess="agents_delete" 
          />
        ) : (
          <>
          <div className="w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 overflow-y-scroll">
           <div className='flex gap-40'>
       
           <p className='text-xs font-semibold text-[#111537] text-left'>Agents : {totalCount}</p>
           {/* <p className='text-sm'>Credit : {}</p>
         <p className='text-sm'>Advance : {}</p>
         */}
        </div> 
            <div className="w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4">
              {data &&
                data.map((item, idx) => (
                  <AgentCard fetcher={getData} item={item} key={idx} />
                ))}
            </div>
          </div>
          <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
        )}
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Agents</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              type="text"
              label="Agent Type"
              placeholder="Choose"
              Options={Type}
              formik={formik}
              name="type"
                width="w-full "           />
                 <FormikSelectTwo
              type="text"
              label="Ratings"
              placeholder="Choose"
              Options={Rating}
              formik={formik}
              name="ratings"
               width="w-full"
            />
                 <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
                width="w-full "           />

                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
      </div>
    </>
  );
}

export default AgentIndex;
