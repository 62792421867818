import React, { useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import { useEffect } from "react";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { NuDataTime, NuDate } from "../../../../Components/Utils/Input";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditCQ from "./MultiEditCQ";
import { GetAccess } from "../../../../Components/Utils/roles";

function CustomerQuotationIndex() {
  const { value: Availablity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Availablity_CustomerQuotes"
  );
  const { value: DeliveryTerm } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerm_CustomerQuotes"
  );
  const { value: PaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_CustomerQuotes"
  );
  const { value: PaymentMethod } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentMethod_CustomerQuotes"
  );
  const { value: Validity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Validity_CustomerQuotes"
  );
  const { value: status } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_customerQuotes"
  );
  const { value: leadTime } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "leadTime_customerQuotes"
  );

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    status: "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    paymentTerms: "",
    deliveryTerms: "",
    fromDate: "",
    toDate: "",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    status: "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    paymentTerms: "",
    deliveryTerms: "",
    fromDate: "",
    toDate: "",
  });
  const clearFilter = () => {
    setParams({
      ...params,
      status: "",
      paymentMethod: "",
      leadTime: "",
      validity: "",
      availability: "",
      paymentTerms: "",
      deliveryTerms: "",
      fromDate: "",
      toDate: "",
    });
    formik.values.status = "";
    formik.values.paymentMethod = "";
    formik.values.leadTime = "";
    formik.values.validity = "";
    formik.values.availability = "";
    formik.values.deliveryTerms = "";
    formik.values.fromDate = "";
    formik.values.toDate = "";
    formik.values.paymentTerms = "";

    setFilter(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({
        ...params,
        paymentTerms: value.paymentTerms,
        toDate: value.toDate,
        fromDate: value.fromDate,
        deliveryTerms: value.deliveryTerms,
        availability: value.availability,
        validity: value.validity,
        leadTime: value.leadTime,
        paymentMethod: value.paymentMethod,
      });
      closeFilterDialog();
      setFilter(true);
      setFilterProps({
        status: "",
        paymentMethod: "",
        leadTime: "",
        validity: "",
        availability: "",
        paymentTerms: "",
        deliveryTerms: "",
        fromDate: "",
        toDate: "",
      });
    },
  });

  const { data, totalCount, getData } = UseGetDataSearch(
    "/customer-quotations/",
    params
  );

  const defaultHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Reference",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purchase Request",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Quote Date",
      width: "10%",
      field: "date",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/`,
        moduleName: "Customer Quotations",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/edit/{id}/viewqoute",
        viewLink: "/sales/quotes/view/",
        EmailRequired: true,
      },
    },
  ];
  const availableHeaders = [
    // {
    //   title: "ID",
    //   width: "5%",
    //   field: "id",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Reference ",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purchase Request",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Quote Date",
      width: "10%",
      field: "date",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Remarks",
    //   width: "10%",
    //   field: "remarks",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
   
    // {
    //   title: "Availability",
    //   width: "10%",
    //   field: "availability",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    
   
    // {
    //   title: "Lead Time",
    //   width: "10%",
    //   field: "leadTime",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Payment Method",
    //   width: "10%",
    //   field: "paymentMethod",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Payment Terms",
      width: "10%",
      field: "paymentTerms",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Prepared By ",
      width: "10%",
      field: "preparedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Reviewed By ",
      width: "10%",
      field: "reviewedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Special Notes",
    //   width: "10%",
    //   field: "specialNotes",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Validity",
      width: "10%",
      field: "validity",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Delivery Terms",
      width: "10%",
      field: "deliveryTerms",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Total Price",
      width: "10%",
      field: "totalPrice",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Approved By ",
      width: "10%",
      field: "approvedByName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/`,
        moduleName: "Customer Quotations",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/edit/{id}/viewqoute",
        viewLink: "/sales/quotes/view/",
        EmailRequired: true,
      },
    },
  ];

  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount });
  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.id);
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[{ name: "Sales" }, { name: "Customer Quotation" }]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />
          <FilterButton handleClick={() => setShowFilterDialog(true)} />
          {filter && (
            <FilterButton handleClick={() => clearFilter()} label="Clear" />
          )}

          {/* <CreateButton label="Import" /> */}
          {GetAccess
          ("customer_quote_create") &&   <Link to="/sales/quotes/create/viewqoutes">
            <CreateButton label="Quote" />
          </Link>}
        </div>
      </div>
      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeaders}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Customer Quotes List"
        storageName="CustomerQuoteTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="id"
        editAccess="customer_quote_edit"      
        deteleAccess="customer_quote_delete" 
      />
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Filter Customer-Quotation</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4">
                <p className="py-2 px-6 text-m">Date</p>
                <div className="flex justify-between  w-full ">
                  <NuDate
                    label="From "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="fromDate"
                    width="w-full "
                  />
                  <NuDate
                    label="To "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="toDate"
                    width="w-full "
                  />
                </div>
                <FormikSelectTwo
                  type="text"
                  label="Deliver Terms"
                  placeholder="Choose"
                  Options={DeliveryTerm}
                  formik={formik}
                  name="deliverTerms"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Payment Terms"
                  placeholder="Choose"
                  Options={PaymentTerms}
                  formik={formik}
                  name="paymentTerms"
                  width="w-full "
                />

                {/* <FormikSelectTwo
                  label="Payment Method"
                  placeholder="Choose"
                  Options={PaymentMethod}
                  formik={formik}
                  name="paymentMethod"
                  width="w-full "
                /> */}
                {/* <FormikSelectTwo
                  type="text"
                  label="Availability"
                  placeholder="Choose"
                  Options={Availablity}
                  formik={formik}
                  name="availability"
                  width="w-full "
                /> */}
                <FormikSelectTwo
                  type="text"
                  label="Validity"
                  placeholder="Choose"
                  Options={Validity}
                  formik={formik}
                  name="validity"
                  width="w-full "
                />
                {/* <FormikSelectTwo
                  type="text"
                  label="Lead Time"
                  placeholder="Choose"
                  Options={leadTime}
                  formik={formik}
                  name="leadTime"
                  width="w-full "
                /> */}
                
                <FormikSelectTwo
                  type="text"
                  label="Status"
                  placeholder="Choose"
                  Options={status}
                  formik={formik}
                  name="status"
                  width="w-full"
                />
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div>
            </form>
          </div>
        </div>
      )}
                         {showEdit && (<MultiEditCQ data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  );
}

export default CustomerQuotationIndex;
