
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Industry = () => {
  return (
    <SettingsMetaData propertyName={"Industry_Customer"} viewPropertyName={"Industry"} />
  );
};

export default Industry;
