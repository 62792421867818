import React from 'react';
import Pagination from '../Utils/Pagenation';
import { useTranslation } from 'react-i18next';
// import Pagination from '../Utils/Pagenation';

function DataTable({headers,total,pageIndex,setPageIndex,dataCount,setDataCount,children,height="h-[calc(100vh-130px)]",bodyHeight="max-h-[calc(100vh-200px)]"}) {
  const {t,i18n} = useTranslation();
  
  return (
    <div className={`w-full ${height} rounded-md bg-white p-3 mt-4`}>
    <div className="w-full h-[93%] overflow-hidden">
  <div className="w-full">
    <div className=" w-full " style={{borderBottom:"1px solid black"}}>
      <table className="w-full" dir={i18n.language =="ar"?"rtl":""}>
        <thead>
          <tr className="text-xs font-semibold">
            {headers &&
              headers.map((header, i) => (
                <th
                  className={`p-2 font-medium truncate ${header.width}`}
                  key={i}
                >
                  {header.label|| "-"}
                </th>
              ))}
          </tr>
        </thead>
      </table>
    </div>
    <div className={`${bodyHeight} z-10 pt-1 pb-4 overflow-y-auto`}>
      <table className="w-full table-auto border-collapse" dir={i18n.language =="ar"?"rtl":""}>
        <tbody className="text-xs w-full">
            {children}
        </tbody>
      </table>
    </div>
  </div>
</div>
<div className=' w-full h-[40px] '>
<Pagination
  total={total}
  pageIndex={pageIndex}
  setPageIndex={setPageIndex}
  dataCount={dataCount}
  setDataCount={setDataCount}
/>
</div>
</div>
  )
}

export default DataTable