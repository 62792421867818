import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      padding: 10,
      fontSize: 12,
      fontFamily: 'Helvetica',
    },
    headingContainer: {
      flexDirection: 'row',
      alignItems: 'center', 
      marginBottom: 10,
    },
    headingTitle: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    headingText: {
      marginTop: 5,
      fontSize: 14,
    },
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: 10,
    },
    label: {
      width: '50%',
      padding: 5,
      fontSize: 12,
      fontWeight: 'bold',
    },
    value: {
      width: '50%',
      padding: 5,
      fontSize: 12,
    },
    productRow: {
      flexDirection: 'row',
      padding: 5,
    },
    productHeader: {
      backgroundColor: '#1e90ff',
      color: '#fff',
      padding: 5,
      fontSize: 12,
      fontWeight: 'bold',
    },
    productCell: {
      padding: 5,
      fontSize: 12,
    },
    note: {
      marginTop: 20,
      padding: 10,
      borderWidth: 1,
      flexDirection: 'row',
      justifyContent: 'space-between', 
      borderColor: '#ccc',
    },
  });
  const DownloadCo = ({ data }) => (
    <Document>
    <Page size="A4" style={styles.page} orientation="portrait">
      <View style={styles.headingContainer}>
        <Text style={styles.headingTitle}>Customer Order</Text>
        <Text style={styles.headingText}>: {data.ref}</Text>
      </View>

      <View style={styles.section}>
        {[
          { label: 'Purchase Requests', value: data.prRef },
          { label: 'Customer Qoute', value: data.cqRef },
          { label: 'Received On', value: data.receivedOn || 'N/A'},
          { label: 'Dead Line', value: data.deadLine || 'N/A'},
          { label: 'Verified By', value: data.verifiedByName || 'N/A'},
          { label: 'Reviewed By ', value: data.reviewedByName || 'N/A'},
          { label: 'Approved By', value: data.approvedByName || 'N/A'},
          { label: 'Sales Person', value: data.salesPersonName || 'N/A'},
          { label: 'Assignee', value: data.assigneeName || 'N/A'},
          { label: 'Status', value: data.status || 'N/A'},
               ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>{item.value}</Text>
          </View>
        ))}
      </View>

      <View style={{ marginTop: 20, backgroundColor: '#D3D3D3' }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            'S.N',  'Item Code','Product Name', 'Product Unit',
            'Quantity', 'Price'
          ].map((header, index) => {
            let width=[5, 20, 30, 10, 15, 20]
            return(
            <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
              {header}
            </Text>
          )})}
        </View>
        {data.products.map((product, index) => (
          <View key={index} style={styles.productRow}>
            <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
            <Text style={[styles.productCell, { width: '20%' }]}>{product.item_code}</Text>
            <Text style={[styles.productCell, { width: '30%' }]}>{product.product_name}</Text>
            <Text style={[styles.productCell, { width: '10%' }]}>{product.product_unit}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
            {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
            <Text style={[styles.productCell, { width: '15%' }]}>{product.quantity}</Text>
            <Text style={[styles.productCell, { width: '20%' }]}>{product.price}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
          </View>
        ))}
      </View>

      <View style={styles.note}>
        <Text>Note From Customer: {data.notesFromCus || 'N/A'}</Text>

        <Text>Total Price: {data.totalPrice}</Text>
      </View>
    </Page>
  </Document>
  );
  

export default DownloadCo