import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Modal from '../../../../Components/Utils/AttachmentView';

function ViewLeads() {
    const { leadsViewId} = useParams();
  const location = useLocation();
  const data = location.state.data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);

  useEffect(()=>{
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      setFilePathList(data?.attachments);
      // console.log('image',createNew?.attachments);
      // setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
},[data])

function UpdateAttachmentList(item) {
let filterList = filePathList.filter((itemList) => itemList.file !== item);
console.log('llll',filterList);
setFilePathList(filterList);
}

function handleImageClick(img) {
setSelectedImage(img);
setIsModalOpen(true);
}
return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb 
        items={[
            {
                name:"Lead",
                link:"/marketing/leads"

            },
            {
                name:"View Lead"
            }
        ]}
        />
      <div className=" flex gap-2">
        <Link to={'/marketing/leads'}><BackButton link={true}/></Link>
        <Link to={`/marketing/leads/edit/${leadsViewId}`} 
        state={{data: data, from:`/marketing/leads/view/${leadsViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <NormalHeading title={"Lead Detail"} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col'>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                 <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Name</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.name}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Industry</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.industry}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.email}</p>
              </div>
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.mobile.display}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Size</p>
                <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data.companySize}</p>
              </div> 
             
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Conversion Probabilities</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.conversionProbabilities}</p>
              </div> 
              {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Description</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.description}</p>
              </div> */}
             
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Lead Source</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.leadSource}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Ratings</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.ratings}</p>
              </div> 
</div>
</div>
              <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


<div className='w-full  flex  '>
<div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>

             
             
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.country}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.state}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data.city}</p>
              </div> 
               <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Zip code</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.postalCode}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Address Line 1</p>
                <p className='w-1/2 py-2 pl-5 text-xs text-[#606279]'>{data.address1}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Address Line 2</p>
                <p className='w-1/2 py-2 pl-5 text-xs text-[#606279]'>{data.address2}</p>
              </div> 
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.status}</p>
              </div>
              <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data.comments}</p>
              </div>
             
            
        
             

            
              </div>
            </div>
    </div>
    <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

<div>
<NormalHeading title={"Lead Attachement"} />
<div className="md:flex justify-between mt-5 px-3 w-full">
                        <div className="w-full flex gap-2 items-center flex-wrap">
                            {data?.attachments &&
                                filePathList.map((imgs, idx) => {
                                    console.log('img', imgs);
                                    return (
                                      <div
                                      className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                      key={idx}
                                      style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                  >
                                    <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                                {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                <img src={imgs?.file} alt={imgs?.file} />
                                            </div>
                                            </div>
                                              );
                                            })}
        
</div>
</div>
</div>

<Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <img src={selectedImage?.file} alt={selectedImage?.file} />
  </Modal>


  </div>
  </div>
)
};
export default ViewLeads;