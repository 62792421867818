import { useContext, createContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CircleLeftArrow from "../../Components/Icons/CircleLeftArrow";
import CircleRightArrow from "../../Components/Icons/CircleRightArrow";
import { useTranslation } from "react-i18next";
import Settings from "../../Components/Icons/Settings";
import { GetAccess, GetMultipleAccess } from "../../Components/Utils/roles";

const SidebarContext = createContext();

const Submenu = ({ subItems }) => {
  const { t, i18n } = useTranslation();

  const [topPosition, setTopPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const submenuHeight = subItems.length * 75; // Adjust this value based on the actual submenu item height
    if (screenHeight < submenuHeight) {
      console.log(screenHeight - submenuHeight);
      setTopPosition(screenHeight - submenuHeight);
    }
    setIsVisible(true);
  }, [subItems]);

  return (
    <div
      style={{ top: `${topPosition}px` }}
      //  className={`absolute rounded-md border border-[#048178] border-solid border-opacity-20 overflow-hidden left-full bg-[#E6F2F2] w-48 shadow-lg p-2 ${isVisible ? 'transition-opacity duration-1000 opacity-100' : 'opacity-0'} ease-in-out`}
      className={`
    absolute left-full  rounded-md px-1 ml-1
    bg-indigo-100 text-indigo-800  text-left
    invisible opacity-20 -translate-x-3 transition-all
    group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 w-52 text-sm
`}
    >
      {subItems.map((subItem, subIndex) => (
        <>
          {GetAccess(subItem?.hasAccess || "") && (
            <Link
              key={subIndex}
              to={`${subItem.link}`}
              className="flex justify-start my-1 rounded-md px-3 py-[3px] xl:py-2 bg-[#E6F2F2] text-gray-800 hover:bg-[#048178] hover:text-[#E6F2F2]"
            >
              {/* <i className={`fas fa-${subItem.icon} text-lg mr-2`}></i> */}
              <p
                className={` text-[11px] 2xl:text-sm text-left ${
                  i18n.language == "ar" ? "text-right" : "text-left"
                }`}
              >
                {t(subItem.name)}
              </p>
            </Link>
          )}
        </>
      ))}
    </div>
  );
};

export default function Sidebar({ children }) {
  const [expanded, setExpanded] = useState(true);
  const { t, i18n } = useTranslation();

  function getExpand() {
    let locExp = localStorage.getItem("expand");
    if (locExp) {
      setExpanded(JSON.parse(locExp));
    } else {
      localStorage.setItem("expand", true);
      setExpanded(true);
    }
  }

  useEffect(() => {
    getExpand();
  }, []);

  function toggleExpanded(e) {
    setExpanded(e);
    localStorage.setItem("expand", e);
  }

  return (
    <aside className="h-full z-[997]">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <div
            className={`overflow-hidden font-medium text-xl text-baseColor-primary text-center  transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
          >
            nutrade
          </div>
          {/* <img
            src="https://img.logoipsum.com/243.svg"
            className={`overflow-hidden transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
            alt=""
          /> */}
          <button
            onClick={() => toggleExpanded(!expanded)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <CircleLeftArrow /> : <CircleRightArrow />}
          </button>
        </div>
        <div className=" w-full h-[calc(100vh-120px)] ">
          <SidebarContext.Provider value={{ expanded }}>
            <ul
              className="flex-1 px-3"
              dir={i18n.language == "ar" ? "rtl" : ""}
            >
              {children}
            </ul>
          </SidebarContext.Provider>
        </div>

        {GetMultipleAccess([
          "company_info_view",
          "product_view",
          "marketing_view",
          "procurement_view",
          "sales_view",
          "payment_view",
          "store_view",
          "shipping_view",
          "maintenance_view",
          "user_view",
          "role_view",
          "user_role_view",
          "notification_view",
          "reference_view",
        ]) && (
          <div className="border-t flex p-3">
            <Link className="flex justify-between items-center" to="/settings">
              <div className="p-2 bg-[#c7d2fe] rounded-lg">
                <Settings color="#048178" height={18} width={18} />
              </div>
            </Link>
            <Link className="flex justify-between items-center" to="/settings">
              <div
                className={`
              flex justify-between items-center
               overflow-hidden transition-all ${
                 expanded ? "w-28 xl:w-32 ml-3" : "w-0"
               }
          `}
              >
                <div className="leading-4">
                  <h4 className="font-semibold  text-xs 2xl:text-sm">
                    Settings
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        )}

        <div className=" w-full px-4">
          <p className={`font-normal text-left ${expanded?"text-xs":"text-[10px]"} text-slate-400`}>
            <a href="https://numota.in" target="_blank">
             {expanded && <span>&#169;</span>} numota
            </a>
          </p>
        </div>
        {/* </div> */}
      </nav>
    </aside>
  );
}

export function SidebarItem({
  icon,
  text,
  active,
  alert,
  hasSubMenu,
  subItem,
  link,
}) {
  const { expanded } = useContext(SidebarContext);
  const { t, i18n } = useTranslation();

  return (
    <>
      {!hasSubMenu ? (
        <Link to={link}>
          <li
            className={`
        relative flex items-center py-1 xl:py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${
          active == true
            ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
            : "hover:bg-indigo-50 text-gray-600"
        }
    `}
          >
            <span className=" w-4 justify-center items-center  text-xs 2xl:text-sm">
              {icon}
            </span>

            <span
              className={`overflow-hidden transition-all text-xs 2xl:text-sm ${
                expanded
                  ? `w-28 xl:w-32 ${i18n.language == "ar" ? "mr-3" : "ml-3"} `
                  : "w-0"
              }`}
            >
              <Link to={link}>{text}</Link>
            </span>
            {alert && (
              <div
                className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
                  expanded ? "" : "top-2"
                }`}
              />
            )}
          </li>
        </Link>
      ) : (
        <li
          className={`
      relative flex items-center py-1 xl:py-2 px-3 my-1
      font-medium rounded-md cursor-pointer
      transition-colors group
      ${
        active == true
          ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
          : "hover:bg-indigo-50 text-gray-600"
      }
  `}
        >
          <span className=" w-4 flex justify-center items-center  text-xs 2xl:text-sm">
            {icon}
          </span>

          <span
            className={`overflow-hidden transition-all text-xs 2xl:text-sm ${
              expanded
                ? `w-28 xl:w-32 ${i18n.language == "ar" ? "mr-3" : "ml-3"} `
                : "w-0"
            }`}
          >
            {text}
          </span>
          {alert && (
            <div
              className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
                expanded ? "" : "top-2"
              }`}
            />
          )}

          <Submenu subItems={subItem || []} />
        </li>
      )}
    </>
  );
}
