import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useState } from "react";
import { NuInput, NuMobileNumber, NuSelect, NuTextArea, NuUpload, SupplierNuInput } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const CreateAgents = () => {
  const {value:Type ,getData:refType} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentType')
  const {value:Designation ,getData:refDesignation} = useFetchMataData(`/dropdown/dropdown-details/`,'AgentDesignation')
  const { value:Rating  } = useFetchMetaStar(`/dropdown/dropdown-details/`,'AgentRating')

  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [servicesList, setServicesList] = useState([]);
  const servicesString = servicesList.join(', ');
  const [createNew, setCreateNew] = useState({
    type: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    billingAddress: "",
    shippingAddress: "",
    crNum: "",
    vatNum: "",
    ratings: "",
    comments: "",
    country: "",
    city: "",
    state: "",
    district: "",
    postalCode: "",
    cpName: "",
    cpEmail: "",
    cpDesignation: "",
    cpCountryCode: "",
    cpPhone: "",
    services: ""
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      const valueWithProducts = {
        ...value,
        services: servicesString
      };
      formData.append("agentDetails", JSON.stringify(valueWithProducts));
      formData.append('agentImage', image.raw);

      addFormData("/agents/new-agent", formData, resetForm);
    },
  });

  const handleAddServices = () => {
    const services = formik.values.services.split(',').map((p) => p.trim()).filter(Boolean);
    setServicesList((prevList) => [...prevList, ...services]);
    formik.setFieldValue('services', '');
  };

  const handleDeleteServices = (index) => {
    setServicesList((prevList) => prevList.filter((_, i) => i !== index));
  };

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
          items={
            [
              {
                name: "Shipping",
                link: "/shipping/agents"
              },
            {
              name: "Agents",
              link: "/shipping/agents"
            },
            {
              name: "Create Agents"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Agent Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

          <DropDownAddMetaData
                label="Agent Type"
                placeholder="Choose"
                Options={Type}
                formik={formik}
                name="type"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refType}
                displayProperty={"Agent Type"}
                propertyName={"AgentType"}
              />
            
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="phone"
              placeholder="phone"
              formik={formik}
              name="phone"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuInput
              type="text"
              label="website"
              placeholder="website"
              formik={formik}
              name="website"
                width="w-full md:w-1/2 lg:w-1/3"
            />
          <NuInput
              type="text"
              label="Contact Person Name"
              placeholder="cpName"
              formik={formik}
              name="cpName"
                width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuMobileNumber
              nunCode="cpCountryCode"
              label="phone"
              placeholder="phone"
              formik={formik}
              name="cpPhone"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="email"
              label="Contact Person Email"
              placeholder="cpEmail"
              formik={formik}
              name="cpEmail"
                width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Contact Person Desigation"
                placeholder="Choose"
                Options={Designation}
                formik={formik}
                name="cpDesignation"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDesignation}
                displayProperty={"Contact Person Desigation"}
                propertyName={"AgentDesignation"}
              />
           
           
           
           
            <NuInput
              type="number"
              label="CRNum"
              placeholder="CRNum"
              formik={formik}
              name="crNum"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Vat Num"
              placeholder="vatNum"
              formik={formik}
              name="vatNum"
                width="w-full md:w-1/2 lg:w-1/3"
            />
          
             <FormikSelectTwo
              type="text"
              label="Ratings"
              placeholder="Choose"
              Options={Rating}
              formik={formik}
              name="ratings"
                width="w-full md:w-1/2 lg:w-1/3"
            />
              <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Address</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

              <NuTextArea
              type="text"
              label="Billing Address"
              placeholder="billingAddress"
              formik={formik}
              name="billingAddress"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              type="text"
              label="Shipping Address"
              placeholder="shippingAddress"
              formik={formik}
              name="shippingAddress"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Country"
              placeholder="country"
              formik={formik}
              name="country"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="state"
              formik={formik}
              name="state"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="district"
              formik={formik}
              name="district"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="city"
              formik={formik}
              name="city"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Postal Code"
              placeholder="postalCode"
              formik={formik}
              name="postalCode"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
                width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />
              <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Services </p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

            <div className="w-1/4 flex flex-col ">
              <div className="flex justify-start">
              <SupplierNuInput
                type="text"
                label="Services"
                placeholder="separate services with ','"
                formik={formik}
                name="services"
                width="w-11/12"
              />
              <button
                onClick={handleAddServices}
                  type="button"
                 className="mt-10 w-10 h-10 text-center cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-md "
                   >
                +
               </button>
               </div>
              <div className="w-11/12 h-28 ml-[8%] rounded-md p-1 flex flex-col border border-solid overflow-y-scroll">
                {servicesList.map((word, index) => (
                  <span key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {word}
                    <span
                onClick={() => {
                  handleDeleteServices(index);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </span>
                ))}
              </div>
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAgents;
