import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { NormalHeading } from '../../../../Components/Utils/Heading';

function ViewUser() {
  const location = useLocation();
    const data = location.state.data;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <Breadcrumb
         items={
          [
            {
              name: "Settings",
              link: "/settings/user"
            },  
          {
            name: "User",
            link: "/settings/user"
          },
          {
            name: "View User"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/settings/user'}><BackButton link={true}/></Link>
          <Link to={`/settings/user/edit/${data?.userId}`} state={{data: data, from:`/settings/user/view/${data.userId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-120px)] bg-white rounded-xl overflow-y-scroll p-3">
      <NormalHeading title={"User Details"} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>User ID</p>
                    <p className='w-1/2 py-2 pl-5  text-sm  text-[#606279]'>{data?.userId || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Name</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.name || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Division</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.division || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.email || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Phone</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.phone?.number || '-'}</p>
                  </div>
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>City</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.city || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>State</p>
                    <p className='w-1/2 py-2 pl-5 text-sm  text-[#606279]'>{data?.state || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Country</p>
                    <p className='w-1/2 py-2 pl-5  text-sm  text-[#606279]'>{data?.country || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Postal Code</p>
                    <p className='w-1/2 py-2 pl-5  text-sm  text-[#606279]'>{data?.postalCode || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 pl-5  text-sm  text-[#606279]'>{data?.status == "" ? "In-Active" : 
                                                                               data?.status == 1 ? "Active" :
                                                                                "-"}
                                                                               </p>
                  </div>
          </div>
        </div>
      </div>
              
          </div>
          
      </div>
  
  )
}

export default ViewUser;