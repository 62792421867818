import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

const Industry = () => {
  const { data, loading } = UseGetDashBoardData(`/dashboard/customers-by-industry`);

  const COLORS = ['#0C1844', '#1679AB', '#DA7297', '#55AD9B'];

  return (
    <div className="col-span-2 bg-white h-[40vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="flex justify-between items-center mb-2">
        <TextMediumSM content="Customer By Industry" color="text-[#4F4768]" />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <>
            <div className='h-full overflow-y-scroll'>

          <div className="flex gap-2 overflow-y-scroll">
            <div className="w-[40%] flex justify-center items-center ">
              <ResponsiveContainer width="100%" height={150}>
                <PieChart>
                  <Pie
                    data={data?.customers_by_industry || []}
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={60}
                    fill="#8884d8"
                    dataKey="count"
                  >
                    {data?.customers_by_industry?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-[60%] flex flex-col justify-center ">
              {data?.customers_by_industry?.map((item, index) => (
                <div key={index} className="flex items-center text-xs mb-1">
                  <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  ></span>
                  <p className='w-[50%]'>{item.industry}</p>
                  <p className='w-[10%]'>: {item.count}</p>
                </div>
              ))}
            </div>
          </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Industry;
