
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Status = () => {
  return (
    <SettingsMetaData propertyName={"Status_invoices"} viewPropertyName={"Status"} />
  );
};

export default Status;
