import React from 'react'

function Video({width="44", height="45"}){ 
  return (
    <svg width={width} height={height} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.6236 0.324219H7.44655C3.41804 0.324219 0.152283 3.58998 0.152283 7.61849V36.7956C0.152283 40.8241 3.41804 44.0898 7.44655 44.0898H36.6236C40.6522 44.0898 43.9179 40.8241 43.9179 36.7956V7.61849C43.9179 3.58998 40.6522 0.324219 36.6236 0.324219Z" fill="#D1EEFE"/>
<path d="M24.9556 10.5254H15.0259C14.4063 10.5254 13.812 10.7715 13.3738 11.2097C12.9357 11.6479 12.6895 12.2421 12.6895 12.8618V31.5529C12.6895 32.1726 12.9357 32.7668 13.3738 33.205C13.812 33.6432 14.4063 33.8893 15.0259 33.8893H29.0443C29.6639 33.8893 30.2582 33.6432 30.6963 33.205C31.1345 32.7668 31.3807 32.1726 31.3807 31.5529V16.9505L24.9556 10.5254Z" stroke="#119DEE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24.3715 10.5254V17.5346H31.3807M19.6987 21.0392L25.5397 24.5437L19.6987 28.0483V21.0392Z" stroke="#119DEE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default Video