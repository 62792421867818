
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const LeadSource = () => {
  return (
    <SettingsMetaData propertyName={"lead_Source"} viewPropertyName={"lead Source"} />
  );
};

export default LeadSource;
