import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const StatusShipping = () => {
  return (
    <SettingsMetaData propertyName={"ShippingStatus"} viewPropertyName={"Shipping Status"}/>

  )
}

export default StatusShipping