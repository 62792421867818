// function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }
// export default capitalizeFirstLetter;
function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') {
      console.error('Expected a string but received:', typeof string, string);
      return ''; // Return an empty string or handle it as needed
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
export default capitalizeFirstLetter;
  