import React, { useEffect, useState } from 'react';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, CancelButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import SideModal from '../../../../Components/Others/SideModel';
import axiosInstance from '../../../../Services/axiosCommon';
import NuDate from '../../../../Components/Utils/NuDate';
import PDFLinkRFQ from './pdf/PDFLinkRFQ';
import ViewPdf from './ViewPdf';
import Comments from './CommentsPr';
import CommonComments from '../../../../Components/Utils/commonComments';
import capitalizeFirstLetter from '../../../../Components/Utils/Funcs';
import Modal from '../../../../Components/Utils/AttachmentView';
import DeleteIcon from '../../../../Components/Icons/Delete';
import { Tab, Tabs } from '../../../../Layouts/MainPage/Tabs';
import NuAmount from '../../../../Components/Utils/NuAmount';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import SQPdfOthers from '../../ViewPdfTemplate/SQPdfOthers';
import FilesView from '../../../../Components/Utils/FilesView';

function ViewRFQ() {
  const { rfqViewId } = useParams();
  // const location = useLocation();
  // const data = location.state.data;
  // console.log(data,"ll");

  const [data,setData] = useState([]);
  const getRFQById = () =>{
    axiosInstance.get(`/rfq/${rfqViewId}`)
    .then((res)=>{
      console.log("rfqget",res?.data?.data);
      setData(res?.data?.data)
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  const [QData,setQData] = useState([]);
  const getSQById = (id) =>{
    if (!id) {
      console.error("Error: ID is required but was not provided.");
      return;
    }
    axiosInstance.get(`/supplier-quotations/${id}`)
    .then((res)=>{
      console.log("rfqget",res?.data?.data);
      setQData(res?.data?.data)
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getRFQById();
    getSQById(data?.supplierQuote);
  },[data?.supplierQuote])
  
  const [activeButton,setActiveButton] = useState(1)
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [prlist, setPrlist] = useState([]);
  const [showPdfComponent, setShowPdfComponent] = useState(false);
  const [showPdfComponentSQ, setShowPdfComponentSQ] = useState(false);


  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const handlePdfIconClickSQ = () => {
    setShowPdfComponentSQ(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };
  const getPr = () => {
    axiosInstance.get(`/PR/pr-products/${data.prId}`).then((res) => {
      if (res?.data?.data) {
        setPrlist(res.data.data);
        // data.productList = res.data.data;
      }
    }).catch((err)=>{
      console.log(err);
    })
  };

  useEffect(() => {
    getPr();
  }, [data.prId]);

  const getInitials = (name) => {
    const names = name.split(' ');
    if (names.length > 1) {
      return names[0][0] + names[1][0];
    }
    return names[0][0];
  };



  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/rfq/download-pdf/${rfqViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};

const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedImage, setSelectedImage] = useState(null);
const [filePathList, setFilePathList] = useState([]);
const [filePathListSQ, setFilePathListSQ] = useState([]);


useEffect(() => {
  if (
    data?.attachments !== null &&
    data?.attachments !== "None" &&
    data?.attachments !== "[]"
  ) {
    setFilePathList(data?.attachments?.length>0 ? data?.attachments : []);
    // console.log('image',createNew?.attachments);
    // setRemoveFilePathList(data?.productImage);
    // console.log('images',filePathList);
  }
}, [data]);

useEffect(() => {
  if (
    QData?.attachments !== null &&
    QData?.attachments !== "None" &&
    QData?.attachments !== "[]"
  ) {
    setFilePathListSQ(QData?.attachments?.length>0 ? QData?.attachments : []);
  }
}, [QData]);

function UpdateAttachmentList(item) {
  let filterList = filePathList.filter((itemList) => itemList.file !== item);
  console.log("llll", filterList);
  setFilePathList(filterList);
}

function handleImageClick(img) {
  setSelectedImage(img);
  setIsModalOpen(true);
}

// const deleteSupplierQuote = () =>{
//   axiosInstance.delete(``)
// }  
const [revData,setRevData] = useState([])
  useEffect(() => {
    if(data?.supplierQuote)getRevData(data?.supplierQuote)
  },[data?.supplierQuote])

  function getRevData(id){
    axiosInstance.get(`/supplier-quotations/products-lists/${id}`).then(res=>{
      console.log("ree",res?.data?.data);
      if(res?.data?.data)setRevData(res?.data?.data)
      
    }).catch(err=>{
      console.log("err", err);
    })
  }

    const [deleteId,setDeleteId] = useState()
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const { handle } = useDeleteData(getRFQById);
    const handleDelete = async () => handle(`/supplier-quotations/` + `${deleteId}`,setIsModalDeleteOpen);

  return (
    <div className="w-full h-full py-2 px-4">
      <style jsx>{`
        .circle-container .circle {
          position: relative;
          display: inline-block;
        }

        .circle-container .circle .circle-tooltip {
          visibility: hidden;
          width: auto;
          background-color: #fff;
          color: #555;
          text-align: center;
          border-radius: 5px;
          padding: 5px;
          position: absolute;
          z-index: 20;
          bottom: 100%; /* Position the tooltip above the circle */
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s;
          border:1px solid #048178
        }

        .circle-container .circle:hover .circle-tooltip {
          visibility: visible;
          opacity: 1;
        }
      `}</style>
      <div className="flex justify-between">
        <Breadcrumb items={[
          { name: "RFQ", link: "/procurement/rfq/" },
          { name: "View" },
        ]} />
        <div className="flex gap-2">
          <Link to={'/procurement/rfq'} ><BackButton link={true} /></Link>
          {/* <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" /> */}
          {data?.supplierQuote === null ?
          <Link to={`/procurement/rfq/supplier-quotes/create`} state={{ data: data, from: `/procurement/rfq/view/${rfqViewId}` }}> <CreateButton label='Create Supplier Quote'/> </Link>
          :
          <Link to={`/procurement/rfq/supplier-quotes/edit/${data?.supplierQuote}`} state={{ data: data, from: `/procurement/rfq/view/${rfqViewId}` }}> <CreateButton svg={false} label='Edit Supplier Quote'/> </Link>
          }
          {/* <PDFLinkRFQ
            data={data}
            fileName={`RFQ_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
          <Link to={`/procurement/rfq/edit/${rfqViewId}`} 
            state={{ data: data, from: `/procurement/rfq/view/${rfqViewId}` }}>
            <EditButton height='8' />
          </Link>
          {/* <button className={`cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`} 
            onClick={handleOpenCommentsModal}>
            Comments
          </button> */}
        </div>
      </div>
      <div className='w-full flex justify-start gap-3 text-xs ml-3'>
         <button
          onClick={() => handleButtonClick(1)}
         className={`flex justify-start ${activeButton === 1 ? "border-b-4 border-b-baseColor-primary" : null} p-1`}
         >
            RfQ View
         </button>
         {data?.supplierQuote === null ?
         null
         :
         <button 
          onClick={() => handleButtonClick(2)}
         className={`flex justify-start gap-1 ${activeButton === 2 ? "border-b-4 border-b-baseColor-primary" : null }  p-1`}
         >
            <p>Quotes</p>
            <div 
             className=''
             onClick={()=>{setDeleteId(data?.supplierQuote); setIsModalDeleteOpen(true)}}
            >
               <DeleteIcon/>
            </div>
         </button>
         } 
      </div>
      {activeButton === 1 &&
      <div className="w-full mx-auto h-[calc(100vh-155px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
       

      <div className="grid items-center px-2">
      <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
            <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
         </p>
         <p className="text-xs text-[#111537]">Created By: {data.preparedByName ? capitalizeFirstLetter(data.preparedByName) : 'None'}
         </p>
         <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p>

         </div>
          </div>
        {/* <p className=" p-2 w-[10%] bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p> */}
              </div>
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8">

        <div className='w-full flex flex-wrap px-2 border border-gary mt-2  rounded-lg '>
          <div className="w-full grid grid-cols-2 gap-x-8 py-1">
            <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchase Requests</p>
              <p className='w-1/2 py-2 pl-2 text-xs text-[#606279]'> : {data?.prRef}</p>
            </div>
            <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Customer Reference</p>
              <p className='w-1/2 py-2 pl-2 text-xs text-[#606279]'> : {data?.customerRfq}</p>
            </div>
            <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>RFQ Type</p>
              <p className='w-1/2 py-2 pl-2 text-xs text-[#606279]'> : {data?.rfqType}</p>
            </div>
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
              <p className='w-1/2 py-2 pl-2 text-xs text-[#606279]'> : {data?.approvalStatus}</p>
            </div> */}
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
              <div className='w-1/2 py-2 pl-2 text-xs text-[#606279]'> : 
                <div className="circle-container" style={{ display: 'flex', alignItems: 'center' }}>
                  {Array.isArray(data?.vendorsName) && data.vendorsName.length > 0 ? data.vendorsName.map((name, index) => (
                    <div key={index} className="circle" style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: '#048178',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      marginLeft: index !== 0 ? '-10px' : '0', // Add overlap effect
                      position: 'relative',
                      zIndex: 10 - index, // Stack circles on top of each other
                      transition: 'all 0.3s ease',
                      cursor: 'pointer'
                    }}>
                      {getInitials(name)}
                      <div className="circle-tooltip">
                        {name}
                      </div>
                    </div>
                  )) : (
                    <p>-</p>
                  )}
                </div>
              </div>
            </div> */}
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Date</p>
              <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data?.date ? <NuDate value={data?.date} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
            </div> */}
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Prepared By</p>
              <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data?.preparedByName}</p>
            </div> */}
           {data && (
  <>
    {data.approvedByName && (
      <div className='w-full flex px-2'>
        <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approved By</p>
        <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data.approvedByName  ? capitalizeFirstLetter(data.approvedByName):"N/A"}</p>
      </div>
    )}
    {data.reviewedByName && (
      <div className='w-full flex px-2'>
        <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Reviewed By</p>
        <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data.reviewedByName  ? capitalizeFirstLetter(data.reviewedByName):"N/A"}</p>
      </div>
    )}
    {data.verifiedByName && (
      <div className='w-full flex px-2'>
        <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Verified By</p>
        <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data.verifiedByName ? capitalizeFirstLetter(data.verifiedByName):"N/A" }</p>
      </div>
    )}
  </>
)}

            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approval Status</p>
              <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data?.status}</p>
            </div> */}
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
              <p className='w-1/2 py-2 pl-2 text-xs  text-[#606279]'> : {data?.status}</p>
            </div> */}
             {/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[20%]">
        <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
          </div>
          
        </div>
        <div className="w-[50%] mt-4">
          <p className=" p-1 text-xs font-medium text-[#111537]">
          RFQ Attachments
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
         

          <div className="w-full flex gap-2 items-center flex-wrap mt-1 ">
              {data?.attachments &&
                filePathList.map((imgs, idx) => {
                  console.log("img", imgs);
                  return (
                    <FilesView fileView={imgs} key={idx}/>
                    // <div
                    //   className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                    //   key={idx}
                    //   style={{
                    //     border: "1px solid gray",
                    //     borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))",
                    //   }}
                    // >
                    //   <div
                    //     className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                    //     onClick={() => handleImageClick(imgs)}
                    //   >
                    //     <img src={imgs?.file} alt={imgs?.file} />
                    //   </div>
                    // </div>
                  );
                })}
            </div>
</div>
</div>
       
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}
        <div className="w-full bg-white   border border-gary mt-4  rounded-lg mb-2">
          <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-2">
            {/* <p className="w-[20%] pl-2 text-left text-white text-xs font-medium">Item Code</p> */}
            <p className="w-[5%]  text-left text-white text-xs font-medium">S.N. </p>

            <p className="w-[60%] text-left  text-white text-xs font-medium">Product </p>
            <p className="w-[10%] text-center  text-white text-xs font-medium">Unit</p>
            <p className="w-[20%] text-center  text-white text-xs font-medium">Quantity</p>
          </div>
          {data?.productList?.map((List, index) => {
            return (
              <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                {/* <p className="w-[20%] pl-2 text-left text-[#606279] text-xs font-light py-1">
                  {List?.item_code}
                </p> */}
                 <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                <p className="w-[60%]  text-left text-[#606279] text-xs font-light py-1">
                  {List?.product_name}
                </p>
                <p className="w-[10%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.product_unit}
                </p>
                <p className="w-[20%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.quantity}
                </p>
              </div>
            );
          })}
        </div>     
</div>
        </div>
        <div className="w-[30%] h-full bg-white rounded-lg drop-shadow-lg py-2 ">
        <div className="h-[98%] overflow-y-scroll p-2 ">
        {/* <div className=" border border-gary py-2 px-1 rounded-lg">
         <p className="text-xs text-[#111537] font-medium">Approval 
         <span>
                   : {data.approvalStatus || "None"}
                </span>
         </p>
          <div className="flex gap-4 mt-2 justify-around">
          <p className=" px-2 py-2 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs  text-center  h-7 ">
               Verify
              </p>
              <p className=" px-2 py-2 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs text-center  h-7  ">
              Decline
              </p>
          </div>
         </div> */}
         <div className="w-full grid  border border-gary   rounded-lg ">

        {data && (
          <>
          
          {data?.vendor &&
          <>
          <div className="bg-baseColor-primary text-xs  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Supplier</p>

            </div>
          <div className='w-full flex px-2 justify-between overflow-y-scroll'>
              {/* <p className=' w-1/2 py-2 text-xs font-medium text-[#111537]'>Supplier </p> */}
                   <p className='  py-3 text-xs text-[#606279]'>  {data.vendorName}</p>
          </div>
          </>
          }
          {data?.purchaser &&
          <>
         <div className="bg-baseColor-primary text-xs  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Local Purchaser</p>

            </div>
            <div className='w-full flex px-2 justify-between overflow-y-scroll'>
              {/* <p className=' w-1/2 py-2 text-xs font-medium text-[#111537]'>Local Purchaser </p> */}
                   <p className=' py-3  text-xs  text-[#606279]'>  {data.purchaserName}</p>
    </div>
    </>
          }
          </>
    )}
              {/* <div className='w-full py-2 pl-1 text-xs text-[#606279]'> 
                <div className="circle-container" style={{ display: 'flex', alignItems: 'center' }}>
                  {Array.isArray(data?.vendorsName) && data.vendorsName.length > 0 ? data.vendorsName.map((name, index) => (
                    <div key={index} className="circle" style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: '#048178',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      marginLeft: index !== 0 ? '-10px' : '0', // Add overlap effect
                      position: 'relative',
                      zIndex: 10 - index, // Stack circles on top of each other
                      transition: 'all 0.3s ease',
                      cursor: 'pointer'
                    }}>
                      {getInitials(name)}
                      <div className="circle-tooltip">
                        {name}
                      </div>
                    </div>
                  )) : (
                    <p>-</p>
                  )}
                </div>
              </div> */}
           
          </div>
          <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white bg-baseColor-primary  p-2 rounded-t-lg font-medium">Documents </p>
           <div className="felx gap-2 flex-wrap overflow-y-scroll h-16">
                      <div onClick={handlePdfIconClick} className="cursor-pointer m-2  ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div>
        </div>
        </div>
          <div className="w-full h-auto flex  border border-gary mt-3 overflow-hidden rounded-lg">
         <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={rfqViewId}
            addurl={'/rfq-comments/new-comment'}
            geturl={`/rfq-comments/by-RFQ/${rfqViewId}`}
          />
         </div>
</div>

        </div>
      </div>}
      {activeButton === 2 &&
      <div className="w-full mx-auto h-[calc(100vh-155px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
            {/* <NormalHeading title={"Supplier Quotes"} />  */}
            <div className="grid  items-center px-2">
            <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{QData.ref}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
               <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {QData?.dateReceived ? (
                  <NuDate value={QData?.dateReceived} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
         </p>
         <p className="text-xs text-[#111537]">Created By: {QData.preparedByName ? capitalizeFirstLetter(data.preparedByName) : 'None'}
         </p>   <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {QData.status || 'None'}</p>

         </div>
                   
                 </div>
                 
          <div className="flex gap-2 w-[10%] justify-end">
          {/* <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status}
              </p> */}
            <Link
              className=" cursor-pointer text-xs text-white bg-[#F0DE36] rounded-md inline-flex w-auto h-7 px-2 mr-3  flex-shrink-0 items-center justify-center"
              to={`/procurement/supplier-quotes/viewsqcreate/${data?.supplierQuote}`}
              state={{
                data: QData,
                from: `/procurement/rfq/view/${rfqViewId}`,
              }}
            >
              Rev.00
            </Link>

            {/* <Link
              className=" cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center"
              to={`/procurement/supplier-quotes/viewsqrevision/${supplierQuotationsViewId}`}
            >
              View Revision
            </Link> */}
          </div>
        </div>
        <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8">
      
        <div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg ">

          <div className=" w-full grid grid-cols-2 gap-x-8 ">
           
            
            {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'> Reference</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.ref}</p>
                </div> */}
           
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                RFQ Reference
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.rfqRef}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Quote Date
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data?.quoteDate}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Quotation Received Date
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data?.dateReceived? (
                  <NuDate value={data?.dateReceived} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Product Availability
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.availability}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Landing Cost
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.landingCost}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Quotation Validity
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {QData.validity}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Lead Time
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.leadTime}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Delivery Terms
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {QData.deliverTerms}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Payment Terms
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {QData.paymentTerms}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Payment Method
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {QData.paymentMethod}
              </p>
            </div>
            {QData.currencyName && <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Currency
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {QData.currencyName}
              </p>
            </div>}
            {QData && (
              <>
              {/* {data.preparedByName &&
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Prepared By
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.preparedByName  ? capitalizeFirstLetter(data.preparedByName):"N/A"}
              </p>
            </div>
} */}
{QData.reviewedByName &&
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Reviewed By
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.reviewedByName  ? capitalizeFirstLetter(QData.reviewedByName):"N/A"}
              </p>
            </div>
}
{QData.approvedByName &&
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Approved By
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.approvedByName  ? capitalizeFirstLetter(QData.approvedByName):"N/A"}
              </p>
            </div>
}
            </>
            )}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Approval Status
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.approvalStatus}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
               
                Status
              </p>
              <p className="w-1/2 py-2 text-xs font-medium text-[#606279]">
              : {data.status}
              </p>
            </div> */}
            {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>  Comments</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>: {data.comments}</p>
                </div> */}
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-[50%]">
        <p className=" pl-1 text-xs font-medium text-[#111537] p-1">
        Special Notes From Supplier
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
       
        {/* <p className=" p-1 text-xs font-medium text-[#111537]">
            Special Notes
          </p> */}
          <p className=" text-xs p-2 text-wrap text-[#606279]">
            {QData.specialNotes || "-"}
          </p>
        </div>
        </div>
        <div className="w-[50%] ">
          <p className=" p-1 text-xs font-medium text-[#111537]">
          Supplier Quote Attachments
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
         

          <div className="w-full flex gap-2 items-center flex-wrap mt-1 ">
              {QData?.attachments &&
                filePathListSQ.map((imgs, idx) => {
                  console.log("img", imgs);
                  return (
                    <FilesView fileView={imgs} key={idx}/>
                    // <div
                    //   className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                    //   key={idx}
                    //   style={{
                    //     border: "1px solid gray",
                    //     borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))",
                    //   }}
                    // >
                    //   <div
                    //     className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                    //     onClick={() => handleImageClick(imgs)}
                    //   >
                    //     <img src={imgs?.file} alt={imgs?.file} />
                    //   </div>
                    // </div>
                  );
                })}
            </div>
</div>
</div>
</div>
        <Tabs>
{
  revData && revData.map((item,index)=>(
<Tab component={<>
        <div className="w-full bg-white  border border-gary  mt-4 rounded-lg ">
        <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg">

            <p className=" w-[5%]  text-center text-white text-xs font-medium">
              S.N.
            </p>
            <p className=" w-[45%]  text-left text-white text-xs font-medium">
              Product
            </p>
            <p className=" w-[10%] text-center text-white text-xs font-medium">
              Unit
            </p>
            <p className=" w-[10%] text-center text-white text-xs font-medium">

              Qty
            </p>
            <p className="w-[13%] text-center text-white text-xs font-medium">
                  Lead Time
                </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Price
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Conv. Price
            </p>
            <p className="w-[13%] text-center text-white text-xs font-medium">
                Landing Cost
                </p>
                <p className="w-[13%] text-center text-white text-xs font-medium">
                Total
                </p>
          </div>
          {item.revProduct && item.revProduct?.map((List, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100"
              >
                <p className=" w-[5%]  text-center text-[#606279] text-xs font-light ">
                  {List?.sno}
                </p>
                {List?.isAlternate === true ? (
                  <p className=" w-[45%]  text-left text-[#606279] text-xs font-light ">
                    {List?.product_name}
                    <p className="text-[10px] text-slate-400">(Alternate)</p>
                  </p>
                ) : List?.isAlternate === false ? (
                  <p className=" w-[45%]  text-left text-[#606279] text-xs font-light ">
                    {List?.product_name}
                  </p>
                ) : (
                  "-"
                )}
                <p className=" w-[10%] text-center text-[#606279] text-xs font-light ">
                  {List?.product_unit}
                </p>

                <p className=" w-[10%] text-center text-[#606279] text-xs font-light ">
                  {List?.quantity}
                </p>
                <p className=" w-[15%] text-center  text-[#606279] text-xs font-light ">
                  {List?.leadTime}
                </p>
                <p className=" w-[15%] text-center  text-[#606279] text-xs font-light ">
                  {List?.price}
                </p>
                <p className=" w-[15%] text-center  text-[#606279] text-xs font-light ">
                  {List?.convPrice}
                </p>
                <p className="text-center w-[13%] text-[#606279] text-xs font-light">
                          {List.landedCost}
                        </p>
                        <p className="text-center w-[13%] text-[#606279] text-xs font-light">
  {((List.convPrice * List.quantity) + parseFloat(List.landedCost))
    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
  {/* <span>{supplierCurrency}</span> */}
</p>

              </div>
            );
          })}
       
       <div className="w-full flex px-2 py-2">
  <div className="flex w-full justify-end">
    <p className="py-3 text-xs font-medium text-[#111537]">
      Total Price
    </p>
    <p className="py-3 text-xs font-medium text-[#606279]">
      : <NuAmount value={(QData?.totalPrice || 0)} />
    </p>
  </div>
</div>

        </div>
        </>} active={index==0} key={index} >
  {item?.revName}
</Tab>
  ))
}
</Tabs>
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

        {/* <div className="w-[55%] flex px-2 border border-gary mt-3">
          <p className=" py-3 text-xs font-medium text-[#111537]">
            Special Notes
          </p>
          <p className=" py-3 text-xs pl-5 text-wrap text-[#606279]">
            {data.specialNotes || "-"}
          </p>
        </div> */}
        </div>
        </div>
        <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">
        {/* <div className=" border border-gary   py-2 px-1 rounded-lg">
         <p className="text-xs text-[#111537] font-medium">Approval 
         <span>
                   : {data.approvalStatus || "N/A"}
                </span>
         </p>
          <div className="flex gap-4 mt-2 justify-evenly">
          <p className=" px-2 py-1 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7 ">
               Approve
              </p>
              <p className=" px-2 py-1 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7 ">
              Decline
              </p>
          </div>
         </div> */}

<div className="w-full grid  border border-gary    rounded-lg">
          {/* <p className="text-xs text-[#111537] font-medium"></p> */}
          <div className="bg-baseColor-primary   p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Quote Detail</p>

            </div>
            <div className="w-full flex px-2 justify-between">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Purchase Requests
              </p>
              <p className=" w-1/2 py-2 text-xs font-medium text-[#606279]">
             {QData.prRef}
              </p>
            </div>
            {QData?.purchaserName &&(
            <div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Purchaser
              </p>
              <p className=" w-1/2 py-2 text-xs font-medium text-[#606279]">
               {QData.purchaserName}
              </p>
            </div>
  )}
          <div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                Supplier
              </p>
              <p className=" w-1/2 py-2 text-xs font-medium text-[#606279]">
               {QData.vendorName}
              </p>
            </div>
           
            <div className="w-full flex px-2 justify-between">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]"> 
                RFQ Reference
              </p>
              <p className=" w-1/2 py-2 text-xs font-medium text-[#606279]">
             {QData.rfqRef}
              </p>
            </div>
          </div>
        <div className="w-full grid  border border-gary mt-4 rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary   p-2 rounded-t-lg">Documents </p>
           <div className="felx gap-2 flex-wrap overflow-y-scroll h-16">
           <div onClick={handlePdfIconClickSQ} className="cursor-pointer m-2   ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div>
        </div>
        </div>
        <div className="w-full h-auto flex border border-gary mt-4 overflow-hidden rounded-lg">
         <CommonComments
            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={data?.supplierQuote}
          addurl={"/sq-comments/new-comment"}
          geturl={`/sq-comments/by-SQ/${data?.supplierQuote}`}
        />
         </div>
        </div>
        </div>
      </div>
      }
      {showPdfComponent && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[95%] h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
               className="absolute top-2 right-2 text-white bg-red-600"
               onClick={() => setShowPdfComponent(false)}
       >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ViewPdf data={data} />
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div> */}
    </div>
  </div>
)}
    {showPdfComponentSQ && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
  <div className="animate-scale-in relative w-[95%] h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
    <button
               className="absolute top-2 right-2 text-white bg-red-600"
               onClick={() => setShowPdfComponentSQ(false)}
       >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <SQPdfOthers data={QData} />
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div> */}
    </div>
  </div>
)}

      {/* {isCommentsModalOpen && (
        <SideModal
          visible={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={rfqViewId}
          addurl={'/rfq-comments/new-comment'}
          geturl={`/rfq-comments/by-RFQ/${rfqViewId}`}
        />
      )} */}
       <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <img src={selectedImage?.file} alt={selectedImage?.file} />
        </Modal>
        <ModalContainer
                visiable={isModalDeleteOpen}
                title="Warning"
                content={`Are you sure, ${QData.ref} will removed from Quotes!`}
                handleClick={() => handleDelete()}
                closeModal={() => setIsModalDeleteOpen(false)}
            />
    </div>
  );
}

export default ViewRFQ;
