import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseCatSubCatDropDown from "../../../../Components/Hooks/useCategoryDropDown";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditReceivables from "./MultiEditReceivables";
import { GetAccess } from "../../../../Components/Utils/roles";

function ReceivablesIndex(){
  const {value:Category} = useFetchMataData(`/dropdown/dropdown-details/`,'Category_Recevable')
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_Recevable')
  const {value:AccountType } = useFetchMataData(`/dropdown/dropdown-details/`,'AccountType__Recevable')

    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
      category:"",
      accountType:"",
      status:""
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      category:"",
      accountType:"",
      status:""
    });
    const clearFilter = ()=> {
      setParams({...params, category:"",
      accountType:"",
      status:""})
      formik.values.status=""
      formik.values.accountType=""
      formik.values.category=""

        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,category:value.category, accountType:value.accountType,status:value.status})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          category:"",
          accountType:"",
          status:""
        });
      }
    })
    const { category: itemsCategory } = UseCatSubCatDropDown('/category/receivableCategory', "receivableCategory", formik?.values?.category);

    const { data, totalCount, getData } = UseGetDataSearch( '/receivable/',params);
  
    const defaultHeaders = [
      // {
      //   title: "ID",
      //   width: "5%",
      //   field: "receivableId",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Category",
        width: "10%",
        isLink: true,
        id: "receivableId",
        link: '/payments/receivables/view/',
        field: "category",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Invoice Outgoing",
        width: "10%",
        isLink: true,
        id: "receivableId",
        link: '/payments/receivables/view/',
        field: "invoiceRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Amount",
        width: "10%",
        field: "amount",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Received Amount",
        width: "10%",
        field: "receivedAmount",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Days Elapsed",
        width: "10%",
        field: "daysElapsed",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"receivableId",
          removeValue:"category",
          url:`/receivable/`,
          moduleName:"Receivable",
          from:`/payments/receivables`,
          editLink:"/payments/receivables/edit/",
          viewLink:"/payments/receivables/view/"
        }
      },
    ];
    const availableHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "receivableId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Category",
        width: "10%",
        isLink: true,
        id: "receivableId",
        link: '/payments/receivables/view/',
        field: "category",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Invoice Outgoing",
        width: "10%",
        isLink: true,
        id: "receivableId",
        link: '/payments/receivables/view/',
        field: "invoiceRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Amount",
        width: "10%",
        field: "amount",
        textAlign: "center",
        rowAlign: "center",
      },   
      {
        title: "Received Amount",
        width: "10%",
        field: "receivedAmount",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Days Elapsed",
        width: "10%",
        field: "daysElapsed",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Received From Name",
        width: "10%",
        field: "receivedFromName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Pub Id",
        width: "10%",
        field: "pubId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Due Date",
        width: "10%",
        field: "dueDate",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Description",
        width: "10%",
        field: "description",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Balance Amount",
        width: "10%",
        field: "balanceAmount",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Account Type",
        width: "10%",
        field: "accountType",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"receivableId",
          removeValue:"category",
          url:`/receivable/`,
          moduleName:"Receivable",
          from:`/payments/receivables`,
          editLink:"/payments/receivables/edit/",
          viewLink:"/payments/receivables/view/"
        }
      },
    ];

    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
       
     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }
  
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
        console.log("I",i?.receivableId);
        tempArr.push(i?.receivableId)
      });
      setFullList(tempArr)
    },[data])


    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb
         items={
          [
            { name: "Payment" },
          {
            name: "Receivables",
          }]
         }
        />
      <div className=' flex gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
       {/* <CreateButton label='Import' /> */}
       {/* {GetAccess("receivables_create") && <Link to="/payments/receivables/create"><CreateButton label='Receivables'/></Link> } */}
      </div>
      </div>
  
      <CustomDataTable
        data={data} 
        fetcher={getData}
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeaders}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Receivables List'
        storageName="ReceivableTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="receivableId"
        editAccess="receivables_edit"      
        deteleAccess="receivables_delete" 
        />
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Receivable</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              label="Category"
              placeholder="Choose"
              Options={Category}
              formik={formik}
              name="category"
              width="w-full "
            />
                   <FormikSelectTwo
              label="Account Type"
              placeholder="Choose"
              Options={AccountType}
              formik={formik}
              name="accountType"
              width="w-full "
            />
            <FormikSelectTwo
              label="Status"
              placeholder="Choose"
              Options={status}
              formik={formik}
              name="status"
              width="w-full"
            />
                      
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
                              {showEdit && (<MultiEditReceivables data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
            />
          </div>
    )
}

export default ReceivablesIndex;