import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInput, NuSelect, NuUpload } from '../../../../Components/Utils/Input';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';

function EditCatalogues() {
  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, 'state');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    category: data.category,
    subCategory: data.subCategory,
    // attachments:[]
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, image: "" }))
    formik.values.image = ""
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append('attachments', image.raw);

      editFormData(`/catalogues/edit-catalogue/${data.catalogueId}`, formData, resetForm, from);
    }
  })

  const { category: cataloguesCategory, subCategory: cataloguesSubCategory  } = UseCatSubCatDropDown('/category/CatalogeCategory', "CatalogeCategory", formik?.values?.category);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Products" },

            {
              name: "Catalogues",
              link: "/products/catalogues"
            },

            { name: "Edit" },

          ]} />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-hidden">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Category"
              Options={cataloguesCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
              label="Category"
              type="text"
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={cataloguesSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditCatalogues