import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ViewMore from '../Icons/ViewMore';
import { useDeleteData } from '../Hooks/useDeleteData';
import ModalContainer from '../Others/ModalContainer';
import EditIcon from '../Icons/Edit';
import DeleteIcon from '../Icons/Delete';
import CommentsIcon from '../Icons/comments';
import ViewIcon from '../Icons/ViewIcon';
import Chat from '../Icons/Chat';

const MoreOptionsCard = ({
    data,
    fetcher,
    id,
    removevalue,
    url,
    moduleName,
    from,
    viewLink,
    editLink
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { handle } = useDeleteData(fetcher);
    const handleDelete = async () => handle(url + `${id}`,setIsModalOpen);

    console.log("ssss",fetcher);
    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let Edit = editLink ? true : null
    let Comment = editLink ? false : null

    let Delete = true

    return (
        <>
            <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                    <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                        <ViewMore />
                    </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[81px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
                    <li className=' w-[100px] bg-black block m-0'>
                        <Link className=' text-black hover:text-black border-none' to={viewLink + `${id}`} state={{ data: data }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            View
                        </span></Link>
                    </li>
                    {Edit && <li className=' w-[100px] bg-black block m-0'>
                        <Link className=' text-black hover:text-black border-none' to={editLink + `${id}`} state={{ data: data, from: from }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            Edit
                        </span></Link>
                    </li>}
                    {
                        Delete &&
                        <li className=''>
                            <a
                                className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                                // to='/members'
                                href="#"
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            </div>
            <ModalContainer
                visiable={isModalOpen}
                title="Warning"
                content={`Are you sure, ${removevalue} will removed from ${moduleName}!`}
                handleClick={() => handleDelete()}
                closeModal={() => setIsModalOpen(false)}
            />
        </>
    );
};
export default MoreOptionsCard;