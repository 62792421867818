import React from 'react';
import CompanyLogo from '../../../assets/images/companyLogo.png'
import CompanyName from '../../../assets/images/companyName.png'
import Seal from '../../../assets/images/seal.png'
import NuDate from '../../../Components/Utils/NuDate';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
const POPdfOther = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);
  const tableBodyStyle = {
    width: '100%',
    height: '400px',
    maxHeight: '900px',
  };
  
  const tableRowStyle = {
    verticalAlign: 'top',
    height: '20px',
  };
  return (
    <div
      ref={containerRef}
      className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border"
    >
      <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className="relative w-full h-full ">
            <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
              <button
                onClick={() => zoomIn()}
                className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold"
              >
                +
              </button>
              <button
                onClick={() => zoomOut()}
                className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold"
              >
                -
              </button>
              <button onClick={() => resetTransform()} className="text-left">
                Reset
              </button>
            </div>
            <div className="flex justify-center items-center w-full h-full">
              <TransformComponent
                wrapperStyle={{ width: "100%", height: "100%" }}
              >
                <div className="min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black">
                  <div className="border border-black">
                    <div className="flex justify-between items-center border-b border-black">
                      <div className="w-full">
                        <div className="w-full flex justify-between items-center p-4">
                          <img
                            src={CompanyName}
                            alt="Loading"
                            className="h-28"
                          />
                          <img
                            src={CompanyLogo}
                            alt="Loading"
                            className="h-28 w-28"
                          />
                        </div>
                        {data?.isDraft === true ? (
                          <p className="text-center text-blue-400 pb-2 text-sm">Draft</p>
                        ) : data?.isDraft === false ? null : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className=" grid grid-cols-3 border-b border-black">
                      <div className=" w-full p-3 flex items-center border-r border-black">
                        Your Ref. :
                      </div>
                      <div className=" w-full p-3 text-center text-lg font-semibold border-r border-black">
                        PURCHASE ORDER
                      </div>
                      <div className=" w-full">
                        <div className=" w-full h-1/2 border-b border-black px-2 pt-1 flex">
                          <p className=" w-8">Date </p>
                          <p className="text-xs">
                            :{" "}
                            {data?.date ? (
                              <NuDate value={data?.date} format="DD-MMM-YYYY" />
                            ) : (
                              "N/A"
                            )}
                          </p>
                        </div>
                        <div className=" w-full h-1/2 px-2 pt-1 flex">
                          <p className=" w-8">PO# </p>
                          <p className="text-xs"> : {data.ref || "N/A"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between px-3  py-2 border-b border-black">
                      <div className=" w-[60%]">
                        <h4 className="font-semibold text-sm">VENDOR</h4>
                      </div>
                      <div className=" w-[40%]">
                        <h4 className="font-semibold text-sm pl-3">SHIP TO</h4>
                      </div>
                    </div>

                    <div className="flex justify-between border-b border-black">
                      <div className=" w-[60%] border-r border-black px-3 py-1">
                        <table className="w-full">
                          <tbody>
                            <tr className=" w-full">
                              <td className=" w-[30%] py-1">Name </td>
                              <td className=" w-[70%] py-1">
                                : {data.vendorName || "N/A"}
                              </td>
                            </tr>
                            <tr className=" w-full">
                              <td className=" w-[30%] py-1">Address </td>
                              <td className=" w-[70%] py-1">
                                : {data.supplierAddress || "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className=" w-[40%] px-3 py-1">
                        <p>{data.deliveryAddress || "N/A"}</p>
                      </div>
                    </div>

                    <table className="w-full border-collapse ">
                      <thead>
                        <tr className="bg-gray-200 text-[12px]">
                          {[
                            "S/N",
                            "Product",
                            "Unit",
                            "Ordered Qty",
                            "Request Qty",
                            "Price",
                            "Total",
                          ].map((header, index) => (
                            <th
                              key={index}
                              className="border-b border-r last:border-r-0  border-black p-2 text-left font-bold"
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody style={tableBodyStyle}>
                        {data?.products?.map((product, index) => (
                          <tr
                            key={index}
                            // className="border-y border-black text-[12px] h-[50px]"
                            style={tableRowStyle}
                          >
                            <td className=" p-2">{index + 1}</td>
                            <td className="border border-black p-2">
                              {product.product_name || "N/A"}
                            </td>
                            <td className="border border-black p-2">
                              {product.product_unit || "N/A"}
                            </td>
                            <td className="border border-black p-2">
                              {product.orderedQty || 0}
                            </td>
                            <td className="border border-black p-2">
                              {product.requestedQty || 0}
                            </td>
                            <td className="border border-black p-2">
                              {product.price || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2">
                              {(product.orderedQty * product.price).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className=" py-3 border-r border-black  pr-3 "
                            colSpan={6}
                          >
                            <div className=" w-full flex justify-end">
                              <div>
                                <p className=" text-xs font-medium">TOTAL</p>
                                <p className=" text-xs font-medium">
                                  NET TOTAL
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className=" w-full">
                              <div>
                                <p className=" text-xs font-medium">
                                  {data.totalPrice.toFixed(2)}
                                </p>
                                <p className=" text-xs font-medium">
                                  {data.totalPrice.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="mt-4 text-[12px] flex justify-between w-full px-2">
                      <div className="">
                        <h4 className="font-bold">Terms & Conditions</h4>
                        <div className="w-full flex  text-[12px]">
                          <p className=" py-1 text-xs  font-medium text-[#111537]">
                            Price
                          </p>
                          <p className=" py-1 text-xs   text-[#606279]">
                            : SAR
                          </p>
                        </div>
                        <div className="w-full flex  text-[12px]">
                          <p className=" py-1 text-xs  font-medium text-[#111537]">
                            Delivery Period
                          </p>
                          <p className=" py-1 text-xs   text-[#606279]">
                            : Immediate
                          </p>
                        </div>
                        <div className="w-full flex text-[12px]">
                          <p className=" py-1 text-xs  font-medium text-[#111537]">
                            Delivery
                          </p>
                          <p className=" py-1 text-xs   text-[#606279]">
                            : {data.deliveryTerms || "N/A"}
                          </p>
                        </div>
                        <div className="w-full flex  text-[12px]">
                          <p className=" py-1 text-xs  font-medium text-[#111537]">
                            Payment
                          </p>
                          <p className=" py-1 text-xs   text-[#606279]">
                            : {data.paymentTerms || "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="text-center">
                        <p className="text-xs ">Authorized By</p>
                        {data?.authorizedBy ?
                          <img
                            src={data?.authorizedBy}
                            alt="Company Seat"
                            className="h-24 mb-2"
                          />
                          :
                          <p className='text-xs mt-3 text-red-400'>Not Authorized</p>
                        }

                      </div>
                    </div>

                    <div className="mt-4 flex justify-between px-2 items-center text-[12px]">
                      <div>
                        <p className="text-xs ">Note:</p>
                        {/* <ul className="text-xs  list-disc list-inside">
            <li>MATERIAL SHOULD BE SAME AS IN THE DATA SHEET PROVIDED BY THE MANUFACTURER.</li>
            <li>MATERIAL TEST CERTIFICATE (MTC) SHOULD BE PROVIDED WITH MATERIAL.</li>
          </ul> */}
                        <p>{data.notesFromCus || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TransformComponent>
            </div>
          </div>
          // </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default POPdfOther;
