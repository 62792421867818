import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatus from "./ApprovalStatus";
import Availablity from "./Availablity";
import DeliveryTerm from "./DeliveryTerm";
import LeadTime from "./LeadTime";
import PaymentTerms from "./PaymemtTerm";
import PaymentMethod from "./PaymentMethod";
import Status from "./Status";
import Validity from "./Validity";

const CustomerQuotes = () =>{
    return(
        <>
        <TextMediumSM content="Customer Quotes" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Availablity/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <DeliveryTerm/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentTerms/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentMethod/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Validity/>
         </div>
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadTime/>
         </div> */}
         {/* <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div> */}
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default CustomerQuotes;