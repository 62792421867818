import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useState } from "react";
import Input, { NuInput, NuMobileNumber, NuSelect, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";

const CreateMyTasks = () => {
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    // type: "",
    // companyName: "",
    // email: "",
    // phone: "",
    // countryCode: "",
    // website: "",
    // billingAddress: "",
    // shippingAddress: "",
    // crNum: "",
    // vatNum: "",
    // ratings: "",
    // comments: "",
    // country: "",
    // city: "",
    // state: "",
    // district: "",
    // postalCode: "",
    // cpName: "",
    // cpEmail: "",
    // cpDesignation: "",
    // cpCountryCode: "",
    // cpPhone: "",
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      // formData.append("agentDetails", JSON.stringify(value));
      // formData.append('agentImage', image.raw);

      // addFormData("/agents/new-agent", formData, resetForm);
    },
  });

  return (
    <div className="w-full h-full px-4 py-2 bg-[#F1F2FB]">
      <div className="flex justify-between">
        <Breadcrumb
          items={
            [{
              name: "My Tasks",
              link: "/tasks/my-tasks"
            },
            {
              name: "Create My Tasks"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-200px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
            {/* <FormikSelectTwo
              type="text"
              label="Agent Type"
              placeholder="Agent Type"
              Options={[
                { value: "Shipping", label: "Shipping" },
                { value: "Cargo", label: "Cargo" },
              ]}
              formik={formik}
              name="type"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/3"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="phone"
              placeholder="phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="website"
              placeholder="website"
              formik={formik}
              name="website"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Billing Address"
              placeholder="billingAddress"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Shipping Address"
              placeholder="shippingAddress"
              formik={formik}
              name="shippingAddress"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="number"
              label="crNum"
              placeholder="crNum"
              formik={formik}
              name="crNum"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="number"
              label="Vat Num"
              placeholder="vatNum"
              formik={formik}
              name="vatNum"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="number"
              label="Ratings"
              placeholder="ratings"
              formik={formik}
              name="ratings"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Country"
              placeholder="country"
              formik={formik}
              name="country"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="state"
              formik={formik}
              name="state"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="district"
              formik={formik}
              name="district"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="city"
              formik={formik}
              name="city"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="number"
              label="Postal Code"
              placeholder="postalCode"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Contact Person Name"
              placeholder="cpName"
              formik={formik}
              name="cpName"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="email"
              label="Contact Person Email"
              placeholder="cpEmail"
              formik={formik}
              name="cpEmail"
              width="w-full md:w-1/3"
            />
            <NuInput
              type="text"
              label="Contact Person Desigation"
              placeholder="Contact Person Desigation"
              formik={formik}
              name="cpDesignation"
              width="w-full md:w-1/3"
            />
            <NuMobileNumber
              nunCode="cpCountryCode"
              label="phone"
              placeholder="phone"
              formik={formik}
              name="cpPhone"
              width="w-full md:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/3"
              onChange={handleChangeImg}
            /> */}

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMyTasks;
