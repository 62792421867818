import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const PaymentMethod = () => {
  return (
    <SettingsMetaData propertyName={"PaymentMethod_Recevable"} viewPropertyName={"Payment Method"} />
  );
};

export default PaymentMethod;
