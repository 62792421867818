
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const VisitPurpose = () => {
  return (
    <SettingsMetaData propertyName={"VisitPurpose_Appoinments"} viewPropertyName={"Visit Purpose"} />
  );
};

export default VisitPurpose;
