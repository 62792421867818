import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useMemo, useState } from "react";
import Input, {
  NuDate,
  NuInput,
  NuSelect,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";

const CreateContracts = () => {
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    customerId: "",
    contractTitle: "",
    scopeOfWork: "",
    contractAmount: "",
    additionalAmount: "",
    totalAmount: "",
    signedOn: "",
    projectDuration: "",
    startDate: "",
    endDate: "",
    comments: "",
    receivables: ""
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
        });
    }
};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
        const datas={
            ...value,
            projectDuration: daysDifference,
            totalAmount: totalAmount
        }
      let formData = new FormData();
      formData.append("contractDetails", JSON.stringify(datas));
      formData.append('attachments', image.raw);
      addFormData("/contracts/new-contract", formData, resetForm);
    },
  });

  const daysDifference = useMemo(() => {
    const startDate = new Date(formik?.values?.startDate);
    const endDate = new Date(formik?.values?.endDate);
  
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return null;
    }
  
    const differenceInMs = Math.abs(endDate - startDate);
    return Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
  }, [formik?.values?.startDate, formik?.values?.endDate]);
  
  const totalAmount = useMemo(()=>{
    const contractAmount = formik?.values?.contractAmount
    const additionalAmount = formik?.values?.additionalAmount
    return contractAmount + additionalAmount
  },[formik?.values?.contractAmount,formik?.values?.additionalAmount])

  const { data: customer } = UseDropDown('/customers/drop-down');

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            { name: "Procurement" },
            {
            name: "Contracts",
            link: "/procurement/contracts"
          },
          {
            name: "Create Contracts"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              label="Customer"
              placeholder="Choose"
              Options={customer}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Contract Title"
              placeholder="ContractTitle"
              formik={formik}
              name="contractTitle"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Scope Of Work"
              placeholder="ScopeOfWork"
              formik={formik}
              name="scopeOfWork"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Signed On"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="signedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="startDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="endDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Project Duration"
              placeholder="ProjectDuration"
              value={daysDifference}
              formik={formik}
              name="projectDuration"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled
            />
            <NuInput
              type="number"
              label="contractAmount"
              placeholder="contractAmount"
              formik={formik}
              name="contractAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="additionalAmount"
              placeholder="additionalAmount"
              formik={formik}
              name="additionalAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="totalAmount"
              placeholder="totalAmount"
              formik={formik}
              value={totalAmount}
              name="totalAmount"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled
            />
            <NuInput
              type="text"
              label="Receivables"
              placeholder="Receivables"
              formik={formik}
              name="receivables"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateContracts;
