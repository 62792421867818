import { Outlet } from "react-router-dom";

function ReceivablesMain(){
    return(
        <div className='h-full w-full'>
            <Outlet/>
        </div>
    )
}

export default ReceivablesMain;