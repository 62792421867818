import React, { useEffect } from 'react'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import MainMarketing from '../Views/Web/Marketing/MainMarketing'
import MainLeads from '../Views/Web/Marketing/Leads/MainLeads'
import LeadsIndex from '../Views/Web/Marketing/Leads'
import CreateLeads from '../Views/Web/Marketing/Leads/CreateLeads'
import EditLeads from '../Views/Web/Marketing/Leads/EditLeads'
import ViewLeads from '../Views/Web/Marketing/Leads/ViewLeads'
import AppoinmentsMain from '../Views/Web/Marketing/Appoinments/MainAppoinment'
import AppoinmentIndex from '../Views/Web/Marketing/Appoinments'
import CreateAppoinment from '../Views/Web/Marketing/Appoinments/CreateAppoinment'
import EditAppoinment from '../Views/Web/Marketing/Appoinments/EditAppoinment'
import ViewAppoinment from '../Views/Web/Marketing/Appoinments/ViewAppoinment'
import { Wrapper } from '../Components/Utils/roles'

const MarketingRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === "/marketing" ||
      location.pathname === "/marketing/") {
      navigate("/page-not-found")
    }
  },[location])
  return (
   <Route path='marketing' element={<MainMarketing/>}>
    <Route path='appointments' element={<AppoinmentsMain/>}>
       <Route index element={< Wrapper accessKey={"appointments_view"}  Component={AppoinmentIndex} />}/>
       <Route path='create' element={< Wrapper accessKey={"appointments_create"}  Component={CreateAppoinment} />}/>
        <Route path='edit/:appointmentsEditId' element={< Wrapper accessKey={"appointments_edit"}  Component={EditAppoinment} />}/>
        <Route path='view/:appointmentsViewId' element={< Wrapper accessKey={"appointments_view"}  Component={ViewAppoinment} />}/>
    </Route>
    <Route path='leads' element={<MainLeads/>}>
        <Route index element={< Wrapper accessKey={"leads_view"}  Component={LeadsIndex} />}/>
        <Route path='create' element={< Wrapper accessKey={"leads_create"}  Component={CreateLeads} />}/>
        <Route path='edit/:leadsEditId' element={< Wrapper accessKey={"leads_edit"}  Component={EditLeads} />}/>
        <Route path='view/:leadsViewId' element={< Wrapper accessKey={"leads_view"}  Component={ViewLeads} />}/>
  </Route>
   </Route>
  )
}

export default MarketingRoutes;