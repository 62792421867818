import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const AccountType = () => {
  return (
    <SettingsMetaData propertyName={"AccountType__Payable"} viewPropertyName={"Account Type"} />

  )
}

export default AccountType