import React from 'react';
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import { TextMediumSM } from '../../../../Components/Text/MediumText';

const AlertDashboard = () => {
  const { data, loading } = UseGetDashBoardData(`/dashboard/PR-daysElasped`);
  
  return (
    <div className=" w-full h-full p-2 bg-white rounded-lg shadow-lg overflow-hidden mb-4">
    <div className="w-full bg-gray-50 px-1 py-2 rounded-md  flex items-center justify-between">
      <TextMediumSM content={`Alerts`} color="text-red-500" />
    </div>
    {/* <div className="bg-white p-4 rounded-lg drop-shadow-lg max-h-[50vh] w-full overflow-hidden mb-4">
      <div className="pl-2">
        <p className="text-red-500 text-sm font-semibold pb-1">Alerts</p> */}

        {loading ? (
          <div className="p-4 flex justify-center items-center max-h-[25vh]">
            <div className="animate-pulse flex space-x-4 w-full">
              <div className="flex-1 space-y-3 py-1">
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  </div>
                  <div className="h-3 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // <div className=' px-2 pb-2 '> 
          <div className="w-full h-full overflow-hidden">
          <div className="w-full h-[94%] pb-4 overflow-y-auto">
       
             {data && Array.isArray(data) ? (
              data.map((item, index) => (
                <div className="flex w-full py-0.5" key={index}>
                  <p className="w-1/2 text-xs  text-[#4F4768]">YPR: {item?.pr}</p>
                  <p className="w-1/2 text-xs  text-[#606279]">Days Elapsed: {item?.dayElaped}</p>
                </div>
              ))
            ) : (
              <p>No alert data available</p>
            )
            }
             
             </div>
           </div>
        )}
        
      </div>
   
  );
};

export default AlertDashboard;
