
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const VendorRating = () => {
  return (
    <SettingsMetaData propertyName={"vendorRating_supplier"} viewPropertyName={"Vendor Rating"} />
  );
};

export default VendorRating;
