import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Asserts from "./Asserts";
import DeliveryNotes from "./DeliveryNotes";
import GRN from "./GRN";
import Inventory from "./Inventory";
import PackingList from "./PackingList";
import Request from "./Requests";
import Returns from "./Returns";


  
  
  const MainStoreSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Store"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><Asserts/></div>
              <div className="p-3"><Inventory/></div>
              <div className="p-3"><GRN/></div>
              <div className="p-3"><DeliveryNotes/></div>
              <div className="p-3"><Returns/></div>
              <div className="p-3"><PackingList/></div>
              <div className="p-3"><Request/></div>
        </div>
        </div>
    )
  }

  export default MainStoreSettings;