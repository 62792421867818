import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';

const ViewAllTask = () => {
  // const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location?.state?.data || {};
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
       <Breadcrumb
         items={
          [{
            name: "My Task",
            link: "/tasks/all-tasks"
          },
          {
            name: "View My Task"
          }]
         }
        />
        <div className=" flex gap-2">
          <BackButton/>
          <Link to={`/tasks/all-tasks/edit/${data?.id||""}`} state={{data: data, from:`/tasks/all-tasks/view/${data?.id||""}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-xl overflow-y-scroll p-3">
      <NormalHeading title={"Task Details"} />
    <div className='flex'> 
         <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>AllTask ID</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.id || '-'}</p>
                  </div>
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>taskName</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.taskName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>taskDesc</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.taskDesc || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>taskStatus</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.taskStatus || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>assignedTo</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.assignedTo?.map((name)=>{
                      return name?.name
                    }) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>copyTo</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.copyTo?.map((name)=>{
                      return name?.name
                    })  || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>startDate</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.startDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>reminderDate</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.reminderDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>createdByName</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.createdByName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>endDate</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.endDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>noOfDays</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.noOfDays || '-'}</p>
                  </div>
                  
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
             
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>noOfWeeks</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.noOfWeeks || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>priority</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.priority || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>pubId</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.pubId || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>refNo</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.id || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>reminderDate</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.id || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>reminderTime</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.id || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>revisedEndDate</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.revisedEndDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>taskCompletion</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.taskCompletion || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>taskCompletionD</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.id || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>updatedByName</p>
                    <p className='w-1/2 py-2 text-xs text-[#606279] pl-5 '>{data?.updatedByName || '-'}</p>
                  </div>
          </div>
        </div>
      </div>
              
          </div>
          
      </div>
  
  )
};
export default ViewAllTask;