import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const VehicleType = () => {
  return (
    <SettingsMetaData propertyName={"VehicleType"} viewPropertyName={"Vehicle Type"}/>
  );
};

export default VehicleType;
