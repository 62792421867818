import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Brand = () => {
  
  return (
    <SettingsMetaData propertyName={"EquipmentBrand"} viewPropertyName={"Equipment Brand"} />
  );
};

export default Brand;
