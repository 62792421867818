import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';

const ViewInventory = () => {
  // const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location?.state?.data||{};
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
         items={
          [{
            name: "Inventory",
            link: "/store/inventory"
          },
          {
            name: "View Inventory"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/store/inventory'}><BackButton link={true}/></Link>
          <Link to={`/store/inventory/edit/${data?.stockId||""}`} state={{data: data, from:`/store/inventory/view/${data?.stockId||""}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-y-scroll p-3">
      <NormalHeading title={"Inventory Details"} />
    <div className='flex'> 
         <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>GRN</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.grnRef || '-'}</p>
                  </div>  
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Product</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.productName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Supplier</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.supplier || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.category || '-'}</p>
                  </div>
                    <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Sub Category</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.subCategory || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Unit</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.unit || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Batch No</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.batchNo || '-'}</p>
                  </div>
                 
                
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>condition</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.condition || '-'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.quantity || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Manufacture Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.mfdDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Expiry Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.expDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>MRP</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.mrp || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchased Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.purchasedDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Stock Location</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.stockLocation || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Min Stock Level</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.minStockLevel || '-'}</p>
                  </div>
                             
            </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
           
                
                 
                  
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Barcode</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.barcode || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Issued Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.issuedQty || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Received Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.receivedQty || '-'}</p>
                  </div> 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Stock Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.stockQty || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Adjusted Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.adjustedQty || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Reason For Adjust</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.reasonForAdjust || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Calibration Reqiure</p>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#606279] pl-5 '>{data?.calibReq ? data?.calibReq ? "Yes" : "No" : '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Calib Expiry</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.calibExp || '-'}</p>
                  </div>
            
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty Applicable</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.warrentyApplicable ?  data?.warrentyApplicable ? "Yes" : "No" : '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty Start Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.warrentyStartDate || '-'}</p>
                  </div> 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty End Date</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.warrentyEndDate || '-'}</p>
                  </div>
                 
                   {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Warrenty Duration</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.warrentyDuration || '-'}</p>
                  </div>   */}
                 
                 
                
                 
                
                 
                
                  
                
                  
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-5 '>{data?.comments || '-'}</p>
                  </div>
                 
                 
          </div>
        </div>
      </div>
              
          </div>
          
      </div>
  
  )
};
export default ViewInventory;