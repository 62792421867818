import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';
import { NuInput } from '../../../../Components/Utils/Input';
import { CreateButton } from '../../../../Components/Utils/Buttons';

const EmailServer = ({ id }) => {
  const toast = useToast();
  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState([]);
  const [createNew, setCreateNew] = useState({
    server_name: '',
    port: '',
    useremail: '',
    passwordOrAuthkey: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      useremail: yup.string().email('Invalid email').required('Email is required'),
      server_name: yup.string().required('Server name is required'),
      port: yup.number().required('Port is required'),
      passwordOrAuthkey: yup.string().required('Password or Auth key is required'),
    }),
    onSubmit: async (value, { resetForm }) => {
      if (isEditMode) {
       
        axiosInstance.put(`/server_email/1`, value).then((res) => {
          handleResponse(res, 'Updated');
        });
      } else {
       
        axiosInstance.post('/server_email/', value).then((res) => {
          handleResponse(res, 'Created');
          resetForm();
        });
      }
    },
  });

  const handleResponse = (res, action) => {
    if (res.data?.status === true) {
      toast.open({
        type: 'success',
        message: 'Success',
        description: res.data?.msg || `${action} Successfully.`,
      });
    } else if (res.data?.status === false && res.data?.error) {
      toast.open({
        type: 'error',
        message: 'Error',
        description: res.data.error || 'Network error.',
      });
    } else {
      toast.open({
        type: 'warning',
        message: 'Warning',
        description: res.data?.msg || 'Warning.',
      })
      .catch((err) => {
        console.log(err);
        toast.open({
          type:"error",
          message:"Error",
          description:  "Network error."
        })
    })
    }
  };

  const getData = () => {
    
      axiosInstance.get(`/server_email/1`).then((res) => {
        if (res.data?.data) {
          setData(res.data.data);
      
            formik.setFieldValue('server_name', res.data.data.server_name || '');
             formik.setFieldValue('port', res.data.data.port || '');
            formik.setFieldValue('useremail', res.data.data.useremail || '');
             formik.setFieldValue('passwordOrAuthkey', res.data.data.passwordOrAuthkey || '');
      
          setIsEditMode(true);
        }
      }).catch(() => {
        toast.open({
          type: 'error',
          message: 'Error',
          description: 'Failed to fetch data.',
        });
      });
    // }
  };

  useEffect(() => {
   
      getData();
  
  }, []);

  return (
    <div className='w-full'>
      <div className="ml-4">
        <p className="w-full py-2 text-left text-sm font-semibold ">
          {"Email Server"}
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-row bg-white w-full flex-wrap md:flex-nowrap">
  <div className="w-full md:w-[15%] py-6 text-left text-sm px-4">{"Email"}</div>
  <div className="w-full md:w-[35%] py-1 text-center text-sm">
    <NuInput
      type="Email"
      placeholder="Email"
      formik={formik}
      name="useremail"
      width="w-full"
    />
  </div>
</div>
        <div className="flex flex-row bg-white w-full flex-wrap md:flex-nowrap">
          <div className="w-full md:w-[15%] py-6 text-left text-sm px-4">{"Smtp Server"}</div>
          <div className="w-full md:w-[35%] py-1 text-center text-sm ">
            <NuInput
              type="text"
              placeholder="Server"
              formik={formik}
              name="server_name"
              width="w-full"
            />
          </div>
        </div>
        <div className="flex flex-row bg-white w-full flex-wrap md:flex-nowrap">
          <div className="w-full md:w-[15%] py-6 text-left text-sm px-4">{"Port"}</div>
          <div className="w-full md:w-[35%]  py-1 text-center text-sm ">
            <NuInput
              type="number"
              placeholder="Port"
              formik={formik}
              name="port"
              width="w-full"
            />
          </div>
        </div>
        <div className="flex flex-row bg-white w-full flex-wrap md:flex-nowrap">
          <div className="w-full md:w-[15%] py-6 text-left text-sm px-4">{"Password (or) Authkey"}</div>
          <div className="w-full md:w-[35%] py-1 text-center text-sm ">
            <NuInput
              type="Password"
              placeholder="Password or Authkey"
              formik={formik}
              name="passwordOrAuthkey"
              width="w-full"
            />
          </div>
        </div>
        <div className="w-[49%] flex justify-end mt-4">
          <CreateButton label="Save" handleClick={formik.handleSubmit} svg={false} />
        </div>
      </form>
    </div>
  );
};

export default EmailServer;
