import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const DeliveryStatus = () => {
  return(
    <SettingsMetaData propertyName={"DelivaryNoteDelivaryStatus"} viewPropertyName={"Delivary Status"} />
  )
};

export default DeliveryStatus;
