import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Payable from "./Payable";
import Recevables from "./Recevables";
import Refunds from "./Refund";


  
  
  const MainPaymentsSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Payments"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><Payable/></div>
              <div className="p-3"><Recevables/></div>
              {/* <div className="p-3"><Refunds/></div> */}
        </div>
        </div>
    )
  }

  export default MainPaymentsSettings;