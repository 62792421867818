import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useMemo, useState } from "react";
import Input, { NuDate, NuInput, NuMobileNumber, NuSelect, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../Services/axiosCommon";
import NuDateDisplay from "../../../../Components/Utils/NuDate"
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import DeleteIcon from "../../../../Components/Icons/Delete";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

const EditReturns = () => {
  const {value:ReasonForReturn} = useFetchMataData(`/dropdown/dropdown-details/`,'ReasonForReturn')
  const {value:ReturnStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ReturnStatus')
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const { data: Item, setData } = UseDropDownNew('/items/new-drop-down');
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_Retuns')

  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [image, setImage] = useState([]);
  const [dataa,setDataa] = useState(data);

  const [error,setError] = useState('')
  const [createNew, setCreateNew] = useState({
    // type: data?.type || "",
    orderId: data?.orderId || "",
    // returnTo: data?.returnTo || "",
    returnToId: data?.returnToId || "",
    type: data?.type || "",
    comments: data?.comments || "",
    // quantity: data?.quantity || "",
    // unit: data?.unit || "",
    returnDate: (data?.returnDate && NuDateDisplay({value:data?.returnDate,format:"YYYY-MM-DD"}) )||"",
    dnRef: data?.dnRef || "",
    status: data?.status || "",
    products:[],
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      orderId: yup.string().required('Purchase Order is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      if (dataa.products.length === 0) {
        setError('Product table is empty');
        return;
      }
      const valueWithItems = {
        ...value,
        products: dataa.products,
      };
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      // formData.append('attachments', image.raw);

      editData(`/returns/edit-return/${data.returnId}`, formData, resetForm, from)

    },
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }
 


  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const { data: DN } = UseDropDown('/delivery-notes/drop-down')
  const [returnMember,setReturnMember] = useState([]);
const listSubQuotes = useMemo(() => {
  if(formik?.values?.type === "from_customer"){
  axiosInstance
  .get(`/customers/drop-down`)
  .then((res) => {
    let list = res.data;
    let array = [];
    list &&
      list.forEach((i) => {
        let num = { value: i?.id, label: i?.name };
        array.push(num);
      });
    res.data && setReturnMember(array);
  })
  .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }
    if(formik?.values?.type === "to_vendor"){
      axiosInstance
      .get(`/vendors/drop-down`)
      .then((res) => {
        let list = res.data;
        let array = [];
        list &&
          list.forEach((i) => {
            let num = { value: i?.id, label: i?.name };
            array.push(num);
          });
        res.data && setReturnMember(array);
      })
      .catch((error) => {
            console.error('Error fetching data:', error);
          });
    }
}, [formik?.values?.type]);

const handleDelete = (productId) => {
  const updatedquoteProducts = dataa?.products.filter(item => item.productId !== productId);
  const deletedProduct = dataa?.products.find(item => item.productId === productId);
  setDataa(prevState => ({
    ...prevState,
    products: updatedquoteProducts
  }));

  // Add deleted product back to Item list
  if (deletedProduct) {
    const newProduct = {
      value: deletedProduct.productId,
      label: deletedProduct.product_name,
      orderedQty: deletedProduct.orderedQty,
      unit: deletedProduct.product_unit,
    };
    setData(prevState => [...prevState, newProduct]);
  }
};

const handleInputChange = (e, index) => {
  const { name, value } = e.target;
  const updatedProducts = [...dataa.products];
  const intValue = parseInt(value);

  if (name === 'returnQty' && intValue <= updatedProducts[index].orderedQty) {
    updatedProducts[index][name] = intValue;
    updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].returnQty;
  }

  setData(prevState => ({
    ...prevState,
    products: updatedProducts
  }));
};

const handleIncrement = (index) => {
  const updatedProducts = [...dataa.products];
  if (updatedProducts[index].returnQty < updatedProducts[index].orderedQty) {
    updatedProducts[index].returnQty += 1;
    updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].returnQty;
    setDataa(prevState => ({
      ...prevState,
      products: updatedProducts
    }));
  }
};

const handleDecrement = (index) => {
  const updatedProducts = [...dataa.products];
  if (updatedProducts[index].returnQty > 0) {
    updatedProducts[index].returnQty -= 1;
    updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].returnQty;
    setDataa(prevState => ({
      ...prevState,
      products: updatedProducts
    }));
  }
};
const handleReasonChange = (e, index) => {
  const updatedItems = [...dataa.products];
  updatedItems[index].reason = e.target.value;
  setDataa(prevState => ({
    ...prevState,
    products: updatedItems
  }));
};
const [deletedItem, setDeletedItem] = useState(null);


const handleAdd = () => {
  let newItem;
  const selectedProduct = Item.find((i) => i.value === formik.values.productId);

  if (selectedProduct) {
    if (deletedItem && deletedItem.productId === selectedProduct.value) {
      // If there's a deletedItem that matches the selectedProduct, restore it
      newItem = {
        ...deletedItem,
        returnQty: deletedItem.orderedQty,  // Set returnQty to orderedQty or your desired default
      };
      setDeletedItem(null); // Clear deletedItem after restoring
    } else {
      // Otherwise, add the new product normally
      newItem = {
        productId: selectedProduct.value,
        product_name: selectedProduct.label,
        orderedQty: selectedProduct.orderedQty || 0,
        product_unit: selectedProduct.unit || '',
        returnQty: selectedProduct.orderedQty || 0,
        reason: ''
      };
    }

    // Update the dataa.products state
    setDataa((prevState) => ({
      ...prevState,
      products: [...prevState.products, newItem]
    }));

    // Update the Item state to remove the added product
    setData(Item.filter((item) => item.value !== formik.values.productId));

    // Clear the productId field in the form
    formik.setFieldValue('productId', '');
  }
};


const [order, setOrder] = useState([]);
function getVendors(num) {
  axiosInstance.get(`/returns/order-drop-down/from_customer/${num}`).then((response) => {
    let tempData = []
    if (response.data) {
      let x = response.data

      x.map((item) => {
        let y = {
          value: item.id,
          label: item.name,
        }
        tempData.push(y)
      })

      setOrder(tempData)
    }
  }).catch((error) => { })
}

useMemo(() => {
  if (formik.values.returnToId) {
    getVendors(formik.values.returnToId);

  }
}, [formik.values.returnToId])

const [returnlist, setReturnlist] = useState([]);

const getPr = (orderId) => {
  if (orderId !== "") {
    axiosInstance
      .get(`/returns/order-products/from_customer/${orderId}`)
      .then(res => {
        let data = res?.data?.products;
        console.log('Fetched products:', data); // Debugging log
        setReturnlist(data || []);  // Ensure default to empty array
      })
      .catch(err => {
        console.error(err);
      });
  }
};


useEffect(() => {
  if (formik.values.orderId !== '') {
    getPr(formik.values.orderId);
  }
}, [formik.values.orderId]);

useEffect(() => {
  if (dataa.products.length > 0) {
    setError('');
  }
}, [dataa.products]);

const filteredProducts = useMemo(() => {
  return Item.filter(item => !returnlist.some(listItem => listItem.productId === item.value));
}, [Item, returnlist]);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={
            [
              // {
              //   name: "Store",
              //   link: "/store/returns"
              // },  
            {
              name: "Returns",
              link: "/store/returns"
            },
            {
              name: "Edit Returns"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
              label="Type"
              placeholder="choose"
              Options={[
                { value: "from_customer", label: "From Customer" },
                { value: "to_vendor", label: "To Supplier" },
              ]}
              formik={formik}
              disabled={true}
              name="type"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Return To"
              placeholder="choose"
              Options={returnMember}
              formik={formik}
              disabled={true}

              name="returnToId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Order Reference"
              placeholder="choose"
              Options={order}
              disabled={true}

              formik={formik}
              name="orderId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />


            <FormikSelectTwo
              type="text"
              label="Delivery Notes"
              placeholder="choose"
              Options={DN}
              formik={formik}
              name="dnRef"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <NuDate
              label="Return Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="returnDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
   <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          />

               <FormikSelectTwo
              label="Status"
              placeholder="Choose"
              Options={ReturnStatus}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />  
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
 <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          x
                        </div>
                        <div
                          className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                          <img src={imgs?.file} alt={imgs?.file} />
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            <div className="w-full flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid">
              <FormikSelectTwo
                type="text"
                label="Product "
                placeholder="Product "
                Options={filteredProducts}
                formik={formik}
                name="productId"
                width="w-full md:w-1/2 lg:w-[80%] "
              />

              <button
                onClick={handleAdd}
                type="button"
                className=" p-1 mt-10 m-1 text-center h-10 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
              >
                Add
              </button>
            </div>
            <div className="w-full bg-white overflow-y-scroll mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className="w-[35%] text-left text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">Unit</p>
                <p className="w-[15%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">Ordered Qty</p>
                <p className="w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">Return Qty</p>
                <p className="w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">Reason</p>

                <p className=" w-[5%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
              Action
              </p>
              </div>
              {dataa?.products?.length>0?
                            <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {dataa?.products.map((List, index) => (

                <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className="w-[35%] text-left text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.product_unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.orderedQty}</p>

                  <div className="w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                      name="returnQty"
                      value={List.returnQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                  <input
                    type="text"
                    className="w-[20%] py-1 text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                    name="reason"
                    placeholder="Enter here..."
                    value={List.reason || ''}
                    onChange={(e) => handleReasonChange(e, index)}
                  />
                  <div className='w-[5%] text-center'>
                    <button
                      onClick={() => handleDelete(List?.productId)}
                      type="button"
                      className="text-red-500 cursor-pointer  ml-4 border-none"
                    >
                      <DeleteIcon
                        color='red'
                        height={4}
                      />
                    </button>
                  </div>
                </div>

              ))}
                          </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

              {/* {dataa?.products.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                  <p className="w-[40%] text-left text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.product_unit}</p>
                  <p className="w-[15%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">{List?.orderedQty}</p>
                 
                  <div className="w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1"
                      onClick={() => handleDecrement(index,'returnQty')}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-[20%] text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                      name="returnQty"
                      value={List.returnQty}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleIncrement(index, 'returnQty')}
                    >
                      +
                    </button>
                  </div>
                   <input
                    type="number"
                    className="w-[15%] py-1 text-center  text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                    name="reason"
                    formik={formik}
                    placeholder="Enter here..."
                    value={List.reason || ''}
                    onChange={(e) => handlePriceChange(e, index)}
                  />

                  <div className='w-[5%] text-center'> 
                 <button
                    onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-red-500 cursor-pointer  ml-4 border-none"
                  >
                    <DeleteIcon
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
              ))} */}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditReturns;
