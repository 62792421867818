import React, { useEffect, useState } from 'react';
import DataTable from '../../../../Components/Others/DataTable';
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
import Importdoc from './Importdoc';
import ModalContainer from '../../../../Components/Others/ModalContainer';
import MultiEditItem from './MultiEditItem';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';

function ItemIndex() {
  const toast = useToast();
  const {value:brand} = useFetchMataData(`/dropdown/dropdown-details/`,'brand_item')
  const {value:Manufacture} = useFetchMataData(`/dropdown/dropdown-details/`,'Manufacture_item')
  const {value:Unit} = useFetchMataData(`/dropdown/dropdown-details/`,'Unit_item')
 
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  });
  const clearFilter = ()=> {
    setParams({...params, category:"",
    subCategory:"",
    brand:"",
    manufacturer:"",
    underPromotion:"",
    unit:""
  })
  formik.values.category=""
  formik.values.subCategory=""
  formik.values.brand=""
  formik.values.manufacturer=""
  formik.values.underPromotion=""
  formik.values.unit=""


      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,category:value.category, subCategory:value.subCategory,brand:value.brand,manufacturer:value.manufacturer,underPromotion:value.underPromotion,unit:value.unit})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        category:"",
        subCategory:"",
        brand:"",
        manufacturer:"",
        underPromotion:"",
        unit:""
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch( '/items/',params);
  const defaultHeader = [
    // {
    //   title: "ID",
    //   width: "5%",
      
    //   field: "productId",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Item Code",
      width: "10%",
      isLink: true,
      id: "productId",
      link: '/products/items/view/',
    
      field: "itemCode",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product",
      isLink: true,
      id: "productId",
      link: '/products/items/view/',
      width: "15%",
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "10%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Sub-Category",
      width: "10%",
      field: "subCategory",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "5%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"productId",
        removeValue:"productName",
        url:`/items/`,
        moduleName:"Items",
        from:`/products/items`,
        editLink:"/products/items/edit/",
        viewLink:"/products/items/view/"

      }
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "productId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Item Code",
      width: "10%",
      isLink: true,
      id: "productId",
      link: '/products/items/view/',
    
      field: "itemCode",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Product",
      isLink: true,
      id: "productId",
      link: '/products/items/view/',
      width: "15%",
      field: "productName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Category",
      width: "10%",
      field: "category",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Sub-Category",
      width: "10%",
      field: "subCategory",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Under Promotion",
      width: "10%",
      field: "isPromotable",
      isCondition: true,
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Unit",
      width: "5%",
      field: "unit",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Manufaturer",
      width: "10%",
      field: "manufaturer",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Brand",
      width: "10%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Product Description",
    //   width: "10%",
    //   field: "productDescription",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents:{
        // fetcher:getData,
        id:"productId",
        removeValue:"productName",
        url:`/items/`,
        moduleName:"Items",
        from:`/products/items`,
        editLink:"/products/items/edit/",
        viewLink:"/products/items/view/"
      }     
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

    const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const { category: itemsCategory, subCategory: itemsSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);
    const [showImportModal, setShowImportModal] = useState(false);

    const handleImportClick = () => {
      setShowImportModal(true);
    };
  
    const closeModal = () => {
      setShowImportModal(false);
    };


    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [listofNumbers, setListofNumbers] = useState([])
    const [fullList, setFullList] = useState([])
  
    const selectOneFunction = (value) =>{ 
      let NewId;
      if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
        NewId = listofNumbers.filter(Temp=> Temp !== value);
        setListofNumbers(NewId)
      }else{
        setListofNumbers([...listofNumbers,value])
      }
    }
  
    const selectMultipleFunction = (e) =>{ 
  console.log(e.target.checked);
  if(e.target.checked){
    setListofNumbers(fullList)
  }else{
    setListofNumbers([])
  }
  
    }

    const handleMultiDelete = (data) => {
       axiosInstance.delete(`items/multi-delete`,{
        headers: {
          'Content-Type': 'application/json',
        },
        data: {"ids" : data},
      })
       .then(res => {
        if (res.data?.status === true) {
          if(res?.data?.not_deleted_ids.length<=0){
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Deleted Successfully."
            });
            getData();
            setShowDelete(false)
          }else{
            toast.open({
              type: "warning",
              message: "Success",
              description: `${res.data?.msg} \n Some Products has dependenies`|| "Deleted Successfully."
            });
            getData();
            setShowDelete(false)
          }

        } else if (res.data?.status === false && res.data?.error) {
          toast.open({
            type: "error",
            message: "Error",
            description: res.data.error || "Delete UnSuccessfull"
          });
        } else {
          toast.open({
            type: "warning",
            message: "Warning",
            description: res.data?.msg || "Warning."
          });
        }
      })
      .catch(err => {
        console.error(err);
        toast.open({
          type: "error",
          message: "Error",
          description: "Network Error."
        });
      });
    }
  
    useEffect(()=>{
      let tempArr = []
      data && data.forEach(i=>{
        console.log("I",i?.productId);
        tempArr.push(i?.productId)
      });
      setFullList(tempArr)
    },[data])
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Product" },

            // {
            //   // name: "Items",
            //   // link: "/products/items"
            // },

          ]} />
        <div className=' flex gap-2'>
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            /> <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {GetRole() && <ImportButton handleClick={handleImportClick} />}
          {GetAccess("items_create") &&<Link to="/products/items/create"><CreateButton label='Item' /></Link>}
        </div>
      </div>
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Product List' 
        storageName="ItemsTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="productId"
        showFieldFalse={false}
        editAccess="items_edit"      
        deteleAccess="items_delete"   
        />
       {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[50%] max-w-[70%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Product</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex justify-start flex-wrap mb-4">
                      <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={itemsCategory}
                        formik={formik}
                        name="category"
                        width="w-1/2"
                      />
                       <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Sub-Category"
              Options={itemsSubCategory}
              formik={formik}
              name="subCategory"
              width="w-1/2 "
            />
                       <FormikSelectTwo
              type="text"
              label="Under Promotion?"
              placeholder="Under Promotion?"
              Options={[
                { value: "No", label: "No" },
                { value: "Yes", label: "Yes" }

              ]}
              formik={formik}
              name="underPromotion"
              width="w-1/2"
            />
              {/* <FormikSelectTwo
                type="text"
                label="Unit"
                placeholder="Choose"
                Options={Unit}
                formik={formik}
                name="unit"
                width="w-1/2"
              /> */}
             <FormikSelectTwo
                type="text"
                label="Manufaturer"
                placeholder="Choose"
                Options={Manufacture}
                formik={formik}
                name="manufacturer"
                width="w-1/2"
              />
               <FormikSelectTwo
                type="text"
                label="Brand"
                placeholder="Choose"
                Options={brand}
                formik={formik}
                name="brand"
                width="w-1/2"
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
          {showEdit && (<MultiEditItem data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
        <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{handleMultiDelete(listofNumbers)}}
                closeModal={() => setShowDelete(false)}
            />
    </div>
  )
}

export default ItemIndex