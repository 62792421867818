import { useState, useMemo } from 'react';
import axiosInstance from '../../../../../Services/axiosCommon';

function useRfqSuppliers(prId) {
  const [rfqSuppliers, setRfqSuppliers] = useState([]);

  useMemo(() => {
    if (prId !== "") {
      axiosInstance
        .get(`/supplier-quotations/vendor/drop-down/${prId}`)
        .then(res => {
          let data = res.data;
          let tempList = []
          data.forEach((item) => {
            let tempObject = { value: item.id, label: item.name };
            tempList.push(tempObject);
          })
          data && setRfqSuppliers(tempList);
        })
        .catch(err => {
          console.log(err, "err");
        })
    }
        else {
            setRfqSuppliers([]);
          }
    }
  , [prId]);

  return rfqSuppliers;
}

export default useRfqSuppliers; 