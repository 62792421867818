import { useState } from 'react';
import axiosInstance from '../../Services/axiosCommon';
// import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../Others/ToastServices';

const FormForComments = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [ api, contextHolder ] = notification.useNotification();
  const navigate = useNavigate()
  const toast = useToast();

  function Oncancel() {
      navigate(-1);
  }

  const addFormData = async (url, formData, resetForm) => {
    setIsLoading(true);

    try {
      const res = await axiosInstance.post(url, formData);
      if (res.data?.status === true) {
        // toast.open({
        //   type:"success",
        //   message:"Success",
        //   description: res.data?.msg || "Created Successfully."
        // })

        resetForm();
        Oncancel();
      } 
    //   else if(res.data?.status === false && res.data?.error){
    //     toast.open({
    //       type:"error",
    //       message:"Comment not send.!",
    //       description: res.data.error || "Network error."
    //     })
    //   } 
      else {
        toast.open({
          type:"warning",
          message:"Comment not send.!",
          description: res.data?.msg || "Warning."
        })
      }
    } catch (err) {
    //   setError(err.message);
      console.log(err);
    //   toast.open({
    //     type:"error",
    //     message:"Error",
    //     description:  "Network error."
    //   })
    } finally {
      setIsLoading(false);
    }
  };

  return { addFormData, isLoading, Oncancel };
};

export default FormForComments;