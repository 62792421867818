import React from "react";

function Payments({ color = "#999999", width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill={color}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path
            fill={color}
            d="M225.557,316.597c20.281,0,83.625,0,83.625,0c16.797,0,30.406-13.609,30.406-30.406 s-13.609-30.406-30.406-30.406c-15.203,0-45.609,0-121.625,0c-76.031,0-100.094,31.672-126.703,58.281l-48.281,42.172 c-3.125,2.719-4.938,6.672-4.938,10.813v140.157c0,1.875,1.094,3.578,2.797,4.359c1.703,0.766,3.703,0.5,5.125-0.719l93.313-80 c3.297-2.797,7.672-3.984,11.922-3.219l145.406,26.438c10.141,1.844,20.594-0.484,29.016-6.438 c0,0,185.609-129.047,199.297-140.454l0,0c13.063-11.984,12.906-29.375,0.922-42.438c-12-13.094-34.375-10.313-49.297,0.734 c-13.672,11.406-107.078,72.797-107.078,72.797h-113.5l-0.359,0.156c-5.984-0.188-10.656-5.203-10.469-11.172 c0.219-5.984,5.219-10.672,11.188-10.469L225.557,316.597z"
          />
          <path
            fill={color}
            d="M362.697,0.003c-27.656,0-51.813,14.781-65.172,36.844c42.719,15.672,73.344,56.641,73.344,104.719 c0,3.547-0.203,7.047-0.531,10.516c38.516-3.844,68.594-36.328,68.594-75.859C438.932,34.128,404.807,0.003,362.697,0.003z"
          />
          <path
            fill={color}
            d="M261.979,121.222h-12.016v24.203h12.016c7.609,0,13.797-5.438,13.797-12.109 C275.775,126.66,269.588,121.222,261.979,121.222z"
          />
          <path
            fill={color}
            d="M259.244,54.441c-48.109,0-87.125,39.016-87.125,87.125c0,48.125,39.016,87.125,87.125,87.125 s87.125-39,87.125-87.125C346.369,93.457,307.354,54.441,259.244,54.441z M261.979,158.863h-12.016v17.609 c0,3.391-2.75,6.125-6.125,6.125h-3.078c-3.391,0-6.125-2.734-6.125-6.125V113.91c0-3.375,2.734-6.125,6.125-6.125h21.219 c16.078,0,29.141,11.453,29.141,25.531C291.119,147.41,278.057,158.863,261.979,158.863z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Payments;
