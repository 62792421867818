// import React, { useEffect, useState } from 'react';
// import axiosInstance from '../../Services/axiosCommon';
// import NuDate from './NuDate';
// import capitalizeFirstLetter from './Funcs';
// import ViewIcon from '../Icons/ViewIcon';
// import Modal from './ModelApproval';
// import ModalContainer from '../Others/ModalContainer';
// import ModalDecline from '../Others/ModalDecline';

// const ApprovalComponent = ({ id, getUrl, putUrl, statusUrl, refSetData, data, type }) => {
//   const [actionDetails, setActionDetails] = useState(null);
//   const [previousAction, setPreviousAction] = useState(null);
//   const [approvalStatus, setApprovalStatus] = useState('None');
//   const [statusDetails, setStatusDetails] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [reason, setReason] = useState('');
//   const [RequiredError, setRequiredError] = useState(false);

//   const [seal,setSeal] = useState([]);
//   const getSeal = () => {
//     axiosInstance.get('/company-seal/get-seal')
//       .then((res) => {
//         setSeal(res?.data?.data || []);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(()=>{
//     getSeal();
//   },[])

//   useEffect(() => {
//     if (id && getUrl) {
//       getActionDetails(id, getUrl);
//     }
//   }, [id, getUrl]);

//   function getActionDetails(id, url) {
//     axiosInstance.get(`${url}/${id}`)
//       .then(res => {
//         console.log("Response data:", res?.data);
//         setActionDetails(res?.data?.data);
//         setPreviousAction(res?.data?.previous_action);
//         setApprovalStatus(res?.data?.approvalStatus || 'None');
//       })
//       .catch(err => {
//         console.error("Error fetching action details:", err);
//       });
//   }

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   function verifyAction(id, url, status) {
//     console.log("Verifying ID:", id);
//     axiosInstance.put(`${url}/${id}`, { status: status, action: true })
//       .then(res => {
//         if (res.data.status) {
//           getActionDetails(id, getUrl); // Refresh data using the getUrl
//           setIsModalOpen(false)
//           type === "PR"  && status.toLowerCase()==="verified" && refSetData({...data,status: "Verified",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "GRN" && status.toLowerCase()==="verified" && refSetData({...data,status: "Verified",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "DN" && status.toLowerCase()==="verified" && refSetData({...data,status: "Verified",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "IN" && status.toLowerCase()==="verified" && refSetData({...data,status: "Verified",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "PO"  && status.toLowerCase()==="approved" && refSetData({...data,status: "Approved",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "OUT"  && status.toLowerCase()==="approved" && refSetData({...data,status: "Approved",isDraft: false,authorizedBy: seal?.[0]?.file})
//           type === "CQ"  && status.toLowerCase()==="approved" && refSetData({...data,status: "Approved",isDraft: false,authorizedBy: seal?.[0]?.file})
//         }
//       })
//       .catch(err => {
//         console.error("Error verifying action:", err);
//       });
//   }

//   const [isDecline, setIsDecline] = useState(false);
//   function declineAction(id, url, status,reason) {
//     if (!reason) {
//       setRequiredError(true);
//       return;
//     }
//     console.log("Decline action payload:", { status: status, action: false, reason }); // Logging the payload

//     axiosInstance.put(`${url}/${id}`, { status: status, action: false ,reason})
//       .then(res => {
//         console.log("Response from decline API:", res.data);
//         if (res.data.status) {
//           getActionDetails(id, getUrl);
//           setIsDecline(false);
//           setReason('');
//         }
//       })
//       .catch(err => {
//         console.error("Error declining action:", err);
//       });
//   }

//   function getAllStatusDetails(id, url) {
//     axiosInstance.get(`${url}/${id}`)
//       .then(res => {
//         console.log("All Status Data:", res?.data);
//         setStatusDetails(res?.data?.data || []);
//         // setShowModal(true);
//       })
//       .catch(err => {
//         console.error("Error fetching all status details:", err);
//       });
//   }

//   return (
//     <div className="border border-gray  rounded-lg">
//       <div className='flex justify-between bg-baseColor-primary  p-2 rounded-t-lg'>
//         <p className="text-xs 2xl:text-sm text-white  font-semibold">
//           Approval
//         </p>
//         <div className='cursor-pointer'
//         onClick={() => {
//            getAllStatusDetails(id, statusUrl);
//            setShowModal(true);
//           }}>
//           <ViewIcon color='white'
//           //  onClick={() => getAllStatusDetails(id, statusUrl)}
//            />
//         </div>
//       </div>
// <div className='p-2'>
// <p className='text-xs '> Status : {approvalStatus ? capitalizeFirstLetter(approvalStatus) : "None"}        </p>
// {previousAction && (
//         <div className="mt-2 items-center">
//           <p className="text-[12px] text-[#111537]">
//             {previousAction?.action
//               ? capitalizeFirstLetter(previousAction.status)
//               : 'Declined'} by {previousAction?.actionByName
//               ? capitalizeFirstLetter(previousAction.actionByName)
//               : 'N/A'}
//           </p>
//           <p className="text-[10px] text-[#606279]">
//             {previousAction?.dateTime ? (
//               <NuDate value={previousAction.dateTime} format="DD-MMM-YY hh:mm a" />
//             ) : 'N/A'}
//           </p>
//         </div>
//       )}

//       {actionDetails && (
//         <div className="flex gap-4 mt-2 justify-around">
//           <p
//             style={{ backgroundColor: actionDetails?.color }}
//             className="px-2 py-1 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
//             // onClick={() => verifyAction(id, putUrl, actionDetails.status)}
//             onClick={()=>{setIsModalOpen(true)}}
//           >
//             {actionDetails.action}
//           </p>

//           {actionDetails.status !== "resubmited" && (
//             <p
//               className="px-2 py-1 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
//               // onClick={() => declineAction(id, putUrl, actionDetails.status)}
//               onClick={()=>{setIsDecline(true)}}
//             >
//               Decline
//             </p>
//           )}
//         </div>
//       )}

//       <Modal show={showModal} onClose={() => setShowModal(false)}>
//         {statusDetails.length > 0 ? (
//           statusDetails.map((status, index) => (
//             <div key={index} className="mt-2">
//               <p className="text-[12px] text-[#111537]">
//                 {status.action
//                   ? capitalizeFirstLetter(status.status)
//                   : 'Declined'} by {status.actionByName
//                   ? capitalizeFirstLetter(status.actionByName)
//                   : 'N/A'}
//               </p>
//              {status.reason && (
//           <p className="text-[12px] text-red-500">
//             Reason: {status.reason}
//           </p>
//         )}
//               <p className="text-[10px] text-[#606279]">
//                 {status.dateTime ? (
//                   <NuDate value={status.dateTime} format="DD-MMM-YY hh:mm a" />
//                 ) : 'N/A'}
//               </p>
//             </div>
//           ))
//         ) : (
//           <div className="w-full text-center  items-center text-[#93949f] text-xs">No Status Details Available.</div>
//           // <p className='text-xs text-gray-400 flex items-center justify-center h-full'></p>
//         )}
//       </Modal>
//     </div>
//     <ModalContainer
//         visiable={isModalOpen}
//         title="Warning"
//         content={
//           <div>
//             <p>Are You Sure!You want to <span className='font-semibold'>{actionDetails?.action}</span></p>
//           </div>
//         }
//         handleClick={() => verifyAction(id, putUrl, actionDetails.status)}
//         closeModal={() => setIsModalOpen(false)}
//       />
//       <ModalDecline

//         visiable={isDecline}
//         title="Decline Action"
//         content={
//           <div>
//             <p className='m-1'>Are you sure you want to <span className='font-semibold'>Decline</span>?</p>

//             <label className='font-semibold'>Reason :<span className="text-red-500">*</span> </label>
//             <textarea
//               className=" w-full p-2 border rounded-md"
//               placeholder="Please provide a reason"
//               value={reason}
//               isRequired={true}
//               onChange={(e) => setReason(e.target.value)}
//             />
//             {RequiredError && (
//               <div className="text-red-500 text-xs mt-1">Reason is required</div>
//             )}
//           </div>
//         }
//         handleClick={() => declineAction(id, putUrl, actionDetails.status, reason)}
//         closeModal={() => setIsDecline(false)}
//         disableOkButton={!reason}
//       />
//     </div>

//   );
// };

// export default ApprovalComponent;

import React, { useEffect, useState } from "react";
import axiosInstance from "../../Services/axiosCommon";
import NuDate from "./NuDate";
import capitalizeFirstLetter from "./Funcs";
import ViewIcon from "../Icons/ViewIcon";
import Modal from "./ModelApproval";
import ModalContainer from "../Others/ModalContainer";
import ModalDecline from "../Others/ModalDecline";
import EditIcon from "../Icons/Edit";

const ApprovalComponent = ({
  id,
  getUrl,
  url,
  putUrl,
  statusUrl,
  refSetData,
  data,
  type,
}) => {
  const [actionDetails, setActionDetails] = useState(null);
  const [previousAction, setPreviousAction] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState("None");
  const [statusDetails, setStatusDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const [RequiredError, setRequiredError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [seal, setSeal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to get seal
  const getSeal = () => {
    axiosInstance
      .get("/company-seal/get-seal")
      .then((res) => {
        setSeal(res?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSeal();
  }, []);

  useEffect(() => {
    if (id && getUrl) {
      getActionDetails(id, getUrl);
    }
  }, [id, getUrl]);

  // Function to get action details
  function getActionDetails(id, url) {
    axiosInstance
      .get(`${url}/${id}`)
      .then((res) => {
        console.log("Response data:", res?.data);
        setActionDetails(res?.data?.data);
        setPreviousAction(res?.data?.previous_action);
        setApprovalStatus(res?.data?.approvalStatus || "None");
      })
      .catch((err) => {
        console.error("Error fetching action details:", err);
      });
  }

  function verifyAction(id, url, status) {
    console.log("Verifying ID:", id);
    axiosInstance
      .put(`${url}/${id}`, { status: status, action: true })
      .then((res) => {
        if (res.data.status) {
          getActionDetails(id, getUrl);
          setShowModal(false);
          type === "PR" &&
            status.toLowerCase() === "verified" &&
            refSetData({
              ...data,
              status: "Verified",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "GRN" &&
            status.toLowerCase() === "verified" &&
            refSetData({
              ...data,
              status: "Verified",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "DN" &&
            status.toLowerCase() === "verified" &&
            refSetData({
              ...data,
              status: "Verified",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "IN" &&
            status.toLowerCase() === "verified" &&
            refSetData({
              ...data,
              status: "Verified",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "PO" &&
            status.toLowerCase() === "approved" &&
            refSetData({
              ...data,
              status: "Approved",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "OUT" &&
            status.toLowerCase() === "approved" &&
            refSetData({
              ...data,
              status: "Approved",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
          type === "CQ" &&
            status.toLowerCase() === "approved" &&
            refSetData({
              ...data,
              status: "Approved",
              isDraft: false,
              authorizedBy: seal?.[0]?.file,
            });
        }
      })
      .catch((err) => {
        console.error("Error verifying action:", err);
      });
  }

  const [isDecline, setIsDecline] = useState(false);
  function declineAction(id, url, status, reason) {
    if (!reason) {
      setRequiredError(true);
      return;
    }
    console.log("Decline action payload:", {
      status: status,
      action: false,
      reason,
    });

    axiosInstance
      .put(`${url}/${id}`, { status: status, action: false, reason })
      .then((res) => {
        console.log("Response from decline API:", res.data);
        if (res.data.status) {
          getActionDetails(id, getUrl);
          setIsDecline(false);
          setReason("");
        }
      })
      .catch((err) => {
        console.error("Error declining action:", err);
      });
  }

  function getAllStatusDetails(id, url) {
    axiosInstance
      .get(`${url}/${id}`)
      .then((res) => {
        console.log("All Status Data:", res?.data);
        setStatusDetails(res?.data?.data || []);
        setShowModal(true);
      })
      .catch((err) => {
        console.error("Error fetching all status details:", err);
      });
  }

  const handleEditReasonClick = (id, existingReason) => {
    setReason(existingReason);
    setStatusId(id);
    setShowEditModal(true);
    setShowModal(false);
  };

  const editReason = (id,statusId, url, reason) => {
    if (!reason) {
      setRequiredError(true);
      return;
    }
    console.log("type",url);
    axiosInstance
      .put(`${url}/edit-reason/${statusId}`, { reason })
      .then((res) => {
        if (res.data.status) {
          getAllStatusDetails(id, statusUrl);
          setShowEditModal(false);
          // setReason("");
        }
      })
      .catch((err) => {
        console.error("Error editing reason:", err);
      });
  };

  return (
    <div className="border border-gray rounded-lg">
      <div className="flex justify-between bg-baseColor-primary p-2 rounded-t-lg">
        <p className="text-xs 2xl:text-sm text-white font-semibold">Approval</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            getAllStatusDetails(id, statusUrl);
            setShowModal(true);
          }}
        >
          <ViewIcon color="white" />
        </div>
      </div>
      <div className="p-2">
        <p className="text-xs ">
          {" "}
          Status :{" "}
          {approvalStatus ? capitalizeFirstLetter(approvalStatus) : "None"}{" "}
        </p>
        {previousAction && (
          <div className="mt-2 items-center">
            <p className="text-[12px] text-[#111537]">
              {previousAction?.action
                ? capitalizeFirstLetter(previousAction.status)
                : "Declined"}{" "}
              by{" "}
              {previousAction?.actionByName
                ? capitalizeFirstLetter(previousAction.actionByName)
                : "N/A"}
            </p>
            <p className="text-[10px] text-[#606279]">
              {previousAction?.dateTime ? (
                <NuDate
                  value={previousAction.dateTime}
                  format="DD-MMM-YY hh:mm a"
                />
              ) : (
                "N/A"
              )}
            </p>
          </div>
        )}

        {actionDetails && (
          <div className="flex gap-4 mt-2 justify-around">
            <p
              style={{ backgroundColor: actionDetails?.color }}
              className="px-2 py-1 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {actionDetails.action}
            </p>

            {actionDetails.status !== "resubmited" && (
              <p
                className="px-2 py-1 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
                onClick={() => {
                  setIsDecline(true);
                }}
              >
                Decline
              </p>
            )}
          </div>
        )}
        {/* </div> */}

        <Modal show={showModal} onClose={() => setShowModal(false)}>
          {statusDetails.length > 0 ? (
            statusDetails.map((status, index) => (
              <div key={index} className="mt-2">
                <p className="text-[12px] text-[#111537]">
                  {status.action
                    ? capitalizeFirstLetter(status.status)
                    : "Declined"}{" "}
                  by{" "}
                  {status.actionByName
                    ? capitalizeFirstLetter(status.actionByName)
                    : "N/A"}
                </p>
                {status.reason && (
                  <div className="flex items-center justify-between gap-2">
                    <p className="text-[12px] text-red-500">
                      Reason: {status.reason}
                    </p>
                    <div className="cursor-pointer" 
                        onClick={() => {handleEditReasonClick(status.id, status.reason);setShowEditModal(true)}}
                    >
                      <EditIcon
                        color="red"
                        width="12"
                        height="12"
                        // onClick={() => handleEditReasonClick(status.id, status.reason)}
                      />
                    </div>
                  </div>
                )}
                <p className="text-[10px] text-[#606279]">
                  {status.dateTime ? (
                    <NuDate
                      value={status.dateTime}
                      format="DD-MMM-YY hh:mm a"
                    />
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            ))
          ) : (
            <div className="w-full text-center  items-center text-[#93949f] text-xs">
              No Status Details Available.
            </div>
            //<p className='text-xs text-gray-400 flex items-center justify-center h-full'></p>
          )}
        </Modal>
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Warning"
        content={
          <div>
            <p>
              Are You Sure!You want to{" "}
              <span className="font-semibold">{actionDetails?.action}</span>
            </p>
          </div>
        }
        handleClick={() => verifyAction(id, putUrl, actionDetails.status)}
        closeModal={() => setIsModalOpen(false)}
      />

      <ModalDecline
        visiable={showEditModal}
        onClose={() => setShowEditModal(false)}
        content={
          <div className="p-2">
            <p className="text-sm font-semibold">Edit Reason</p>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="w-full h-24 border border-gray-300 p-2 mt-2 rounded-md"
            />
            {RequiredError && !reason && (
              <p className="text-red-500 text-sm">Reason is required</p>
            )}
          </div>
        }
        handleClick={() => editReason(id,statusId, type, reason)}
        closeModal={() => setShowEditModal(false)}

        // <div className="flex justify-end gap-4 mt-4">
        //   <button
        //     onClick={() => {
        //       editReason(statusId, statusUrl, reason);
        //       setShowEditModal(false);
        //     }}
        //     className="px-4 py-2 bg-blue-500 text-white rounded-md"
        //   >
        //     Submit
        //   </button>
        //   <button
        //     onClick={() => setShowEditModal(false)}
        //     className="px-4 py-2 bg-gray-500 text-white rounded-md"
        //   >
        //     Cancel
        //   </button>
        // </div>
        // </div>
      />

      <ModalDecline
        visiable={isDecline}
        title="Decline Action"
        content={
          <div>
            <p className="m-1">
              Are you sure you want to{" "}
              <span className="font-semibold">Decline</span>?
            </p>

            <label className="font-semibold">
              Reason :<span className="text-red-500">*</span>{" "}
            </label>
            <textarea
              className=" w-full p-2 border rounded-md"
              placeholder="Please provide a reason"
              value={reason}
              isRequired={true}
              onChange={(e) => setReason(e.target.value)}
            />
            {RequiredError && (
              <div className="text-red-500 text-xs mt-1">
                Reason is required
              </div>
            )}
          </div>
        }
        handleClick={() =>
          declineAction(id, putUrl, actionDetails.status, reason)
        }
        closeModal={() => setIsDecline(false)}
        disableOkButton={!reason}
      />
    </div>
  );
};

export default ApprovalComponent;
