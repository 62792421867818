import React from "react";
import CompanyLogo from '../../../assets/images/companyLogo.png'
import CompanyName from '../../../assets/images/companyName.png'
import Seal from '../../../assets/images/seal.png'
import NuDate from '../../../Components/Utils/NuDate';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const GRNPdfNu = () => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);
  return (
    <div
    ref={containerRef}
    className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border">
     <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className='relative w-full h-full '>
          <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
            <button onClick={() => zoomIn()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">+</button>
            <button onClick={() => zoomOut()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">-</button>
            <button onClick={() => resetTransform()} className='text-left'>Reset</button>
          </div>
      <div className="flex justify-center items-center w-full h-full">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <div className='min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black'>
      <div className="border border-black">
      {/* Header */}
        <div className="flex justify-between items-center border-b pb-4 mb-4">
          <div>
            <div className="bg-gray-300 h-16 w-32 mb-4"></div>
            <div className="text-xs">
              <p>[Company Name]</p>
              <p>[Street Address]</p>
              <p>[City, ST, ZIP]</p>
              <p>Phone: [000-000-0000]</p>
              <p>Fax: [000-000-0000]</p>
            </div>
          </div>
          <div className="text-right">
            <h2 className="text-[24px] font-bold">GRN</h2>
            <p className="text-xs mt-2">Date: 12/11/2023</p>
            <p className="text-xs">P.O. #: [12356]</p>
          </div>
        </div>

        {/* Vendor and Ship To */}
        <div className="grid grid-cols-2 gap-8 mb-4">
          <div>
            <h3 className="font-bold  bg-blue-800 text-sm text-white p-1">Vendor</h3>
            <div className="p-1 text-xs">
              <p>[Company Name]</p>
              <p>[Website]</p>
              <p>[Street Address]</p>
              <p>[City, ST, ZIP]</p>
              <p>Phone: [000-000-0000]</p>
            </div>
          </div>
          <div>
            <h3 className="font-bold  bg-blue-800 text-sm text-white p-1">Ship To</h3>
            <div className="p-1 text-xs">
              <p>[Attn: Name]</p>
              <p>[Company Name]</p>
              <p>[Street Address]</p>
              <p>[City, ST, ZIP]</p>
              <p>Phone: [000-000-0000]</p>
            </div>
          </div>
        </div>

        {/* Shipping Details */}
        <div className="grid grid-cols-3 gap-8 mb-4">
          <div>
            <h3 className="font-bold  bg-blue-800 text-sm text-white p-1">Shipping Terms</h3>
            <div className="p-1 text-xs">USPS</div>
          </div>
          <div>
            <h3 className="font-bold  bg-blue-800 text-sm text-white p-1">Shipping Method</h3>
            <div className="p-1 text-xs">USPS</div>
          </div>
          <div>
            <h3 className="font-bold  bg-blue-800 text-sm text-white p-1">Delivery Date</h3>
            <div className="p-1 text-xs">01/15/2024</div>
          </div>
        </div>

        {/* Product Table */}
        <table className="w-full text-xs mb-4">
          <thead>
            <tr className= "bg-blue-800 text-sm text-white border-collapse">
              <th className="p-1 border">Item #</th>
              <th className="p-1 border">Description</th>
              <th className="p-1 border">QTY</th>
              <th className="p-1 border">Unit Price</th>
              <th className="p-1 border">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-1 border">123456</td>
              <td className="p-1 border">Product A</td>
              <td className="p-1 border">27</td>
              <td className="p-1 border">$220.00</td>
              <td className="p-1 border">$5,940.00</td>
            </tr>
            <tr>
              <td className="p-1 border">789012</td>
              <td className="p-1 border">Product B</td>
              <td className="p-1 border">3</td>
              <td className="p-1 border">$55.00</td>
              <td className="p-1 border">$165.00</td>
            </tr>
            <tr>
              <td className="p-1 border"></td>
              <td className="p-1 border"></td>
              <td className="p-1 border"></td>
              <td className="p-1 border"></td>
              <td className="p-1 border"></td>
            </tr>
          </tbody>
        </table>

        {/* Totals */}
        <div className="flex justify-between text-xs mb-4">
          <div className="w-1/2">
            <h3 className="font-bold">Comments</h3>
            <p className="border p-1 h-24"></p>
          </div>
          <div className="w-1/3">
            <div className="flex justify-between p-1 border-b">
              <p>SUBTOTAL</p>
              <p>$6,105.00</p>
            </div>
            <div className="flex justify-between p-1 border-b">
              <p>TAX RATE</p>
              <p>5.00%</p>
            </div>
            <div className="flex justify-between p-1 border-b">
              <p>TAX TOTAL</p>
              <p>$305.25</p>
            </div>
            <div className="flex justify-between p-1 border-b">
              <p>SHIPPING</p>
              <p>$1,000.00</p>
            </div>
            <div className="flex justify-between p-1 border-b">
              <p>OTHER</p>
              <p>$0.00</p>
            </div>
            <div className="flex justify-between p-1  bg-blue-800 text-sm text-white font-bold">
              <p>TOTAL</p>
              <p>$7,410.25</p>
            </div>
          </div>
        </div>

        {/* Authorization */}
        <div className="flex justify-between items-center text-xs">
          <div>
            <p>Authorized by</p>
            <div className="border-b w-48"></div>
          </div>
          <div>
            <p>Date</p>
            <div className="border-b w-48"></div>
          </div>
        </div>
        </div>
      </div>
      </TransformComponent>
      </div>
      </div>
      // </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default GRNPdfNu;