import React, { useState } from "react";
import ReportTable from "../../../../Components/Others/ReportTable";
import NuDate from "../../../../Components/Utils/NuDate";
import DownloadXlsx from "../xlsx/DownloadXlsx";
import DefaultDownloadLink from "../pdf/DefaultDownloadLink";
import { BackButton, FilterButton } from "../../../../Components/Utils/Buttons";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";

function CustomersReport() {
  const [params, setParams] = useState({
    page: 1,
    per_page: 100,
    search: "",
    searchBy: ""
  });
  const { data, totalCount, getData } = UseGetDataSearch( "/customers/", params );
  let headers = [
    {
      title: "ID",
      width: "5%",
      field: "customerId",
        textAlign: "left",
        rowAlign: "left",
    },
    {
      title: "Company Name",
      width: "10%",
      field: "companyName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Email",
      width: "10%",
      field: "image.file",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Postal Code",
      width: "10%",
      field: "postalCode",
      textAlign: "left",
      rowAlign: "left",
    },
  ];



  return (
    <div className=" w-full h-full px-4 py-2  overflow-hidden">
      <div className=" w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Reports",
              link: "/reports",
            },
            {
              name: "Customers",
            },
          ]}
        />
        <BackButton />
      </div>

      <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
        <div className=" w-full h-full">
          <div className=" w-full flex justify-between items-center">
            <TextMediumSM content={"Customers Report"} />
            <div className=" flex items-center gap-4">
              <FilterButton height="h-[32px]" />
              <DefaultDownloadLink
                headers={headers}
                data={data}
                fileName={`Customers Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}.pdf`}
              />
              <DownloadXlsx
                header={headers}
                data={data}
                fileName={`Customers Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}`}
              />
            </div>
          </div>
          <ReportTable
            bodyHeight="max-h-[calc(100vh-245px)]"
            height="h-[calc(100vh-235px)]"
            headers={headers}
            data={data}
          ></ReportTable>
        </div>
      </div>
    </div>
  );
}

export default CustomersReport;
