import React, { useState } from 'react';

const Carousel = ({ images, onClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const renderThumbnails = () => {
    return (
      <div className="flex overflow-x-auto mt-8">
        {images.map((image, idx) => (
          <button
            key={idx}
            className={`flex-shrink-0 w-14 h-10 mx-1 ${currentIndex === idx ? 'border-2 border-blue-500' : ''}`}
            onClick={() => setCurrentIndex(idx)}
          >
            <img src={image.file} alt={`thumbnail ${idx}`} className="w-full h-full object-cover" />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="relative w-full max-w-3xl mx-auto h-[90%] bg-white flex flex-col items-center justify-center">
      {/* <button className="absolute left-2 p-2 text-black rounded-full z-10" onClick={handlePrevious}>
        &lt;
      </button> */}
      <div className="w-full h-[85%] items-center justify-center flex overflow-hidden">
        <div className="flex transition-transform duration-500 rounded-lg" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((img, idx) => (
            <div key={idx} className="w-full h-full flex-shrink-0 flex items-center justify-center cursor-pointer ">
              <img src={img.file} alt={`slide ${idx}`} className="w-full h-full object-contain " onClick={() => onClick(img)} />
            </div>
          ))}
        </div>
      </div>
      {/* <button className="absolute right-2 p-2 text-black rounded-full z-10" onClick={handleNext}>
        &gt;
      </button> */}
      <div className="absolute bottom-4 w-full flex justify-center ">
        {renderThumbnails()}
      </div>
    </div>
  );
};

export default Carousel;
