import React, { useState } from 'react'
import Breadcrumb from '../../../Components/Utils/Breadcrumb'
import Select from "react-select";
import { CreateButton, SubmitButton } from '../../../Components/Utils/Buttons';
import { AgentsReport, AssetsReport, CustomersReport, EquipmentReport, GRNReport, InvoicesReport, LeadsReports, PayabalesReport, ProductsReport, PurchaseRequestsReport, ReceivablesReport, SalesOrderReport, StocksReport, TasksReport, VehiclesReports, VendorPOReports, VendorsReports } from './Components';
import { Link } from 'react-router-dom';
import { TextMediumSM } from '../../../Components/Text/MediumText';

function ReportMainIndex() {
  const [selectedValues, setSelectedValues] = useState("")
  const [show,setShow] = useState("")

  function handleChange(e){
    setSelectedValues(e.value)
  }


  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };




  let options = [
    {
      label: "Agents",
      value:1,
      link:'/reports/agents'
    },
    {
      label: "Assets",
      value:2,
      link:'/reports/assets'
    },
    {
      label: "Customers",
      value:3,
      link:'/reports/customers'
    },
    {
      label: "Equipment",
      value:4,
      link:'/reports/equipment'
    },
    {
      label: "GRN",
      value:5,
      link:'/reports/grn'
    },
    {
      label: "Invoices",
      value:6,
      link:'/reports/invoices'
    },
    {
      label: "Leads",
      value:7,
      link:'/reports/leads'
    },
    {
      label: "Payabales",
      value:8,
      link:'/reports/payabales'
    },
    {
      label: "Purchase Requests",
      value:9,
      link:'/reports/purchase-requests'
    },
    {
      label: "Products",
      value:10,
      link:'/reports/products'
    },
    {
      label: "Receivables",
      value:11,
      link:'/reports/receivables'
    },
    {
      label: "Sales Order",
      value:12,
      link:'/reports/sales-order'
    },
    {
      label: "Stocks",
      value:13,
      link:'/reports/stocks'
    },
    {
      label: "Tasks",
      value:14,
      link:'/reports/tasks'
    },
    {
      label: "Vendors",
      value:15,
      link:'/reports/vendors'
    },
    {
      label: "Vendor PO",
      value:16,
      link:'/reports/vendor-po'
    },
    {
      label: "Vehicles",
      value:17,
      link:'/reports/vehicles'
    },
  ]


  function handleSubmit(){
    if(selectedValues){
      setShow(selectedValues)
    }else{
      setShow("")
    }
  }

  return (
    <div className=' w-full h-full px-4 py-2  overflow-hidden'>
        <Breadcrumb
         items={[
            {
                name:"Report"
            }
         ]}
        />
        <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
          <div className=' w-full h-full grid grid-cols-3 lg:grid-cols-4 gap-3 overflow-y-auto'>
            {
              options && options.map((item,index)=>{
                return (
                  <div key={index} className=' bg-slate-50 p-2 flex  items-center rounded-lg hover:shadow-lg'>
                    <Link to={item.link}>
                      <TextMediumSM content={item.label||""} />
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
    </div>
  )
}

export default ReportMainIndex