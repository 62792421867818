import React from 'react'
import { CreateButton } from '../../../../../Components/Utils/Buttons'
import { PDFDownloadLink } from '@react-pdf/renderer'
import DownloadCo from './DownloadCo'

function PDFLINKCO({headers,data,fileName}) {
    return (
        <PDFDownloadLink document={<DownloadCo header={headers} data={data} />} fileName={fileName}>
        {({ blob, url, loading, error }) =>
          loading ? (
            "..."
          ) : (
            <CreateButton svg={false} label="Download as PDF" />
          )
        }
      </PDFDownloadLink>
      )
    }

export default PDFLINKCO