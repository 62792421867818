import React from "react";

function Plus({ color = "white", width = 13, height = 13 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1072 6.94442H7.8215V11.2301C7.8215 11.4575 7.73119 11.6755 7.57045 11.8362C7.4097 11.997 7.19168 12.0873 6.96436 12.0873C6.73703 12.0873 6.51901 11.997 6.35826 11.8362C6.19752 11.6755 6.10721 11.4575 6.10721 11.2301V6.94442H1.8215C1.59417 6.94442 1.37615 6.85412 1.21541 6.69337C1.05466 6.53263 0.964355 6.31461 0.964355 6.08728C0.964355 5.85995 1.05466 5.64193 1.21541 5.48119C1.37615 5.32044 1.59417 5.23014 1.8215 5.23014H6.10721V0.944423C6.10721 0.717095 6.19752 0.499077 6.35826 0.338331C6.51901 0.177586 6.73703 0.0872803 6.96436 0.0872803C7.19168 0.0872803 7.4097 0.177586 7.57045 0.338331C7.73119 0.499077 7.8215 0.717095 7.8215 0.944423V5.23014H12.1072C12.3345 5.23014 12.5526 5.32044 12.7133 5.48119C12.8741 5.64193 12.9644 5.85995 12.9644 6.08728C12.9644 6.31461 12.8741 6.53263 12.7133 6.69337C12.5526 6.85412 12.3345 6.94442 12.1072 6.94442Z"
        fill={color}
      />
    </svg>
  );
}

export default Plus;
