import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CountryMIN } from "../../assets/CountryMini";
import axiosInstance from "../../Services/axiosCommon";
import { useToast } from "../Others/ToastServices";
import { useFormik } from "formik";
import { CancelButton, SubmitButton } from "./Buttons";
import { NuInput } from "./Input";
import * as yup from 'yup';


function DropDownAdd({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  customClass,
  isRequired,
  width,
  addData,
  getFunc,
  displayProperty,
  categoryName
}) {
  const options = Options;
  const toast = useToast();
  const [showPropertyAdd, setShowPropertyAdd] = useState(false);

  const [category, setCategory] = useState("");
    // console.log(">>>",value);
    const add = () => {
      const data = {
        module: `${categoryName}`,
        category: category
      }
      axiosInstance.post(`/category/new-category`, data)
              .then(res => {               
                  if (res.data) {
                      // console.log("Created", resetForm)
                      // resetForm();
                      toast.open({
                        type:"success",
                        message: `${displayProperty}  Added successfully`,
                        // description:'',
                        // placement: 'bottomRight',
                    });
                    resetForm();
                    closeFilterDialog();
                    getFunc();
                    console.log("hhh",getFunc());
                  }
                  else {
                      console.log("Failed")
                      toast.open({
                        type:"error",
                        message: `${displayProperty} Added failed`,
                        // description: response.data.msg || 'Network error',
                        // placement: 'bottomRight',
                    });
                  }
              }).catch(error => {
                console.error("Error adding category:", error);
                toast.open({
                  type: "error",
                  message: `${displayProperty} Addition failed`,
                  description: error.message || 'Network error',
                  // placement: 'bottomRight',
                });
              }); 
    }

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const resetForm = () => {
    setCategory("");
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
      // zIndex:0
    }),
  };

  const closeFilterDialog = () => {
    setShowPropertyAdd(false);
  };
  return (
    <>
      <div className={`${width} relative px-6 py-2`}>
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <div className="mt-2 flex gap-1">
          <Select
            options={options}
            value={options.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} w-[86%] text-xs capitalize 2xl:text-sm font-medium `}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
          />
          {/* {options == null ?  */}
             <button onClick={()=>setShowPropertyAdd(true)} type="button" className="w-[14%] p-1 text-center h-10 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg " > + </button>
             {/* :
             null
           } */}
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
        {showPropertyAdd && (            
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 {/* <label className="text-xl mb-4">Add Category</label> */}
                    <div className="flex flex-col mb-4">
                      <NuInput
                       label={`${displayProperty}`}
                       type="text"
                       placeholder={`${displayProperty}`}
                       name="category"
                       value={category}
                       onChange={(e) => setCategory(e.target.value)}
                       className="w-full"
                      />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <button className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md flex items-center" type="button" onClick={add}>
                    Submit
                  </button>
                   </div>
               </div>
             </div>
           )}
      </div>
    </>
  );
}

export default DropDownAdd;








