import React from 'react';
import { Navigate } from 'react-router-dom';
import DecryptData from '../Components/Utils/Decrypt';

function PrivateRoute({ children }) {
  
  const auth_key= DecryptData('nEkOtReSuEdArT')||"";

const getAuthKey = () => {
  // const tokenString = sessionStorage.getItem(auth_key);

    // const tokenString = localStorage.getItem(auth_key);
  const userToken = auth_key;
    if(!userToken){
        return false;
    }else{
      return true;
    }
    
  };
  const auth = getAuthKey();
  return auth ? children : <Navigate to='/auth/login' />;
}

export default PrivateRoute;
