import React, { createContext, useContext, useState } from 'react';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem("currency")||"");
  

  const updateCurrency = (newCurrency) => {
    setCurrencySymbol(newCurrency);
    localStorage.setItem("currency", newCurrency);
  };



  return (
    <CurrencyContext.Provider value={{ currencySymbol, updateCurrency, }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useStandard must be used within a CurrencyContext');
  } 
  return context;
};
