import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DeleteIcon from '../../../../Components/Icons/Delete';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { useToast } from '../../../../Components/Others/ToastServices';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];


function EditRQF() {
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_RFQ')
  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);

  const {value:Status} = useFetchMataData(`/dropdown/dropdown-details/`,'status_RFQ')
  const { data: Purchaser } = UseDropDown('/user/purchaser/drop-down');

    const { Oncancel, editFormData, contextHolder, isLoading } =
  useEditFormData();  
   const { data:vendors } = UseDropDown('/vendors/drop-down');
    const { data:PR } = UseDropDown('/PR/drop-down');

    const location = useLocation();

    const data = location.state.data;
    const from = location.state.from;
  const [dataa,setData] = useState(data);
  console.log("ccc",dataa);

    const [createNew, setCreateNew] = useState({
        // date: data.date,
        prId: data.prId,
        vendor: data.vendor,
        comments: data.comments,
        isActive: data.isActive,
        status: data.status,
        rfqType:data.rfqType,
        localOption:data.localOption,
        purchaser:data.purchaser,
        approvalStatus:data.approvalStatus,
        customerRfq: data.customerRfq    
    })
  const [error, setError] = useState('');
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        prId: yup.string().required("Purchase Request is requred!"),
        rfqType: yup.string().required("RFQ Type is requred!"),
      }),
  
      onSubmit: async (value, { resetForm }) => {
        if (dataa.productList.length === 0) {
          setError('Product table is empty');
          return;
        }
      let formData = new FormData();

        const valueWithItems = {
          ...value,
          products: dataa.productList,
        filesToRemove: fileRemoveList,

        };
        formData.append("json_data", JSON.stringify(valueWithItems));
        if (selectedFile !== null) {
          selectedFile.forEach((file) => {
            formData.append("attachments", file);
          });
        } else {
          formData.append("attachments", []);
        }
        
        editFormData(`/rfq/edit-rfq/${data.id}`, formData, resetForm, from);
    
        // editData(`/rfq/edit-rfq/${data.id}`, valueWithItems, resetForm, from)
      }
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePathList, setFilePathList] = useState([]);
    const [removefilePathList, setRemoveFilePathList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const toast = useToast();
  
    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = [];
      files.map((item) => {
        if (item.size < maxFileSize) {
          fileArr.push(item);
        } else {
          toast.open({
            type: "warning",
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            // placement: "bottomRight",
          });
        }
      });
      let tempArr = [];
      setSelectedFile(fileArr);
      files.map((item) => {
        if (item.size < maxFileSize) {
          let fileName = item?.name;
          console.log("fname", fileName);
          let fileExtention = fileName.split(".").reverse()[0];
          if (filetype?.includes(fileExtention)) {
            tempArr.push(fileName);
          } else {
            let x = URL.createObjectURL(item);
            tempArr.push(x);
          }
        }
      });
      console.log(tempArr, "temp");
      setImage(tempArr);
    }
  
    useEffect(() => {
      if (
        data?.attachments !== null &&
        data?.attachments !== "None" &&
        data?.attachments !== "[]"
      ) {
        console.log(data.attachments, "ghjkllgd");
        setFilePathList(data?.attachments);
       
        setRemoveFilePathList(data?.attachments);
       
      }
    }, [data]);
  
    function UpdateAttachmentList(item) {
      let filterList = filePathList.filter((itemList) => itemList.file !== item);
      let removeFile = filePathList.find((itemList) => itemList.file === item);
      console.log("llll", filterList);
      setFileRemoveList([...fileRemoveList, removeFile.file]);
      setFilePathList(filterList);
    }
  
    function handleImageClick(img) {
      setSelectedImage(img);
      setIsModalOpen(true);
    }
  
    
    const [prlist, setPrlist] = useState([]);

  const getPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/PR/pr-products/${prId}`)
        .then(res => {
          let data = res.data.data;
          setPrlist(data);
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (formik.values.prId) {
      getPr(formik.values.prId);
    }
  }, [formik.values.prId]);
  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  const handleDelete = (productId) => {
    const updateProducts = dataa?.productList.filter(item => item.productId !== productId);
console.log(updateProducts,"pp");
    setData(prevState => ({
      ...prevState,
      productList: updateProducts
    }));
    const deletedItems = dataa?.productList.filter(item => item.productId == productId);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, ...deletedItems]);
    // setDeletedItem(deletedItems)
    // data.productList = updateProducts
    // setRemove([...remove,productId]);
  };
  console.log("dl",deletedItem);
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);
    console.log("dele",deletedItemDropDown);

    const handleAdd = () => {
      const selectedProductId = formik.values.productId;
    
      // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
      const selectedProduct = deletedItem.find(
        (item) => item.productId === selectedProductId
      );
    
      if (selectedProduct) {
        // Add the selected product to prList
        setData((prevState) => ({
          ...prevState,
          productList: [...prevState.productList, selectedProduct],
        }));
    
        // Optionally, remove the selected product from the dropdown after adding it to prList
        setDeletedItem((prevDeletedItems) =>
          prevDeletedItems.filter((item) => item.productId !== selectedProductId)
        );
      }
    };

  useEffect(() => {
    if (dataa.productList.length > 0) {
      setError('');
    }
  }, [dataa.productList]);

    return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
    
        <Breadcrumb items={[
          // { name: "Procurement" },
  
          { name: "RFQ",
            link:"/procurement/rfq/"},
            
          { name: "Edit"},
        
    ]} />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
            <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="Purchase Request"
              placeholder="Purchase Request"
              Options={PR}
              formik={formik}
              disabled={true}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              label="Customer Reference"
              type="text"
              formik={formik}
              placeholder="Customer Reference"
              name="customerRfq"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />
            <FormikSelectTwo
              type="text"
              label="RFQ Type"
              placeholder="RFQ Type"
              Options={[
                { value: "local", label: "Local" },
                { value: "international", label: "International" },
              ]}
              formik={formik}
              disabled={true}

              name="rfqType"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {/* {
            formik.values.rfqType === 'local' && (
              <div className="w-full md:w-1/2 lg:w-1/3">
                <div className="flex items-center mt-9">
                  <input
                    type="radio"
                    name="localOption"
                    value="purchaser"
                    checked={formik.values.localOption === 'purchaser'}
                    onChange={() => formik.setFieldValue('localOption', 'purchaser')}
                  />
                  <label className="ml-2">Local Purchaser</label>
                </div>
                <div className="flex items-center mt-1">
                  <input
                    type="radio"
                    name="localOption"
                    value="supplier"
                    checked={formik.values.localOption === 'supplier'}
                    onChange={() => formik.setFieldValue('localOption', 'supplier')}
                  />
                  <label className="ml-2">Supplier</label>
                </div>
              </div>
            )
            } */}
            {data?.rfqType === 'international' && (
              <FormikSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
              disabled={true}

                Options={vendors}
                formik={formik}
                name="vendor"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
            )}
            {data?.purchaser && (
              <FormikSelectTwo
                type="text"
                label="Local Purchaser"
                placeholder="Choose"
                Options={Purchaser}
              disabled={true}

                formik={formik}
                name="purchaser"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )}
            {data?.rfqType === 'local' && (
              <FormikSelectTwo
                type="text"
                label="Supplier"
                placeholder="Choose"
              disabled={true}

                Options={vendors}
                formik={formik}
                name="vendor"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )}
          
         {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
         <FormikSelectTwo
            type="text"
            label="Status"
            placeholder="Status"
            Options={Status}
           formik={formik}
           name="status"
           width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuTextArea
           label="Comments"
           type="text"
           formik={formik}
           placeholder="Comments"
           name="comments"
           width="w-full md:w-1/2 lg:w-1/3"
         />
           <div className='w-full'>
            <label className="text-xs 2xl:text-sm px-4">RFQ Attachment</label>
          
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          x
                        </div>
                        <div
                          className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                          <img src={imgs?.file} alt={imgs?.file} />
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
</div>
          <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
          {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAdd}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
            <div className="w-full bg-white overflow-y-scroll mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.No </p>

                <p className="w-[60%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[20%] text-center text-white text-xs font-medium">Quantity</p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {dataa?.productList?.length>0?
                            <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {dataa?.productList?.map((List, index) => (
                <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                
                <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>  <p className="w-[60%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                  <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.product_unit}</p>
                  <p className="w-[20%] text-center text-[#606279] text-xs font-light">{List?.quantity}</p>
                  <div className='w-[5%] text-center'> 
                 <button
                    onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-red-500 cursor-pointer  ml-4 border-none"
                  >
                    <DeleteIcon
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
              ))}
                          </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>

               
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel() }} />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }


export default EditRQF