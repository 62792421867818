import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { NuDate } from '../../../../Components/Utils/Input';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import * as yup from 'yup';

function ViewRevision() {
  const { viewrevisionId } = useParams();
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'status_RFQ')
  const { data: user } = UseDropDown('/user/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
 
  const [filterProps, setFilterProps] = useState({
  
    status:"",
    rfqAssignee:"",
    salesPerson:"",
    deadLineFrom:"",
    deadLineTo:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    prId:viewrevisionId,
    search:"",
    status:"",
    rfqAssignee:"",
    salesPerson:"",
    deadLineFrom:"",
    deadLineTo:""
  });

  const clearFilter = ()=> {
    setParams({...params,status:"", rfqAssignee:"", salesPerson:"",deadLineFrom:"", deadLineTo:""})
    formik.values.status=""
    formik.values.rfqAssignee=""
    formik.values.salesPerson=""
    formik.values.deadLineFrom=""
    formik.values.deadLineTo=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,status:value.status, rfqAssignee:value.rfqAssignee, salesPerson:value.salesPerson, deadLineFrom:value.deadLineFrom, deadLineTo:value.deadLineTo})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        status:"",
        rfqAssignee:"",
        salesPerson:"",
        deadLineFrom:"",
        deadLineTo:""
      });
    }
  })
  const { data, totalCount, getData } = UseGetDataSearch( '/PR/revisions',params);


  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "id",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Purchase Request",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/purchase-requests/viewidrevision/',
      field: "ref",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "RFQ Assignee",
      width: "10%",
      field: "rfqAssigneeName",
      textAlign: "left",
      rowAlign: "left",
    },
   
    {
      title: "DeadLine",
      width: "10%",
      field: "deadLine",
      type:"date",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Cost Code",
    //   width: "10%",
    //   field: "costCode",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "left",
      rowAlign: "left",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/PR/revision/`,
        moduleName:"Purchase Requests",
        from:`/procurement/purchase-requests`,
        editLink:"/procurement/purchase-requests/viewcreate/",
        viewLink:"/procurement/purchase-requests/viewidrevision/"

      }
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "id",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Purchase Request",
      width: "10%",
      isLink: true,
      id: "id",
      link: '/procurement/purchase-requests/viewidrevision/',
      field: "ref",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Customer Name",
    //   width: "10%",
    //   field: "customerName",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
  
    {
      title: "DeadLine",
      width: "10%",
      field: "deadLine",
      type:"date",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Notes From Customer",
    //   width: "5%",
    //   field: "notesFromCustomer",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    // {
    //   title: "Cost Code",
    //   width: "10%",
    //   field: "costCode",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Revision Count",
      width: "10%",
      field: "revisionCount",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "RFQ Assignee",
      width: "10%",
      field: "rfqAssigneeName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Sales Person",
      width: "5%",
      field: "salesPerson",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Is Active",
      width: "10%",
      field: "isActive",
      isCondition: true,
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Priority",
      width: "10%",
      field: "priority",
      isCondition: true,
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Prepared By",
    //   width: "5%",
    //   field: "preparedByName",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Reviewed By",
      width: "5%",
      field: "reviewedByName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Approved By",
      width: "5%",
      field: "approvedByName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Status",
      width: "5%",
      field: "status",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Verified By",
    //   width: "5%",
    //   field: "verifiedByName",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    // {
    //   title: "Comments",
    //   width: "5%",
    //   field: "comments",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "left",
      rowAlign: "left",
      contents:{
        // fetcher:getData,
        id:"id",
        removeValue:"ref",
        url:`/PR/revision/`,
        moduleName:"Purchase Requests",
        from:`/procurement/purchase-requests`,
        editLink:"/procurement/purchase-requests/viewcreate/",
        viewLink:"/procurement/purchase-requests/viewidrevision/"

      }
    },
  ];
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

   const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            { name: "Purchase Request" },

            { name: "Revision" },
          ]} />
        <div className=' flex gap-2'>
          <SearchBar handleChange={(e) => setParams({...params,search:e.target.value})} />
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
          <BackButton/>
          {/* <CreateButton label='Import' /> */}
          {/* <Link to="/procurement/purchase-requests/create"><CreateButton label='Revision' /></Link> */}
        </div>
      </div>

      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Revision List'
        storageName="RevisionTable"
        />
         {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Revision</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Choose"
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full"
              />
           
                    <p className="py-2 px-6 text-m">Dead Line</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label=" From"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLineFrom"
              width="w-full"
            />
              <NuDate
              label=" To"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLineTo"
              width="w-full"
            />
            </div>
              <FormikSelectTwo
              type="text"
              label="RFQ Assignee"
              placeholder="RFQ Assignee"
              Options={user}
              formik={formik}
              name="rfqAssignee"
              width="w-full"
            />
            
             <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
              width="w-full"
            />
                     
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton
                      handleClick={closeFilterDialog} />
                     <SubmitButton
                      name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
  )
}


export default ViewRevision