// import React from 'react'

// const ViewPdf = ({ data }) => {

//   return (
//     <div className="py-2 text-sm md:text-sm lg:text-xs xl:text-sm font-normal bg-white font-helvetica w-[50%]  mx-auto  border rounded-lg ">
//      <div className='h-[calc(100vh-80px)] overflow-y-scroll p-2'>
//        <div className="flex gap-3 items-center">
//        <h3 className="text-[14px] font-normal">Invoice Incoming</h3>
//       <p className="text-sm font-light"> : {data.ref}</p>
//     </div>

//       <div className="grid  items-center mt-2 mb-2">
//         {[
//           { label: 'Supplier ', value: data.supplierName },
//           { label: 'Invoice Date ', value: data.date },
//           { label: 'Purchase Order', value: data.poref || 'N/A'},
//           { label: 'Amount ', value: data.Amount || 'N/A'},
//           { label: 'Tax', value: data.tax || 'N/A'},
//           { label: 'Total Amount ', value: data.totalAmount || 'N/A'},
//           { label: 'Payment DueDate', value: data.paymentDueDate || 'N/A'},
//           { label: 'Payment ReleasedOn   ', value: data.paymentReleasedOn || 'N/A'},
//           { label: 'Mode Of Payment ', value: data.modeOfPayment || 'N/A'},
//           { label: 'Division ', value: data.division || 'N/A'},
//           { label: 'Comments ', value: data.comments || 'N/A'},
//               ].map((item, index) => (
//           <div key={index} className="flex text-[12px] ">
//             <p className="w-[40%] py-1 font-medium">{item.label}</p>
//             <p className="w-[40%] py-1">{item.value}</p>
//           </div>
//         ))}
//       </div>

//       <div className=" bg-gray-300">
//         <div className="w-full flex justify-between text-left text-[12px]">
//           {[
//                             'S.N','Item Code','Product Name', 'Product Unit', 'Ordered Quantity'

//           ].map((header, index) => (
//             <p key={index} className="bg-blue-500 text-white py-1 p-2 font-bold "
//             style={{ width: `${[5, 20, 40, 15, 20][index]}%` }}>
//               {header}
//             </p>
//           ))}
//         </div>
//         {data?.productList?.map((product, index) => (
//           <div key={index} className=" w-full flex justify-between py-1 p-2 text-[12px] text-left">
//             <p className="w-[5%] ">{index + 1}</p>
//             <p className="w-[20%] ">{product.item_code}</p>
//             <p className="w-[40%] ">{product.product_name}</p>
//             <p className="w-[15%] ">{product.product_unit}</p>
//             <p className="w-[15%] ">{product.orderedQty}</p>
//           </div>
//         ))}
//       </div>

//       {/* <div className="flex-row justify-between p-2 my-4 border-2 border-gray-300">
//         <p>Notes From Customer:{data.notesFromCustomer}</p>
//       </div> */}
//     </div>
//     </div>
//   );
// }

// export default ViewPdf

import React from "react";
import CompanyLogo from "../../../assets/images/companyLogo.png";
import CompanyName from "../../../assets/images/companyName.png";
import Seal from "../../../assets/images/seal.png";
import NuDate from "../../../Components/Utils/NuDate";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
const InVoInPdfOthers = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);

  const columns = [
    { header: "S.N", widthClass: "w-[4%]" },
    { header: "Description", widthClass: "w-[40%]" },
    { header: "QTY", widthClass: "w-[8%]" },
    { header: "Unit", widthClass: "w-[8%]" },
    { header: "Rate", widthClass: "w-[10%]" },
    { header: "EX VAT AMT", widthClass: "w-[12%]" },
    { header: "TAX%", widthClass: "w-[6%]" },
    { header: "VAT Amount", widthClass: "w-[12%]" },
  ];
  return (
    <div
      ref={containerRef}
      className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border"
    >
      <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className="relative w-full h-full ">
            <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
              <button
                onClick={() => zoomIn()}
                className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold"
              >
                +
              </button>
              <button
                onClick={() => zoomOut()}
                className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold"
              >
                -
              </button>
              <button onClick={() => resetTransform()} className="text-left">
                Reset
              </button>
            </div>
            <div className="flex justify-center items-center w-full h-full">
              <TransformComponent
                wrapperStyle={{ width: "100%", height: "100%" }}
              >
                <div className="min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black">
                  <div className="border border-black">
                    <div className="flex justify-between items-center border-b border-black">
                      <div className="w-full">
                        <div className="w-full flex justify-between items-center p-4">
                          <img
                            src={CompanyName}
                            alt="Loading"
                            className="h-28"
                          />
                          <img
                            src={CompanyLogo}
                            alt="Loading"
                            className="h-28 w-28"
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" grid grid-cols-3 border-b border-black">
                      <div className=" w-full p-3 flex items-center border-r border-black">
                        VAT NO. :
                      </div>
                      <div className=" w-full p-3 text-center text-lg font-semibold border-r border-black">
                       INVOICE
                      </div>
                      <div className=" w-full">
                        <div className=" w-full h-1/2 border-b border-black px-2 pt-1 flex">
                          <p className=" w-8">Date </p>
                          <p className="text-xs">
                            :{" "}
                            {data?.date ? (
                              <NuDate value={data?.date} format="DD-MMM-YYYY" />
                            ) : (
                              "N/A"
                            )}
                          </p>
                        </div>
                        <div className=" w-full h-1/2 px-2 pt-1 flex">
                          <p className=" w-8">InNo# </p>
                          <p className="text-xs"> : {data.ref || "N/A"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between px-3  py-2 border-b border-black">
                      <div className=" w-[60%]">
                        <h4 className="font-semibold text-sm">Customer</h4>
                      </div>
                      <div className=" w-[40%]">
                        <h4 className="font-semibold text-sm pl-3">
                          Purchase Order
                        </h4>
                      </div>
                    </div>

                    <div className="flex justify-between border-b border-black">
                      <div className=" w-[60%] border-r border-black px-3 py-2">
                        <p>{data.supplierName || "N/A"}</p>
                      </div>
                      <div className=" w-[40%] px-3 py-2">
                        <table>
                          <tbody>
                            <tr className=" w-full">
                              <td className=" w-[30%] py-1">PO </td>
                              <td className=" w-[70%] py-1">
                                : {data.poref || "N/A"}
                              </td>
                            </tr>
                            <tr className=" w-full">
                              <td className=" w-[30%] py-1">DN </td>
                              <td className=" w-[70%] py-1">
                                : {data.dnref || "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-200 text-[12px]">
                          {columns.map((header, index) => (
                            <th
                              key={index}
                              className={`border-b border-r last:border-r-0 border-black p-2 text-left font-bold text-wrap ${header.widthClass}`}
                            >
                              {header.header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.productList?.map((product, index) => (
                          <tr
                            key={index}
                            className="border-y border-black text-[12px]"
                          >
                            <td className="p-2">{index + 1}</td>
                            <td className="border border-black p-2 w-[4%]">
                              {product.item_code || "N/A"}
                            </td>
                            <td className="border border-black p-2 w-[40%] text-wrap">
                              {product.product_name || "N/A"}
                            </td>
                            <td className="border border-black p-2 w-[8%]">
                              {product.product_unit || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2 w-[8%]">
                              {product.orderedQty || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2 w-[10%]">
                              {product.orderedQty || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2 w-[12%]">
                              {product.orderedQty || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2 w-[6%]">
                              {product.orderedQty || 0}
                            </td>
                            <td className="border border-r-0 border-black p-2 w-[12%]">
                              {product.orderedQty || 0}
                            </td>
                            {/* <td className="border border-gray-300 p-2">{product.price || 0}</td>
              <td className="border border-gray-300 p-2">{(product.orderedQty * product.price).toFixed(2)}</td> */}
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className=" py-3 border-r border-black  pr-3 "
                            colSpan={5}
                          >
                            <div className=" w-full flex justify-end">
                              <div>
                                <p className=" text-xs font-medium">TOTAL</p>
                                {/* <p className=' text-xs font-medium'>NET TOTAL</p> */}
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-3 border-r border-black">
                            <div className=" w-full">
                              <div>
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                                {/* <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p> */}
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-3 border-r border-black">
                            <div className=" w-full">
                              <div>
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                                {/* <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p> */}
                              </div>
                            </div>
                          </td>
                          <td className="px-2 py-3">
                            <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className="py-3 border-r border-black  pr-3 align-top"
                            colSpan={3}
                            rowSpan={4}
                          >
                            <div className=" w-full ">
                                <p className=" text-xs font-medium">TOTAL Amount In Words</p>
                                {/* <p className=' text-xs font-medium'>NET TOTAL</p> */}
                            </div>
                          </td>
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          >
                            <div className=" w-full flex justify-start">
                              <div>
                                <p className=" text-xs font-medium">VAT</p>
                                {/* <p className=' text-xs font-medium'>NET TOTAL</p> */}
                              </div>
                            </div>
                          </td>
                          <td className="py-3 border-r border-black  pr-3 ">
                            <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          >
                            <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          ></td>
                          <td className="py-3 border-r border-black  pr-3 ">
                          <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          ></td>
                        </tr>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          ></td>
                          <td className="py-3 border-r border-black  pr-3 ">
                          <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          ></td>
                        </tr>
                        <tr className="border-y border-black text-[12px]">
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          >
                            <div className=" w-full flex justify-start">
                              <div>
                                <p className=" text-xs font-medium">Grand Total</p>
                                {/* <p className=' text-xs font-medium'>NET TOTAL</p> */}
                              </div>
                            </div>
                          </td>
                          <td className="py-3 border-r border-black  pr-3 ">
                          <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                          <td
                            className="py-3 border-r border-black  pr-3 "
                            colSpan={2}
                          >
                            <div className=" w-full">
                                <p className=" text-xs font-medium">
                                  {data?.totalPrice || "N/A"}
                                </p>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    {/* <div className="flex justify-between items-center p-2 border-t border-gray-300">
        <p className="font-medium text-[12px]">TOTAL</p>
        <p className="font-normal text-[12px]">{data.totalPrice.toFixed(2)}</p>
      </div>
      <div className="flex justify-between items-center p-2 border-t border-gray-300 text-[12px]">
        <p className="font-medium">NET TOTAL</p>
        <p className="font-normal">{data.totalPrice.toFixed(2)}</p>
      </div> */}

<div className=" text-[12px] flex justify-start w-full border-b border-black">
        <div className="text-left w-[35%] border-r border-black p-4">
        {/* <h4 className="font-bold mb-4"> for YANAMA TRADING COMPANY</h4> */}
        <p className="text-xs ">Customer Signature</p>
        <img src={Seal} alt="Company Seat" className="h-24 mb-2 ml-2" />
        </div>
        <div className="text-center w-[25%] p-4 border-r border-black">
            <p>Qr Scan</p>
        </div>
        <div className="text-right w-[40%] p-4">
        <h4 className="font-bold mb-4"> for YANAMA TRADING COMPANY</h4>
        <img src={Seal} alt="Company Seat" className="h-24 mb-2 ml-24" />
        <p className="text-xs ">Authorized Signature</p>
        </div>
      
      </div>

                    <div className="mt-4 pb-4 px-3 flex justify-between items-center text-[12px]">
                      <div>
                        <p className="text-xs ">Note:</p>
                        {/* <ul className="text-xs  list-disc list-inside">
            <li>MATERIAL SHOULD BE SAME AS IN THE DATA SHEET PROVIDED BY THE MANUFACTURER.</li>
            <li>MATERIAL TEST CERTIFICATE (MTC) SHOULD BE PROVIDED WITH MATERIAL.</li>
          </ul> */}
                        <p>{data.notesFromCustomer || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TransformComponent>
            </div>
          </div>
          // </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default InVoInPdfOthers;
