import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import NuDate from "../../../../Components/Utils/NuDate";
import NuAmount from "../../../../Components/Utils/NuAmount";

let DateNow = NuDate({ value: new Date(), format: "DD-MMM-YYYY" });
let TimeNow = NuDate({ value: new Date(), format: "hh:mm:ss a" });

function Date(data) {
  if (data) {
    return NuDate({ value: data, format: "DD-MMM-YYYY" });
  } else {
    return " ";
  }
}

const DownloadPDF = ({
  company = "",
  status,
  standardname,
  data = [],
  Name = "",
  review = "",
  startDate = "",
  endDate = "",
  header = [],
}) => {

  console.log("hesder",header);
  function Header({ standardname, companyName, companyImg }) {
    return (
      <View
        style={{
          width: "100%",
          height: "12%",
          flexDirection: "column",
        }}
        fixed
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "50%",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 13,
                marginBottom: 5,
                textAlign: "center",
                fontWeight: "semibold",
              }}
            >{`${companyName || ""}`}</Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "10px",
                color: "black",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >{`${standardname || ""}`}</Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "10px",
                color: "black",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >{`${Name}`}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          ></View>
        </View>
        <View
          style={{
            width: "100%",
            height: "50%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F1E0FF",
          }}
        >
          {header.length > 0 &&
            header.map((item, idx) => {
              return (
                <View
                  key={idx}
                  style={{
                    width: item?.width || "10%",
                    textAlign:item?.rowAlign|| "center",
                    paddingVertical: "4px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.title}
                  </Text>
                </View>
              );
            })}
        </View>
      </View>
    );
  }

  function Footer() {
    return (
      <View
        style={{
          width: "100%",
          height: "7%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          bottom: "15px",
          left: "30px",
        }}
        fixed
      >
        <View style={{ width: "33.3%" }}></View>
        <View
          style={{
            width: "33.3%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "3px",
          }}
        >
          <Text
            style={{
              fontSize: 9,
              textAlign: "center",
              color: "grey",
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
        <View
          style={{
            width: "33.3%",
            textAlign: "right",
            gap: "3px",
          }}
        >
          <Text
            style={{
              fontSize: "7px",
              color: "grey",
              fontWeight: "bold",
            }}
          >
            {` ${DateNow} / ${TimeNow}`}
          </Text>
        </View>
      </View>
    );
  }



  function getField(row,field,typeValue){
    let splitValue = field.split(".");
    let value = ""
    if(splitValue.length >= 2){
      if(splitValue.length == 3){
        value = row[splitValue[0]][splitValue[1]][splitValue[2]]
      }else {
        value = row[splitValue[0]][splitValue[1]]
      }
    }else{
        value = row[splitValue[0]]
    }

    if(typeValue === "date-time"){
      return  (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY hh:mm a" />  )||""
    }else if(typeValue === "date"){
      console.log("date format");
      return (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY" />  )||""
    }else if(typeValue === "time"){
      return (value !=="" && value !=="None" &&<NuDate value={value} format="hh:mm a" />  )||""
    }else if(typeValue === "amount"){
      return <NuAmount value={value}/> ||""
    }else{
      return value
    }
  }

  function MainContent() {
    return (
      <View
        style={{
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 0,
        }}
      >
        {data &&
          data.map((item, idx) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                borderStyle: "solid",
                borderWidth: 0.2,
                borderRightWidth: 0,
                borderLeftWidth: 0,
                borderBottomWidth: 0,
              }}
              key={idx}
            >
              {header.map((items, colIndex) => {
                console.log(`width ${colIndex+1}`,items?.width);
                return (
                  <View
                    style={{
                      width: items?.width || "10%",
                      textAlign: items?.textAlign|| "center",
                      paddingVertical: "8px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "8px",
                        color: "black",
                      }}
                    >
                      {
                         items.isCondition ? (item[items.field] ? 'Yes' : 'No') 
                         : getField(item,items?.field,items?.type)
                          }
                    </Text>
                  </View>
                );
              })}
            </View>
          ))}
      </View>
    );
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "15px 30px 60px 30px ",
        }}
        orientation="landscape"
      >
        <Header
          standardname={standardname}
          status={status}
          companyName={company?.companyName}
          companyImg={company?.companyLogo}
        />
        <MainContent />
        <Footer startDate={startDate} endDate={endDate} review={review} />
      </Page>
      {/* ))
          } */}
    </Document>
  );
};

export default DownloadPDF;
