import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ShippingMethod = () => {
  return (
    <SettingsMetaData 
     propertyName={"shippingMethod_SalesOrder"} viewPropertyName={"Shipping Method"} />

  )
}

export default ShippingMethod