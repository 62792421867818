import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import {
  NuDataTime,
  NuInput,
  NuTextArea,
  NuDate,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

function CreateVehicle() {
  const currentDate = new Date().toISOString().split('T')[0];
  const {value:VehicleName ,getData:refVehicleName} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleName')
  const {value:VehicleType ,getData:refVehicleType} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleType')
  const {value:VehicleModel ,getData:refVehicleModel} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleModel')
  const {value:VehicleBrand,getData:refVehicleBrand} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleBrand')
  const {value:VehicleYearOfManufacture,getData:refVehicleYearOfManufacture} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleYearOfManufacture')
  const {value:VehicleCondition,getData:refVehicleCondition} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleCondition')
  const {value:FuelType,getData:refFuelType} = useFetchMataData(`/dropdown/dropdown-details/`,'Vehicle_FuelType')

  // const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const { data: users } = UseDropDown("/user/drop-down");

  const [createNew, setCreateNew] = useState({
    companyId: 1,
    vehicleType: "",
    name: "",
    brand: "",
    model: "",
    yearOfMan: "",
    fuelType: "",
    purchasedOn: currentDate || "",
    condition: "",
    plateNo: "",
    chasisNo: "",
    assignedTo: "",
    comments: "",
    insDate:"",
    insExpDate:"",
    insCompany:"",
    fahasDate:"",
    fahasExpDate:"",
    istimaraExpDate:"",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      addFormData("/vehicles/new-vehicle", value, resetForm);
    },
  });
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
     <Breadcrumb
          items={[
            {
              name: "Vehicle",
              link: "/maintenance/vehicle",
            },
            {
              name: "Create Vehicle",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
          <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Vehicle Detail</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

          <DropDownAddMetaData
                label="Vehicle Type"
                placeholder="Choose"
                Options={VehicleType}
                formik={formik}
                name="vehicleType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleType}
                displayProperty={"Vehicle Type"}
                propertyName={"VehicleType"}
              />
          
             <DropDownAddMetaData
                label="Vehicle Name"
                placeholder="Choose"
                Options={VehicleName}
                formik={formik}
                name="name"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleName}
                displayProperty={"Name"}
                propertyName={"VehicleName"}
              />
            
           <DropDownAddMetaData
                label="Brand"
                placeholder="Choose"
                Options={VehicleBrand}
                formik={formik}
                name="brand"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleBrand}
                displayProperty={"Brand"}
                propertyName={"VehicleBrand"}
              />
             
             <DropDownAddMetaData
                label="Model"
                placeholder="Choose"
                Options={VehicleModel}
                formik={formik}
                name="model"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleModel}
                displayProperty={"Model"}
                propertyName={"VehicleModel"}
              />
           
           <DropDownAddMetaData
                label="Year Of Manufacturing"
                placeholder="Choose"
                Options={VehicleYearOfManufacture}
                formik={formik}
                name="yearOfMan"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleYearOfManufacture}
                displayProperty={"Year Of Manufacturing"}
                propertyName={"VehicleYearOfManufacture"}
              />
            <DropDownAddMetaData
                label=" Fuel Type"
                placeholder="Choose"
                Options={FuelType}
                formik={formik}
                name="fuelType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refFuelType}
                displayProperty={" Fuel Type"}
                propertyName={"Vehicle_FuelType"}
              />
           
           
            <NuDate
              label="Purchased On"
              placeholder="Purchased On"
              formik={formik}
              name="purchasedOn"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Condition"
                placeholder="Choose"
                Options={VehicleCondition}
                formik={formik}
                name="condition"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refVehicleCondition}
                displayProperty={"Condition"}
                propertyName={"VehicleCondition"}
              />
             
           
            <NuInput
              type="text"
              label="Plate No"
              placeholder="Plate No"
              formik={formik}
              name="plateNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Chasis No"
              placeholder="Chasis No"
              formik={formik}
              name="chasisNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <FormikSelectTwo
              type="text"
              label="Assigned To"
              placeholder="Assigned To"
              Options={users}
              formik={formik}
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
 <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Insurance</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
    
      <NuDate
              label="Insurance Date"
              placeholder="Insurance Date"
              formik={formik}
              name="insDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Insurance Expiry Date"
              placeholder="insurance Expiry Date"
              formik={formik}
              name="insExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="text"
              label="Insurance Company"
              placeholder="Insurance Company"
              formik={formik}
              name="insCompany"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Fahas Detail</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
    
 
             <NuDate
              label="Fahas Date"
              placeholder="Fahas Date"
              formik={formik}
              name="fahasDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Fahas Expiry Date"
              placeholder="Fahas Expiry Date"
              formik={formik}
              name="fahasExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="Istimara Expiry Date"
              placeholder="Istimara Expiry Date"
              formik={formik}
              name="istimaraExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />




            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1);}}/>
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateVehicle;
