import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Customer from "./Customer";
import CustomerQuotes from "./CustomerQuotes";
import Invoices from "./Invoices";
import InvoicesIncoming from "./InvoicesIncoming/index.jsx";
import SalesOrder from "./SalesOrder/index.jsx";
import CustomerOrder from "./CustomerOrder/index"


  
  
  const MainSalesSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Sales"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><Customer/></div>
              {/* <div className="p-3"><CustomerOrder/></div> */}
              <div className="p-3"><CustomerQuotes/></div>
              <div className="p-3"><Invoices/></div>
              <div className="p-3"><InvoicesIncoming/></div>
              <div className="p-3"><SalesOrder/></div>
        </div>
        </div>
    )
  }

  export default MainSalesSettings;