// import React from 'react'
// import { TextMediumBase, TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText'
// import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';

// export default function TopSuppliersDashboard() {
//   const { data, loading } = UseGetDashBoardData(`/dashboard/topSuppliers`);
//   return (
//     <div className="col-span-2 row-span-2 col-start-3 row-start-6 bg-white max-h-[35vh] p-2 rounded-lg drop-shadow-lg">
//        <div className=" w-full bg-emerald-500 px-1 py-[2px] rounded-md">
//     <TextMediumSM content={"Top Suppliers"} color='text-white' />
//     </div>
//     {loading == true ? (
//         <>
//           <div className=" p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
//             <div className="animate-pulse flex space-x-4 w-full">
//               <div className="flex-1 space-y-3 py-1">
//                 <div className="h-3 bg-slate-200 rounded"></div>
//                 <div className="space-y-3">
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                   </div>
//                   <div className="h-3 bg-slate-200 rounded"></div>
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className=" w-full h-[90%]">
//             <table className=' w-full h-full'>
//               <thead>
//                 <tr> 
//                   {/* <th><TextMediumSM content={"Supplier Id"} /></th> */}
//                   <th className=' text-left'><TextMediumSM content={"Supplier Name"} /></th>
//                   <th className=' text-left'><TextMediumSM content={"Total Price"} /></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {
//                   data&&data.map((data, index) => {
//                     return (
//                       <tr key={index}> 
//                       {/* <td> <TextMediumSM align={"center"} content={data?.vendorId||""}/></td> */}
//                       <td> <TextMediumXS  content={data?.vendorName}/> </td>
//                       <td> <TextMediumXS  content={data?.totalPrice}/> </td>
//                       </tr>
//                     )
//                   })
//                 }
//               </tbody>
//             </table>
//           </div>
//             </>
//       )}
//     </div>
//   );
// }


import React from 'react';
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

export default function TopValueDashboard() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/topCustomer`);
  
  return (
    <div className="col-span-2  bg-white h-[35vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="w-full  px-1 py-2 rounded-md ">
        <TextMediumSM content="Top Customers By Value" color="text-[#4F4768]" />
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      </div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full mb-1">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[96%] overflow-y-scroll px-6">
          <table className="w-full ">
          <thead className="sticky top-0 bg-white shadow-sm">

              <tr>
                <th className="text-left px-2 py-1"><TextMediumSM content="Customer" /></th>
                <th className="text-left px-2 py-1"><TextMediumSM content="Amount" /></th>
                
              </tr>
            </thead>
            <tbody>
                
                {data && data.map((item, index) => (
                <tr className="odd:bg-white even:bg-slate-50 hover:shadow-md hover:scale-y-105 " key={index}>
                  <td className="px-2 py-1"><TextMediumXS content={item?.customerName || "N/A"} /></td>
                  <td className="px-2 py-1"><TextMediumXS content={item?.totalSpent || "N/A"} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
       
      )}
    </div>
  );
}

