import React from 'react'

const CloseIcon=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 11 11" fill="none">
        <path d="M9.23051 10.6306L5.63892 7.039L2.04732 10.6306C1.85681 10.8211 1.59842 10.9281 1.329 10.9281C1.05958 10.9281 0.801194 10.8211 0.610684 10.6306C0.420174 10.4401 0.313146 10.1817 0.313146 9.91228C0.313146 9.64286 0.420174 9.38447 0.610683 9.19396L4.20228 5.60236L0.610683 2.01077C0.420173 1.82026 0.313146 1.56187 0.313146 1.29245C0.313146 1.02303 0.420173 0.764642 0.610683 0.574131C0.801193 0.383622 1.05958 0.276594 1.329 0.276594C1.59842 0.276594 1.85681 0.383621 2.04732 0.574132L5.63892 4.16573L9.23051 0.574132C9.42102 0.383621 9.67941 0.276594 9.94883 0.276594C10.2183 0.276594 10.4766 0.383622 10.6671 0.574131C10.8577 0.764642 10.9647 1.02303 10.9647 1.29245C10.9647 1.56187 10.8577 1.82026 10.6671 2.01077L7.07555 5.60236L10.6671 9.19396C10.8577 9.38447 10.9647 9.64286 10.9647 9.91228C10.9647 10.1817 10.8577 10.4401 10.6671 10.6306C10.4766 10.8211 10.2183 10.9281 9.94883 10.9281C9.67941 10.9281 9.42102 10.8211 9.23051 10.6306Z" fill="black"/>
      </svg>
    )
}

export default CloseIcon