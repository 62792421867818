import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const YearOfMan = () => {
  return (
    <SettingsMetaData propertyName={"VehicleYearOfManufacture"} viewPropertyName={"Vehicle Year Of Manufacture"}/>
  );
};

export default YearOfMan;
