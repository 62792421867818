import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const PaymentTerms = () => {
  return (
    <SettingsMetaData propertyName={"PaymentTerms_Supplierqoutes"} viewPropertyName={"Payment Terms"} />

  )
}

export default PaymentTerms