import React from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../Dashboard';
import { TextBoldBase } from '../../../../Components/Text/BoldText';
import { NormalHeading, SmallHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

function ViewCatalogues() {
  const { catalogueViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
return (
  <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
         <Breadcrumb 
       items={[
        // { name: "Product" },

        { name: "Catalogue",
          link:"/products/catalogues"},
          
        { name: "View"},
      
  ]} />
      <div className=" flex gap-2">
        <Link to={'/products/catalogues'}><BackButton link={true}/></Link>
        <Link to={`/products/catalogues/edit/${catalogueViewId}`} 
        state={{data: data, from:`/products/catalogues/view/${catalogueViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
    </div> 
    <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-hidden p-3'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Cataglogues</h2> */}
    <NormalHeading title={"Catalogues"} />
    <div className='w-full py-5 flex gap-3 '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col gap-2 '>
                <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                  <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Category</p>
                  <p className='w-1/4 py-2 pl-5 text-sm  text-[#606279]'>{data?.category || '-'}</p>
                </div>
                <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                  <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Sub-Category</p>
                  <p className='w-1/4 py-2 pl-5 text-sm  text-[#606279]'>{data?.subCategory || '-'}</p>
                </div>
              </div>
    </div>
    </div>
  </div>
)
};

export default ViewCatalogues