import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, {
  NuInput,
  NuDataTime,
  NuSelect,
  NuTextArea,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";

const CreateAppoinment = () => {
  const { value: Industry, getData: refIndustry } = useFetchMataData(`/dropdown/dropdown-details/`, 'Industry_Appoinments');
  const { value: VisitPurpose, getData: refVisitPurpose } = useFetchMataData(`/dropdown/dropdown-details/`, 'VisitPurpose_Appoinments');

  const { data: customers } = UseDropDown('/customers/drop-down');
  const { data: lead } = UseDropDown('/leads/drop-down');

  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [email, setEmail] = useState("");

  const currentDateTime = new Date().toISOString().slice(0, 16);

  const formik = useFormik({
    initialValues: {
      industry: "",
      visitPurpose: "",
      title: "",
      reminder: currentDateTime || "",
      dateTime: currentDateTime || "",
      comments: "",
      holderType: "", // lead and customer
      holderId: "",
      email: ""
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      title: yup.string().required('Title is required!'),
      industry: yup.string().required('Industry is required!'),
      visitPurpose: yup.string().required('Visit Purpose is required!'),
      holderType: yup.string().required('Holder Type is required!'),
      holderId: yup.string().required('Holder Id is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      addFormData("/appointments/new-appointment", value, resetForm);
    },
  });

  const getData = async (holderId, holderType) => {
    try {
      const endpoint = holderType === "Customer" ? `/customers/${holderId}` : `/leads/${holderId}`;
      const response = await axiosInstance.get(endpoint);
      console.log('Fetched Data:', response.data?.data);
      if (response.data?.data) {
        const fetchedEmail = response.data.data.email || ""; // Default to empty string if no email
        setEmail(fetchedEmail);
        formik.setFieldValue('email', fetchedEmail); // Update formik value
      } else {
        setEmail("");
        formik.setFieldValue('email', ""); // Set to empty if no data
      }
    } catch (err) {
      console.error("Error fetching data", err);
      setEmail("");
      formik.setFieldValue('email', ""); // Set to empty in case of error
    }
  };

  useEffect(() => {
    if (formik.values.holderId && formik.values.holderType) {
      console.log('holderId:', formik.values.holderId, 'holderType:', formik.values.holderType);
      getData(formik.values.holderId, formik.values.holderType);
    } else {
      setEmail("");
      formik.setFieldValue('email', ""); // Clear email when holderType or holderId changes
    }
  }, [formik.values.holderId, formik.values.holderType]);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            {
              name: "Marketing",
              link: "/marketing/appointments",
            },
            {
              name: "Appointment",
              link: "/marketing/appointments",
            },
            {
              name: "Create Appointment",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden  mt-4">
          <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
            <NuInput
              type="text"
              label="Title"
              placeholder="title"
              formik={formik}
              name="title"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <DropDownAddMetaData
              label="Industry"
              placeholder="Choose"
              Options={Industry}
              formik={formik}
              name="industry"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refIndustry}
              displayProperty={"Industry"}
              propertyName={"Industry_Appoinments"}
              isRequired={true}
            />
            <DropDownAddMetaData
              label="Visit Purpose"
              placeholder="Choose"
              Options={VisitPurpose}
              formik={formik}
              name="visitPurpose"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refVisitPurpose}
              displayProperty={"Visit Purpose"}
              propertyName={"VisitPurpose_Appoinments"}
              isRequired={true}
            />
            <NuDataTime
              label="Reminder"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="reminder"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDataTime
              label="Appoinment Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dateTime"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Holder Type"
              placeholder="Choose"
              Options={[
                { value: "Lead", label: "Lead" },
                { value: "Customer", label: "Customer" },
              ]}
              formik={formik}
              name="holderType"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {formik.values.holderType === "Customer" && (
              <FormikSelectTwo
                type="text"
                label="Holder"
                placeholder="Choose"
                Options={customers}
                formik={formik}
                name="holderId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            )}
            {formik.values.holderType === "Lead" && (
              <FormikSelectTwo
                type="text"
                label="Holder"
                placeholder="Choose"
                Options={lead}
                formik={formik}
                name="holderId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            )}
             {formik.values.holderId && (
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              value={formik.values.email} 
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true} 
            />
          )}
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
               <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAppoinment;
