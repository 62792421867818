import React from 'react'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton } from '../../../../Components/Utils/Buttons'

function ViewRole() {
    <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <Breadcrumb
      items={[
        {
          name: "Settings",
        },
        {
          name: "Roles"
        },
        {
            name: "View",
        }
      ]}
    />
    
    <BackButton/>
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto px-4 py-2 rounded-md overflow-y-scroll">

    </div>
    </div>
}

export default ViewRole