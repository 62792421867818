import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const PaymentMethod = () => {
  return (
    <SettingsMetaData propertyName={"paymentMethod_supplierquotes"} viewPropertyName={"Payment Method"} />

  )
}

export default PaymentMethod