import React, { useEffect, useState } from 'react'
import { BackButton, CreateButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link,useLocation ,useParams} from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import DeleteIcon from '../../../../Components/Icons/Delete';
import axiosInstance from '../../../../Services/axiosCommon';
import EditIcon from '../../../../Components/Icons/Edit';
import NuDate from '../../../../Components/Utils/NuDate';
import capitalizeFirstLetter from '../../../../Components/Utils/Funcs';

function ViewVehicle() {
    const { vehicleViewId} = useParams();
  const location = useLocation();
  // const data = location.state.data;

  const handleDelete = (vehicleId) =>{
    axiosInstance.delete(`/vehicles/maintenance/${vehicleId}`)
    getVihicleView()
  }
  const [data, setData] = useState([]);
  async function getVihicleMainView() {
    await axiosInstance
      .get(`/vehicles/${vehicleViewId}`)
      .then(async (res) => {
        // console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [dataa, setDataa] = useState([]);
  async function getVihicleView() {
    await axiosInstance
      .get(`/vehicles/all-maintenance/${vehicleViewId}`)
      .then(async (res) => {
        // console.log("find", res?.data?.data);
        (await res?.data?.data?.data) && setDataa(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(()=>{
    getVihicleMainView();
    getVihicleView();
  },[]);
 
return (
  <div className="w-full h-full py-2 px-4 ">
  <div className="flex justify-between ">

       <Breadcrumb
          items={[
            {
              name: "Vehicle",
              link: "/maintenance/vehicle"

            },
            {
              name: "View Vehicle"
            }
          ]}
        />

        
      <div className=" flex gap-2">
        <Link to={'/maintenance/vehicle'}><BackButton link={true}/></Link>
        <Link to={`/maintenance/vehicle/edit/${vehicleViewId}`} 
        state={{data: data, from:`/maintenance/vehicle/view/${vehicleViewId}`}}
        ><EditButton height='8'/></Link>
      </div>
      </div>

      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4">

    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <div className='flex justify-between items-center'>
    <h2 className='text-[14px] font-bold text-[#111537] p-3 items-center'>Vehicle Detail</h2>
    <div className='mt-3'> <Link to={`/maintenance/vehicle/add/${vehicleViewId}`} 
        state={{data: data, from:`/maintenance/vehicle/view/${vehicleViewId}`}}
        ><CreateButton label='Vehicle Maintenance' /></Link></div>
    
              {/* <div className='mt-3'><Link to={`/maintenance/vehicle/add/${vehicleViewId}`}><CreateButton label='Vehicle Maintenance' /></Link></div> */}
        </div>
   
        <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
                <div className='w-full flex px-2'>
                     <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Assigned To </p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.assignedToName ? capitalizeFirstLetter(data?.assignedToName) : 'N/A'}</p>
              </div>
              {/* <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Vehicle name</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.name}</p>
              </div>  */}
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Brand</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.brand ? capitalizeFirstLetter(data?.brand) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Vehicle Model</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.model ? capitalizeFirstLetter(data?.model) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Year Of Manufacturing</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.yearOfMan}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Vehicle Type</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.vehicleType ? capitalizeFirstLetter(data?.vehicleType) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Chasis No</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.chasisNo}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Condition</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.condition ? capitalizeFirstLetter(data?.condition) : 'N/A'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Fuel Type</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.fuelType ? capitalizeFirstLetter(data?.fuelType) : 'N/A'}</p>
              </div>
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Plate No</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.plateNo}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Purchased On</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.purchasedOn ? (
                  <NuDate value={data?.purchasedOn} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Status</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.status }</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Insurance Date</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.insDate ? (
                  <NuDate value={data?.moreDetails?.insDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Insurance Expiry Date</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.insExpDate ? (
                  <NuDate value={data?.moreDetails?.insExpDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Insurance Company</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.insCompany ? capitalizeFirstLetter(data?.moreDetails?.insCompany) : 'None'}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Fahas Date</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.fahasDate ? (
                  <NuDate value={data?.moreDetails?.fahasDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Fahas Expiry Date</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.fahasExpDate ? (
                  <NuDate value={data?.moreDetails?.fahasExpDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Istimara Expiry Date</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.moreDetails?.istimaraExpDate ? (
                  <NuDate value={data?.moreDetails?.istimaraExpDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
              </div> 
              
              <div className='w-full flex px-2'>
                <p className='w-1/2 py-2  text-xs font-medium text-[#111537]'>Comments</p>
                 <p className='w-1/2 py-2  text-xs  text-[#606279]'>: {data?.comments ? capitalizeFirstLetter(data?.comments) : 'N/A'}</p>
              </div>
             </div>
        <div className="w-full h-0.5 opacity-20 bg-[#C4C2C2] my-6 mx-auto"></div>

             <div className="w-full h-48 bg-white overflow-y-scroll mt-1">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg px-2">
              <p className=" w-[15%] pl-2 text-left  text-white text-xs font-medium"> Maintenance Date</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Maintenance By</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Maintenance Type</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Next Maintenance</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Comments</p>
              {/* <p className=" w-[10%] text-center text-white text-xs font-medium"> Edit</p> */}
              <p className=" w-[5%] text-center text-white text-xs font-medium">  Action </p>
            </div>
            {dataa?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100 px-2">
                  <p className=" w-[15%] pl-2 text-left  text-black text-xs font-light ">
                    {List?.mainDate ?(
                  <NuDate value={List?.mainDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.mainBy? capitalizeFirstLetter(List?.mainBy) : 'None'}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.mainType? capitalizeFirstLetter(List?.mainType) : 'None'}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.nextMain ? (
                  <NuDate value={List?.nextMain} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
                  </p>
                  <p className=" w-[20%] text-center text-black text-xs font-light ">
                    {List?.comments ? capitalizeFirstLetter(List?.comments) : 'None'}
                  </p>
                  <div className=' w-[5%] text-center'>
                  <button
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white  border-none"
                  >
                  <Link to={`/maintenance/vehicle/edit-maintenance/${List?.maintanenceId}`} state={{data:List, from:`/maintenance/vehicle/view/${vehicleViewId}`}}>
                    <EditIcon/>
                  </Link>
                  </button>                
                 
                  <button
                    onClick={() => {
                    //  handledelete(List?.id,List?.paidAmount,List?.balanceAmount,setIsModalOpen(true))
                    handleDelete(List?.maintanenceId)
                  }}
                    type="button"
                    className="text-yellow-500 cursor-pointer bg-white ml-1 border-none"
                  >
                     <DeleteIcon/>
                  </button>
                  </div>
                </div>
              );
            })}
          </div>
                  </div>
          </div>
          
      </div>
  
  )
};
export default ViewVehicle;