import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import { NuInput, NuMobileNumber, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo, {
  SelectCountry,
} from "../../../../Components/Utils/Select";
import { useLocation } from "react-router-dom";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";

function EditUser() {
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    city: data?.city||"",
    streetName: data?.streetName||"",
    district: data?.district||"",
    state: data?.state||"",
    country: data?.country||"",
    postalCode: data?.postalCode||"",
    name: data?.name||"",
    designation: data?.designation||"",
    division: data?.division||"",
    email: data?.email||"",
    password: data?.password||"",
    phone: data?.phone.number,
    countryCode: data?.phone.countryCode,
    status: data?.status||"",
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({}),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("json_data", JSON.stringify(value));
      formData.append("userImage", image.raw);
      editFormData(
        `/user/edit-user-details/${data.userId}`,
        formData,
        resetForm,
        from
      );
    },
  });

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "User",
            },
            {
              name: "Create",
            },
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap py-2 px-4  overflow-y-auto">
            <div className=" w-full mb-3">
              <TextMediumSM content={"Personal Info"} />
            </div>
            <NuInput
              type="text"
              label="Name"
              placeholder="Enter Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuUpload
              label="User Image"
              width="w-full md:w-1/3"
              onChange={handleChangeImg}
            />
            {/* <div className=" w-[97%] my-6 mx-auto h-[1px] bg-slate-200"></div>

            <div className=" w-full mb-3">
              <TextMediumSM content={"Address"} />
            </div>
            <NuInput
              type="text"
              label="Building / Street Name"
              placeholder="Type Here"
              formik={formik}
              name="streetName"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="Enter City"
              formik={formik}
              name="city"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="Enter District"
              formik={formik}
              name="district"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="Enter State"
              formik={formik}
              name="state"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <SelectCountry
              formik={formik}
              name="country"
              width="w-full md:w-1/3 md:-mt-2"
            />
            <NuInput
              type="number"
              label="Zip Code"
              placeholder="Type Here"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/3 md:-mt-2"
            /> */}

            <div className=" w-[97%] my-6 mx-auto h-[1px] bg-slate-200"></div>
            <div className=" w-full mb-3">
              <TextMediumSM content={"Job Details"} />
            </div>
            <FormikSelectTwo
              type="text"
              label="Division"
              placeholder="Choose Division"
              Options={[
                {
                  value: "option",
                  label: "option",
                },
              ]}
              formik={formik}
              name="division"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <FormikSelectTwo
              type="text"
              label="Designation"
              placeholder="Choose Designation"
              Options={[
                {
                  value: "salesman",
                  label: "Sales Man",
                },
                {
                  value: "procurement",
                  label: "Procurement",
                },
                {
                  value: "purchaser",
                  label: "Purchaser",
                },
              ]}
              formik={formik}
              name="designation"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* {formik.values.designation === "salesMan" && (
              <>
                <FormikSelectTwo
                  type="text"
                  label="Monthly"
                  placeholder="Choose"
                  Options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  formik={formik}
                  name="monthlyTar"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
                <FormikSelectTwo
                  type="text"
                  label="Quarterly"
                  placeholder="Choose"
                  Options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  formik={formik}
                  name="quarterlyTar"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
                <FormikSelectTwo
                  type="text"
                  label="HalfYear"
                  placeholder="Choose"
                  Options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  formik={formik}
                  name="halfYearTar"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
                <FormikSelectTwo
                  type="text"
                  label="Yearly"
                  placeholder="Choose"
                  Options={[
                    { value: "option1", label: "Option 1" },
                    { value: "option2", label: "Option 2" },
                  ]}
                  formik={formik}
                  name="yearlyTar"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
              </>
            )} */}
            <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose Status"
              Options={[
                {
                    value: 0,
                    label: "In-Active",
                  },
                {
                  value: 1,
                  label: "Active",
                },
              ]}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditUser;
