import React from 'react'
import CustomerSalesDashboard from './Components/CustomerSales'
import RequestSales from './Components/RequestSales'
import QuoteSubmmitted from './Components/QuoteSubmmitted'
import SalesOrderSales from './Components/SalesOrderSales'
import TopCustomersOrderDashboard from './Components/TopCustomersDashboard'
import TopValueDashboard from './Components/TopSuppliersDashboard'
import MarketingSales from './Components/MarketingSales'
import RequestFromCustomer from './Components/RequestFromCustomer'
import Industry from './Components/Industry'
import Product from './Components/Product'
import Sales from './Components/Sales'
import Approval from './Components/Approval'


function Salesindex() {
  return (
    <div className="w-[100%] h-[calc(100vh-90px)] py-2 ">
<div className="w-full grid grid-cols-4 gap-4 ">
  <CustomerSalesDashboard/>
  
  <RequestSales/>
  <QuoteSubmmitted/>
  <SalesOrderSales/>
{/* <MarketingSales/> */}
</div>
<div className="w-full grid grid-cols-5 gap-4 mt-4">
    <Approval/>   
    </div>
<div className="w-full grid grid-cols-4 gap-4 ">
    <RequestFromCustomer/>
    <Sales/>
      </div>
<div className="w-full grid grid-cols-4 gap-4">
    <TopCustomersOrderDashboard/>
    <TopValueDashboard/>
    </div>
    <div className="w-full grid grid-cols-4 gap-4">
    <Industry/>
    <Product/>
    </div>
  
</div>
  )
}

export default Salesindex