import React, { useEffect } from 'react'
import MainPage from '../Views/Web/Product/MainPage'
import MainCatalogues from '../Views/Web/Product/Catalogues/MainCatalogues'
import CatalogueIndex from '../Views/Web/Product/Catalogues'
import CreateCatalogues from '../Views/Web/Product/Catalogues/CreateCatalogues'
import EditCatalogues from '../Views/Web/Product/Catalogues/EditCatalogues'
import ViewCatalogues from '../Views/Web/Product/Catalogues/ViewCatalogues'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import ViewItems from '../Views/Web/Product/Items/ViewItems'
import EditItems from '../Views/Web/Product/Items/EditItems'
import CreateItems from '../Views/Web/Product/Items/CreateItems'
import MainItems from '../Views/Web/Product/Items/MainItems'
import ItemIndex from '../Views/Web/Product/Items'
import { Wrapper } from '../Components/Utils/roles'
const ProductRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (
        location.pathname === "/procurement" ||
        location.pathname === "/procurement/") {
        navigate("/page-not-found")
      }
    },[location])
    return(
        <Route path="products" element={<MainPage/>}>
          <Route path="catalogues" element={<MainCatalogues/>}>
              <Route index element={<Wrapper accessKey={"catalogue_view"}  Component={CatalogueIndex} />}/>
              <Route path="create" element={<Wrapper accessKey={"catalogue_create"}  Component={CreateCatalogues} />}/>
              <Route path='edit/:catalogueEditId' element={<Wrapper accessKey={"catalogue_edit"}  Component={EditCatalogues} />} />
              <Route path='view/:catalogueViewId' element={<Wrapper accessKey={"catalogue_view"}  Component={ViewCatalogues} />} />
          </Route>
          <Route path="items" element={<MainItems/>}>
              <Route index element={<Wrapper accessKey={"items_view"}  Component={ItemIndex} />}/>
              <Route path="create" element={<Wrapper accessKey={"items_create"}  Component={CreateItems} />}/>
              <Route path='edit/:itemsEditId' element={<Wrapper accessKey={"items_edit"}  Component={EditItems} />} />
              <Route path='view/:itemsViewId' element={<Wrapper accessKey={"items_view"}  Component={ViewItems} />} />
          </Route>
          </Route>
    )
}
export default ProductRoutes