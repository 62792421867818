// import React from 'react'
// import { TextMediumBase, TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText'
// import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
// import NuAmount from '../../../../Components/Utils/NuAmount';

// export default function PurchaseOrdersfromCustomers() {
//   const { data, loading } = UseGetDashBoardData(`/dashboard/customerOrderStats`);
//   return (
//     <div className="col-span-3 row-span-2 col-start-4 row-start-8 max-h-[35vh] p-2 bg-white  rounded-lg drop-shadow-lg">
//          <div className=" w-full bg-emerald-500 px-1 py-[2px] rounded-md">
//         <TextMediumSM content={`Purchase Orders from Customers`} color="text-white" />
//       </div>
//     {/* <TextMediumSM content={""} /> */}
//     {loading == true ? (
//         <>
//           <div className=" p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
//             <div className="animate-pulse flex space-x-4 w-full">
//               <div className="flex-1 space-y-3 py-1">
//                 <div className="h-3 bg-slate-200 rounded"></div>
//                 <div className="space-y-3">
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                   </div>
//                   <div className="h-3 bg-slate-200 rounded"></div>
//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="h-3 bg-slate-200 rounded col-span-1"></div>
//                     <div className="h-3 bg-slate-200 rounded col-span-2"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           <div className=" w-full flex flex-col  h-[90%]">
//             {/* <div className=" text-center w-full mb-1">
//               <TextMediumSM content={"Approval Status Orders"} />
//               {data?.approval_status_orders?.map((item)=>{
//                 return(
//                   <div className='flex justify-evenly'>
//                       <TextMediumBase
//                         align="center"
//                         content={`Category: ${item?.category || 0}`}
//                       />
//                       <TextMediumBase
//                         align="center"
//                         content={`Count: ${item?.count || 0}`}
//                       />
//                   </div>
//                 )
//               })}
//             </div> */}
//             <div className=" w-full mb-1">
//               <TextMediumSM content={"Customer Category Wise"} />
//               {data?.customer_category_wise?.map((item)=>{
//                 return(
//                   <div className='flex gap-3 px-3 py-1'>
//                       <TextMediumXS
//                         // align="center"
//                         content={`Category: ${item?.category || 0}`}
//                       />
//                       <TextMediumXS
//                         // align="center"
//                         content={`Count: ${item?.count || 0}`}
//                       />
//                   </div>
//                 )
//               })}
//             </div>
//             <div className=" text-center flex justify-between items-center w-full">
//               <div className=" text-center w-full">
//                 <TextMediumSM content={"Percentage last Month"} />
//                 <TextMediumXS
//                   align="center"
//                   content={data?.percentage_last_month || 0}
//                 />
//               </div>
//               <div className=" text-center w-full">
//                 <TextMediumSM content={"Total Price"} />
//                 <TextMediumXS
//                   align="center"
//                   content={<NuAmount value={data?.total_price || 0}/>}
//                 />
//               </div>
//               <div className=" text-center w-full">
//                 <TextMediumSM content={"Total Purchase Orders"} />
//                 <TextMediumXS
//                   align="center"
//                   content={data?.total_purchase_orders || 0}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       )}
// </div>
//   )
// }


import React from 'react';
import { TextMediumBase, TextMediumSM, TextMediumXS } from '../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import NuAmount from '../../../../Components/Utils/NuAmount';

export default function PurchaseOrdersfromCustomers() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/customerOrderStats`);
  console.log("dttttt", data);
  
  return (
    // <div className=" h-[40vh] w-full bg-white p-2 rounded-lg drop-shadow-lg">
    <div className="  bg-white h-[40vh] p-4 pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
   {/* <div className='h-[40vh] '> */}
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4 ">
        <TextMediumSM content="PO from Customers" color="text-[#4F4768]" />
        <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">



        <p className='text-[#333333] text-[13px] font-semibold '> <NuAmount  value={data?.total_price || 0}/> </p>
        </div>
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
      
      {loading ? (
        <div className="p-4 flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        // <div className='overflow-y-scroll'>
          <div className="  h-[96%] overflow-y-scroll">
            <div className='grid'>
          {/* <div className="grid justify-around w-full "> */}
            {/* <TextMediumSM content="Customer Category Wise" /> */}
            {data?.customer_category_wise?.map((item, index) => (
              <div key={index} className="flex  justify-evenly p-2">
                <div>
                <p className='text-xs 2xl:text-sm'>
  {item.category ? `${item.category.charAt(0).toUpperCase() + item.category.slice(1).toLowerCase()}` : ""}
</p>
          <p className='text-[#048178] text-[10px] 2xl:text-xs text-start'>{item.difference||""}</p>
              </div>
              <div>
              <p className='text-[#333333] text-base  2xl:text-[22px] font-semibold text-center'>{`${item.count || 0}`}</p>
          <p className='text-[#ACACAC] text-[10px] 2xl:text-xs font-light'> <NuAmount currency={item.currency || ""}  value={item.price}/> </p>
          </div>

       
                
              </div>
            ))}
          {/* </div> */}
          {/* <div key={index} style={{ borderRight: "1px solid #f0f0f0" }} className="flex flex-col items-center p-2"> */}
          
          {/* <div className="flex justify-around items-center">
            <div className="text-center">
              <TextMediumSM content="Percentage Last Month" />
              <TextMediumXS content={`${data?.percentage_last_month || 0}%`} />
            </div>
            <div className="text-center">
              <TextMediumSM content="Total Price" />
              <TextMediumXS content={<NuAmount value={data?.total_price || 0} />} />
            </div>
            <div className="text-center">
              <TextMediumSM content="Total Purchase Orders" />
              <TextMediumXS content={data?.total_purchase_orders || 0} />
            </div>
          </div> */}
        </div>
        </div>
        // </div>
      )}
      {/* </div> */}
    </div>
  );
}
