import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/Others/DataTable";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/Utils/SearchBar";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import { GetAccess } from "../../../../Components/Utils/roles";

function VehicleIndex() {
  const {value:VehicleBrand} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleBrand')
  const {value:VehicleType} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleType')
  const {value:VehicleCondition} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleCondition')
  const {value:VehicleStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'VehicleStatus')
  const { data:users} = UseDropDown ("/user/drop-down");
  const {value:FuelType} = useFetchMataData(`/dropdown/dropdown-details/`,'Vehicle_FuelType')

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    status:"",
    assignedTo:"",
    condition:"",
    fuelType:"",
    brand:"",
    vehicleType:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    status:"",
    assignedTo:"",
    condition:"",
    fuelType:"",
    brand:"",
    vehicleType:""
  });

  const clearFilter = ()=> {
    setParams({...params, status:"",
    assignedTo:"",
    condition:"",
    fuelType:"",
    brand:"",
    vehicleType:""})
    formik.values.status=""
    formik.values.assignedTo=""
    formik.values.condition=""
    formik.values.fuelType=""
    formik.values.brand=""
    formik.values.vehicleType=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,status:value.status, assignedTo:value.assignedTo,condition:value.condition,fuelType:value.fuelType,brand:value.brand,vehicleType:value.vehicleType})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        status:"",
    assignedTo:"",
    condition:"",
    fuelType:"",
    brand:"",
    vehicleType:""
      });
    }
  })
  const { data, totalCount, getData } = UseGetDataSearch( "/vehicles/",params);
  // console.log(data, "dddd");

  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "vehicleId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Name",
      width: "9%",
      isLink: true,
      id: "vehicleId",
      link: '/maintenance/vehicle/view/',
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Type",
      width: "9%",
      field: "vehicleType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Brand",
      width: "9%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "vehicleId",
        removeValue: "Vehicles",
        url: "/vehicles/",
        moduleName: "vehicle",
        from: "/maintenance/vehicle",
        editLink: "/maintenance/vehicle/edit/",
        viewLink: "/maintenance/vehicle/view/",
      },
    },
  ];

  let availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "vehicleId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Name",
      width: "9%",
      isLink: true,
      id: "vehicleId",
      link: '/maintenance/vehicle/view/',
      field: "name",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Type",
      width: "9%",
      field: "vehicleType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Brand",
      width: "9%",
      field: "brand",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Assigned To ",
      width: "9%",
      field: "assignedToName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "ChasisNo",
      width: "9%",
      field: "chasisNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Condition",
      width: "9%",
      field: "condition",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Fuel Type",
      width: "9%",
      field: "fuelType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Plate No",
      width: "9%",
      field: "plateNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purchased On",
      width: "9%",
      field: "purchasedOn",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Insurance Date",
      width: "9%",
      field: "moreDetails.insDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Insurance Expiry",
      width: "9%",
      field: "moreDetails.insExpDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Insurance Company",
      width: "9%",
      field: "moreDetails.insCompany",
      textAlign: "center",
      rowAlign: "center",
    },
   
    {
      title: "Fahas Date",
      width: "9%",
      field: "moreDetails.fahasDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Fahas Expiry Date",
      width: "9%",
      field: "moreDetails.fahasExpDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Istimara Expiry Date",
      width: "9%",
      field: "istimaraExpDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Status",
      width: "9%",
      field: "status",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "vehicleId",
        removeValue: "Leads",
        url: "/vehicles/",
        moduleName: "vehicle",
        from: "/maintenance/vehicle",
        editLink: "/maintenance/vehicle/edit/",
        viewLink: "/maintenance/vehicle/view/",
      },
    },
  ];

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Maintenance",
            },
            {
              name: "Vehicle",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
          {/* <CreateButton label="Import" /> */}
          {GetAccess("vehicle_create") && <Link to="/maintenance/vehicle/create/">
            <CreateButton label="Vehicle" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Vehicle List"
        storageName="VehicleTable"
        getById="vehicleId"
        editAccess="vehicle_edit"      
        deteleAccess="vehicle_delete" 
      />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Vehicle</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              label="Vehicle Type"
              placeholder="Choose"
              formik={formik}
              Options={VehicleType}
              name="vehicleType"
              width="w-full "
            />
            
          
             <FormikSelectTwo
              label="Brand"
              placeholder="Choose"
              formik={formik}
              Options={VehicleBrand}
              name="brand"
              width="w-full "
            />
            <FormikSelectTwo
              label="Condition"
              placeholder="Choose"
              formik={formik}
              Options={VehicleCondition}
              name="condition"
              width="w-full "
            />
             <FormikSelectTwo
            type="text"
            label="Assigned To"
            placeholder="Assigned To"
            Options={users}
            formik={formik}
            name="assignedTo"
            width="w-full "
          />
           <FormikSelectTwo
              label="Fuel Type"
              placeholder="Fuel Type"
              formik={formik}
              Options={FuelType}
              name="fuelType"
              width="w-full "
            />
             <FormikSelectTwo
              label="status"
              placeholder="status"
              formik={formik}
              Options={VehicleStatus}
              name="status"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
     
    </div>
  );
}
export default VehicleIndex;
