import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const Status = () => {
  return(
    <SettingsMetaData propertyName={"GrnStatus"} viewPropertyName={"GRN Status"} />
  )
};

export default Status;
