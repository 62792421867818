import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInput, NuSelect, NuUpload } from '../../../../Components/Utils/Input';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from "formik";
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
function CreateCatalogues() {
  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    category: "",
    subCategory: "",
    // attachments:[]
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append('attachments', image.raw);

      addFormData('/catalogues/new-catalogue', formData, resetForm);
    }
  })
  // const cataloguesCategory =[
  //   {value:"Hi",label:"Hi"},
  //   {value:"Freinds",label:"Freinds"},
  //   {value:"Naan",label:"Naan"},
  //   {value:"Ungal",label:"Ungal"},
  //   {value:"Deva",label:"Deva"},
  // ];
  // const cataloguesSubCategory =[
  //   {value:"Hi",label:"Hi"},
  //   {value:"Freinds",label:"Freinds"},
  //   {value:"Naan",label:"Naan"},
  //   {value:"Ungal",label:"Ungal"},
  //   {value:"Deva",label:"Deva"},
  // ];

  // const adddd=()=>{
  //   cataloguesSubCategory.push(formik.values.subCategory)
  //   // formik.values.subCategory = 
  // }

  const { category: cataloguesCategory, subCategory: cataloguesSubCategory  } = UseCatSubCatDropDown('/category/CatalogeCategory', "CatalogeCategory", formik?.values?.category);

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Products" },

            {
              name: "Catalogues",
              link: "/products/catalogues"
            },

            { name: "Create" },

          ]} />

        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Choose"
              Options={cataloguesCategory}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
            <FormikSelectTwo
              type="text"
              label="Sub-Category"
              placeholder="Choose"
              Options={cataloguesSubCategory}
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <span><button type='button' onClick={()=>{adddd()}}>+</button></span> */}
            
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  )
}
export default CreateCatalogues