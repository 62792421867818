// ModalContainer.js

import React from 'react';
import ReactDOM from 'react-dom';
import { TextMediumXL } from '../Text/MediumText';
import { TextSM } from '../Text/NormalText';
import { useTranslation } from 'react-i18next';

const ModalContainer = ({ visiable, closeModal, title, content, hasContent=false, handleClick, hasButton=true, okText = "OK", cancelText = "Cancel",outSideClick=true }) => {
  const { t, i18n } = useTranslation();

  const handleOverlayClick = (e) => {
    if(outSideClick){
      if (e.target === e.currentTarget) {
        // Close modal only if clicked on the overlay (not inside the modal content)
        closeModal();
      }
    }else{
      return false
    }
  };
  return ReactDOM.createPortal(
    <>
      {
        visiable && (
          <div className=" fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-[9999]" onClick={handleOverlayClick}>
            <div className="animate-scale-in min-w-[20%] max-w-[98%] max-h-[99vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
              <div className='pb-3 justify-start '>
                <span className='justify-start' dir={i18n.language == "ar" ? "rtl" : ""}><TextMediumXL content={t(title)} otherStyles=' mb-2' align='start' /></span>
                 {
                  hasContent == true ? <div>{content}</div>:<TextSM content={content} otherStyles=' mb-2' />
                 }
              </div>

              {
                hasButton == true && (
                  <div className=' flex justify-end items-center gap-4 ' dir={i18n.language == "ar" ? "rtl" : ""}>
                  <button className='px-4 py-[8px]  text-xs hover:bg-gray-300
      rounded-md flex items-center text-textColor-main bg-[#E6F2F2]' onClick={closeModal}>{t(cancelText)}</button>
                  <button className=' px-4 py-[8px] text-xs bg-baseColor-primary rounded-md text-white ' onClick={handleClick}>{t(okText)}</button>
                </div>
                )
              }
            </div>
          </div>
        )
      }
    </>,
    document.getElementById('modal-root')
  );
};

export default ModalContainer;
