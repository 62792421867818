import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Brand from "./Brand";
import Condition from "./Condition";
import FuelType from "./FuelType";
import MaintenceType from "./MaintenceType";
import Model from "./Model";
import Status from "./Status";
import VehicleName from "./VehicleName";
import VehicleType from "./VehicleType";
import YearOfMan from "./YearOfMan";




const Vehicle = () =>{
    return(
        <>
        <TextMediumSM content="Vehicle" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VehicleName/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <VehicleType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <FuelType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Model/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Brand/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <YearOfMan/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Condition/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <MaintenceType/>
         </div>
         </div>
         </>
    )
}

export default Vehicle;