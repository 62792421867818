import React from "react";

export default function Maintanance({ color = "#999999", width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <title>maintenance-warning</title>{" "}
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="add"
            fill={color}
            transform="translate(42.666667, 42.666667)"
          >
            <path
              d="M213.333333,7.10542736e-15 C330.959705,7.10542736e-15 426.666667,95.7069604 426.666667,213.333333 C426.666667,330.959705 330.959705,426.666667 213.333333,426.666667 C95.7069604,426.666667 7.10542736e-15,330.959705 7.10542736e-15,213.333333 C7.10542736e-15,95.7069604 95.7069604,7.10542736e-15 213.333333,7.10542736e-15 Z M213.333333,42.6666667 C118.892964,42.6666667 42.6666667,118.892964 42.6666667,213.333333 C42.6666667,307.773704 118.892964,384 213.333333,384 C307.773704,384 384,307.773704 384,213.333333 C384,118.892964 307.773704,42.6666667 213.333333,42.6666667 Z M214.247352,115.448129 C230.38415,136.430834 235.828347,162.243841 230.657609,181.089155 C230.011918,184.190056 252.755616,208.371626 298.888703,253.633865 C313.973648,268.71881 313.973648,283.803754 298.888703,298.888699 C284.691108,313.086294 270.493513,313.921446 256.295918,301.394157 L181.426839,230.319924 C162.579882,235.491139 136.767529,230.046951 115.785814,213.909668 C100.396892,193.674631 95.0886432,167.225458 99.3755574,148.268642 L132.19607,181.089155 L165.016583,164.678898 L181.426839,131.858386 L148.606327,99.0378728 C167.562553,94.7513198 194.012816,100.058863 214.247352,115.448129 Z"
              id="Combined-Shape"
            >
            </path>
          </g>
        </g>
      </g>
    </svg>
  );
}
