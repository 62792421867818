import React from 'react';
import PrFromCustomer from './PrFromCustomer';
import QuotationToCustomer from './QuotationToCustomer';
import SalesOrderFromCustomer from './SalesOrderFromCustomer';
import Invoice from './Invoice';

function Approval() {

  return (
    <div className="col-span-5 bg-white h-[40vh] p-4  pb-10 rounded-lg shadow-lg overflow-hidden mb-4">
     <p className="text-sm text-[#4F4768] font-medium ">Approval</p>
     <div className='flex'>

    <PrFromCustomer/>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>
<QuotationToCustomer/>
     
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

     <SalesOrderFromCustomer/>
      <div className='border-r border-[1px] border-slate-200/60 border-solid'/>

    <Invoice/>
      </div>
    </div>
  );
}

export default Approval;
