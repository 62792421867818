const   colorCodes = [
    "#FF5733", // Red-Orange
    "#33FF57", // Lime Green
    "#3357FF", // Royal Blue
    "#FF33A1", // Pink
    "#FF33FF", // Magenta
    "#33A1FF", // Sky Blue
    "#FF3333", // Red
    "#33FF33", // Green
    "#3333FF", // Blue
    "#FFD700", // Gold
    "#40E0D0", // Turquoise
    "#8A2BE2", // Blue Violet
    "#FF4500", // Orange Red
    "#A1FF33", // Light Green
    "#2E8B57", // Sea Green
    "#DAA520", // Golden Rod
    "#9ACD32", // Yellow Green
    "#FF69B4", // Hot Pink
    "#4682B4", // Steel Blue
    "#6A5ACD"  // Slate Blue
  ];

  export default colorCodes;