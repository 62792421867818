import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import Delete from '../../../../Components/Icons/Delete';
import axiosInstance from '../../../../Services/axiosCommon';
import useQuotProducts from './Hooks/useMemo';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import NuAmount from '../../../../Components/Utils/NuAmount';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function EditPo() {
  const {value:paymentStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'paymentStatus_po')
  const {value:approvalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'approvalStatus_po')
  const {value:BillingAddress} = useFetchMataData(`/dropdown/dropdown-details/`,'BillingAddress_po')
  const {value:deliveryAddress} = useFetchMataData(`/dropdown/dropdown-details/`,'deliveryAddress_po')
  const {value:paymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_po')
  const {value:deliverTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'deliverTerms_po')
  const {value:Status,getData:refStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_po')
  const { data: Agents ,getData:getAgents} = UseDropDown('/agents/drop-down');
  const {value:ShippingMethod,getData:refShippingMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'ShippingMethod')

  const { data: user } = UseDropDown('/user/drop-down');
  // const { data: Item } = UseDropDown('/items/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  // const { data: vendors } = UseDropDown('/vendors/drop-down');
  
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  // console.log(data,"gg");
  const from = location.state.from;
  const [dataa,setData] = useState(data);
  // console.log(dataa,"hhh");
  const [items, setItems] = useState([]);
const [remove, setRemove] = useState([]);
  const [newItemData, setNewItemData] = useState({
    productId:"",
    requestedQty:"",
    orderedQty: "",
    quoteProductId:"" ,
    product_name:"",
      price:"",
      quantity:"",
      negotiatedPrice:""
   });
  const [createNew, setCreateNew] = useState({
    prId: data.prId,
    refNumber: data.ref,
    date: data.date,
    vendorId: data.vendorId,
    totalPrice: data.totalPrice,
    vendorName: data.vendorName,
    paymentStatus: data.paymentStatus,
    comments: data.comments,
    requestedDeliveryDate: data.requestedDeliveryDate,
    requestedBy: data.requestedBy,
    approvedBy: data.approvedBy,
    products: data.products,
    quoteProductId: data.quoteProductId,
    deliveryAddress: data.deliveryAddress,
    billingAddress: data.billingAddress,
    productId: data.productId,
    requestedQty: data.requestedQty,
    status:data.status,
    deliveryTerms:data.deliveryTerms,
    paymentTerms:data.paymentTerms,
    sqId:data?.sqId,
    bookingEnabled: data?.shipmentDetails ? true :false,
          bookingDate:data?.shipmentDetails ? data?.shipmentDetails.bookingDate:"",
          ETD:data?.shipmentDetails ? data?.shipmentDetails.ETD:"",
          ETA:data?.shipmentDetails ? data?.shipmentDetails.ETA:"",
          permitReq :data?.shipmentDetails ? data?.shipmentDetails.permitReq:"",
          agent  :data?.shipmentDetails ? data?.shipmentDetails.agent:"",
          modeOfShipment:data?.shipmentDetails ? data?.shipmentDetails.modeOfShipment:"",
  })
  // const handleDelete = (productId) => {
  //   const updatedProducts = dataa?.products.filter(item => item.productId !== productId);
  //   setData(prevState => ({
  //     ...prevState,
  //     products: updatedProducts
  //   }));

  //   setRemove([...remove,productId]);
  // };

  const handleDelete = (productId, list) => {
    const updatedProducts = dataa?.products.filter(item => item.productId !== productId);
  
    // Find the product to be deleted from the list
    const deletedProduct = dataa?.products?.find(item => item.productId === productId);
  
    // If the product exists and has a valid price and quantity, calculate the reduction in total price
    if (deletedProduct && deletedProduct.negotiatedPrice && deletedProduct.orderedQty) {
      const priceToRemove = deletedProduct.negotiatedPrice || 0;
      const quantityToRemove = deletedProduct.orderedQty || 0;
      const reduction = priceToRemove * quantityToRemove;
  
      // Calculate the new total price by subtracting the reduction
      // const newTotalPrice = formik.values.totalPrice - reduction;
      const newTotalPrice = updatedProducts.length > 0 
                            ? formik.values.totalPrice - reduction 
                            : 0;
  
      // Update the state with the filtered products and the new total price
      setData(prevState => ({
        ...prevState,
        products: updatedProducts,
        totalPrice: newTotalPrice
      }));
  
      // Update the formik field for the total price
      formik.setFieldValue('totalPrice', newTotalPrice);
  
      // Optionally, update any other state or perform additional actions
      setRemove([...remove, productId]);
    }
  };

  const handleAdd = () => {
    const newItem = {
      // productId: formik.values.productId,
      // productName : Item.find((i)=> i.value===formik.values.productId),
      price: formik.values.price,
      negotiatedPrice: formik.values.negotiatedPrice,

       requestedQty: formik.values.requestedQty,
       productId: formik.values.productId,
       quoteProductId: formik.values.quoteProductId,
      orderedQty: formik.values.orderedQty,
      product_name: formik.values.product_name,
      

    };
    setItems([...items, newItem]);
    formik.values.totalPrice = parseFloat(formik.values.totalPrice) + (parseFloat(newItem.negotiatedPrice) * parseFloat(newItem.orderedQty));

    setNewItemData({
      price: "",
      product_name:"",
      quoteProductId: "",
      orderedQty: "",
      requestedQty:"",
      productId:"",
      negotiatedPrice:""
      
    });
    formik.values.orderedQty ='';
    formik.values.quoteProductId = '';
    formik.values.price =''
  };

  // const handleDeleteItem = (indexToDelete,list) => {
  //   const updatedItems = items.filter((_, index) => index !== indexToDelete);
  //   setItems(updatedItems);
  //   formik.values.totalPrice = parseFloat(formik.values.totalPrice) - (parseFloat(list?.price * list?.orderedQty))

  // };
  const [error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required('Purchase Request is required!'),
      sqId: yup.string().required('Supplier Quotes is required!'),
      // bookingDate: yup.string().required('Booking Book is required!'),
      // agent: yup.string().required('Agent  is required!'),

      vendorId: yup.string().required('Supplier is required!')

      
}),

    onSubmit: async (value, { resetForm }) => {
      if (dataa.products.length === 0) {
        setError('Product table is empty');
        return;
      }
      const valueWithItems = {
        ...value,
        products: dataa.products,
        removedProducts:remove
      };
      editData(`/PO/edit-PO/${data.poId}`, valueWithItems, resetForm, from)
    }
  });

  const quotProducts = useQuotProducts(formik.values.prId, formik.values.vendorId); 

  console.log(quotProducts,"quotes");

  useMemo(()=>{
    if (formik.values.quoteProductId!==''){
      let tem_product = quotProducts? quotProducts?.find((i)=> i.quoteProductId === formik.values.quoteProductId) : [];
      formik.values.price = tem_product && tem_product.price;
      formik.values.negotiatedPrice = tem_product && tem_product.negotiatedPrice;

      formik.values.productId = tem_product && tem_product.productId;
      formik.values.requestedQty = tem_product && tem_product.requestedQty
      formik.values.product_name = tem_product && tem_product.productName
      formik.values.orderedQty = tem_product && tem_product.orderedQty
      
      console.log(tem_product);
    }
  },[formik.values.quoteProductId])
  
  const [vendors, setVendors] = useState([])

  function getVendors(num){
    axiosInstance.get(`/supplier-quotations/quote/drop-down/${num}`).then((response) => {
      let tempData =[]
      if(response.data){
        let x = response.data

        x.map((item) => {
        let y = {
          value: item.id,
          label: item.name,
        }
        tempData.push(y)
        })

        setVendors(tempData)
      }
    }).catch((error) => {})
   }

   useMemo(()=>{
    if(formik.values.prId){
      getVendors(formik.values.prId);
    }
   },[formik.values.prId])

   const [product, setProducts] = useState(dataa?.products);

   useEffect(() => {
     if (formik.values.sqId) {
       axiosInstance
         .get(`/supplier-quotations/selected-products/drop-down/${formik.values.prId}/${formik.values.vendorId}`)
         .then(res => {
          console.log("rrr",res?.data?.data?.products);
           let data = res?.data?.data?.products;
           let tPrice=0.00
           for (let i=0;i<data?.length;i++){
            tPrice = parseFloat(tPrice) + (parseFloat(data[i].negotiatedPrice) * parseFloat(data[i].orderedQty));
           }
          //  setTotalPrice(tPrice);
          // formik.setFieldValue('totalPrice', tPrice);
          // setProducts(data); // Update products state with fetched data
         })
         .catch(err => {
           console.log(err, "err");
           // Handle errors here
         });
     }
     
   }, [formik.values.sqId]);

   useEffect(() => {
    const totalPrice = product?.reduce((total, item) => {
      return total + (parseFloat(item.negotiatedPrice) * parseFloat(item.orderedQty || 0));
    }, 0);
    console.log(totalPrice,"kkk");

    formik.setFieldValue('totalPrice', totalPrice);
  }, [product]);
  

   const handleQuantityChange = (index, neworderedQty) => {
    const updatedItems = [...product];
    updatedItems[index].orderedQty = neworderedQty;
    setProducts(updatedItems);
  };
  
  const handleIncrement = (index) => {
    handleQuantityChange(index, product[index].orderedQty + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(0, product[index].orderedQty - 1));
  };

  // const handleInputChange = (event, index) => {
  //   const value = Math.max(0, Number(event.target.value));
  //   handleQuantityChange(index, value);
  // };
  const handleInputChange = (event, index) => {
    const value = event.target.value;
    
    // Allow input to include 0 but prevent negative numbers
    const sanitizedValue = value === '' || value === '-' ? value : Math.max(0, Number(value));
  
    handleQuantityChange(index, sanitizedValue);
  };
  
  
  useEffect(() => {
    if (dataa.products?.length > 0) {
      setError('');
    }
  }, [dataa.products]);

  const handlenegotiatedPriceChange = (e, index) => {
    const updatedItems = [...product];
    updatedItems[index].negotiatedPrice = e.target.value;
    setProducts(updatedItems);
  };
  let { data: suppliers } = UseDropDown("/vendors/drop-down");

  const [quotes, setQuotes] = useState(dataa?.products);

  const getQuotes = (prId, vendorId) => {
    if (prId !== "" && vendorId !== "") {
      axiosInstance
        .get(`/supplier-quotations/quotes/drop-down/${prId}/${vendorId}`)
        .then((res) => {
          const data = res.data;
          const rfqOptions = data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setQuotes(rfqOptions); 
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };
  
  useEffect(() => {
    
    if (formik.values.prId && formik.values.vendorId) {
      getQuotes(formik.values.prId, formik.values.vendorId);
    

    }
    
  }, [formik.values.prId, formik.values.vendorId]);

  const [showPopup, setShowPopup] = useState(false);
   
  const [newProduct, setNewProduct] = useState({

   
      customerType: "",
      companyName: "",
      email: "",
      phone: "",
      countryCode: "",
      website: "",
      // industry: "",
    
          });
  

const togglePopup = () => {
  setShowPopup(!showPopup);
};


 const handlePopupSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  // formData.append('customerImage', JSON.stringify(newProduct));
  formData.append(
    "agentDetails",
    JSON.stringify({
      type: formik.values.type || "",
      companyName: formik.values.companyName || "",
      email: formik.values.email || "",
      phone: formik.values.phone || "",
      countryCode: formik.values.countryCode || "",
      website: formik.values.website || "",
      // industry: formik.values.industry || "",
    })
  );
  try {
   
     axiosInstance.post("/agents/new-agent", formData).then(res=>{
      if(res?.data?.status==true){
        formik.resetForm();
        getAgents
        ()
        formik.setValues(prevValues => ({
          ...prevValues,
          // Reset fields related to the new vendor
          companyName: "",
          email: "",
          phone: "",
          countryCode: "",
          website: "",
          // industry: "",
          customerType: "",
        }));
        setShowPopup(false);
      }
     }).catch(err=>{
      console.log("err",err);
     })

  } catch (error) {
    console.error("Error creating new customer:", error);
  }
};

  const handleCheckboxChange = (e) => {
    formik.setFieldValue('bookingEnabled', e.target.checked); };


  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
     <Breadcrumb 
      items={[
        // { name: "Procurement" },

        { name: "Purchase Order",
        link:"/procurement/purchase-order/"},
        
      { name: "Edit"},
      
  ]} />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request "
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
               <FormikSelectTwo
              type="text"
              label="Supplier "
              placeholder="Supplier "
              Options={suppliers}
              formik={formik}
              name="vendorId"
              disabled={true}
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {/* <FormikSelectTwo
              type="text"
              label="Supplier "
              placeholder="Supplier "
              Options={vendors}
              formik={formik}
              name="vendorId"
              
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {formik.values.vendorId && 
             <FormikSelectTwo
             type="text"
             label="Supplier Quote"
             placeholder="Choose"
             Options={quotes}
             formik={formik}
             name="sqId"
             disabled={true}
             width="w-full md:w-1/2 lg:w-1/3"
            
           />
            // <NuInput
            //     label="Supplier Quote"
            //     type="text"
            //     formik={formik}
            //     placeholder="Supplier Quote"
            //     disabled={true}
            //     name="sqId"
            //     width="w-full md:w-1/2 lg:w-1/3"
            // />
            }
              {/* <NuDate
                label="Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="date"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             */}
              {/* <NuInput
                label="Total Price"
                type="text"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
                <NuDate
                label="Requested Delivery Date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="requestedDeliveryDate"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
               {/* <FormikSelectTwo
                type="text"
                label="Approved Status"
                placeholder="Approved Status"
                Options={approvalStatus}
                formik={formik}
                name="approvalStatus"
                width="w-full w-full md:w-1/2 lg:w-1/3 "
              /> */}
               <FormikSelectTwo
                type="text"
                label="Billing Address "
                placeholder="Choose"
                Options={BillingAddress}
                formik={formik}
                name="billingAddress"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <FormikSelectTwo
                type="text"
                label="Delivery Address"
                placeholder="Choose"
                Options={deliveryAddress}
                formik={formik}
                name="deliveryAddress"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Choose"
              Options={deliverTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            /> 
             {/* <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Choose"
              Options={paymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
                type="text"
                label="Payment Status"
                placeholder="Payment Status"
                Options={paymentStatus}
                formik={formik}
                name="paymentStatus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               {/* <FormikSelectTwo
                type="text"
                label="Approval Status"
                placeholder="Approval Status"
                Options={approvalStatus}
                formik={formik}
                name="approvalStatus"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               <DropDownAddMetaData
              label="Status"
              placeholder="Choose"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refStatus}
              displayProperty={"Status"}
              propertyName={"Status_po"}
            />
            
                   <div className="flex items-center mt-4 ml-6  w-[30%] text-sm">
      <input
        type="checkbox"
        id="bookingEnabled "
        name="bookingEnabled "
        checked={formik.values.bookingEnabled }
        onChange={handleCheckboxChange}
        className="mr-2 w-4 h-4"
      />
      <label htmlFor="bookingEnabled ">Booking</label>
    </div>

    {formik.values.bookingEnabled && (
      <>
         <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Booking Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

       <NuDate
              label="Booking Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="bookingDate"
              isRequired={true}
              width="w-full md:w-1/2 lg:w-1/3"
            />
         <div className=" flex items-center w-full md:w-1/2 lg:w-1/3 ml-7">

 <FormikSelectTwo
              type="text"
              label="Agent "
              placeholder="Agent  "
              Options={Agents}
              formik={formik}
              name="agent"
              isRequired={true}
              width="w-[75%]  "
              px='0'
             
            />
              <button onClick={togglePopup} type="button" className="w-[12%] p-1 text-center h-9 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg " > + </button>
            
            </div>
             <DropDownAddMetaData
              label="Mode Of Shipment"
              placeholder="Choose"
              Options={ShippingMethod}
              formik={formik}
              name="modeOfShipment"
              width="w-full md:w-1/2 lg:w-1/3"
              getFunc={refShippingMethod}
              displayProperty={"Mode Of Shipment"}
              propertyName={"ShippingMethod"}
            />
            <FormikSelectTwo
              type="text"
              label="Permit Required?"
              placeholder="Permit Required?"
              Options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" }

              ]}
              formik={formik}
              name="permitReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuDate
              label="ETD"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETD"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
             <NuDate
              label="ETA"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="ETA"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
      </>
    )}
            

              <NuTextArea
                label="Comments"
                type="text"
                formik={formik}
                placeholder="Comments"
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
          <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

       
       <div className='w-full bg-white overflow-y-scroll mt-5'>
       {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
       <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-4'>
       <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

              <p className=" w-[40%] text-left text-white text-xs font-medium">Product</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">Unit</p>
              <p className=" w-[15%] text-center text-white text-xs font-medium">Requested Qty</p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">Ordered Qty</p>
              <p className=" w-[15%] text-center text-white text-xs font-medium">Price</p>
              <p className=" w-[15%] text-center text-white text-xs font-medium">Negotiated Price</p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">
                Total
                </p>
              <p className=" w-[5%] text-center text-white text-xs font-medium">Action</p>
              </div>
            {data?.products?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

           {dataa?.products.map((List, index) => {
            return (
              <div key={index} className="w-full py-2 flex justify-between px-4 odd:bg-white even:bg-slate-100">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className=" w-[40%] text-left text-[#606279] text-xs font-light">
                    {List?.product_name}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                    {List?.requestedQty}
                  </p>
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                    <button
                      type="button"
                      className="border rounded-full p-1 "
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
  type="number"
  className="w-[20%] text-center border text-[#606279] text-xs font-light"
  name="orderedQty"
  value={List.orderedQty}
  onChange={(e) => handleInputChange(e, index)}
/>
                    <button
                      type="button"
                      className="border rounded-full p-1"
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                  <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                    {List?.price}
                  </p>
                 
                  <div className="w-[15%] px-1">
                    <input
                            type="number"
                            className="w-[90%] py-1 text-center border text-[#606279] text-xs font-light"
                            name="negotiatedPrice"
                            formik={formik}
                            placeholder="Enter the Negotiated Price"
                            value={List.negotiatedPrice || ""}
                            onChange={(e) => handlenegotiatedPriceChange(e, index)}
                          />
                          </div>
                          <p className="text-center w-[10%] text-[#606279] text-xs font-light">
  {(List.negotiatedPrice * List.orderedQty).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>

               <div className='w-[5%] text-center'> 
               <button
                  onClick={() => handleDelete(List?.productId)}
                  type="button"
                  className="text-red-500 cursor-pointer  ml-4 border-none"
                >
                  <Delete
                   color='red'
                   height={4}
                  />
                </button>
                </div>
              </div>
            );
          })} 
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

      </div>
      <div className="w-full my-6 flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount value={formik?.values?.totalPrice || 0} />
              </p>           {/* <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
            </div>
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{Oncancel(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
        </div>
      </form>
    </div>
  );
}
export default EditPo