import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import seal from '../../../../../assets/images/seal.png'
import CompanyName from '../../../../../assets/images/companyName.png'
import CompanyLogo from '../../../../../assets/images/companyLogo.png'

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  styImage:{
    display:"flex",
    justifyContent: "space-between",
    alignContent:"flex-start",
    flexDirection:"row",
    width: "100%"
  },
  headers: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: 5
  },
  side:{
    fontSize: 12,
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'center', 
    marginBottom: 10,
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  headingText: {
    marginTop: 5,
    fontSize: 14,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 10,
  },
  label: {
    width: '50%',
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    width: '50%',
    padding: 5,
    fontSize: 12,
  },
  productRow: {
    flexDirection: 'row',
    padding: 5,
  },
  productHeader: {
    backgroundColor: '#f0f0f0',
    // color: '#fff',
    padding: 8,
    // fontSize: 12,
    fontWeight: 'bold',
  },
  productCell: {
    padding: 5,
    fontSize: 12,
  },
  note: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between', 
    borderColor: '#ccc',
  },
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="portrait">
    <View style={styles.header}>
        <View style={styles.styImage}>
          {/* <Text style={styles.title}>Yanama Trading Company</Text> */}
          <Image src={CompanyName} style={{width: "60%" }}></Image>
          <Image src={CompanyLogo} style={{width: "20%" }}></Image>
        </View>
      </View>
      <View style={styles.headers}>
        <Text style={styles.title}>PURCHASE Request : {data.ref || 'N/A'}</Text>
        <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
        {/* <View>
          <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
          <Text style={styles.side}>PO#: {data.ref || 'N/A'}</Text>
        </View> */}
      </View>
      {/* <View style={styles.headingContainer}>
        <Text style={styles.headingTitle}>Purchase Request</Text>
        <Text style={styles.headingText}>: {data.ref}</Text>
      </View> */}

      <View style={styles.section}>
        {[
          { label: 'Customer ', value: data.customerName },
          { label: 'Dead Line', value: data.deadLine || 'N/A'},
          { label: 'Cost Code', value: data.costCode || 'N/A'},
          { label: 'RFQ Assigneee', value: data.rfqAssigneeName || 'N/A'},
          { label: 'Sales Person', value: data.salesPersonName || 'N/A'},
          { label: 'Customer RFQ Reference', value: data.customerRfqRef },
          // { label: 'Delivery Terms', value: data.deliveryTerms },
          // { label: 'Payment Status', value: data.paymentStatus },
          { label: 'Prepared By', value: data.preparedByName },
          { label: 'Reviewed By', value: data.reviewedByName },
          { label: 'Approved By', value: data.approvedByName },

          { label: 'Status', value: data.status },
          // { label: 'Comments', value: data.comments },
        ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>{item.value}</Text>
          </View>
        ))}
      </View>

      <View style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            'S.N', 'Product Name', 'Item Code', 'Product Unit',
            'Quantity'
          ].map((header, index) => {
            let width=[5, 40, 25, 15, 15]
            return(
            <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
              {header}
            </Text>
          )})}
        </View>
        {data?.productList && data?.productList?.map((product, index) => (
          <View key={index} style={styles.productRow}>
            <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
            <Text style={[styles.productCell, { width: '40%' }]}>{product.product_name}</Text>
            <Text style={[styles.productCell, { width: '25%' }]}>{product.item_code}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.product_unit}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
            {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
            <Text style={[styles.productCell, { width: '15%' }]}>{product.quantity}</Text>
            {/* <Text style={[styles.productCell, { width: '10%' }]}>{product.price}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
          </View>
        ))}
      </View>

      <View style={{
        width:'100%',
        flexDirection:"row",
        justifyContent:"space-between"
      }}>
      <View style={styles.section}>
        <Text style={{paddingTop:8}}>Note: {data.notesFromCustomer || 'N/A'}</Text>
      </View>
      <View style={{fontSize: 12,
                     marginTop: 15,
                     marginBottom: 15,
                     marginRight: 8
                     }} >
        <Text style={{paddingBottom: 8, paddingRight:4}}>Authorized By</Text>
        <Image src={seal} style={{width: "100%"}}></Image>
      </View>
      </View>
    </Page>
  </Document>
);

export default PDFDocument;