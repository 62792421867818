import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const CustomerDiscount = () => {
  return (
    <SettingsMetaData propertyName={"CustomerDiscount_SalesOrder"} viewPropertyName={"Customer Discount"} />
  );
};

export default CustomerDiscount;
