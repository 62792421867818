import { useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";

function ContractsIndex(){
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
      category:"",
      subCategory:""
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      category:"",
      subCategory:""
    });
    const clearFilter = ()=> {
      setParams({...params,category:"", subCategory:""})
        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,category:value.category, subCategory:value.subCategory})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          category: "",
          subCategory: ""
        });
      }
    })
  
    const { data, totalCount, getData} = UseGetDataSearch('/contracts/',params);
    console.log(data,'dddd');

    const defaultHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "id",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Contract Title",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/procurement/contracts/view/',
        field: "contractTitle",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Customer Name",
        width: "10%",
        field: "customerName",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Start Date",
        width: "10%",
        field: "startDate",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "End Date",
        width: "10%",
        field: "endDate",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Project Duration",
        width: "10%",
        field: "projectDuration",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "left",
        rowAlign: "left",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"contractTitle",
          url:`/contracts/`,
          moduleName:"Contracts",
          from:`/procurement/contracts`,
          editLink:"/procurement/contracts/edit/",
          viewLink:"/procurement/contracts/view/"
        }
      },
    ];

    const availableHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "id",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Contract Title",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/procurement/contracts/view/',
        field: "contractTitle",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Customer Name",
        width: "10%",
        field: "customerName",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Start Date",
        width: "10%",
        field: "startDate",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "End Date",
        width: "10%",
        field: "endDate",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Project Duration",
        width: "10%",
        field: "projectDuration",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Additional Amount",
        width: "10%",
        field: "additionalAmount",
        textAlign: "left",
        rowAlign: "left",
      },
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "left",
      //   rowAlign: "left",
      // },
      {
        title: "Contract Amount",
        width: "10%",
        field: "contractAmount",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Receivables",
        width: "10%",
        field: "receivables",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Scope Of Work",
        width: "10%",
        field: "scopeOfWork",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Signed On",
        width: "10%",
        field: "signedOn",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Total Amount",
        width: "10%",
        field: "totalAmount",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "left",
        rowAlign: "left",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"contractTitle",
          url:`/contracts/`,
          moduleName:"Contracts",
          from:`/procurement/contracts`,
          editLink:"/procurement/contracts/edit/",
          viewLink:"/procurement/contracts/view/"
        }
      },
    ];
  
      const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb
         items={
          [
            { name: "Procurement" },
            {
            name: "Contracts",
          }]
         }
        />
      <div className=' flex gap-2'>
      <SearchBar handleChange={(e) => setParams({...params,search:e.target.value})} />
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
         
       <CreateButton label='Import' />
       <Link to="/procurement/contracts/create"><CreateButton label='Contracts'/></Link> 
      </div>
      </div>
  
      <CustomDataTable
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeaders}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Contracts List'
        storageName="ContractTable"
        />
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Catalogues</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                      <FormikSelectTwo 
                        label="Category"
                        placeholder="Choose"
                        Options={[
                          {
                            value: "Option1", label: "Option1"
                          },
                          {
                            value: "Option2", label: "Option2"
                          }
          
                        ]}
                        formik={formik}
                        name="category"
                        width="w-full"
                      />
                      <FormikSelectTwo 
                        label="Sub Category"
                        placeholder="Choose"
                        Options={[
                          {
                            value: "Option1", label: "Option1"
                          },
                          {
                            value: "Option2", label: "Option2"
                          }
          
                        ]}
                        formik={formik}
                        name="subCategory"
                        width="w-full"
                      />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
    )
}

export default ContractsIndex;