import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";



function BarChartView({data}) {
    
  return (
    <ResponsiveContainer width="100%" height="90%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 10,
        }}
        
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="month" fontSize={12} fontWeight={500}  />
        {/* <YAxis /> */}
        <Tooltip contentStyle={{fontSize:'12px'}} />
        <Legend fontSize={8} verticalAlign="top" height={26} iconSize={12} iconType="circle" wrapperStyle={{ fontSize: '12px' }} />
        <Bar
          dataKey="In-Coming"
          fill="#8884d8"
          barSize={10}
          radius={[10,10,0,0]}
        />
        <Bar
          dataKey="Out-Going"
          fill="#82ca9d"
          barSize={10}
          radius={[10,10,0,0]}

        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartView;
