import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Agent from "./Agent";
import ShipmentTracking from "./ShipmentTracking";


  
  
  const MainShippingSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Shipping"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><ShipmentTracking/></div>
              <div className="p-3"><Agent/></div>
        </div>
        </div>
    )
  }

  export default MainShippingSettings;