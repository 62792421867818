// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   Image,
// } from "@react-pdf/renderer";
// import NuDate from "../../../../../Components/Utils/NuDate";
// import NuAmount from "../../../../../Components/Utils/NuAmount";

// let DateNow = NuDate({ value: new Date(), format: "DD-MMM-YYYY" });
// let TimeNow = NuDate({ value: new Date(), format: "hh:mm:ss a" });

// function Date(data) {
//   if (data) {
//     return NuDate({ value: data, format: "DD-MMM-YYYY" });
//   } else {
//     return " ";
//   }
// }

// const DownloadPDF = ({
//   company = "",
//   status,
//   standardname,
//   data = [],
//   Name = "",
//   review = "",
//   startDate = "",
//   endDate = "",
//   header = [],
// }) => {

//   // console.log("hesder",header);
//   function Header({ standardname, companyName, companyImg }) {
//     return (
//       <View
//         style={{
//           width: "100%",
//           height: "12%",
//           flexDirection: "column",
//         }}
//         fixed
//       >
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//             alignItems: "center",
//             width: "100%",
//             height: "50%",
//           }}
//         >
//           <View
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               gap: "4px",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Text
//               style={{
//                 fontSize: 13,
//                 marginBottom: 5,
//                 textAlign: "center",
//                 fontWeight: "semibold",
//               }}
//             >{`${companyName || ""}`}</Text>
//           </View>
//           <View>
//             <Text
//               style={{
//                 fontSize: "10px",
//                 color: "black",
//                 fontWeight: "normal",
//                 textAlign: "center",
//               }}
//             >{`${standardname || ""}`}</Text>
//           </View>
//           <View>
//             <Text
//               style={{
//                 fontSize: "10px",
//                 color: "black",
//                 fontWeight: "normal",
//                 textAlign: "center",
//               }}
//             >{`${Name}`}</Text>
//           </View>
//           <View
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               gap: "2px",
//             }}
//           ></View>
//         </View>
//         <View
//           style={{
//             width: "100%",
//             height: "50%",
//             flexDirection: "row",
//             justifyContent: "space-between",
//             alignItems: "center",
//             backgroundColor: "#F1E0FF",
//           }}
//         >
//           {header.length > 0 &&
//             header.map((item, idx) => {
//               return (
//                 <View
//                   key={idx}
//                   style={{
//                     width: item?.width || "10%",
//                     textAlign:item?.rowAlign|| "center",
//                     paddingVertical: "4px",
//                   }}
//                 >
//                   <Text
//                     style={{
//                       fontSize: "9px",
//                       color: "black",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     {item?.title}
//                   </Text>
//                 </View>
//               );
//             })}
//         </View>
//       </View>
//     );
//   }

//   function Footer() {
//     return (
//       <View
//         style={{
//           width: "100%",
//           height: "7%",
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           alignItems: "center",
//           position: "absolute",
//           bottom: "15px",
//           left: "30px",
//         }}
//         fixed
//       >
//         <View style={{ width: "33.3%" }}></View>
//         <View
//           style={{
//             width: "33.3%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             textAlign: "center",
//             gap: "3px",
//           }}
//         >
//           <Text
//             style={{
//               fontSize: 9,
//               textAlign: "center",
//               color: "grey",
//             }}
//             render={({ pageNumber, totalPages }) =>
//               `${pageNumber} / ${totalPages}`
//             }
//             fixed
//           />
//         </View>
//         <View
//           style={{
//             width: "33.3%",
//             textAlign: "right",
//             gap: "3px",
//           }}
//         >
//           <Text
//             style={{
//               fontSize: "7px",
//               color: "grey",
//               fontWeight: "bold",
//             }}
//           >
//             {` ${DateNow} / ${TimeNow}`}
//           </Text>
//         </View>
//       </View>
//     );
//   }



//   function getField(row,field,typeValue){
//     let splitValue = field.split(".");
//     let value = ""
//     if(splitValue.length >= 2){
//       if(splitValue.length == 3){
//         value = row[splitValue[0]][splitValue[1]][splitValue[2]]
//       }else {
//         value = row[splitValue[0]][splitValue[1]]
//       }
//     }else{
//         value = row[splitValue[0]]
//     }

//     if(typeValue === "date-time"){
//       return  (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY hh:mm a" />  )||""
//     }else if(typeValue === "date"){
//       console.log("date format");
//       return (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY" />  )||""
//     }else if(typeValue === "time"){
//       return (value !=="" && value !=="None" &&<NuDate value={value} format="hh:mm a" />  )||""
//     }else if(typeValue === "amount"){
//       return <NuAmount value={value}/> ||""
//     }else{
//       return value
//     }
//   }

//   function MainContent() {
//     return (
//       <View
//         style={{
//           display: "table",
//           width: "auto",
//           borderStyle: "solid",
//           borderWidth: 0,
//           borderRightWidth: 0,
//           borderBottomWidth: 0,
//         }}
//       >
//         {data &&
//           data.map((item, idx) => (
//             <View
//               style={{
//                 width: "100%",
//                 flexDirection: "row",
//                 borderStyle: "solid",
//                 borderWidth: 0.2,
//                 borderRightWidth: 0,
//                 borderLeftWidth: 0,
//                 borderBottomWidth: 0,
//               }}
//               key={idx}
//             >
//               {header.map((items, colIndex) => {
//                 console.log(`width ${colIndex+1}`,items?.width);
//                 return (
//                   <View
//                     style={{
//                       width: items?.width || "10%",
//                       textAlign: items?.textAlign|| "center",
//                       paddingVertical: "8px",
//                     }}
//                   >
//                     <Text
//                       style={{
//                         fontSize: "8px",
//                         color: "black",
//                       }}
//                     >
//                       {
//                          items.isCondition ? (item[items.field] ? 'Yes' : 'No') 
//                          : getField(item,items?.field,items?.type)
//                           }
//                     </Text>
//                   </View>
//                 );
//               })}
//             </View>
//           ))}
//       </View>
//     );
//   }

//   return (
//     <Document>
//       <Page
//         size="A4"
//         style={{
//           flexDirection: "column",
//           backgroundColor: "#fff",
//           padding: "15px 30px 60px 30px ",
//         }}
//         orientation="landscape"
//       >
//         <Header
//           standardname={standardname}
//           status={status}
//           companyName={company?.companyName}
//           companyImg={company?.companyLogo}
//         />
//         <MainContent />
//         <Footer startDate={startDate} endDate={endDate} review={review} />
//       </Page>
//       {/* ))
//           } */}
//     </Document>
//   );
// };

// export default DownloadPDF;

// import React from 'react';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// // Define styles
// const styles = StyleSheet.create({
//   page: {
//     padding: 10,
//     fontSize: 12,
//     fontFamily: 'Helvetica',
//   },
//   headingContainer: {
//     flexDirection: 'row',
//     alignItems: 'center', 
//     marginBottom: 10,
//   },
//   headingTitle: {
//     fontSize: 16,
//     fontWeight: 'bold',
//   },
//   headingText: {
//     marginTop: 5,
//     fontSize: 14,
//   },
//   section: {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: 10,
//   },
//   label: {
//     width: '50%',
//     padding: 5,
//     fontSize: 12,
//     fontWeight: 'bold',
//   },
//   value: {
//     width: '50%',
//     padding: 5,
//     fontSize: 12,
//   },
//   productRow: {
//     flexDirection: 'row',
//     padding: 5,
//   },
//   productHeader: {
//     backgroundColor: '#1e90ff',
//     color: '#fff',
//     padding: 5,
//     fontSize: 12,
//     fontWeight: 'bold',
//   },
//   productCell: {
//     padding: 5,
//     fontSize: 12,
//   },
//   note: {
//     marginTop: 20,
//     padding: 10,
//     borderWidth: 1,
//     flexDirection: 'row',
//     justifyContent: 'space-between', 
//     borderColor: '#ccc',
//   },
// });

// const PDFDocument = ({ data }) => (
//   <Document>
//     <Page size="A4" style={styles.page} orientation="portrait">
//       <View style={styles.headingContainer}>
//         <Text style={styles.headingTitle}>Purchase Order</Text>
//         <Text style={styles.headingText}>: {data.ref}</Text>
//       </View>

//       <View style={styles.section}>
//         {[
//           { label: 'Purchase Request', value: data.prRrf },
//           { label: 'Date', value: data.date },
//           { label: 'Supplier', value: data.vendorName },
//           { label: 'Supplier Address', value: data.supplierAddress },
//           { label: 'Shipping Address', value: data.deliveryAddress},
//           { label: 'Billing Address', value: data.billingAddress},
//           { label: 'Payment Terms', value: data.paymentTerms },
//           { label: 'Delivery Terms', value: data.deliveryTerms },
//           { label: 'Payment Status', value: data.paymentStatus },
//           { label: 'Requested Delivery Date', value: data.requestedDeliveryDate },
//           // { label: 'Requested By', value: data.requestedByName },
//           // { label: 'Status', value: data.status },
//           // { label: 'Approved By', value: data.approvedByName },
//           // { label: 'Comments', value: data.comments },
//         ].map((item, index) => (
//           <View key={index} style={{ flexDirection: 'row' }}>
//             <Text style={styles.label}>{item.label}</Text>
//             <Text style={styles.value}>{item.value}</Text>
//           </View>
//         ))}
//       </View>

//       <View style={{ marginTop: 20 }}>
//         <View style={{ flexDirection: 'row' }}>
//           {[
//             'S.N',  'Item Code', 'Product Name','Product Unit',
//             'Quantity', 'Request Quanity','Price'
//           ].map((header, index) => {
//             let width=[5, 15, 40,  15, 10, 10, 15]
//             return(
//             <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
//               {header}
//             </Text>
//           )})}
//         </View>
//         {data.products.map((product, index) => (
//           <View key={index} style={styles.productRow}>
//             <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
//             <Text style={[styles.productCell, { width: '15%' }]}>{product.item_code}</Text>

//             <Text style={[styles.productCell, { width: '40%' }]}>{product.product_name}</Text>
//             <Text style={[styles.productCell, { width: '15%' }]}>{product.product_unit}</Text>
//             {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
//             {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
//             <Text style={[styles.productCell, { width: '10%' }]}>{product.orderedQty}</Text>
//             <Text style={[styles.productCell, { width: '10%' }]}>{product.requestedQty}</Text>
//             <Text style={[styles.productCell, { width: '15%' }]}>{product.price}</Text>
//           </View>
//         ))}
//       </View>

//       <View style={styles.note}>
//         <Text>Notes From Customer: {data.notesFromCus}</Text>

//         <Text>Total Price: {data.totalPrice}</Text>
//       </View>
//     </Page>
//   </Document>
// );

// export default PDFDocument;

import React from 'react';
import ReactDOM from 'react-dom';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import seal from '../../../../../assets/images/seal.png'
import CompanyName from '../../../../../assets/images/companyName.png'
import CompanyLogo from '../../../../../assets/images/companyLogo.png'

// Register font for PDF
Font.register({
  family: 'Helvetica',
  src: 'https://fonts.gstatic.com/s/helvetica/v12/1Pt_g8LJR9RzqFFUhqV7og.woff2',
});

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  headers: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: 5
  },
  name:{
     paddingBottom:5
  },
  section: {
    fontSize: 12,
    marginTop: 15,
    marginBottom: 15,
    display:'flex',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '16.6%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 10,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  },
  textRight: {
    textAlign: 'right',
    paddingBottom: 5
  },
  side:{
    fontSize: 12,
  },
  styImage:{
    display:"flex",
    justifyContent: "space-between",
    alignContent:"flex-start",
    flexDirection:"row",
    width: "100%"
  }
});

const DownloadPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation='portrait'>
      <View style={styles.header}>
        <View style={styles.styImage}>
          {/* <Text style={styles.title}>Yanama Trading Company</Text> */}
          <Image src={CompanyName} style={{width: "60%" }}></Image>
          <Image src={CompanyLogo} style={{width: "20%" }}></Image>
        </View>
      </View>
      <View style={styles.headers}>
        <Text style={styles.title}>PURCHASE ORDER : {data.ref || 'N/A'}</Text>
        <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
        {/* <View>
          <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
          <Text style={styles.side}>PO#: {data.ref || 'N/A'}</Text>
        </View> */}
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>VENDOR</Text>
        <Text style={styles.name}>{data.vendorName || 'N/A'}</Text>
        <Text>{data.supplierAddress || 'N/A'}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>SHIP TO</Text>
        <Text>{data.deliveryAddress || 'N/A'}</Text>
      </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.tableHeader]}>
          {['S/N', 'Product', 'Unit', 'Ordered Qty', 'Request Qty', 'Price', 'Total'].map((header, index) => (
            <View key={index} style={styles.tableCol}>
              <Text>{header}</Text>
            </View>
          ))}
        </View>
        {data?.products?.map((product, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text>{index + 1}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{product.product_name || 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{product.product_unit || 'N/A'}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{product.orderedQty || 0}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{product.requestedQty || 0}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{product.price || 0}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{(product.orderedQty * product.price).toFixed(2)}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.section}>
        <Text style={styles.textRight}>TOTAL: {data.totalPrice.toFixed(2)}</Text>
        <Text style={styles.textRight}>NET TOTAL: {data.totalPrice.toFixed(2)}</Text>
      </View>
      <View style={{
        width:'100%',
        flexDirection:"row",
        justifyContent:"space-between"
      }}>
      <View style={styles.section}>
        <Text style={styles.title}>Terms & Conditions</Text>
        <Text style={styles.name}>Price: SAR</Text>
        <Text style={styles.name}>Delivery Period: Immediate</Text>
        <Text style={styles.name}>Delivery: {data.deliveryTerms || 'N/A'}</Text>
        <Text style={styles.name}>Payment: {data.paymentTerms || 'N/A'}</Text>
      </View>
      <View style={{fontSize: 12,
                     marginTop: 15,
                     marginBottom: 15,
                     marginRight: 8
                     }} >
        <Text style={{paddingBottom: 8, paddingRight:4}}>Authorized By</Text>
        <Image src={seal} style={{width: "100%"}}></Image>
      </View>
      </View>
      {/* <View style={{
        width:'100%',
        flexDirection:"row",
        justifyContent:"space-between"
      }}> */}
      <View style={styles.section}>
        <Text>Note: {data.notesFromCustomer || 'N/A'}</Text>
      </View>
      {/* <View style={styles.section}>
        <Text>Authorized By</Text>
        <Image src={seal} style={{width: "80%"}}></Image>
      </View> */}
      {/* </View> */}
    </Page>
  </Document>
);

export default DownloadPDF;

