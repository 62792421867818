import React, { useState } from 'react'
import ModalContainer from '../../../../Components/Others/ModalContainer';
import ViewMore from '../../../../Components/Icons/ViewMore';
import { Link } from 'react-router-dom';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';

const ViewMoreCard =  ({
    data,
    fetcher,
    id,
    removevalue,
    url,
    moduleName,
    from,
    viewLink,
    editLink
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { handle } = useDeleteData(fetcher);
    const handleDelete = async () => handle(url + `${id}`,setIsModalOpen);

    // console.log("ssss",fetcher);
    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let Edit = editLink ? true : null
    let Comment = editLink ? false : null

    let Delete = true

    const toast = useToast();
  const [status, setStatus] = useState(data?.status || 0); 
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  
  const handleStatusChange = () => {
    setStatusModalOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    // console.log("ID being used in API call:", data?.userId);
    try {
       await axiosInstance.put(`/user/update-status/${data?.userId}`, {
          status: status === 1 ? 0 : 1,
       });
       
       setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1));
 
       toast.open({
          type: "success",
          message: "Success",
          description: "Status updated successfully.",
       });
 
       setStatusModalOpen(false);
       fetcher();
    } catch (err) {
       toast.open({
          type: "error",
          message: "Error",
          description: err.response?.data?.error || err.response?.data?.message || "Network error.",
       });
    }
 };
 


    return (
        <>
            <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                    <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                        <ViewMore />
                    </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[81px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
              
                <li className='w-[100px] bg-black block m-0 cursor-pointer'>
                            <span
                                className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                                onClick={handleStatusChange}
                            >
                                {status == 1 ? "In-Active" : "Active"}
                            </span>
                        </li>
                
                    <li className=' w-[100px] bg-black block m-0'>
                        <Link className=' text-black hover:text-black border-none' to={viewLink + `${id}`} state={{ data: data }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            View
                        </span></Link>
                    </li>
                    {Edit && <li className=' w-[100px] bg-black block m-0'>
                        <Link className=' text-black hover:text-black border-none' to={editLink + `${id}`} state={{ data: data, from: from }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            Edit
                        </span></Link>
                    </li>}
                    {
                        Delete &&
                        <li className=''>
                            <a
                                className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                                // to='/members'
                                href="#"
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            </div>
            <ModalContainer
                visiable={isModalOpen}
                title="Warning"
                content={`Are you sure, ${removevalue} will removed from ${moduleName}!`}
                handleClick={() => handleDelete()}
                closeModal={() => setIsModalOpen(false)}
            />
             <ModalContainer
        visiable={statusModalOpen}
        title="Warning"
        content={`Are you sure you want to ${
        //   status===1?"In-Active":"Active"
          status === 1 ? "Deactivate" : "Activate"
        } this user?`}
        handleClick={handleConfirmStatusChange}
        closeModal={() => setStatusModalOpen(false)}
      />
        </>
    );
};

export default ViewMoreCard