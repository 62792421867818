import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ApprovalStatus = () => {
  return (
    <SettingsMetaData propertyName={"ApprovalStatus_PackingList"} viewPropertyName={"Approval Status"} />

  )
}

export default ApprovalStatus