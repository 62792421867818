import React, { useEffect, useState } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-color"; // Import XLSX from xlsx-color
import { CreateButton } from '../../../../Components/Utils/Buttons';
import NuDate from '../../../../Components/Utils/NuDate';
import NuAmount from '../../../../Components/Utils/NuAmount';


function DownloadXlsx({ fileName,header, data, standardname="",company="" }) {
    const [csvData, setCSVData] = useState([]);


    useEffect(() => {
        if (data) {
            let finalData = [];
            data.forEach((item) => {
                let tempData = {};
                header.forEach((i) => {
                    if(i?.isCondition){
                        console.log("condition");
                        tempData[i.title] = item[i.field]?"Yes":"No"    
                    }else if(i?.type === "date-time"){
                        tempData[i.title] = (item[i.field] !=="" && item[i.field] !=="None" && NuDate({value:item[i.field], format:"DD-MMM-YYYY hh:mm a"}))||""
                    }else if(i?.type === "date"){
                        console.log("data");
                        tempData[i.title] = (item[i.field] !=="" && item[i.field] !=="None" && NuDate({value:item[i.field], format:"DD-MMM-YYYY"}))||"----"
                    }else if(i?.type === "time"){
                        tempData[i.title] = (item[i.field] !=="" && item[i.field] !=="None" && NuDate({value:item[i.field], format:"hh:mm a"}))||""
                    }else if(i?.type === "amount"){
                        tempData[i.title] = (item[i.field] !=="" && item[i.field] !=="None" && NuAmount({value:item[i.field]}))||""
                    }else{
                        tempData[i.title] = item[i.field];
                    }
        
                });
                finalData.push(tempData);
                console.log(tempData);
            });
            setCSVData(finalData);
        }
    }, [data]);

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = () => {
        const wb = XLSX.utils.book_new();

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet([
            [],
            [],
            [],
            [], // Empty row for spacing
            Object.keys(csvData[0]), // Headers
            ...csvData.map(obj => Object.values(obj)), // Data rows
            [],
            [],
            [],
            [],
            ]);

        const headers = Object.keys(csvData[0]);
        headers.forEach((header, index) => {
            console.log("iNdx",index);
            const headerCellRef = XLSX.utils.encode_cell({ r: 4, c: index });
            console.log("ssss",headerCellRef);
            if (!ws[headerCellRef]) return;
            ws[headerCellRef].s = { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true } ,
                alignment: { horizontal: "center" },
                border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } }
            };
        });


   
        // const startRow = 4; 
        // const startCol = 0; 
        // csvData.forEach((rowData, rowIndex) => {
        //     Object.keys(rowData).forEach((key, colIndex) => {
        //         const cellRef = XLSX.utils.encode_cell({ r: startRow + rowIndex+1, c: startCol + colIndex });
        //         if (!ws[cellRef]) return;
        //         ws[cellRef].s = { border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } } };
        //     });
        // });

        
        ws['!cols'] = [{ width: 20}, { width: 20 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 }];
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <CreateButton handleClick={exportToCSV} svg={false}  label="Download as XLSX" />

        // <button onClick={exportToCSV}>Download</button>
    );
}

export default DownloadXlsx;
