import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';

const ViewContracts = () => {
  // const { patientsViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={
            [
              {
              name: "Contracts",
              link: "/procurement/contracts"
            },
            {
              name: "View Contracts"
            }]
          }
        />
        <div className=" flex gap-2">
          <Link to={'/procurement/contracts'}><BackButton link={true} /></Link>
          <Link to={`/procurement/contracts/edit/${data?.id}`} state={{ data: data, from: `/procurement/contracts/view/${data?.id}` }}><EditButton height='8' /></Link>
        </div>
        
      </div>
      <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4'>
    {/* <h2 className='text-base font-normal text-[#111537] p-3'>Customers</h2> */}
    <NormalHeading title={"Contracts Details"} />
    <div className='flex'> 
         <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
                <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Contracts ID</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.id || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Contract Title</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.contractTitle || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Customer Name</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.customerName || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Scope Of Work</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.scopeOfWork || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Signed On</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.signedOn || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Start Date</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.startDate || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>End Date</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.endDate || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Project Duration</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.projectDuration || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Contract Amount</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.contractAmount || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/2 py-2 text-sm font-medium text-[#111537]'>Additional Amount</p>
               <p className='w-1/2 py-2 text-sm  text-[#606279]'>{data?.additionalAmount || '-'}</p>
            </div>
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
             
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Total Amount</p>
               <p className='w-1/4 py-2 text-sm  text-[#606279]'>{data?.totalAmount || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Status</p>
               <p className='w-1/4 py-2 text-sm  text-[#606279]'>{data?.status}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Receivables</p>
               <p className='w-1/4 py-2 text-sm  text-[#606279]'>{data?.receivables || '-'}</p>
            </div>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
              <p className='w-1/4 py-2 text-sm font-medium text-[#111537]'>Comments</p>
               <p className='w-1/4 py-2 text-sm  text-[#606279]'>{data?.comments || '-'}</p>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>

  )
};
export default ViewContracts;