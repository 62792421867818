import React from 'react'

function ViewMore() {
  return (
    <svg width="13" height="4" viewBox="0 0 13 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.29639 3.57256C3.12481 3.57256 3.79639 2.90098 3.79639 2.07256C3.79639 1.24413 3.12481 0.572556 2.29639 0.572556C1.46796 0.572556 0.796387 1.24413 0.796387 2.07256C0.796387 2.90098 1.46796 3.57256 2.29639 3.57256Z" fill="#999999"/>
    <path d="M6.79639 3.57256C7.62481 3.57256 8.29639 2.90098 8.29639 2.07256C8.29639 1.24413 7.62481 0.572556 6.79639 0.572556C5.96796 0.572556 5.29639 1.24413 5.29639 2.07256C5.29639 2.90098 5.96796 3.57256 6.79639 3.57256Z" fill="#999999"/>
    <path d="M12.7964 2.07256C12.7964 2.90098 12.1248 3.57256 11.2964 3.57256C10.468 3.57256 9.79639 2.90098 9.79639 2.07256C9.79639 1.24413 10.468 0.572556 11.2964 0.572556C12.1248 0.572556 12.7964 1.24413 12.7964 2.07256Z" fill="#999999"/>
    </svg>
  )
}

export default ViewMore