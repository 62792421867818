import React from 'react'
import Stock from './Components/Stock'
import Approvals from './Components/Approvals'
import PoSupplier from './Components/PoSupplier'
import PurchaseOrderSupplier from './Components/PurchaseOrderSupplier'
import OrderReceived from './Components/OrderReceived'
import OrderDelived from './Components/OrderDelived'
import Request from './Components/Request'
import Returns from './Components/Returns'

function StoreIndex() {
  return (
    <div className="w-[100%] h-[calc(100vh-90px)] py-2 ">
<div className="w-full grid grid-cols-5 gap-4 ">
    <Stock/>
    <Approvals/>
    </div>
    <div className="w-full grid grid-cols-4 gap-4   mt-4">
    <PoSupplier/>
    <PurchaseOrderSupplier/>
    </div>
    <div className="w-full grid grid-cols-4 gap-4 ">
    <OrderReceived/>
    <OrderDelived/>
    </div>
    <div className="w-full grid grid-cols-4 gap-4 ">
    <Returns/>

    <Request/>
    </div>
    </div>
  )
}

export default StoreIndex