import React from "react";

function Doc({color="#5191F5"}) {
  return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="30" rx="5" fill={color} fillOpacity="0.1"/>
<path d="M9 25H6.33333C5.97971 25 5.64057 24.8595 5.39052 24.6095C5.14048 24.3594 5 24.0203 5 23.6667V6.33333C5 5.97971 5.14048 5.64057 5.39052 5.39052C5.64057 5.14048 5.97971 5 6.33333 5H15.7813C15.9565 4.99992 16.1299 5.03434 16.2917 5.1013C16.4535 5.16825 16.6006 5.26643 16.7244 5.39022L21.9431 10.6098C22.1929 10.8597 22.3333 11.1986 22.3333 11.552V15.6667" stroke="#5191F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3333 11.6667H17C16.6463 11.6667 16.3072 11.5262 16.0571 11.2761C15.8071 11.0261 15.6666 10.687 15.6666 10.3333V5" stroke="#5191F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.6666 24.9997C12.3739 24.9997 13.0521 24.7187 13.5522 24.2186C14.0523 23.7185 14.3333 23.0403 14.3333 22.333V20.9997C14.3333 20.2924 14.0523 19.6142 13.5522 19.1141C13.0521 18.614 12.3739 18.333 11.6666 18.333V24.9997Z" stroke="#5191F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3333 18.333C17.9797 18.333 17.6406 18.4735 17.3905 18.7235C17.1405 18.9736 17 19.3127 17 19.6663V23.6663C17 23.8414 17.0345 24.0148 17.1015 24.1766C17.1685 24.3384 17.2667 24.4853 17.3905 24.6092C17.5143 24.733 17.6613 24.8312 17.8231 24.8982C17.9849 24.9652 18.1582 24.9997 18.3333 24.9997C18.5084 24.9997 18.6818 24.9652 18.8436 24.8982C19.0053 24.8312 19.1523 24.733 19.2761 24.6092C19.4 24.4853 19.4982 24.3384 19.5652 24.1766C19.6322 24.0148 19.6667 23.8414 19.6667 23.6663V19.6663C19.6667 19.3127 19.5262 18.9736 19.2761 18.7235C19.0261 18.4735 18.687 18.333 18.3333 18.333Z" stroke="#5191F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25 18.333C24.6498 18.333 24.3031 18.402 23.9796 18.536C23.656 18.67 23.362 18.8664 23.1144 19.1141C22.8668 19.3617 22.6704 19.6557 22.5364 19.9792C22.4023 20.3027 22.3334 20.6495 22.3334 20.9997V22.333C22.3334 22.6832 22.4023 23.03 22.5364 23.3535C22.6704 23.677 22.8668 23.971 23.1144 24.2186C23.362 24.4662 23.656 24.6627 23.9796 24.7967C24.3031 24.9307 24.6498 24.9997 25 24.9997" stroke="#5191F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  );
}

export default Doc;
