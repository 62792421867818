import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axiosInstance from "../../Services/axiosCommon";
import { useToast } from "../Others/ToastServices";
import { NuInputSetting } from "../Utils/Input";
import { TextMediumXS } from "../Text/MediumText";


const SettingsMetaData = ({propertyName,viewPropertyName}) => {
  const [data, setData] = useState([]);
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    property:`${propertyName}`,
    value:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
      // category: yup.string().required('Status is Required'),
    // }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance.post(`/dropdown/create-dropdown`, value)
              .then(res => {               
                  if (res.data) {
                      console.log("Created", resetForm)
                      resetForm();
                      GetData();
                      toast.open({
                        type:"success",
                        message: `${createNew?.property}  Added successfully`,
                        // description:'',
                        // placement: 'bottomRight',
                    });
                  }
                  else {
                      console.log("Failed")
                      toast.open({
                        type:"error",
                        message: `${createNew?.property} Added failed`,
                        // description: response.data.msg || 'Network error',
                        // placement: 'bottomRight',
                    });
                  }
              })    
    },
  });
  const GetData = async () => {
    await axiosInstance.get(`/dropdown/dropdown-details/${createNew?.property}`).then(res => {
        console.log("data", res.data?.data[createNew?.property])
        let data = res.data?.data[createNew?.property];
        let tempData = [];
        if(data){
        data.forEach ((item) => {
            let tempObject = {value:item,label:item};
            tempData.push(tempObject)
        })
      }else{
        console.error("Brand Status");
      }
        // res.data?.data?.Brand && setnotes(res.data?.data?.Brand)
        res.data?.data[createNew?.property] && setData(tempData)          
    })
}

const handleDelete = (value) => {
  axiosInstance.delete(`/dropdown/remove-value/${createNew?.property}/${value}`)
    .then(res => {
      if (res?.status == 200) {
        GetData();
        toast.open({
          type:"success",
          message: `${createNew?.property}  Deleted successfully`,
          // placement: 'bottomRight',
        });
      }
      else {
        console.log("Failed")
        toast.open({
          type:"error",
          message: `${createNew?.property} Deleted failed`,
          // placement: 'bottomRight',
        });
      }
    })
}

useEffect(() => {
  GetData();
}, [])
  return (
    <div className=" w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className={`w-full text-xs`}>
          <div className="px-3 mb-1">
            <label className="font-medium text-textColor-500">
               {viewPropertyName}
            </label>
            <NuInputSetting
              type="text"
              placeholder={viewPropertyName}
              formik={formik}
              name="value"
              // width="w-full md:w-1/3 md:-mt-2"
            />
          </div>
        </div>
      </form>
      <div className=" px-3">
        <div className="w-full rounded-lg h-44 bg-[#EEF4F6] overflow-x-hidden overflow-y-auto">
          <ul className="">
            {data.map((List, idx) => {
                return (
            <li
              className=" m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50"
              key={idx}
            >
              {/* {"Testing Data" || "-"} */}
              <TextMediumXS color={"text-[#009858]"} content={List.value || "-"} />
              {List?.value && (
              <span
                onClick={() => {
                  handleDelete(List?.value);
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
                {/* <FontAwesomeIcon icon={faXmark} /> */}
              </span>
               )} 
            </li>
             );
              })} 
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SettingsMetaData;
