import CryptoJS from "crypto-js";

function DecryptData(dncToken){
    let value = localStorage.getItem(dncToken)
    if(value){
      let data = CryptoJS.AES.decrypt(value,process.env.REACT_APP_ACCESS_KEY)
      let final = data.toString(CryptoJS.enc.Utf8);
      let before = JSON.parse(final);
      return before;
    }else{
      return "";
    }
  }

export default DecryptData;