import React from 'react'
import Breadcrumb from '../../../../Components/Utils/Breadcrumb'
import { BackButton } from '../../../../Components/Utils/Buttons'
import CreateSeal from './CreateSealAndSignature'
import UserSignature from './UserSignature'

const MainSealPage = () => {
        return(
          <div className='w-full h-full px-4 py-2'>
            <div className='w-full flex justify-between items-center'>
            <Breadcrumb
              items={[
                {
                  name: "Settings",
                },
                {
                  name: "Seal And Signature"
                }
              ]}
            />
            <BackButton/>
            </div>
            <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
                  <div className="p-3"><CreateSeal/></div>
                  <div className="p-3"><UserSignature/></div>
                  </div>
            </div>
        )
      }

export default MainSealPage