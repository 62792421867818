import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseDropDown = (url) => {
    const [data, setData] = useState([]);

    async function getData() {
        axiosInstance.get(url).then(res => {
            // console.log('daataa',res.data?.data);
            let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setData(tempData);
        }).catch(err => {
            console.log(err, "err");
        })
    }
    //     try {
    //       const res = await axiosInstance.get(url);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    useEffect(() => {
        getData();
    }, []);

    return { data,getData };
};

export default UseDropDown;