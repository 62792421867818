import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from 'yup';

function ViewSQRevision() {
  const { value: Validity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Validity_Supplierquotes');
  const { value: PaymentTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'PaymentTerms_Supplierqoutes');
  const { value: LeadTime } = useFetchMataData(`/dropdown/dropdown-details/`, 'LeadTime_Supplierqoutes');
  const { value: DeliveryTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'DeliveryTerms_Supplierqoutes');
  const { value: Availability } = useFetchMataData(`/dropdown/dropdown-details/`, 'Availability_Supplierqoutes');
  const { value: paymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`, 'paymentMethod_supplierquotes');
  const { value: Status } = useFetchMataData(`/dropdown/dropdown-details/`, 'Status_Supplierqoutes');

    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
     
      status:"",
      paymentMethod:"",
      leadTime:"",
      validity:"",
      availability:"",
      paymentTerms:"",
      deliveryTerms:""
      // sqId:""
    });
    const [params, setParams] = useState({
      page:page,
      per_page:pageCount,
      search:"",
      status:"",
      paymentMethod:"",
      leadTime:"",
      validity:"",
      availability:"",
      paymentTerms:"",
      deliveryTerms:"",
      sqId:"",
    });

    const clearFilter = ()=> {
      setParams({...params,status:"",
        paymentMethod:"",
        leadTime:"",
        validity:"",
        availability:"",
        paymentTerms:"",
        deliveryTerms:""})
      formik.values.status=""
      formik.values.paymentMethod=""
      formik.values.leadTime=""
      formik.values.validity=""
      formik.values.availability=""
      formik.values.paymentTerms=""
      formik.values.deliveryTerms=""


        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // console.log("ffff",value);
        setParams({...params,status:value.status, paymentMethod:value.paymentMethod,leadTime:value.leadTime,validity:value.validity,availability:value.availability,paymentTerms:value.paymentTerms,deliveryTerms:value.deliveryTerms})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
          paymentMethod:"",
          leadTime:"",
          validity:"",
          availability:"",
          paymentTerms:"",
          deliveryTerms:""
        });
      }
    })
    const { data, totalCount, getData } = UseGetDataSearch( '/supplier-quotations/',params);
  
    const defaultHeader = [
      {
        title: "ID",
        width: "5%",
        field: "id",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Quotation Reference",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/procurement/supplier-quotes/viewsqcreate/',
        field: "ref",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Quotation Received",
        width: "10%",
        field: "dateReceived",
        type: "date",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Deliver Terms",
        width: "10%",
        field: "deliverTerms",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Total Price",
        width: "10%",
        field: "totalPrice",
        textAlign: "left",
        rowAlign: "left",
      },
      // {
      //   title: "Product Availability",
      //   width: "10%",
      //   field: "availability",
      //   textAlign: "left",
      //   rowAlign: "left",
      // },
      {
        title: "Quotation Validity",
        width: "10%",
        field: "validity",
        textAlign: "left",
        rowAlign: "left",
      },
     
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "left",
        rowAlign: "left",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/supplier-quotations/revision/`,
          moduleName:"Revision",
          from:`/procurement/supplier-quotes`,
          editLink:"/procurement/supplier-quotes/viewsqcreate/",
          viewLink:"/procurement/supplier-quotes/viewsqIdrevision/"
  
        }
      },
    ];
    const availableHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "id",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Quotation Reference",
        width: "10%",
        isLink: true,
        id: "id",
        link: '/procurement/supplier-quotes/viewsqcreate/',
        field: "ref",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Quote Date",
        width: "10%",
        field: "quoteDate",
        type: "date",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Quotation Received",
        width: "10%",
        field: "dateReceived",
        type: "date",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Deliver Terms",
        width: "10%",
        field: "deliverTerms",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Payment Terms",
        width: "10%",
        field: "paymentTerms",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Payment Method",
        width: "10%",
        field: "paymentMethod",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        
        title: "Total Price",
        width: "10%",
        field: "totalPrice",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Supplier",
        width: "10%",
        field: "vendorName",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Purchase Requests",
        width: "10%",
        field: "prRef",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "RFQ ",
        width: "10%",
        field: "rfqRef",
        textAlign: "left",
        rowAlign: "left",
      },
     
      // {
      //   title: "Product Availability",
      //   width: "10%",
      //   field: "availability",
      //   textAlign: "left",
      //   rowAlign: "left",
      // },
      {
        title: "Quotation Validity",
        width: "10%",
        field: "validity",
        textAlign: "left",
        rowAlign: "left",
      },
      // {
      //   title: "Special Notes",
      //   width: "10%",
      //   field: "specialNotes",
      //   textAlign: "left",
      //   rowAlign: "left",
      // },
      {
        title: "Lead Time",
        width: "10%",
        field: "leadTime",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Prepared By",
        width: "10%",
        field: "preparedByName",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Reviewed By",
        width: "10%",
        field: "reviewedByName",
        textAlign: "left",
        rowAlign: "left",
      },
      {
        title: "Approved By",
        width: "10%",
        field: "approvedByName",
        textAlign: "left",
        rowAlign: "left",
      },
    
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "left",
      //   rowAlign: "left",
      // },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "left",
        rowAlign: "left",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/supplier-quotations/revision/`,
          moduleName:"Revision",
          from:`/procurement/supplier-quotes`,
          editLink:"/procurement/supplier-quotes/viewsqcreate/",
          viewLink:"/procurement/supplier-quotes/viewsqIdrevision/"
  
  
        }
      },
    ];
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
      
     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb 
       items={[
        { name: "Supplier Quotations" },

        { name: "Revision"},
          
      
      
  ]} />
      <div className=' flex gap-2'>
        
       <SearchBar handleChange={(e) => setParams({...params,search:e.target.value})} />
       <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
       <BackButton/>
       {/* <CreateButton label='Import' /> */}
       {/* <Link to="/procurement/supplier-quotes/create"><CreateButton label='Supplier Quotes'/></Link>  */}
      </div>
      </div>
  
      <CustomDataTable 
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeader}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Revision List'
        storageName="SqRevisionTable"
        />
         {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Revision</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              type="text"
              label="Quotation Validity"
              placeholder="Validity"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full"
            />
            {/* <FormikSelectTwo
              type="text"
              label="Product Availability"
              placeholder="Availability"
              Options={Availability}
              formik={formik}
              name="availability"
              width="w-full"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Delivery Terms"
              placeholder="Delivery Terms"
              Options={DeliveryTerms}
              formik={formik}
              name="deliverTerms"
              width="w-full"
            />
            <FormikSelectTwo
              type="text"
              label="Payment Terms"
              placeholder="Payment Terms"
              Options={PaymentTerms}
              formik={formik}
              name="paymentTerms"
              width="w-full "
            />
            <FormikSelectTwo
              type="text"
              label="Lead Time"
              placeholder="Lead Time"
              Options={LeadTime}
              formik={formik}
              name="leadTime"
              width="w-full "
            />
             <FormikSelectTwo
              type="text"
              label="Payment Method"
              placeholder="Payment Method"
              Options={paymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full"
            />
         <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={Status}
              formik={formik}
              name="status"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton
                      name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
    )
  }
export default ViewSQRevision