import React from 'react';

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-hidden">
      <div className=" bg-white rounded-lg p-4 w-[60%] h-[50%]  overflow-y-scroll">
        {/* <button className="text-right text-red-500" onClick={onClose}>
          Close
        </button> */}
        <button
            className="absolute top-2 right-2 text-white bg-red-600"
            onClick={onClose}
     >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
