import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, { NuDate, NuInput, NuSelect, NuTextArea } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import axiosInstance from "../../../../Services/axiosCommon";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import NuDateDisplay from "../../../../Components/Utils/NuDate"
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

const EditPayables = () => {
  const {value:Category} = useFetchMataData(`/dropdown/dropdown-details/`,'Category__Payable')
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_Payable')
  const {value:AccountType } = useFetchMataData(`/dropdown/dropdown-details/`,'AccountType__Payable')

  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;

  const [createNew, setCreateNew] = useState({
    description: data?.description || "",
    amount: data?.amount || "",
    category: data?.category || "",
    accountType: data?.accountType || "",
    payTo: data?.payTo || "",
    // daysElapsed: data?.daysElapsed || "",
    dueDate: (data?.dueDate && NuDateDisplay({value:data?.dueDate,format:"YYYY-MM-DD"}) )||"",
    // paidAmount: "",
    // balanceAmount: data?.balanceAmount || "",
    // paymentMethod: "",
    // paidOn: "",
    // comments: "",
    invoiceId: data?.invoiceId || "",
    status: data?.status || ""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      editData(`/payable/edit-payable/${data?.payableId}`, value, resetForm, from)

    },
  });
  const { data: invoiceIncomming } = UseDropDown('/invoices-incoming/drop-down');
  const { data: customer } = UseDropDown('/customers/drop-down');
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            { name: "Payment",
            link: "/payments/payables" },  
          {
            name: "Payable",
            link: "/payments/payables"
          },
          {
            name: "Edit Payables"
          }]
         }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-lg overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
              label="Invoice"
              placeholder="Choose"
              Options={invoiceIncomming}
              formik={formik}
              name="invoiceId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Amount"
              placeholder="amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Category"
              placeholder="Choose"
              Options={Category}
              formik={formik}
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Account Type"
              placeholder="Choose"
              Options={AccountType}
              formik={formik}
              name="accountType"
              width="w-full "
            />
            {/* <FormikSelectTwo
              label="Account Type"
              placeholder="Choose"
              Options={[
                { value: "sbi", label: "SBI" },
                { value: "canara", label: "CANARA" },
              ]}
              formik={formik}
              name="accountType"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              label="Pay To"
              placeholder="Choose"
              Options={customer}
              formik={formik}
              name="payTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
              type="number"
              label="Days Elapsed"
              placeholder="daysElapsed"
              formik={formik}
              name="daysElapsed"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuDate
              label="Due Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="dueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuInput
              type="number"
              label="Paid Amount"
              placeholder="Paid Amount"
              formik={formik}
              name="paidAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuInput
              type="number"
              label="Balance Amount"
              placeholder="Balance Amount"
              formik={formik}
              name="balanceAmount"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <NuInput
              type="text"
              label="Description"
              placeholder="description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            />
    <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
                        <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPayables;
