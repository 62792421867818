import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Unit = () => {
  
  return (
    <SettingsMetaData propertyName={"EquipmentUnit"} viewPropertyName={"Equipment Unit"}/>
  );
};

export default Unit;
