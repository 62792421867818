import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Condition = () => {
  return (
    <SettingsMetaData propertyName={"InventoryCondition"} viewPropertyName={"Inventory Condition"} />

  )
}

export default Condition