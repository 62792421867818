import React, { useEffect, useState } from "react";
import {
  NormalHeading,
  SmallHeading,
} from "../../../../Components/Utils/Heading";
import { BackButton, CancelButton, CreateButton, EditButton } from "../../../../Components/Utils/Buttons";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import SideModal from "../../../../Components/Others/SideModel";
import NuDate from "../../../../Components/Utils/NuDate";
import Modal from "../../../../Components/Utils/AttachmentView";
import ViewPdf from "./ViewPdf";
import Comments from "./CommentsPr";
import PDFLinkPR from "./pdf/PDFLinkPR";
import { Tab, Tabs } from "../../../../Layouts/MainPage/Tabs";
import axiosInstance from "../../../../Services/axiosCommon";
import CommonComments from "../../../../Components/Utils/commonComments";
import axios from "axios";
import capitalizeFirstLetter from "../../../../Components/Utils/Funcs";
import ApprovalComponent from "../../../../Components/Utils/approvalComponent";
import PdfComponent from "../../../../Components/Utils/PdfComponent";
import PRPdfOther from "../../ViewPdfTemplate/PRPdfOther";
import PDFViewer from "../../../../Components/Utils/PDFViewer";
import FilesView from "../../../../Components/Utils/FilesView";

function ViewPr() {
  const { prViewId } = useParams();
  const location = useLocation();
  const [data,setData] = useState(location.state.data);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [showPdfComponent, setShowPdfComponent] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      setFilePathList(data?.attachments?.length>0 ? data?.attachments : []);
      // console.log('image',createNew?.attachments);
      // setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    console.log("llll", filterList);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }
  const handlePdfIconClick = () => {
    setShowPdfComponent(true);
  };
  const closeFilterDialog = () => {
    setShowPdfComponent(false);
  };


  const [revData,setRevData] = useState([])

  useEffect(() => {
    if(prViewId)getRevData(prViewId)
    // getPDF(prViewId)
  },[prViewId])

  function getRevData(id){
    axiosInstance.get(`/PR/products-lists/${id}`).then(res=>{
      console.log("ree",res?.data?.data);
      if(res?.data?.data)setRevData(res?.data?.data)
      
    }).catch(err=>{
      console.log("err", err);
    })
  }

 
  const id = prViewId; 
  const getUrl = `/PR/get-status`;
  const putUrl = `/PR/edit-status`; 
  const statusUrl = '/PR/all-status';



  // const pdfId = 'some-id';
  // const pdfUrl = '/PR/download-pdf';

  const downloadPDF = async () => {
    try {
        const response = await axiosInstance.get(`/PR/download-pdf/${prViewId}`, {
            responseType: 'blob' // Important to specify the response type as blob
        });

        // Create a Blob from the response
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `${data.ref}.pdf`; // The filename you want for the downloaded file

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
};


  return (
    <div className="w-full h-full py-2 px-4 mb-3 relative">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            //   { name: "Procurement" ,
            // link:'#'},

            {
              name: "Purchase Request",
              link: "/procurement/purchase-requests/",
            },

            { name: "View" },
          ]}
        />
        <div className=" flex gap-2">
          {/* <Link
            className="items-center text-[14px] py-2 text-gray-700 underline hover:text-blue-600"
            to={`/procurement/purchase-requests/viewquotes/${prViewId}`}
          >
            View Quotes
          </Link> */}
          <Link to={"/procurement/purchase-requests"}>
            <BackButton link={true} />
          </Link>
          <CreateButton svg={false} handleClick={downloadPDF} label="Download as PDF" />
          {/* <button onClick={}>PDF Download</button> */}
          {/* <PDFLinkPR
            data={data}
            fileName={`Purchase_Request_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          /> */}
 {/* <PdfComponent id={pdfId} url={pdfUrl} /> */}
          <Link
            to={`/procurement/purchase-requests/edit/${prViewId}`}
            state={{
              data: data,
              from: `/procurement/purchase-requests/view/${prViewId}`,
            }}
          >
            <EditButton height="8" />
          </Link>
          {/* <button
            className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`}
            onClick={handleOpenCommentsModal}
          >
            Comments
          </button> */}
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10">  
             <div className="grid  items-center px-2 w-[90%]">
              <div className="flex text-[14px] mb-2 text-[#111537] font-semibold w-full">
            <p className="w-[20%]">{data.ref}</p>
            <p className="w-[60%]">: {data?.description || 'None'}</p>
            </div>
            <div className="flex gap-4">
            <p className="text-xs text-[#111537]">Date : {data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
         </p>
         <p className="text-xs text-[#111537]">Created By: {data.preparedByName ? capitalizeFirstLetter(data.preparedByName) : 'None'}
         </p>
         <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p>

         </div>
            {/* <NormalHeading title={"Purchase Request"} /> : */}
            {/* <p className="text-xs grid pt-5"> {data.ref} <span className="font-light text-[11px] px-2">{data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</span></p>
            */}

          </div>
          <div className="flex gap-2 w-[10%] justify-end">
          {/* <p className=" p-2  bg-[#F0DE36] text-white items-center rounded-md text-xs h-8 ">
                {data.status || 'None'}
              </p> */}
              {data.approvalStatus !== 'verified'&&<Link
              className="text-xs cursor-pointer text-white bg-[#F0DE36] rounded-md inline-flex w-auto h-7 px-2 mr-3 flex-shrink-0 items-center justify-center"
              to={`/procurement/purchase-requests/viewcreate/${prViewId}`}
              state={{
                data: data,
                from: `/procurement/purchase-requests/view/${prViewId}`,
              }}
            >
              Rev.00
            </Link>}
          </div>     
            {/* <Link
              className=" cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2  flex-shrink-0 items-center justify-center"
              to={`/procurement/purchase-requests/viewrevision/${prViewId}`}
            >
              View Revision
            </Link> */}
          {/* </div>  */}
        </div>
          <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8 ">

        <div className=" w-full flex flex-wrap  px-2 border border-gary mt-2  rounded-lg ">
          <div className=" w-full grid grid-cols-2 gap-x-8 ">
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Customer
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data?.customerName}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                RFQ Assigneee
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data.rfqAssigneeNames && data.rfqAssigneeNames.map((item, index) => {
  return (
    <span key={index}>
      {item}{index !== data.rfqAssigneeNames.length - 1 ? ',' : ''}
    </span>
  );
})|| "Not Assigned"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Assigned Date
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data?.date ? (
                  <NuDate value={data?.date} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Dead Line
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data?.deadLine ? (
                  <NuDate value={data?.deadLine} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Sales Person
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                : {data.salesPersonName}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Cost Code
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data.costCode}
              </p>
            </div> */}

            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                RFQ Assigneee
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data.rfqAssigneeName}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Priority
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data.priority}
              </p>
            </div> */}
           
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                {" "}
                Day Elapsed{" "}
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                : {data.daysElapsed}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
               
              Revision Count
               
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                : {data.revisionCount}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Customer RFQ Reference
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data.customerRfqRef}
              </p>
            </div> */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Is Active
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {!data.isActive ? "No" : "Yes"}
              </p>
            </div> */}
            {data && (
              <>
               {/* {data.preparedByName && (
             <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Prepared By
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                : {data.preparedByName}
              </p>
            </div>
               )} */}
            {data.reviewedByName && (
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Reviewed By
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data.reviewedByName}
              </p>
            </div>
            )}
            {/* {data.approvedByName && (
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Approved By
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data.approvedByName}
              </p>
            </div>
            )} */}
            {data.verifiedByName && (
            <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Verified By
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
               : {data.verifiedByName}
              </p>
            </div>
            )} 
            </>
            )}
          {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approved Status</p>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#606279]'>{data.approvalStatus}</p>
                </div>   */}
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
                Status
              </p>
              <p className="w-1/2 py-2 text-xs pl-5 text-[#606279]">
                {data.status}
              </p>
            </div> */}

            {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-1/2 py-2 text-xs pl-5 text-[#606279]'>{data.comments}</p>
                </div> */}
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-[50%] ">
        <p className="  text-xs font-medium text-[#111537] p-1">
              Notes from Customer
            </p>
        <div className="  border border-gary  h-28 overflow-y-scroll rounded-lg items-center">
           
            <p className="  text-xs p-2 break-words text-[#606279]">
              {data.notesFromCustomer || "-"}
            </p>
          </div>
          </div>
          <div className="w-[50%] ">
          <p className=" p-1 text-xs font-medium text-[#111537]">
          PR Attachments
            </p>
          <div className=" border border-gary  overflow-y-scroll h-28 rounded-lg p-2">
          {/* <SmallHeading title={"PR Attachments"} /> */}

          <div className="w-full flex gap-2 flex-wrap ">
              {data?.attachments &&
                filePathList.map((imgs, idx) => {
                  console.log("img", imgs);
                  return (
                    <FilesView fileView={imgs} key={idx}/>
                    // <div
                    //   className=" w-10 h-11 relative flex justify-center items-center rounded-lg"
                    //   key={idx}
                    //   style={{
                    //     border: "1px solid gray",
                    //     borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))",
                    //   }}
                    // >
                    //   <div
                    //     className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                    //     onClick={() => handleImageClick(imgs)}
                    //   >
                    //     <img src={imgs?.file} alt={imgs?.file} />
                    //   </div>
                    // </div>
                  );
                })}
            </div>
</div>
</div>
{/* <div onClick={handlePdfIconClick} className="cursor-pointer m-2 border border-gary p-4 rounded-lg w-[10%]">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div> */}
</div>
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

        <Tabs>
{
  revData && revData.map((item,index)=>(
<Tab component={<>
  
  <div className="w-full bg-white  border border-gary  mt-2 rounded-lg ">
          <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg">
            {/* <p className=" w-[20%]  text-left text-white text-xs font-medium">
              {" "}
              Item Code
            </p> */}
              <p className="w-[5%]  text-left pl-2 text-white text-xs font-medium">S.No </p>

            <p className=" w-[60%]   text-left text-white text-xs font-medium">
              {" "}
              Product{" "}
            </p>
            <p className=" w-[10%] text-center text-white text-xs font-medium">
              {" "}
              Unit
            </p>
            {/* <p className=" w-[20%] text-left text-white text-xs font-medium">  Category</p> */}
            <p className=" w-[15%] text-center  text-white text-xs font-medium">
              {" "}
              Quantity
            </p>
          </div>
          {item.revProduct && item.revProduct?.map((List, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100"
              >
                 <p className="w-[5%]  text-left pl-2 text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                {/* <p className=" w-[20%] pl-1 text-left text-[#606279] text-xs font-light py-1">
                  {List?.item_code}
                </p> */}
                <p className=" w-[60%]   text-left text-[#606279] text-xs font-light py-1">
                  {List?.product_name}
                </p>
                <p className=" w-[10%] text-center text-[#606279] text-xs font-light py-1">
                  {List?.product_unit}
                </p>
                {/* <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                    {List?.product_category}
                  </p> */}

                <p className=" w-[15%] text-center  text-[#606279] text-xs font-light py-1">
                  {List?.quantity}
                </p>
              </div>
            );
          })}
        </div>

  </>} active={index==0} key={index} >
  {item?.revName}
</Tab>
  ))
}
</Tabs>
      
      
        </div>
        </div>
        {/* <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div> */}

        <div className="w-[30%] bg-white rounded-lg drop-shadow-lg py-1">
        <div className="h-[98%] overflow-y-scroll p-2 ">

        {/* <div className="border border-gray py-2 px-2 rounded-lg">
      <p className="text-[13px] text-[#111537] font-semibold">Approval
        <span>: {previousAction?.approvalStatus || "None"}</span>
      </p>
      {previousAction&& <div className="mt-2 items-center ">
          <p className="text-[13px] text-[#111537]">{previousAction?.action 
    ? capitalizeFirstLetter(previousAction.status) 
    : 'Declined'}  by {previousAction?.actionByName 
      ? capitalizeFirstLetter(previousAction.actionByName) 
      : 'N/A'}</p>
       <p className="text-[10px] text-[#606279]">
 
  {previousAction?.dateTime ? (
    <NuDate value={previousAction.dateTime} format="DD-MMM-YYYY hh:mm a" />
  ) : 'N/A'}
</p>
    </div>}
      {actionDetails && <div className="flex gap-4 mt-2 justify-around">
            <p
              className="px-2 py-1 bg-green-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
              onClick={() => verifyAction(prViewId,actionDetails.status)}
            >
              {actionDetails.action}
            </p>
            {actionDetails.status!=="resubmited" && <p
              className="px-2 py-1 bg-red-500 hover:cursor-pointer text-white items-center rounded-md text-xs h-7"
              onClick={() => declineAction(prViewId,actionDetails.status)}
            >
              Decline
            </p>}
          </div>}
     
    </div> */}

<ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl} refSetData={setData} data={data} type={"PR"} />
    
          {data?.customerId &&
<div className="w-full grid  border border-gary mt-4  rounded-lg">
            <div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Customer Detail</p>

            </div>
            {data.customerName&&<div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]">
                Customer
              </p>
              <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
                 {data?.customerName}
              </p>
            </div>}
            <div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]">
                Customer Reference
              </p>
              <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
                 {data.customerRfqRef}
              </p>
            </div> 
            <div className="w-full flex px-2 justify-between ">
  {data.customerCat&&<><p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
    Customer Category
  </p>
  <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
     {data.customerCat?.charAt(0).toUpperCase() + data.customerCat?.slice(1)}
  </p></>}
</div>           
         </div>
        }
         
          {/* <div className="w-full grid px-2 border border-gary mt-1 py-2 rounded-lg">
          <p className="text-xs text-[#111537] font-medium">Customer Detail</p>
              <div className="w-full flex p-2">
              <p className="w-[40%]  text-xs font-medium text-[#111537]">
                Customer
              </p>
              <p className="w-[60%]  text-xs pl-5 text-[#606279]">
                : {data?.customerName}
              </p>
            </div>
            <div className="w-full flex p-2">
              <p className="w-[40%]  text-xs font-medium text-[#111537]">
                Customer Reference
              </p>
              <p className="w-[60%]  text-xs pl-5 text-[#606279]">
                : {data.customerRfqRef}
              </p>
            </div> 
            <div className="w-full flex px-2">
  <p className="w-[40%] py-2 text-xs font-medium text-[#111537]">
    Customer Category
  </p>
  <p className="w-[60%] py-2 text-xs pl-5 text-[#606279]">
    : {data.customerCat.charAt(0).toUpperCase() + data.customerCat.slice(1)}
  </p>
</div>

            
           </div> */}
           <div className="w-full grid  border border-gary mt-4  rounded-lg ">
           <p className="text-xs text-white font-medium bg-baseColor-primary  p-2 rounded-t-lg">Documents </p>
           <div className="felx gap-2 flex-wrap overflow-y-scroll h-16">
           <div onClick={handlePdfIconClick} className="cursor-pointer m-2   ">
          <svg
            width="30"
            height="30"
            fill="#048178"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
          </svg>
        </div>
        </div>
        </div>
         <div className="w-full h-auto flex  border border-gary mt-4  rounded-lg">
         <CommonComments

            visible={true}
            // closeModal={handleCloseCommentsModal}
            title="Comments"
            mainId={prViewId}
            addurl={"/pr-comments/new-comment"}
            geturl={`/pr-comments/by-PR/${prViewId}`}
          />
         </div>
          {/* <div className="md:flex justify-between mt-5 px-3 w-[50%]">
            <div className="w-full flex gap-2 items-center flex-wrap">
              {data?.attachments &&
                filePathList.map((imgs, idx) => {
                  console.log("img", imgs);
                  return (
                    <div
                      className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                      key={idx}
                      style={{
                        border: "1px solid gray",
                        borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))",
                      }}
                    >
                      <div
                        className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                        onClick={() => handleImageClick(imgs)}
                      >
                        <img src={imgs?.file} alt={imgs?.file} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div> */}
          </div>
        </div>
        {/* </div> */}

        <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {
            selectedImage?.file.split(".").reverse()[0] == "pdf"? <PDFViewer pdfFile={selectedImage?.file}/>: 
            <img src={selectedImage?.file} alt={selectedImage?.file} />
          } 
        </Modal>

        {showPdfComponent && (
 <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
 <div className="animate-scale-in relative w-[95%] h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
   <button
             className="absolute top-2 right-2 text-white bg-red-600"
             onClick={() => setShowPdfComponent(false)}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      {/* <ViewPdf data={data} /> */}
      <PRPdfOther data={data} />
      {/* <div className="flex justify-end gap-2 ">
                <CancelButton handleClick={closeFilterDialog} />
             
              </div> */}
    </div>
  </div>
)}
        {/* {isCommentsModalOpen && ( */}
         
        {/* )} */}
      </div>
    </div>
  );
}

export default ViewPr;
