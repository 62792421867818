import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

function QuotationToCustomer() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/quotes-to-customer-approvals`);

  const COLORS_Two = ['#973131', '#E0A75E'];
  const chartData = [
    { name: 'Pending', value: data?.pendding || 0 },

    { name: 'Approved', value: data?.approved || 0 },
  ];
  
  return (
    <div className="w-full md:w-[25%]  px-2">
        <div className="flex items-center" >
          <div className="pl-1">
            <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">Quotation To Customer</p>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-pulse space-y-4 w-full">
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
              <div className="h-4 bg-slate-200 rounded"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex h-[96%]">
              <div className="w-1/2 flex justify-center items-center">
                <ResponsiveContainer width="100%" height={150}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={20}
                      outerRadius={35}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS_Two[index % COLORS_Two.length]} />
                      ))}
                       <Label
                      value={`${data?.total}`}
                      position="center"
                      className="text-center"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    />
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="w-1/2 flex flex-col justify-center">
                {chartData.map((item, index) => (
                  <div key={index} className="flex gap-2 items-center text-xs mb-1">
                    <span
                      className="inline-block w-2 h-2 rounded-full mr-2"
                      style={{ backgroundColor: COLORS_Two[index % COLORS_Two.length] }}
                    ></span>
                    <p className="w-[60%]">{item.name}</p>
                    <p className="w-[40%]">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {/* <p className="text-sm font-semibold mt-1">Total: {data.total}</p> */}
      </div>
  )
}

export default QuotationToCustomer