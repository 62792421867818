import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import AgentType from "./AgentType";
import Designation from "./Designation";
import Rating from "./Rating";
import Status from "./Status";




const Agent = () =>{
    return(
        <>
        <TextMediumSM content="Agent" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <AgentType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Designation/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Rating/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default Agent;