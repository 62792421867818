import { useFormik } from "formik";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { NuInput, NuInputSetting } from "../../../../../Components/Utils/Input";
import { useState } from "react";
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Consignee = () => {
  return(
 <SettingsMetaData propertyName={"Consignee"} viewPropertyName={"Consignee"} />
  )
};

export default Consignee;
