import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const DeliverTerms = () => {
  return (
    <SettingsMetaData propertyName={"deliverTerms_po"} viewPropertyName={"Delivery Terms"} />

  )
}

export default DeliverTerms