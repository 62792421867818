import React from "react";

function Search({ color = "#048178", width = 14, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2273 10.3774C11.2372 9.39379 11.8637 8.02309 11.8637 6.50698C11.8637 3.51376 9.4216 1.08728 6.40914 1.08728C3.39667 1.08728 0.95459 3.51376 0.95459 6.50698C0.95459 9.5002 3.39667 11.9267 6.40914 11.9267C7.89575 11.9267 9.24345 11.3358 10.2273 10.3774ZM10.2273 10.3774L12.9546 13.0873"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Search;
