import React from 'react'

export default function Staff({ color = "#999999", width = 20, height = 17 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M3.4027 0.0658593C2.60571 0.254225 1.90638 0.92104 1.69932 1.69334C1.48053 2.51838 1.83606 3.67495 2.52757 4.39074C3.01593 4.88802 3.44959 5.08016 4.03952 5.05378C4.32472 5.03871 4.43802 5.01611 4.64508 4.92193C5.32096 4.62054 5.87183 3.92359 6.15312 3.00813C6.29377 2.54852 6.30158 1.99849 6.18047 1.61423C6.01248 1.10187 5.60226 0.589516 5.13344 0.318269C4.66461 0.0432553 3.94575 -0.0584621 3.4027 0.0658593Z"  fill={color}/>
<path id="Vector_2" d="M15.3811 0.1033C14.6466 0.314269 14.0528 0.883134 13.8144 1.61399C13.6933 1.99826 13.7011 2.54829 13.8418 3.0079C14.1231 3.92712 14.6505 4.59017 15.3498 4.91793C15.6975 5.08369 16.182 5.11006 16.557 4.98574C17.5416 4.66552 18.3542 3.4223 18.3581 2.23937C18.3581 1.62906 18.1354 1.11294 17.6744 0.672164C17.0649 0.0806956 16.2015 -0.130274 15.3811 0.1033Z"  fill={color}/>
<path id="Vector_3" d="M9.58723 2.49547C8.42299 2.6763 7.49316 3.44483 7.14545 4.51475C7.03606 4.85381 7.0009 5.44905 7.06732 5.84085C7.16499 6.44739 7.48535 7.21592 7.86041 7.75464C7.94636 7.87896 8.16123 8.11631 8.33313 8.28584C8.85274 8.78312 9.38408 9.02046 9.98573 9.02046C10.603 9.02423 11.1226 8.79819 11.6422 8.3009C11.8259 8.12384 12.0485 7.87896 12.1345 7.75464C12.5096 7.21592 12.8299 6.44739 12.9276 5.84085C13.1737 4.35652 12.2634 2.97392 10.7749 2.57458C10.4506 2.48794 9.88024 2.45026 9.58723 2.49547Z"  fill={color}/>
<path id="Vector_4" d="M3.12141 5.69775C1.86731 5.95393 0.804648 6.80534 0.304572 7.95437C0.0818815 8.47426 0.0271857 8.74551 0.00765145 9.51027C-0.0196965 10.3617 0.0154651 10.5274 0.292851 10.8213C0.648374 11.2018 0.499914 11.183 2.84793 11.1943L4.89902 11.2056L5.07483 10.9682C5.31315 10.6518 5.88745 10.1017 6.2547 9.83426C6.62585 9.56678 7.12593 9.28047 7.46191 9.14485C7.59865 9.08834 7.71977 9.03559 7.72367 9.03183C7.73149 9.02806 7.62209 8.89243 7.47754 8.73421C6.98528 8.18795 6.55943 7.43448 6.32502 6.68479L6.1961 6.27415L5.9148 6.11969C5.57881 5.93132 5.1139 5.76556 4.71149 5.68645C4.30909 5.60357 3.55116 5.6111 3.12141 5.69775Z"  fill={color}/>
<path id="Vector_5" d="M15.2561 5.68645C14.8771 5.76556 14.4044 5.93886 14.0801 6.11969L13.7988 6.27415L13.6699 6.68479C13.4355 7.43448 13.0096 8.18795 12.5174 8.73421C12.3728 8.89244 12.2634 9.02429 12.2713 9.03183C12.2752 9.03559 12.4236 9.09964 12.5955 9.17122C13.4667 9.54418 14.4278 10.3014 14.9748 11.0436L15.092 11.2056L17.147 11.1943C19.1746 11.183 19.1981 11.183 19.3583 11.1001C19.5966 10.9795 19.8701 10.6668 19.9404 10.437C20.0224 10.1733 20.0185 9.25786 19.9365 8.78695C19.6552 7.21221 18.405 5.9803 16.7876 5.68268C16.393 5.6111 15.6155 5.61487 15.2561 5.68645Z"  fill={color}/>
<path id="Vector_6" d="M9.5091 9.52923C7.41112 9.75151 5.67648 11.0814 5.02013 12.9763C4.80135 13.6017 4.75056 13.9634 4.74665 14.9316C4.74274 15.7453 4.74665 15.7867 4.83651 16.0053C4.94981 16.2727 5.23891 16.5703 5.52411 16.7022L5.71945 16.7964H9.99745H14.2754L14.4981 16.6909C14.7755 16.559 15.0412 16.2803 15.1623 15.9939C15.2483 15.7905 15.2522 15.734 15.2483 14.9316C15.2443 14.3175 15.2248 13.986 15.1779 13.726C14.799 11.6653 13.1581 10.0341 11.0484 9.61965C10.6655 9.5443 9.83727 9.49533 9.5091 9.52923Z"  fill={color}/>
</g>
</svg>

  )
}
