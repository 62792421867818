import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { BackButton } from '../../../../Components/Utils/Buttons';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import NuDate from '../../../../Components/Utils/NuDate';

function ViewIdRevision() {
    const { viewidrevisionId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filePathList, setFilePathList] = useState([]);

  useEffect(()=>{
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      setFilePathList(data?.attachments);
      // console.log('image',createNew?.attachments);
      // setRemoveFilePathList(data?.productImage);
      // console.log('images',filePathList);
    }
},[data])

function UpdateAttachmentList(item) {
let filterList = filePathList.filter((itemList) => itemList.file !== item);
console.log('llll',filterList);
setFilePathList(filterList);
}

function handleImageClick(img) {
setSelectedImage(img);
setIsModalOpen(true);
}
  return (
     <div className="w-full h-full py-2 px-4 mb-3">
      <div className="flex justify-between ">
         <Breadcrumb
          items={[
        //   { name: "Procurement" ,
        // link:'#'},

          {
            name: "Purchase Request",
            link: "/procurement/purchase-requests/"
          },

          { name: "Revision View" },
        ]} />
        <div className=" flex gap-2">
        {/* <Link className='items-center text-[14px] py-2 text-gray-700 underline hover:text-blue-600' to={`/procurement/purchase-requests/viewquotes/${viewidrevisionId}`}>View Quotes</Link> */}
          <Link to={`/procurement/purchase-requests/viewrevision/${viewidrevisionId}`}><BackButton link={true}/></Link>
          {/* <Link to={`/procurement/purchase-requests/viewcreate/${viewidrevisionId}`} 
          state={{data: data, from:`/procurement/purchase-requests/view/${viewidrevisionId}`}}
          ><EditButton height='8'/></Link> */}
          {/* <button className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`} onClick={handleOpenCommentsModal}>Comments</button> */}

        </div>
      </div> 
      <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden p-3 overflow-y-auto mt-4'>
      <div className='flex justify-between'>
        <div className='flex gap-3 items-center'>
      <NormalHeading title={"Revision"} />  : <p className='text-xs '>{data.revisionCount}</p>
      </div>
     
      </div>
          <div className=" w-full flex flex-wrap ">
              <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
              <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Purchase Request</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data?.ref}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Dead Line</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data?.deadLine ? <NuDate value={data?.deadLine} format="DD-MMM-YYYY hh:mm a" /> : '-'}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Cost Code</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.costCode}</p>
                </div>  */}
                
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>RFQ Assigneee</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.rfqAssigneeName}</p>
                </div>
                
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Sales Person</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.salesPersonName}</p>
                </div>
                {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Customer RFQ Reference</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.customerRfqRef}</p>
                </div> */}
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Is Active</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{!data.isActive?'No':'Yes'}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Priority</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.priority}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Reviewed By</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.reviewedByName}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Approved By</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.approvedByName}</p>
                </div>
                
                
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Status</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.status}</p>
                </div>
               
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Comments</p>
                  <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data.comments}</p>
                </div>
                
              </div>
              </div>

            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
                

                <div className="w-full bg-white overflow-y-scroll  ">
            <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg">
            <p className=" w-[20%] pl-1 text-left text-white text-xs font-medium">  Item Code</p>
             
              <p className=" w-[40%]  text-left text-white text-xs font-medium">  Product </p>
              <p className=" w-[20%] text-left text-white text-xs font-medium">  Unit</p>
              {/* <p className=" w-[20%] text-left text-white text-xs font-medium">  Category</p> */}
              <p className=" w-[20%] text-left text-white text-xs font-medium">  Quantity</p>
            </div>
            {data?.prItems?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100">
                  <p className=" w-[20%] pl-1 text-left text-[#606279] text-xs font-light py-1">
                    {List?.item_code}
                  </p>
                  <p className=" w-[40%]  text-left text-[#606279] text-xs font-light py-1">
                    {List?.product_name}
                  </p>
                  <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                    {List?.product_unit}
                  </p>
                  {/* <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                    {List?.product_category}
                  </p> */}
                 
                  <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                    {List?.quantity}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

          <div className='flex'>
          <div className='w-[50%] flex px-2 border border-gary mt-3'>
                  <p className=' py-3 text-xs font-medium text-[#111537]'>Notes From Customer</p>
                  <p className=' py-3 text-xs pl-5 break-words text-[#606279]'>{data.notesFromCustomer}</p>
                </div>
         

{/* <div> */}
{/* <div className="md:flex justify-between mt-5 px-3 w-[50%">
                        <div className="w-full flex gap-2 items-center flex-wrap">
                            {data?.attachments &&
                                filePathList.map((imgs, idx) => {
                                    console.log('img', imgs);
                                    return (
                                      <div
                                      className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                      key={idx}
                                      style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                  >
                                    <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                                <img src={imgs?.file} alt={imgs?.file} />
                                            </div>
                                            </div>
                                              );
                                            })}
        
</div>
</div> */}
</div>
{/* </div> */}

{/* <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <img src={selectedImage?.file} alt={selectedImage?.file} />
  </Modal>
          {isCommentsModalOpen && (
        <SideModal
          visible={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={prViewId}
          addurl={'/pr-comments/new-comment'}
          geturl={`/pr-comments/by-PR/${prViewId}`}
        />
      )} */}
      </div>
</div>        
  )
  };

export default ViewIdRevision