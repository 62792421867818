import { Route, useLocation, useNavigate } from "react-router-dom";
import ShippingMain from "../Views/Web/Shipping/MainShipping";
import AgentsMain from "../Views/Web/Shipping/Agents/AgentsMain";
import AgentIndex from "../Views/Web/Shipping/Agents";
import CreateAgents from "../Views/Web/Shipping/Agents/CreateAgents";
import EditAgents from "../Views/Web/Shipping/Agents/EditAgents";
import ViewAgents from "../Views/Web/Shipping/Agents/ViewAgents";
import { CreateShipmentTracking, EditShipmentTracking, ShipmentTrackingIndex, ShipmentTrackingMain, ViewShipmentTracking } from "../Views/Web/Shipping/ShipmentTracking";
import { useEffect } from "react";
import { Wrapper } from "../Components/Utils/roles";




function ShippingRoutes(){
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (
        location.pathname === "/shipping" ||
        location.pathname === "/shipping/") {
        navigate("/page-not-found")
      }
    },[location])
    return(

        <Route path="shipping" element={<ShippingMain/>}>
            <Route path="agents" element={<AgentsMain/>}>
                <Route index element={<Wrapper accessKey={"agents_view"}  Component={AgentIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"agents_create"}  Component={CreateAgents} />}/>
                <Route path="edit/:agentsEditId" element={<Wrapper accessKey={"agents_edit"}  Component={EditAgents} />}/>
                <Route path="view/:agentsViewId" element={<Wrapper accessKey={"agents_view"}  Component={ViewAgents} />}/>
            </Route>
            <Route path="shipment-tracking" element={<ShipmentTrackingMain/>}>
                <Route index element={<Wrapper accessKey={"shipment_tracking_view"}  Component={ShipmentTrackingIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={"shipment_tracking_create"}  Component={CreateShipmentTracking} />}/>
                <Route path="edit/:shipmentTrackingEditId" element={<Wrapper accessKey={"shipment_tracking_edit"}  Component={EditShipmentTracking} />}/>
                <Route path="view/:shipmentTrackingViewId" element={<Wrapper accessKey={"shipment_tracking_view"}  Component={ViewShipmentTracking} />}/>
            </Route>
        </Route>
    )
}

export default ShippingRoutes;