import React, { useState } from 'react';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import PieCharts from '../../../../../Components/Charts/PieChart';
import colorCodes from '../../../../../Components/Utils/ColorCode';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function POToSuppliersProDash() {
  const [params, setParams] = useState({ period: "monthly" });
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');

  const { data, loading } = UseGetDashBoardWithParams('/dashboard/purchase-order-approvals', params);

  const status = data ? [
    { name: 'Approved', value: data.approved || 0 },
    { name: 'Pending', value: data.pending || 0 },
  ] : [];

  const hanChange = (e) => {
    const value = e.target.value;
    setParams({ period: value });
    setSelectedPeriod(value);
  };

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
  <div className="flex justify-between items-center mb-2">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Purchase Order Approval</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-sm text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
        <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className='w-full flex'>
          <div className="w-1/2 flex justify-center items-center">
            <PieCharts
              data={status}
              label={true}
              labelValue={status.reduce((acc, item) => acc + item.value, 0)}
              COLORS={colorCodes.slice(0, status.length)}
              height={180}
            />
          </div>
          <div className="w-1/2">
            {status.map((item, idx) => {
              const color = colorCodes[idx];
              return (
                <div key={idx} className='w-full flex justify-start gap-2 mt-5 ml-2 text-xs'>
                  <div className={`w-2 h-2 rounded-full mt-1.5`} style={{ backgroundColor: color }}></div>
                  <p>{item.name}</p>
                  <p>{item.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default POToSuppliersProDash;
