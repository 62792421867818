import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import Appoinments from "./Appoinments";
import Leads from "./Leads";


  
  
  const MainMarketingSettings = () =>{
    return(
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Marketing"
            }
          ]}
        />
        <BackButton/>
        </div>
        <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md overflow-y-scroll">
              <div className="p-3"><Appoinments/></div>
              <div className="p-3"><Leads/></div>
        </div>
        </div>
    )
  }

  export default MainMarketingSettings;