import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import ApprovalComponent from '../../../../Components/Utils/approvalComponent';
import capitalizeFirstLetter from '../../../../Components/Utils/Funcs';

const ViewRequest = () => {
  const { requestViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;

    const id = requestViewId; 
    const getUrl = `/requests/get-status`;
    const putUrl = `/requests/edit-status`; 
    const statusUrl = '/requests/all-status';

  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
         items={
          [
            { name: "Store",
            link: "/store/requests" },
          {
            name: "Request",
            link: "/store/requests"
          },
          {
            name: "View Request"
          }]
         }
        />
        <div className=" flex gap-2">
          <Link to={'/store/requests'}><BackButton link={true}/></Link>
          <Link to={`/store/requests/edit/${data?.requestId}`} state={{data: data, from:`/store/requests/view/${data.requestId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-125px)]  rounded-md overflow-hidden p-2   flex gap-2 text-[#111537]">
        <div className="w-[70%]  bg-white rounded-lg drop-shadow-lg py-1 overflow-hidden ">
        <div className="w-[98%]  bg-white px-2 mx-2 border border-gray py-2 rounded-lg flex justify-between items-center  z-10 mt-4">  
       

      <div className="grid items-center px-2">
      <div className="flex text-[14px] mb-1 text-[#111537] font-semibold w-[90%]">
            <p className="">{data?.itemName || 'None'}</p>
            {/* <p className=""> : {data?.description || 'None'}</p> */}
            </div>
            <div className="flex gap-4">
           
         <p className='text-xs text-[#111537]'>Request by : {data?.preparedByName  ? capitalizeFirstLetter(data?.preparedByName) : 'None'}</p>
         <p className="bg-[#579BB1] text-white items-center rounded-md text-xs p-[2px]"> Status : {data.status || 'None'}</p>

         </div>
          </div>
      
              </div>
              {/* <div className="h-[calc(100vh-200px)] overflow-y-auto p-2 pb-8"> */}

        <div className='w-[98%] flex flex-wrap px-2 border border-gary mt-4 mx-2 rounded-lg '>
          <div className="w-full grid grid-cols-2 gap-x-8 py-1">
           
           
          {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Agent ID</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.requestId || '-'}</p>
                  </div> */}
                  {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Request For</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.requestFor || '-'}</p>
                  </div> */}
                  {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purpose</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.purpose || '-'}</p>
                  </div> */}
                  {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Item</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.itemName || '-'}</p>
                  </div> */}
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Quantity</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.quantity || 'N/A'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Unit</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.unit || 'N/A'}</p>
                  </div>
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Is Returnable</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.isReturnable == true ? "Yes" : "No"}</p>
                  </div>
                  {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.status || '-'}</p>
                  </div>                 */}
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Approved By</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.approvedByName  ? capitalizeFirstLetter(data.approvedByName) : 'N/A'}</p>
                  </div>
                   <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Issueed By</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.issueedByName  ? capitalizeFirstLetter(data.issueedByName) : 'N/A'}</p>
                  </div>
                  {/* <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Prepared By</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.preparedByName  ? capitalizeFirstLetter(data.preparedByName) : 'N/A'}</p>
                  </div> */}
                  <div className='w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Reviewed By</p>
                    <p className='w-1/2 py-2 text-xs  text-[#606279] pl-2 '>: {data?.reviewedByName  ? capitalizeFirstLetter(data.reviewedByName) : 'N/A'}</p>
                  </div>
          </div>
        </div>
      </div>
             
          
          <div className="w-[30%] h-full bg-white rounded-lg drop-shadow-lg py-2 ">
        <div className="h-[98%] overflow-y-scroll p-2 ">
<ApprovalComponent id={id} getUrl={getUrl} putUrl={putUrl} statusUrl={statusUrl}/>
<div className="w-full grid  border border-gary mt-4  rounded-lg">
<div className="bg-baseColor-primary  p-2 rounded-t-lg">
            <p className="text-xs text-white font-medium">Request Detail</p>

            </div>
            <div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]">
              Agent ID
              </p>
              <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
                 {data?.requestId || '0'}
              </p>
            </div> 
            <div className="w-full flex px-2 justify-between">
              <p className=" w-1/2 py-2 text-xs font-medium text-[#111537]">
              Request For
              </p>
              <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
                 {data?.requestFor || 'N/A'}
              </p>
            </div>
           
            <div className="w-full flex px-2 justify-between ">
  <p className="w-1/2 py-2 text-xs font-medium text-[#111537]">
  Purpose
  </p>
  <p className=" w-1/2 py-2 text-xs pl-5 text-[#606279]">
     {data?.purpose || 'N/A'}
  </p>
</div>

            
         </div>
         </div>
         </div>
           </div>
              </div>
           
  
  )
};
export default ViewRequest;