import React from 'react'
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function OrderReceived() {
    const { data, loading } = UseGetDashBoardData(``);
  
   
 
   return (
    <div className="col-span-2 bg-white max-h-[35vh] p-4  pb-10  rounded-lg shadow-lg overflow-hidden mb-4">
    <div className="w-full  px-1 py-2 rounded-md ">
       <TextMediumSM content={"Order Received Recently "} color="text-[#4F4768]" />
       <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
        
       </div>
 
       {loading ? (
         <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
           <div className="animate-pulse flex space-x-4 w-full">
             <div className="flex-1 space-y-3 py-1">
               <div className="h-3 bg-slate-200 rounded"></div>
               <div className="space-y-3">
                 <div className="grid grid-cols-3 gap-4">
                   <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                   <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                 </div>
                 <div className="h-3 bg-slate-200 rounded"></div>
                 <div className="grid grid-cols-3 gap-4">
                   <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                   <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       ) : (
        
 
     <div className="w-full h-[89%] overflow-y-scroll px-6">
       <table className="w-full ">
       <thead className="sticky top-0 bg-white shadow-sm">

           <tr>
             <th className="text-left px-2 py-1"><TextMediumSM content="Order" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="Shipped" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="Date Received" /></th>

             
           </tr>
         </thead>
         <tbody className=''>
            <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Mobile"/></td>
               <td className="px-2 py-1"><TextMediumXS content="02/01/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="04/01/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Game Console"/></td>
               <td className="px-2 py-1"><TextMediumXS content="04/03/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="06/03/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Household Furniture"/></td>
               <td className="px-2 py-1"><TextMediumXS content="03/03/2023" /></td>
               <td className="px-2 py-1"><TextMediumXS content="05/03/2023" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Home Appliances"/></td>
               <td className="px-2 py-1"><TextMediumXS content="07/05/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="10/05/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Clothing"/></td>
               <td className="px-2 py-1"><TextMediumXS content="02/01/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="04/01/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Mobile"/></td>
               <td className="px-2 py-1"><TextMediumXS content="02/01/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="04/01/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Game Console"/></td>
               <td className="px-2 py-1"><TextMediumXS content="04/03/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="06/03/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Household Furniture"/></td>
               <td className="px-2 py-1"><TextMediumXS content="03/03/2023" /></td>
               <td className="px-2 py-1"><TextMediumXS content="05/03/2023" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Home Appliances"/></td>
               <td className="px-2 py-1"><TextMediumXS content="07/05/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="10/05/2024" /></td>

             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Clothing"/></td>
               <td className="px-2 py-1"><TextMediumXS content="02/01/2024" /></td>
               <td className="px-2 py-1"><TextMediumXS content="04/01/2024" /></td>

             </tr>
           {/* {data && data.map((item, index) => (
             <tr className="odd:bg-white even:bg-slate-50 " key={index}>
               <td className="px-2 py-1"><TextMediumXS content={item?.customerName || "N/A"} /></td>
               <td className="px-2 py-1"><TextMediumXS content={item?.totalSpent || "N/A"} /></td>
             </tr>
           ))} */}
         </tbody>
       </table>
     </div>
   )}
 </div>
);
}

export default OrderReceived