import { Outlet } from "react-router-dom";

    const MainUserSettings = () =>{
    return(
      <div className='w-full h-full'>
        <Outlet/>
      </div>
    )
  }

  export default MainUserSettings;