import { useEffect, useState } from "react";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import { NuInputReffrence } from "../../../../Components/Utils/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { useToast } from "../../../../Components/Others/ToastServices";
import PurchaseRequest from "./PurchaseReqest";
import RequestForQuotation from "./RequestForQuotation";
import SupplierQuotes from "./SupplierQuotes";
import CustomerOrder from "./CustomerOrder";
import CustomerQuotes from "./CustomerQuotes";
import PurchaseOrder from "./PurchaseOrder";
import SalesOrder from "./SalesOrder";
import InvoiceIn from "./InvoiceIn";
import InvoiceOut from "./InvoiceOut";



const ReffrenceSettings = () => {
  
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Reference",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto  p-2 rounded-md overflow-y-scroll">
        <div className=" w-full flex justify-between items-center">
          <TextMediumSM content="Reference List" />
        </div>
        {/* <form onSubmit={formik?.handleSubmit}> */}
        <div className="w-full max-h-[calc(100vh-200px)] overflow-y-auto mt-2">
  <div className="flex flex-row">
    <div className="w-1/5 text-left text-sm font-medium py-4 px-4">Module Name</div>
    <div className="w-1/5 text-center text-sm font-medium py-2 px-4"><p>Prefix</p><p className="text-[10px] text-gray-400">1 to 10 Any Alphabet</p></div>
    <div className="w-1/5 text-center text-sm font-medium py-2 px-4"><p>Suffix</p><p className="text-[10px] text-gray-400">3 to 15 Any Numbers</p></div>
    <div className="w-1/5 text-center text-sm font-medium py-4 px-4">Display Zero</div>
    <div className="w-1/5 text-center text-sm font-medium py-4 px-4">Save</div>
  </div>
  <div>
    <PurchaseRequest />
    <RequestForQuotation/>
    {/* <SupplierQuotes/> */}
    {/* <CustomerOrder/> */}
    <CustomerQuotes/>
    <PurchaseOrder/>
    <SalesOrder/>
    {/* <InvoiceIn/> */}
    <InvoiceOut/>
  </div>
</div>

        {/* </form> */}
      </div>
    </div>
  );
};

export default ReffrenceSettings;
