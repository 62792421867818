import React, { useState } from 'react'
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import SQDownloadLink from './pdf/SQDownloadLink';
import NuDate from '../../../../Components/Utils/NuDate';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

function ViewIdSQRevision() {
    const { viewIdrevisionsqId } = useParams();
    const location = useLocation();
    const data = location.state.data;
    console.log('sss',data?.quoteProducts);
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  // const [prlist, setPrlist] = useState([]);

  // const getPr = () => {
  //   axiosInstance.get(`/PR/pr-products/${data.prId}`).then((res) => {
  //     if (res?.data?.data) {
  //       setPrlist
  //       (res.data.data);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getPr();
  // }, [data.prId]);
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            { name: "Revision", link: "/procurement/supplier-quotes/viewsqrevision" },

            { name: "Revision View" },
          ]}
        />
         <BackButton/>
        {/* <div className=" flex gap-2">
          <Link to={"/procurement/supplier-quotes"}>
            <BackButton link={true} />
          </Link>
          <SQDownloadLink
            data={data}
            fileName={`Supplier_Quote_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          />
          <Link
            to={`/procurement/supplier-quotes/edit/${supplierQuotationsViewId}`}
            state={{
              data: data,
              from: `/procurement/supplier-quotes/view/${supplierQuotationsViewId}`,
            }}
          >
            <EditButton height="8" />
          </Link>
          <button
            className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`}
            onClick={handleOpenCommentsModal}
          >
            Comments
          </button>
        </div> */}
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden p-3 overflow-y-auto mt-4">
      <div className='flex justify-between'>
        <div className='flex gap-3 items-center'>
        <NormalHeading title={"Revision"} /> :{" "}
        <p className="text-xs text-[#606279]">{data.ref}</p>
      </div>
     
      </div>
        
        <div className=" w-full flex flex-wrap ">
          <div className=" w-full grid grid-cols-3 gap-x-8 m-4">
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Quote Date
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data?.quoteDate}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Quotation Received Date
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data?.dateReceived}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Delivery Terms
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.deliverTerms}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Payment Terms
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.paymentTerms}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Payment Method
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.paymentMethod}
              </p>
            </div>
            {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Reference</p>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data.ref}</p>
                </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Supplier
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.vendorName}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Purchase Requests
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.prRef}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                RFQ Reference
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.rfqRef}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Product Availability
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.availability}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Quotation Validity
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.validity}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Lead Time
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.leadTime}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Prepared By
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.preparedByName}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Reviewed By
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.reviewedByName}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Approved By
              </p>
              <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
                {data.approvedByName}
              </p>
            </div>
            <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Approval Status</p>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data.status}</p>
                </div>
                <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'> Status</p>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data.status}</p>
                </div>
            {/* <div className='w-full flex px-2'>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'> Comments</p>
                  <p className='w-1/2 py-3 text-xs font-medium text-[#606279]'>{data.comments}</p>
                </div> */}
          </div>
        </div>

        <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

        <div className="w-full bg-white overflow-y-scroll ">
          <div className="w-full py-[1%] bg-baseColor-primary flex justify-between rounded-lg">
            <p className=" w-[5%] pl-2 text-left text-white text-xs font-medium">
              {" "}
              S.No 
            </p>
            <p className=" w-[30%] pl-2 text-left text-white text-xs font-medium">
              {" "}
              Product 
            </p>
            <p className=" w-[10%] text-left text-white text-xs font-medium">
              {" "}
              Unit
            </p>
            <p className=" w-[15%] text-left text-white text-xs font-medium">
              {" "}
              Qty
            </p>
            <p className=" w-[20%] text-left text-white text-xs font-medium">
              {" "}
              Price
            </p>
            <p className=" w-[20%] text-left text-white text-xs font-medium">
              {" "}
              Category
            </p>
           
          </div>
          {data?.quoteProducts?.map((List, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between odd:bg-white even:bg-slate-100"
              >
                <p className=" w-[5%] pl-2 text-left text-[#606279] text-xs font-light py-1">
                  {List?.sno}
                </p>
                <p className=" w-[30%] pl-2 text-left text-[#606279] text-xs font-light py-1">

                
                  {List?.product_name}
                </p>
                <p className=" w-[10%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.product_unit}
                </p>

                 <p className=" w-[15%] text-left text-[#606279] text-xs font-light py-1">

                  {List?.quantity}
                </p>
                <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.price}
                </p>
                <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.product_category}
                </p>
               
              </div>
            );
          })}
        </div>
        <div className="w-full flex px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
              {data.totalPrice}
            </p>
          </div>
        </div>
        <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

        <div className="w-[55%] flex px-2 border border-gary mt-3">
          <p className=" py-3 text-xs font-medium text-[#111537]">
            Special Notes
          </p>
          <p className=" py-3 text-xs pl-5 text-wrap text-[#606279]">
            {data.specialNotes || "-"}
          </p>
        </div>
      </div>

      {/* {isCommentsModalOpen && (
        <SideModal
          visible={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={supplierQuotationsViewId}
          addurl={"/sq-comments/new-comment"}
          geturl={`/sq-comments/by-SQ/${supplierQuotationsViewId}`}
        />
      )} */}
    </div>
  );
  };

export default ViewIdSQRevision