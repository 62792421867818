import React, { useState, useEffect } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import UseAddData from '../../../../Components/Hooks/useAddData';
import * as yup from 'yup';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import DeleteIcon from '../../../../Components/Icons/Delete';
import { NuInput, NuMobileNumber, NuSelect, NuTextArea } from '../../../../Components/Utils/Input';
import MultiSelect from '../../../../Components/Utils/MultiSelect';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { useToast } from '../../../../Components/Others/ToastServices';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateRFQ() {
   const { Oncancel, addFormData, contextHolder } = useAddFormData();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const { data: vendors ,getData:getVendor} = UseDropDown('/vendors/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
  const [items, setItems] = useState([]);
  const { data: Purchaser } = UseDropDown('/user/purchaser/drop-down');
  const {value:vendorType ,getData:refvendorType} = useFetchMataData(`/dropdown/dropdown-details/`,'SupplierType')
  const toast = useToast();
 
  const [createNew, setCreateNew] = useState({
    prId: "",
    // vendor: "",
    vendors:[],
    comments: "",
    rfqType: "",
    localOption: "",
    purchaser: [],
    customerRfq:""
  });
  
  const [error, setError] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      prId: yup.string().required("Purchase Request is requred!"),
      rfqType: yup.string().required(" RFQ Type is requred!"),
    //  vendors: yup.array()
    // .min(1, "At least one vendor must be selected")
    // .of(yup.string().required("Vendor is required"))
    // .required("Vendors is required!"),
    // purchaser: yup.string().required("Purchaser is requred!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      if (prlist.length === 0) {
        setError('Product table is empty');
        return;
      }
let formData = new FormData();

      const valueWithItems = {
        ...value,
        productList: prlist,
      };
      formData.append('json_data', JSON.stringify(valueWithItems));
      // formData.append('attachments', image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 
      addFormData("/rfq/new-rfq", formData, resetForm);
 
      // addData('/rfq/new-rfq', valueWithItems, resetForm);
    },
  });

  const [prlist, setPrlist] = useState([]);
  console.log("jndks",prlist);


  const getPr = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/PR/pr-products/${prId}`)
        .then(res => {
          let data = res.data.data;
          setPrlist(data);
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  // const [cusRef,setCusRef] = useState([]);
  const getCustomerRef = (prId) => {
    if (prId !== "") {
      axiosInstance
        .get(`/rfq/customer-ref/${prId}`)
        .then(res => {
          console.log("res",res.data.data);
          let data = res.data.data;
          // setCusRef(data);
          formik?.setFieldValue('customerRfq',data)
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  useEffect(() => {
    if (prlist.length > 0) {
      setError('');
    }
  }, [prlist]);

  useEffect(() => {
    if (formik.values.prId) {
      getPr(formik.values.prId);
    }
    if (formik.values.prId) {
      getCustomerRef(formik.values.prId);
    }
  }, [formik.values.prId]);

  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  console.log("bbbbb",deletedItem);
  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = prlist.filter((_, index) => index !== indexToDelete);
    setPrlist(updatedItems);
    const deletedItems = prlist.filter((_, index) => index == indexToDelete);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems, ...deletedItems]);
  };
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);
    console.log("dele",deletedItemDropDown);

    const handleAdd = () => {
      const selectedProductId = formik.values.productId;
    
      // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
      const selectedProduct = deletedItem.find(
        (item) => item.productId === selectedProductId
      );
    
      if (selectedProduct) {
        // Add the selected product to prList
        setPrlist((prevPrList) => [...prevPrList, selectedProduct]);
    
        // Optionally, remove the selected product from the dropdown after adding it to prList
        setDeletedItem((prevDeletedItems) =>
          prevDeletedItems.filter((item) => item.productId !== selectedProductId)
        );
      }
    };


  const [showPopup, setShowPopup] = useState(false);
   
  const [newProduct, setNewProduct] = useState({

   
      customerType: "",
      companyName: "",
      email: "",
      phone: "",
      countryCode: "",
      website: "",
      industry: "",
    
          });
  

const togglePopup = () => {
  setShowPopup(!showPopup);
};


 const handlePopupSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  // formData.append('customerImage', JSON.stringify(newProduct));
  formData.append(
    "vendorDetails",
    JSON.stringify({
      customerType: formik.values.customerType || "",
      companyName: formik.values.companyName || "",
      email: formik.values.email || "",
      phone: formik.values.phone || "",
      countryCode: formik.values.countryCode || "",
      website: formik.values.website || "",
      industry: formik.values.industry || "",
    })
  );
  try {
   
    const response = await axiosInstance.post("/vendors/new-vendor", formData)
    const createdCustomer = response.data?.data;

    if(response.data?.status){
      formik.resetForm();
      getVendor()
      formik.setValues(prevValues => ({
        ...prevValues,
        // Reset fields related to the new vendor
        companyName: "",
        email: "",
        phone: "",
        countryCode: "",
        website: "",
        industry: "",
        customerType: "",
      }));
      setShowPopup(false);
    }

  } catch (error) {
    console.error("Error creating new customer:", error);
  }
};
const sortedVendors = vendors.sort((a, b) => a.label.localeCompare(b.label));

const [selectedFile, setSelectedFile] = useState(null);
const [image, setImage] = useState([]);

function handleImageChange(e) {
  const maxFileSize = 200000000; // 200 MB
  const files = Array.from(e.target.files);
  let fileArr = []
  files.map(item=>{
    if (item.size < maxFileSize ){
      fileArr.push(item)
    }else{
      toast.open({
        type:"warning",
        message: `File Size to big`,
        description: `${item.name} is Removed and not Uploaded`,
        // placement: "bottomRight",
      });
    }
  })    
  let tempArr = []
  setSelectedFile(fileArr);
  files.map(item=>{
    if (item.size < maxFileSize ){
    let fileName = item?.name
    console.log('fname',fileName);
    let fileExtention =  fileName.split(".").reverse()[0]
    if(filetype?.includes(fileExtention)){
      tempArr.push(fileName)
    }else{
      let x = URL.createObjectURL(item)
      tempArr.push(x)
    }
  }
  })
  console.log(tempArr,'temp');
  setImage(tempArr)
}
  return (
    <div className="w-full h-full py-2 px-4">
      <div className="flex justify-between">
        <Breadcrumb items={[
          { name: "RFQ", link: "/procurement/rfq/" },
          { name: "Create" },
        ]} />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
            <FormikSelectTwo
              type="text"
              label="Purchase Request"
              placeholder="Purchase Request"
              Options={PR}
              formik={formik}
              name="prId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              label="Customer Reference"
              type="text"
              formik={formik}
              placeholder="Customer Reference"
              name="customerRfq"
              width="w-full md:w-1/2 lg:w-1/3"
              disabled={true}
            />
            <FormikSelectTwo
              type="text"
              label="RFQ Type"
              placeholder="RFQ Type"
              Options={[
                { value: "local", label: "Local" },
                { value: "international", label: "International" },
              ]}
              formik={formik}
              name="rfqType"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            {formik.values.rfqType === 'local' && (
              <div className="w-full px-6 md:w-1/2 lg:w-1/3">
                <div className="flex items-center mt-8">
                  <input
                    type="radio"
                    name="localOption"
                    value="purchaser"
                    checked={formik.values.localOption === 'purchaser'}
                    onChange={() => formik.setFieldValue('localOption', 'purchaser')}
                  />
                  <label className="ml-2 text-sm 2xl:text-sm">Local Purchaser</label>
                </div>
                <div className="flex items-center mt-1">
                  <input
                    type="radio"
                    name="localOption"
                    value="supplier"
                    checked={formik.values.localOption === 'supplier'}
                    onChange={() => formik.setFieldValue('localOption', 'supplier')}
                  />
                  <label className="ml-2 text-sm 2xl:text-sm">Supplier</label>
                </div>
              </div>
            )}
           
   
                          {formik.values.rfqType === 'international' && ( 
                          <div className=" flex items-center w-full md:w-1/2 lg:w-1/3 ">

<MultiSelect
  type="text"
  label="Supplier"
  placeholder="Choose"
  Options={sortedVendors}
  formik={formik}
  name="vendors"
  width="w-full"
/>
           <button onClick={togglePopup} type="button" className="w-[12%] p-1 text-center h-9 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg " > + </button>
            
            
               </div>
    )}
   
            
       
            
            {formik.values.rfqType === 'local' && formik.values.localOption === 'purchaser' && (
              <MultiSelect
                type="text"
                label="Local Purchaser"
                placeholder="Choose"
                Options={Purchaser}
                formik={formik}
                name="purchaser"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )}
            {formik.values.rfqType === 'local' && formik.values.localOption === 'supplier' && (
                          <div className=" flex items-center w-full md:w-1/2 lg:w-1/3 ">
             
             <MultiSelect
  type="text"
  label="Supplier"
  placeholder="Choose"
  Options={sortedVendors}
  formik={formik}
  name="vendors"
  width="w-full"
/>
               <button onClick={togglePopup} type="button" className="w-[12%] p-1 text-center h-9 mt-7 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg " > + </button>
            
            
            </div>
            )}
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2 lg:w-1/2"
            />
              <div className='w-full'>
              <label className="text-xs 2xl:text-sm px-4">RFQ Attachment</label>

           <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
           </div>
            <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAdd}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
            <div className="w-full bg-white overflow-y-scroll mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-2 bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.No </p>
                <p className="w-[60%] text-left text-white text-xs font-medium">Product</p>
                <p className="w-[10%] text-center text-white text-xs font-medium">Unit</p>
                <p className="w-[20%] text-center text-white text-xs font-medium">Quantity</p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {prlist?.length > 0 ?
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                  {prlist.map((List, index) => (
                    <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                      <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                      <p className="w-[60%] text-left text-[#606279] text-xs font-light">{List?.product_name}</p>
                      <p className="w-[10%] text-center text-[#606279] text-xs font-light">{List?.product_unit}</p>
                      <p className="w-[20%] text-center text-[#606279] text-xs font-light">{List?.quantity}</p>
                      <div className='w-[5%] text-center'> 
                        <button
                          onClick={() => handleDeleteItem(index)}
                          type="button"
                          className="text-red-500 cursor-pointer ml-4 border-none"
                        >
                          <DeleteIcon color='red' height={4} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div> : <div className="w-full text-center text-[#93949f]">No Items here</div>}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1); }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in w-[60%] max-h-[80vh] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <h2 className="text-lg font-normal text-[12px]">Add Supplier</h2>
            <form onSubmit={handlePopupSubmit}>
              <div className="mb-4 flex flex-wrap">
              <DropDownAddMetaData
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={formik}
                name="vendorType"
                width="w-full md:w-1/2 lg:w-1/2"
                getFunc={refvendorType}
                displayProperty={"Type"}
                propertyName={"SupplierType"}
              />
              <NuInput
              type="text"
              label="Company Name"
              placeholder="company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/2 lg:w-1/2"
            />
          
           <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/2 lg:w-1/2"
            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/2"
              isRequired={true}
            />         

                         </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={togglePopup}
                  className="px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm bg-[#E6F2F2]  hover:bg-gray-300
                  rounded-md flex items-center mr-2 text-textColor-main"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#048178] px-5 py-[9px] cursor-pointer outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateRFQ;
