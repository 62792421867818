import { useState, useMemo } from 'react';
import axiosInstance from '../../../../../Services/axiosCommon';

function usePrProducts(prId) {
  const [products, setProducts] = useState([]);

  useMemo(() => {
    if (prId !== "") {
      axiosInstance
        .get(`/supplier-quotations/PR-products/drop-down/${prId}`)
        .then(res => {
          let data = res.data;
          let tempList = []
          data.forEach((item) => {
            let tempObject = { value: item.id, label: item.name };
            tempList.push(tempObject);
          })
          data && setProducts(tempList);
        })
        .catch(err => {
          console.log(err, "err");
        })
    }
        else {
          setProducts([]);
          }
    }
  , [prId]);

  return products;
}

export default usePrProducts; 