import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import Brand from "./Brand";
import EquipmentType from "./EquipmentType";
import Manufacture from "./Manufacturer";
import WarrentyPeriod from "./WarrentyPeriod";
import Unit from "./EquipmentUnit";
import MaintenanceType from "./MaintenanceType";





const Equipment = () =>{
    return(
        <>
        <TextMediumSM content="Equipment" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <EquipmentType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Brand/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Manufacture/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Unit/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <WarrentyPeriod/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <MaintenanceType/>
         </div>
         </div>
         </>
    )
}

export default Equipment;