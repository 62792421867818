import React, { useEffect, useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import {
  NuDate,
  NuInput,
  NuInputWithError,
  NuTextArea,
  NuUpload,
} from "../../../../Components/Utils/Input";
import FormikSelectTwo, { SelectWithError } from "../../../../Components/Utils/Select";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import UseEditData from "../../../../Components/Hooks/useEditData";
import * as yup from "yup";
import Dashboard from "../../../../Components/Icons/Dasboard";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDownNew from "../../../../Components/Hooks/useDDNew";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import Modal from "../../../../Components/Utils/AttachmentView";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

function EditPr() {
  const { value: status } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "rfqStatus_pr"
  );
  const { value: Costcode } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "costCode_pr"
  );
  const { value: approvalStatus } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "approval_status_pr"
  );
  
  const { data: Item, setData } = UseDropDownNew("/items/new-drop-down");

  const { data: RFQAssignee } = UseDropDown('/user/procurement/drop-down');
  const { data: Salesman } = UseDropDown('/user/salesman/drop-down');

  const { data: customers } = UseDropDown("/customers/drop-down");

  const [image, setImage] = useState([]);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  console.log(location.state, "state");
  const [dataa, setDatas] = useState(data);
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } =
    useEditFormData();
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);

  // console.log(items, "kk");
  // console.log(data, "pp");
  const [newItemData, setNewItemData] = useState({
    productId: "",
    quantity: "",
     product_unit:""
  });
  const [createNew, setCreateNew] = useState({
    customerId: data?.customerId,
    customerRfqRef: data?.customerRfqRef,
    deadLine: data?.deadLine,
    isActive: data?.isActive,
    notesFromCustomer: data?.notesFromCustomer,
    costCode: data?.costCode,
    salesPerson: data?.salesPerson,
    preparedBy: data?.preparedBy,
    reviewedBy: data?.reviewedBy,
    approvedBy: data?.approvedBy,
    reasonForPurchase: data?.reasonForPurchase,
    comments: data?.comments,
    status: data?.status,
    removedProducts: [],
    filesToRemove: [],
    approvalStatus:data?.approvalStatus,
    description:data?.description,
    rfqAssignee: data?.rfqAssigneeIds,

    rfqAssigneeNames:[],
    // rfqAssigneeIds:data?.rfqAssigneeIds,
    // rfqAssigneeNames:data?.rfqAssigneeNames,
  });
  const handleDelete = (productId) => {
   
    const updatedProductList = dataa?.productList.filter(
      (item) => item.productId !== productId
    );
    // const deletedItem = dataa?.productList.find(item => item.productId === productId);
    console.log(updatedProductList, "lll");
   
    setDatas((prevState) => ({
      ...prevState,
      productList: updatedProductList,
    }));
    setRemove([...remove, productId]);

    // setremove(prevState => ({
    //   ...prevState,
    //   removedProducts: [...prevState.removedProducts, deletedItem]
    // }));
  };
       const [error, setError] = useState('');
       const [productError, setProductError] = useState('');
       const [quantityError, setQuantityError] = useState('');
  const handleAdd = () => {
    let hasError = false;

    if (!formik.values.productId) {
      setProductError('Product is required');
      hasError = true;
    } else {
      setProductError('');
    }

    if (!formik.values.quantity) {
      setQuantityError('Quantity is required');
      hasError = true;
    } else {
      setQuantityError('');
    }

    if (hasError) return;

    if (formik.values.productId && formik.values.quantity){
    const selectedProduct = Item.find(
      (i) => i.value === formik.values.productId
    );
    const newItem = {
      productId: formik.values.productId,
      productName: Item.find((i) => i.value === formik.values.productId),
      quantity: formik.values.quantity,
      unit: selectedProduct.unit,
    };
    setItems([...items, newItem]);
   
    setNewItemData({
      productId: "",

      quantity: "",
    });
    let updatedItems = Item.filter((it)=> it.value!==formik.values.productId)
    setData(updatedItems)
    formik.values.productId = "";
    formik.values.quantity = "";
    setError('')
  }
  };

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter((_, index) => index !== indexToDelete);
    let ddUpdatedItem = items.find((_,i)=> i === indexToDelete)
       setItems(updatedItems);
    let tempDd = ddUpdatedItem.productName
    setData([...Item,tempDd])
    // localStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew((prevState) => ({ ...createNew, image: "" }));
    formik.values.image = "";
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
        customerId: yup.string().required('Customer is required!'),
        salesPerson: yup.string().required('Sales person is required!'),
        customerRfqRef: yup.string().required('Customer RFQ Reference person is required!')
    }),
    onSubmit: async (value, { resetForm }) => {
      if (items.length === 0 && data?.productList?.length===0) {
        setError('Product table is empty');
        return;
      }
      let formData = new FormData();
      const valueWithItems = {
        ...value,
        productList: items,
        removedProducts: remove,
        filesToRemove: fileRemoveList,
      };
     
      formData.append("prDetails", JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      editFormData(`/PR/edit-PR/${data.prId}`, formData, resetForm, from);
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
     
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }

  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

  const selectedProductIds = [
    ...items.map((item) => item.productId),
    ...dataa.productList.map((product) => product.productId)
  ];
  
  // Step 2: Filter out the selected products from the dropdown options
  const filteredOptions = Item.filter(
    (product) => !selectedProductIds.includes(product.value)
  );
  
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Procurement" },

            {
              name: "Purchase Request",
              link: "/procurement/purchase-requests/",
            },

            { name: "Edit" },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuInput
              label="Description"
              type="text"
              formik={formik}
              placeholder="Description"
              name="description"
              width="w-full md:w-1/2 lg:w-[66%]"
            />
            <FormikSelectTwo
              disabled={true}
              type="text"
              label="Customer "
              placeholder="Customer "
              Options={customers}
              formik={formik}
              name="customerId"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <NuInput
              disabled={true}
              label="Customer RFQ Reference"
              type="text"
              formik={formik}
              placeholder="Customer Reference"
              name="customerRfqRef"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <MultiSelect
              // type="text"
              label="RFQ Assignee"
              placeholder="RFQ Assignee"
              Options={RFQAssignee}
              formik={formik}
              name="rfqAssignee"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Dead Line"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="deadLine"
              width="w-full md:w-1/2 lg:w-1/3"
            />

            {/* <FormikSelectTwo
              type="text"
              label="Cost Code"
              placeholder="Choose"
              Options={Costcode}
              formik={formik}
              name="costCode"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
           
            
            <FormikSelectTwo
              type="text"
              label="Sales Person"
              placeholder="Choose"
              Options={Salesman}
              formik={formik}
              name="salesPerson"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />

            {/* <FormikSelectTwo
              type="text"
              label="Is Active"
              placeholder="Is Active"
              Options={[
                { value: false, label: "No" },
                { value: true, label: "Yes" },
              ]}
              formik={formik}
              name="isActive"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={status}
              formik={formik}
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
  {/* <FormikSelectTwo
                type="text"
                label="Approved Status"
                placeholder="Approved Status"
                Options={approvalStatus}
                formik={formik}
                name="approvalStatus"
                width="w-full w-full md:w-1/2 lg:w-1/3 "
              /> */}
               {/* <NuTextArea
              label="Description"
              type="text"
              formik={formik}
              placeholder="Description"
              name="description"
              width="w-full md:w-1/2 "
            /> */}
            <NuTextArea
              label="Notes From Customer"
              type="text"
              formik={formik}
              placeholder="Notes From Customer"
              name="notesFromCustomer"
              width="w-full md:w-1/2"
            />
            <NuTextArea
              label="Comments"
              type="text"
              formik={formik}
              placeholder="Comments"
              name="comments"
              width="w-full md:w-1/2"
            />
            <div className='w-full'>
            <label className="text-xs 2xl:text-sm px-4">PR Attachment</label>
          
            <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          x
                        </div>
                        <div
                          className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                          <img src={imgs?.file} alt={imgs?.file} />
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
</div>
            <div className="w-full h-0.5 opacity-20 bg-[#C4C2C2] my-6 mx-auto"></div>
            <label className="text-xs 2xl:text-sm px-3 py-1">Requested Items</label>

            <div className="w-full flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid rounded-lg">
            <SelectWithError
  type="text"
  label="Product"
  placeholder="Product"
  Options={filteredOptions} 
  formik={formik}
  name="productId"
  width="w-full md:w-1/2 lg:w-[67%]"
  ErrMessage={productError}
/>
         <NuInputWithError
           label="Quantity"
           type="number"
           formik={formik}
           placeholder="Quantity"
           name="quantity"
           width="w-full md:w-1/2 lg:w-1/4 "
           ErrMessage={quantityError}
         />

<button
                onClick={handleAdd}
                  type="button"
                 className=" p-1 mt-10 m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
                           </div>
            <div className="w-full bg-white overflow-hidden mt-5">
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6">
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.No </p>

                <p className=" w-[60%] text-left text-white text-xs font-medium">
                  Product
                </p>
                <p className=" w-[10%] text-center pl-4 text-white text-xs font-medium">
                  Unit
                </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium">
                  Quantity
                </p>

                <p className=" w-[5%] text-right text-white text-xs font-medium">
                  Action
                </p>
              </div>
              {(items?.length>0 || dataa.productList?.length>0)?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {items.map((List, index) => {
                return (
                  <div
                    key={index}
                    className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                  >
                    <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                    </p>
                    <p className=" w-[60%] text-left text-[#606279] text-xs font-light ">
                      {List?.productName.label}
                    </p>
                    <p className=" w-[10%] text-center pl-4 text-[#606279] text-xs font-light">
                      {List?.unit}
                    </p>

                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light">
                      {List?.quantity}
                    </p>

                    <div className="w-[5%] text-right">
                      <button
                        onClick={() => handleDeleteItem(index)}
                        type="button"
                        className="text-red-500 cursor-pointer  ml-4 border-none"
                      >
                        <Delete color="red" height={4} />
                      </button>
                    </div>
                  </div>
                );
              })}
              {dataa?.productList?.map((List, index) => {
                return (
                  <div
                    key={index}
                    className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100"
                  >
                     <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1 + items.length}
                    </p>
                    <p className=" w-[60%] text-left text-[#606279] text-xs  font-light ">
                      {List?.product_name}
                    </p>
                    <p className=" w-[10%] text-center pl-4 text-[#606279] text-xs font-light">
                      {List?.product_unit}
                    </p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light ">
                      {List?.quantity}
                    </p>

                    <div className="w-[5%] text-right">
                      <button
                        onClick={() => handleDelete(List?.productId)}
                        type="button"
                        className="text-red-500 cursor-pointer  ml-4 border-none"
                      >
                        <Delete color="red" height={4} />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

              {/* </div> */}
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 py-4">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    Oncancel();
                  }}
                />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
    </div>
  );
}

export default EditPr;