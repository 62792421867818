import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const Category = () => {
  return (
    <SettingsMetaData propertyName={"Category__Payable"} viewPropertyName={"Category"} />
  );
};

export default Category;
