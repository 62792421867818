import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const StatusSalesorder = () => {
  return (
    <SettingsMetaData propertyName={"Status_SalesOrder"} viewPropertyName={"Status"} />

  )
}

export default StatusSalesorder