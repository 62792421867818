import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import Modal from '../../../../Components/Utils/AttachmentView';
import { useState } from 'react';
import NuDate from '../../../../Components/Utils/NuDate';

const ViewShipmentTracking = () => {
  // const { patientsViewId } = useParams();
    const location = useLocation();
    const data = location?.state?.data||{};
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    function handleImageClick(img) {
      setSelectedImage(img);
      setIsModalOpen(true);
    }
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
         items={
          [{
            name: "Shipment Tracking",
            link: "/shipping/shipment-tracking"
          },
          {
            name: "View Shipment Tracking"
          }]
         }
        />
        <div className=" flex gap-2">
        <Link to={'/shipping/shipment-tracking'}><BackButton link={true}/></Link>
          <Link to={`/shipping/shipment-tracking/edit/${data?.shippingId||""}`} state={{data: data, from:`/shipping/shipment-tracking/view/${data?.shippingId||""}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3">
      <NormalHeading title={"Shipment Tracking"} />
    <div className='flex'> 
         <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
            {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Reffrence</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.ref || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchase Request</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.prRef || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Purchase Order</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.poRef || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Agent</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.agentName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Date By PO</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.deliveryDatebyPO && NuDate({value: data?.deliveryDatebyPO, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipped On</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.shippedOn && NuDate({value: data?.shippedOn, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Estimated Delivery Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.ETD && NuDate({value: data?.ETD, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Estimated Arrival Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.ETA && NuDate({value: data?.ETA, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Actual Arrival Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.actualArrivalDate && NuDate({value: data?.actualArrivalDate, format: "DD-MM-YYYY"}) || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipping Method</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.shippingMethod ?'Yes':'No'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipped On</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.shippedOn || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shipment Status</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.shipmentStatus || '-'}</p>
                  </div>
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
                 <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Shippment Bill Number</p>
                    <p className='w-1/2 py-2 text-xs  pl-5 text-[#606279]'>{data?.shipmentBill || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Permit Requred</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.permitReq === true ? "Yes" : "No" || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Delivery Person Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.deliveryPersonName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Identity Type</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.identityType || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Identity Number</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.identityNumber || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vehicle Type</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.vehicleType || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Vehicle Number</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.vehicleNumber || '-'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>insuracePolicyNumber</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.insuracePolicyNumber || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>insurance Provider</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.insuranceProvider || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Insurance Type</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.insuranceType || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage Start Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.coverageStartDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage End Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.coverageEndDate || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Coverage Amount</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.coverageAmount || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Insurance Premium</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.insurancePremium || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Claim Contact Info</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.claimContactInfo || '-'}</p>
                  </div> */}
          </div>
        </div>
      </div>
      <div className='w-full'>
      <NormalHeading title={"Shipment Tracking Attachment"} />
            {/* <label className="text-xs 2xl:text-sm px-2">Shipment Tracking Attachment</label> */}
            <div className="md:flex justify-between mt-3 w-full">
              {data?.attachments.length > 0 ?
                            <div className="w-full flex gap-2 items-center flex-wrap pl-3">
                                {data?.attachments &&
                                    data?.attachments.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                {/* <div
                                                    className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                                                    onClick={() => UpdateAttachmentList(imgs?.file)}
                                                >
                                                    x
                                                </div> */}
                                                <div className=" w-32 h-14 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(imgs)}>
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} 
                                                    className="w-full h-full object-fill"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                {/* <Attachement
                                    handleChange={handleImageChange}
                                    preview={multiImage || ""}
                                    width="w-full md:w-1/2"
                                    label="Attach"
                                    multiple={true}
                                /> */}
                            </div>
                            :
                             <p>-</p> }
                        </div>
                        </div> 
                        {/* <div className='w-full mt-5'>
      <NormalHeading title={"Insurence Certificate"} /> */}
            {/* <label className="text-xs 2xl:text-sm px-2">Insurence Certificate</label> */}
            {/* {data?.insuranceCertificate?.file ? 
            <div className="md:flex justify-between mt-3 w-full">
                            <div className="w-full flex gap-2 items-center flex-wrap pl-3"> */}
                                {/* {data?.insuranceCertificate?.file &&
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                // key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-32 h-14 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" onClick={() => handleImageClick(data?.insuranceCertificate?.file)}>
                                                    <img src={data?.insuranceCertificate?.file} alt={data?.insuranceCertificate?.file} 
                                                    className="w-full h-full object-fill"
                                                    />
                                                </div>
                                            </div>
                                      } */}
                                {/* <Attachement
                                    handleChange={handleImageChange}
                                    preview={multiImage || ""}
                                    width="w-full md:w-1/2"
                                    label="Attach"
                                    multiple={true}
                                /> */}
                            {/* </div>
                        </div>
                        :
                         <p>-</p>     }
                        </div> */}
          </div> 
          <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={selectedImage?.file} alt={selectedImage?.file} />
      </Modal>
      </div>
  
  )
};
export default ViewShipmentTracking;