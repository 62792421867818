import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'center', 
    marginBottom: 10,
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  headingText: {
    marginTop: 3,
    fontSize: 14,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  label: {
    width: '50%',
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    width: '50%',
    padding: 5,
    fontSize: 12,
  },
  productRow: {
    flexDirection: 'row',
    padding: 5,
  },
  productHeader: {
    backgroundColor: '#1e90ff',
    color: '#fff',
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  productCell: {
    padding: 5,
    fontSize: 12,
  },
  note: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between', 
    borderColor: '#ccc',
  },
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="portrait">
      <View style={styles.headingContainer}>
        <Text style={styles.headingTitle}>Supplier Quote</Text>
        <Text style={styles.headingText}>: {data.ref}</Text>
      </View>

      <View style={styles.section}>
        {[
          { label: 'PR Reference', value: data.prRef },
          { label: 'RFQ Reference', value: data.rfqRef },
          { label: 'Quoted Date', value: data.quoteDate || 'N/A'},
          { label: 'Quotation Received Date', value: data.dateReceived || 'N/A'},
          { label: 'Supplier', value: data.vendorName || 'N/A'},
          { label: 'Supplier Address', value: data.supplierAddress || 'N/A'},
          { label: 'Shipping Address', value: data.deliveryAddress || 'N/A'},
          { label: 'Billing Address', value: data.billingAddress || 'N/A'},
          { label: 'Payment Terms', value: data.paymentTerms || 'N/A'},
          { label: 'Delivery Terms', value: data.deliverTerms || 'N/A'},
          { label: 'Payment Method', value: data.paymentMethod || 'N/A'},
          { label: 'Payment Status', value: data.paymentStatus || 'N/A'},
          { label: 'Status', value: data.status || 'N/A'},
          { label: 'Quotation Validity', value: data.validity || 'N/A'},
          { label: 'Lead Time', value: data.leadTime || 'N/A'},
          { label: 'Reviewed By', value: data.reviewedByName || 'N/A'},
          // { label: 'Comments', value: data.comments },
        ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>{item.value}</Text>
          </View>
        ))}
      </View>

      <View style={{ marginTop: 20, backgroundColor: '#D3D3D3' }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            'S.N', 'Product Name', 'Item Code', 'Product Unit',
            'Quantity', 'Price'
          ].map((header, index) => {
            let width=[5, 40, 15, 10, 10, 15]
            return(
            <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
              {header}
            </Text>
          )})}
        </View>
        {data.quoteProducts.map((product, index) => (
          <View key={index} style={styles.productRow}>
            <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
            <Text style={[styles.productCell, { width: '40%' }]}>{product.product_name}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.item_code}</Text>
            <Text style={[styles.productCell, { width: '10%' }]}>{product.product_unit}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
            {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
            <Text style={[styles.productCell, { width: '10%' }]}>{product.quantity}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.price}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
          </View>
        ))}
      </View>

      <View style={styles.note}>
        <Text>Special Customer: {data.specialNotes || 'N/A'}</Text>

        <Text>Total Price: {data.totalPrice}</Text>
      </View>
    </Page>
  </Document>
);

export default PDFDocument;


