import React, { useState } from "react";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from "formik";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import * as yup from "yup";
import { NuInput, NuMobileNumber, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import useFetchMetaStar from "../../../../Components/Hooks/useFetchMetaStar";
import { TextMediumBase, TextMediumSM } from "../../../../Components/Text/MediumText";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
import { useToast } from "../../../../Components/Others/ToastServices";
import { CountryMIN } from "../../../../assets/CountryMini";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateLeads() {
  const {value:Source ,getData:refSource} = useFetchMataData(`/dropdown/dropdown-details/`,'lead_Source')
  const {value:Industry ,getData:refIndustry} = useFetchMataData(`/dropdown/dropdown-details/`,'Industry_leads')
  const {value:CoversionProbablity ,getData:refCoversionProbablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Lead_CoversionProbablity')
  const {value:CompanySize ,getData:refCompanySize} = useFetchMataData(`/dropdown/dropdown-details/`,'CompanySize_leads')
  const {value:LeadsRatings} = useFetchMetaStar(`/dropdown/dropdown-details/`,'leadsRating')
  const toast = useToast();


  const [image, setImage] = useState([]);
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  
  const [createNew, setCreateNew] = useState({
    industry: "",
    companyAddress: "",
    companySize: "",
    contactInfo: "",
    conversionProbabilities: "",
    description: "",
    leadOwner: "",
    leadSource: "",
    name: "",
    ratings: "",
    comments: "",
    country:"",
    city:"",
    postalCode:"",
    state:"",
    address1:"",
    address2:"",
    email: "",
    mobile: "",
    countryCode: "",
    
  });

  const [selectedFile, setSelectedFile] = useState(null);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        toast.open({
          type:"warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setImage(tempArr)
  }

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required('Name is required!'),
      industry: yup.string().required('Industry is required!'),
      email: yup.string().required('Email is required!'),
      mobile: yup.string().required('Mobile is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("leadDetails", JSON.stringify(value));
      // formData.append("attachments", image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 
      addFormData("/leads/new-lead", formData, resetForm);
    },
  });
  return (
    <div className="w-full h-full px-4 py-2 ">
      <div className="flex justify-between">
        <Breadcrumb
          items={[
            {
              name: "Lead",
              link: "/marketing/leads"

            },
            {
              name: "Create Lead"
            }
          ]}
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex py-2 justify-start content-start flex-wrap  overflow-y-auto">


            <NuInput
              type="text"
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <DropDownAddMetaData
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refIndustry}
                displayProperty={"Industry"}
                propertyName={"Industry_leads"}
                isRequired={true}
              />
             <DropDownAddMetaData
                label="Company Size"
                placeholder="Choose"
                Options={CompanySize}
                formik={formik}
                name="companySize"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCompanySize}
                displayProperty={"Company Size"}
                propertyName={"CompanySize_leads"}
              />

            
           
           <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
          
            <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
            <FormikSelectTwo
              Options={LeadsRatings}
              label="Performance Ratings"
              placeholder="Ratings"
              formik={formik}
              name="ratings"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <DropDownAddMetaData
                label="Conversion Probabilities"
                placeholder="Choose"
                Options={CoversionProbablity}
                formik={formik}
                name="conversionProbabilities"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCoversionProbablity}
                displayProperty={"Conversion Probabilities"}
                propertyName={"Lead_CoversionProbablity"}
              />
           
           
            {/* <NuInput
              type="text"
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuInput
              type="text"
              label="LeadOwner"
              placeholder="LeadOwner"
              formik={formik}
              name="leadOwner"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <DropDownAddMetaData
                label="Lead Source"
                placeholder="Choose"
                Options={Source}
                formik={formik}
                name="leadSource"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refSource}
                displayProperty={"Lead Source"}
                propertyName={"lead_Source"}
              />
             
            <div className="w-11/12 h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

             <div className="w-full ml-6">
                 <TextMediumBase content={"Address"} />
            </div>
           
           
            <FormikSelectTwo
              type="text"
              label="Country"
              placeholder="Country"
              Options={CountryMIN}
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuInput
              type="text"
              label="State"
              placeholder="State"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuInput
              type="text"
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
            <NuInput
              type="number"
              label="Zip Code"
              placeholder="Zip Code"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="flex w-full flex-wrap">
            <NuTextArea
              type="test"
              label="Address Line 1"
              placeholder="Address"
              formik={formik}
              name="address1"
              new_width="w-full md:w-1/2 lg:w-1/2"
            />
             <NuTextArea
              type="number"
              label="Address Line 2"
              placeholder="Address "
              formik={formik}
              name="address2"
              new_width="w-full md:w-1/2 lg:w-1/2"
            />
            
           
            </div>

            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuUpload
              label="Attachments"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> */}
             <div className='w-full '>
              {/* <div className="ml-10 w-[15%] "> */}
              
              <Attachement
                  handleChange={handleImageChange}
                  preview={image||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}

                />
                        {/* </div> */}
                        </div>
                        <div className="w-full my-4 mt-8 ">
              <div className="flex justify-end gap-4">
              <CancelButton
                  handleClick={() => {
                    Oncancel(-1);
                  }}
                />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateLeads;
