
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const Desigination = () => {
  return (
    <SettingsMetaData propertyName={"desigination_Supplier"} viewPropertyName={"Desigination"} />
  );
}

export default Desigination;
