import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input';
import DeleteIcon from '../../../../Components/Icons/Delete';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import axiosInstance from '../../../../Services/axiosCommon';
import useRfqSuppliers from './Hooks/UseRFQSuppliers copy';
import usePrProducts from './Hooks/UsePrProducts';
import { useFormik } from 'formik';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useLocation } from 'react-router-dom';
import UseEditData from '../../../../Components/Hooks/useEditData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from 'yup';

function ViewSQCreate() {
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_SQ')

    const { value: Validity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Validity_Supplierquotes');
    const { value: PaymentTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'PaymentTerms_Supplierqoutes');
    const { value: LeadTime } = useFetchMataData(`/dropdown/dropdown-details/`, 'LeadTime_Supplierqoutes');
    const { value: DeliveryTerms } = useFetchMataData(`/dropdown/dropdown-details/`, 'DeliveryTerms_Supplierqoutes');
    const { value: Availability } = useFetchMataData(`/dropdown/dropdown-details/`, 'Availability_Supplierqoutes');
    const { value: paymentMethod } = useFetchMataData(`/dropdown/dropdown-details/`, 'paymentMethod_supplierquotes');
    const { value: Status } = useFetchMataData(`/dropdown/dropdown-details/`, 'Status_Supplierqoutes');
  
    const { data: PR } = UseDropDown('/PR/drop-down');
    const { data: user } = UseDropDown('/user/drop-down');
  
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
  
    const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
    const [dataa, setData] = useState(data);
    const [items, setItems] = useState([]);
    const [remove, setRemove] = useState([]);
    const [newItemData, setNewItemData] = useState({
      productId: "",
      quantity: "",
      price: "",
      product_unit:""
    });
    const [missingProducts, setMissingProducts] = useState([]);
  
    const [createNew, setCreateNew] = useState({
      dateReceived: data.dateReceived,
      deliverTerms: data.deliverTerms,
      paymentTerms: data.paymentTerms,
      paymentMethod: data.paymentMethod,
      totalPrice: data.totalPrice,
      vendorId: data.vendorId,
      prId: data.prId,
      rfqId: data.rfqId,
      ref: data.ref,
      availability: data.availability,
      validity: data.validity,
      specialNotes: data.specialNotes,
      leadTime: data.leadTime,
      comments: data.comments,
      removedProducts: [],
      price: data.price,
      quantity: data.quantity,
      status:data.status,
      quoteDate:data.quoteDate,
    });
  
    const [createtNew, setCreatetNew] = useState({
     
      totalPrice: data.totalPrice || 0,
    });
    
   
    const handleDelete = (quotProductId, list) => {
      const updatedquoteProducts = dataa?.quoteProducts.filter(item => item.quotProductId !== quotProductId);
    
     
      const deletedProduct = dataa?.quoteProducts.find(item => item.quotProductId === quotProductId);
    
      if (deletedProduct && deletedProduct.price && deletedProduct.quantity) {
        const priceToRemove = deletedProduct.price || 0;
        const quantityToRemove = deletedProduct.quantity || 0;
        const reduction = priceToRemove * quantityToRemove;
    
        const newTotalPrice = formik.values.totalPrice - reduction;
    
        setData(prevState => ({
          ...prevState,
          quoteProducts: updatedquoteProducts,
          totalPrice: newTotalPrice
        }));
    
        formik.setFieldValue('totalPrice', newTotalPrice);
    
        setRemove([...remove, quotProductId]);
      }
    }; 
     
       const handleDeleteItem = (indexToDelete, list) => {
      const updatedItems = items.filter((_, index) => index !== indexToDelete);
      setItems(updatedItems);
      formik.setFieldValue('totalPrice', formik.values.totalPrice - (list.price * list.quantity));
  
    };
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      //   supplierId: yup.string().required('Supplier is required'),
      //   validity: yup.string().required('Validity is required'),
      //   paymentTerm: yup.string().required('Payment Term is required'),
      //   leadTime: yup.string().required('Lead Time is required'),
      }),
      onSubmit: async (value, { resetForm }) => {
        const valueWithItems = {
          ...value,
          products: items,
          removedProducts: remove
        };
        editData(`/supplier-quotations/quotation-revision/${data.id}`, valueWithItems, resetForm, from);
      }
    });
  
    const suppliers = useRfqSuppliers(formik.values.prId);
    const products = usePrProducts(formik.values.prId);
  
    const handleAdd = () => {
      const newItem = {
        productId: formik.values.productId,
        product_name: missingProducts.find((i) => i.value === formik.values.productId)?.label,
        price: formik.values.price,
        quantity: prlist.find(item => item.productId === formik.values.productId)?.quantity || '',
        product_unit: prlist.find(item => item.productId === formik.values.productId)?.product_unit || ''
      };
      setData({ ...dataa, quoteProducts: [...dataa.quoteProducts, newItem] });
      const newTotalPrice = parseFloat(formik.values.totalPrice) + (parseFloat(newItem.price) * parseFloat(newItem.quantity));
      formik.setFieldValue('totalPrice', newTotalPrice);
      setMissingProducts(missingProducts.filter(product => product.value !== formik.values.productId));
    
      formik.resetForm({
        values: {
          ...formik.values,
          productId: '',
          quantity: '',
          price: ''
        }
      });
    };
    
    const [prlist, setPrlist] = useState([]);
  
    const getPr = (prId) => {
      if (prId !== "") {
        axiosInstance
          .get(`/PR/pr-products/${prId}`)
          .then(res => {
            let data = res.data.data;
            setPrlist(data);
          })
          .catch(err => {
            console.log(err, "err");
          });
      }
    };
  
    useEffect(() => {
      if (formik.values.prId) {
        getPr(formik.values.prId);
      }
    }, [formik.values.prId]);
  
    useEffect(() => {
      const quoteProductIds = dataa?.quoteProducts.map(item => item.productId);
      const missing = prlist
        .filter(prItem => !quoteProductIds.includes(prItem.productId))
        .map(item => ({
          value: item.productId,
          label: item.product_name,
          unit:item.product_unit
        }));
      setMissingProducts(missing);
    }, [prlist, dataa?.quoteProducts]);
  
    
    const handlePriceChange = (e, index, isDataa = true) => {
      const value = e.target.value;
      if (isDataa) {
        const updatedDataa = { ...dataa };
        updatedDataa.quoteProducts[index].price = value;
        setData(updatedDataa);
      } else {
        const updatedItems = [...items];
        updatedItems[index].price = value;
        setItems(updatedItems);
      }
      updateTotalPrice();
    };
    
    const handleQuantityChange = (index, newQuantity, isDataa = true) => {
      if (isDataa) {
        const updatedDataa = { ...dataa };
        updatedDataa.quoteProducts[index].quantity = newQuantity;
        setData(updatedDataa);
      } else {
        const updatedItems = [...items];
        updatedItems[index].quantity = newQuantity;
        setItems(updatedItems);
      }
      updateTotalPrice();
    };
    
    const updateTotalPrice = () => {
      const totalPrice = [...dataa.quoteProducts, ...items].reduce((total, item) => {
        return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
      }, 0);
      formik.setFieldValue('totalPrice', totalPrice);
    };
    
  
    
    const handleIncrement = (index, isDataa = true) => {
      const newQuantity = isDataa ? dataa.quoteProducts[index].quantity + 1 : items[index].quantity + 1;
      handleQuantityChange(index, newQuantity, isDataa);
    };
  
    const handleDecrement = (index, isDataa = true) => {
      const newQuantity = isDataa ? Math.max(1, dataa.quoteProducts[index].quantity - 1) : Math.max(1, items[index].quantity - 1);
      handleQuantityChange(index, newQuantity, isDataa);
    };
  
    const handleInputChange = (event, index, isDataa = true) => {
      const value = Math.max(Number(event.target.value), 1);
      handleQuantityChange(index, value, isDataa);
    };
  
    
    return (
      <div className="w-full h-full py-2 px-4 ">
        <div className="flex justify-between ">
          <Breadcrumb
            items={[
              {
                name: "Supplier Quotes",
                link: "/procurement/supplier-quotes/viewsqrevision",
              },
              { name: "Revision Create" },
            ]}
          />
          <div className="flex gap-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
            <div className="w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto">
              <FormikSelectTwo
                type="text"
                label="Purchase Requests"
                placeholder="Purchase Requests"
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Supplier"
                disabled={true}
                placeholder="Supplier"
                Options={suppliers}
                formik={formik}
                name="vendorId"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuInput
                disabled={true}
                label="Quotation Reference"
                type="text"
                formik={formik}
                placeholder="Enter Reference"
                name="ref"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <NuDate
                label="Quote date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="quoteDate"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              <NuDate
                label="Quotation Received date"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="dateReceived"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Quotation Validity"
                placeholder="Validity"
                Options={Validity}
                formik={formik}
                name="validity"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <FormikSelectTwo
                type="text"
                label="Product Availability"
                placeholder="Availability"
                Options={Availability}
                formik={formik}
                name="availability"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              <FormikSelectTwo
                type="text"
                label="Delivery Terms"
                placeholder="Delivery Terms"
                Options={DeliveryTerms}
                formik={formik}
                name="deliverTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Payment Terms"
                placeholder="Payment Terms"
                Options={PaymentTerms}
                formik={formik}
                name="paymentTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Lead Time"
                placeholder="Lead Time"
                Options={LeadTime}
                formik={formik}
                name="leadTime"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Payment Method"
                placeholder="Payment Method"
                Options={paymentMethod}
                formik={formik}
                name="paymentMethod"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <FormikSelectTwo
              type="text"
              label="Approval Status"
              placeholder="Approval Status"
              Options={ApprovalStatus}
             formik={formik}
             name="approvalStatus"
             width="w-full md:w-1/2 lg:w-1/3"
            /> */}
              <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Status"
                Options={Status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <NuTextArea
                label="Special Notes"
                placeholder="Special Notes"
                formik={formik}
                name="specialNotes"
                width="w-full md:w-1/2 lg:w-1/3"
              />
  
              <NuTextArea
                label="Comments"
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
              <div className="w-full bg-white overflow-y-scroll mt-5">
                <div className="w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6">
                  <p className=" w-[30%] text-left text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                    Product
                  </p>
                  <p className=" w-[15%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                    Unit
                  </p>
                  <p className=" w-[20%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                    Quantity
                  </p>
                  <p className=" w-[15%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                    Price
                  </p>
                  <p className=" w-[5%] text-center text-white text-sm md:text-sm lg:text-xs xl:text-sm font-medium">
                    Action
                  </p>
                </div>
                {dataa?.quoteProducts.map((List, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
                    >
                      <p className=" w-[30%] text-left text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light ">
                        {List?.product_name}
                      </p>
                      <p className=" w-[15%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                        {List?.product_unit}
                      </p>
                      {/* <p className=" w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light ">
                        {List?.quantity}
                      </p> */}
                      <div className="w-[20%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                        <button
                          type="button"
                          className="border rounded-full p-1 m-1"
                          onClick={() => handleDecrement(index)}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          className="w-[20%] text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                          name="quantity"
                          value={List.quantity}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                        <button
                          type="button"
                          className="border rounded-full p-1 m-1"
                          onClick={() => handleIncrement(index)}
                        >
                          +
                        </button>
                      </div>
                      <input
                        type="number"
                        className="w-[15%] py-1 text-center border text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light"
                        name="price"
                        formik={formik}
                        placeholder="Enter the price"
                        value={List.price || ""}
                        onChange={(e) => handlePriceChange(e, index)}
                      />
                      {/* <p className=" w-[15%] text-center text-[#606279] text-sm md:text-sm lg:text-xs xl:text-sm font-light">
                        {List?.price}
                      </p> */}
                      <div className="w-[5%] text-center">
                        <button
                          onClick={() => handleDelete(List?.quotProductId)}
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
  
             
              <div className=" w-full my-6  flex justify-end items-center gap-4">
                <TextMediumBase content={"Total Price : "} />
                <NuInput
                  label=""
                  type="number"
                  formik={formik}
                  placeholder="Total Price"
                  name="totalPrice"
                  width="w-full md:w-1/2 lg:w-1/3"
                  disabled={true}
                />
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton
                    handleClick={() => {
                      Oncancel(-1);
                    }}
                  />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  
  
  

export default ViewSQCreate