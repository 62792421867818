import React, { useEffect, useState } from 'react'
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { NuDate } from '../../../../Components/Utils/Input';
import { GetAccess } from '../../../../Components/Utils/roles';

function CustomerOrderIndex() {
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_CO')

    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
      status:"",
      receivedOnFrom:"",
      receivedOnTo:"",
      deadLineFrom:"",
      deadLineTo:"",
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      status:"",
      receivedOnFrom:"",
      receivedOnTo:"",
      deadLineFrom:"",
      deadLineTo:"",
    });
  
    const clearFilter = ()=> {
      setParams({...params, status:"",
        receivedOnFrom:"",
        receivedOnTo:"",
        deadLineFrom:"",
        deadLineTo:"",})
        formik.values.status=""
        formik.values.receivedOnFrom=""
        formik.values.receivedOnTo=""
        formik.values.deadLineFrom=""
        formik.values.deadLineTo=""

        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,status:value.status, receivedOnFrom:value.receivedOnFrom,receivedOnTo:value.receivedOnTo,deadLineFrom:value.deadLineFrom,deadLineTo:value.deadLineTo})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          status:"",
      receivedOnFrom:"",
      receivedOnTo:"",
      deadLineFrom:"",
      deadLineTo:"",
        });
      }
    })
  
    const { data, totalCount, getData } = UseGetDataSearch('/customer-orders/',params);
  
    const defaultHeader = [
      {
        title: "ID",
        width: "5%",
        field: "id",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Customer PO Reference",
        width: "18%",
        isLink: true,
        id: "id",
        link: '/sales/customer-order/view/',
        field: "ref",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Customer Quote",
        width: "10%",
        field: "cqRef",
        textAlign: "center",
        rowAlign: "center",
      },
     
      // {
      //   title: "DeadLine",
      //   width: "10%",
      //   field: "deadLine",
      //   type:"date",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Received On",
        width: "10%",
        field: "receivedOn",
        type:"date",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
            // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/customer-orders/`,
          moduleName:"Customer order",
          from:`/sales/customer-order`,
          editLink:"/sales/customer-order/edit/",
          viewLink:"/sales/customer-order/view/"
  
        }
      },
    ];
    const availableHeaders = [
        {
            title: "ID",
            width: "5%",
            field: "id",
            textAlign: "center",
            rowAlign: "center",
          },
          {
            title: "Customer PO Reference",
            width: "10%",
            isLink: true,
            id: "id",
            link: '/sales/customer-order/view/',
            field: "ref",
            textAlign: "center",
            rowAlign: "center",
          },
          {
            title: "Purchase Requests",
            width: "10%",
            
            field: "prRef",
            textAlign: "center",
            rowAlign: "center",
          },
          
          {
            title: "Customer Quote",
            width: "10%",
            field: "cqRef",
            textAlign: "center",
            rowAlign: "center",
          },
         
          // {
          //   title: "Dead Line",
          //   width: "10%",
          //   field: "deadLine",
          //   type:"date",
          //   textAlign: "center",
          //   rowAlign: "center",
          // },
          {
            title: "Received On",
            width: "10%",
            field: "receivedOn",
            type:"date",
            textAlign: "center",
            rowAlign: "center",
          },
          {
            title: " Total Price",
            width: "5%",
            field: "totalPrice",
            textAlign: "center",
            rowAlign: "center",
          },
      {
        title: " Assignee",
        width: "5%",
        field: "assigneeName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Sales Person",
        width: "5%",
        field: "salesPersonName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Verified By",
        width: "5%",
        field: "verifiedByName",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Reviewed By",
        width: "5%",
        field: "reviewedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Approved By",
        width: "5%",
        field: "approvedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"id",
          removeValue:"ref",
          url:`/customer-orders/`,
          moduleName:"Customer order",
          from:`/sales/customer-order`,
          editLink:"/sales/customer-order/edit/",
          viewLink:"/sales/customer-order/view/"
  
        }
      },
    ];
    useEffect (() => {
      getData()
    },[page,pageCount])
    const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
          <Breadcrumb
            items={[
              { name: "Sales" },
  
              { name: "Customer Order" },
            ]} />
          <div className=' flex gap-2'>
          <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
         {/* <CreateButton label='Import' /> */}
         {GetAccess
         ("customer_order_create") &&  <Link to="/sales/customer-order/create"><CreateButton label='Customer Order' /></Link>}
          </div>
        </div>
  
        <CustomDataTable 
        fetcher={getData}
          data={data} 
          availableHeaders={availableHeaders} 
          defaultHeader={defaultHeader}
          bodyHeight='max-h-[calc(100vh-260px)]'
          height='h-[calc(100vh-145px)]'
          total={totalCount}
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
          title='Customer Order List'
          storageName="COTable"
          editAccess="customer_order_edit"      
          deteleAccess="customer_order_delete" 
          />
            {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Customer Order</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">RequestedOn Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
                label="From"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOnFrom"
                width="w-full "
              />
                <NuDate
                label="To"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOnTo"
                width="w-full "
              />
              </div>
              <p className="py-2 px-6 text-m">Dead Line</p>
                    <div className="flex justify-between  w-full ">
                <NuDate
                label="From"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLineFrom"
                width="w-full "
              />
                <NuDate
                label="To"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLineTo"
                width="w-full "
              />
</div>
                    <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Status"
                Options={status}
                formik={formik}
                name="status"
                width="w-full "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
      </div>
    )
  }
  

export default CustomerOrderIndex