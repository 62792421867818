import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import capitalizeFirstLetter from '../../../../../Components/Utils/Funcs';
import NuDate from '../../../../../Components/Utils/NuDate';
import seal from '../../../../../assets/images/seal.png'
import CompanyName from '../../../../../assets/images/companyName.png'
import CompanyLogo from '../../../../../assets/images/companyLogo.png'

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  styImage:{
    display:"flex",
    justifyContent: "space-between",
    alignContent:"flex-start",
    flexDirection:"row",
    width: "100%"
  },
  headers: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: 5
  },
  side:{
    fontSize: 12,
  },
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'center', 
    marginBottom: 10,
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  headingText: {
    marginTop: 5,
    fontSize: 14,
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 10,
  },
  label: {
    width: '50%',
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  value: {
    width: '50%',
    padding: 5,
    fontSize: 12,
  },
  productRow: {
    flexDirection: 'row',
    padding: 5,
  },
  productHeader: {
    backgroundColor: '#f0f0f0',
    // color: '#fff',
    padding: 8,
    // fontSize: 12,
    fontWeight: 'bold',
  },
  productCell: {
    padding: 5,
    fontSize: 12,
  },
  note: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between', 
    borderColor: '#ccc',
  },
  supplierItem: {
    marginBottom: 2,
  },
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="portrait">
    <View style={styles.header}>
        <View style={styles.styImage}>
          {/* <Text style={styles.title}>Yanama Trading Company</Text> */}
          <Image src={CompanyName} style={{width: "60%" }}></Image>
          <Image src={CompanyLogo} style={{width: "20%" }}></Image>
        </View>
      </View>
      <View style={styles.headers}>
        <Text style={styles.title}>RFQ : {data.ref || 'N/A'}</Text>
        <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
        {/* <View>
          <Text style={styles.side}>Date: {data.date || 'N/A'}</Text>
          <Text style={styles.side}>PO#: {data.ref || 'N/A'}</Text>
        </View> */}
      </View>

      <View style={{
                    width: "100%",
                    borderTop: "1px solid #f0f0f0",
                    borderBottom: "1px solid #f0f0f0",
                    paddingVertical: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                    }}>
        <View style={{width: "50%"}}>
            <Text style={styles.label}>{"Supplier"}</Text>
            <Text style={styles.value}>{data.vendorsName || "N/A"}</Text>
        </View>
        <View style={{width: "50%"}}>
            <Text style={{fontSize: 12,
    fontWeight: 'bold',textAlign: "right",paddingBottom: 5}}>{"Purchase Request"}</Text>
            <Text style={{fontSize: 12,textAlign: "right"}}>{data.prRef}</Text>
        </View>
      </View>

      {/* <View style={styles.section}> */}
        {/* {[
          { label: 'PR Reference', value: data.prRef },
          // { label: 'Suppliers', value: data.vendorsName },
          { label: 'Date', value: data?.date ? <NuDate value={data?.date} format="DD-MMM-YYYY hh:mm a" /> : 'N/A'},
          { label: 'Supplier', value: data.vendorsName ? capitalizeFirstLetter(data.vendorsName) : 'N/A'},
          { label: 'Purchaser', value: data.purchaserName ? capitalizeFirstLetter(data.purchaserName) : 'N/A'},
          { label: 'Reviewed By', value: data.reviewedByName ? capitalizeFirstLetter(data.reviewedByName) : 'N/A'},

          { label: 'Verified By', value: data.verifiedByName ? capitalizeFirstLetter(data.verifiedByName) : 'N/A'},
          { label: 'Approved By', value: data.approvedByName ? capitalizeFirstLetter(data.approvedByName) : 'N/A'},
          { label: 'Status', value: data.status !== "" || null ? data.status : 'N/A'},
          // { label: 'Payment Terms', value: data.paymentTerms },
          // { label: 'Delivery Terms', value: data.deliveryTerms },
          // { label: 'Payment Status', value: data.paymentStatus },
          // { label: 'Requested Delivery Date', value: data.requestedDeliveryDate },
          // { label: 'Requested By', value: data.requestedByName },
          // { label: 'Status', value: data.status },
          // { label: 'Approved By', value: data.approvedByName },
          // { label: 'Comments', value: data.comments },
        ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>{item.value}</Text>
          </View>
        ))} */}

        {/* <View style={{ marginTop: 10 }}>
          <Text style={styles.label}>Suppliers:</Text>
          {data.vendorsName.map((supplier, index) => (
            <Text key={index} style={styles.supplierItem}>{supplier}</Text>
          ))}
        </View> */}
      {/* </View> */}
      {/* <View style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            'S.N', 'Supplier Name'
          ].map((header, index) => {
            let width=[15, 85]
            return(
            <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
              {header}
            </Text>
          )})}
        </View> */}
        {/* {data?.vendorsName?.map((product, index) => ( */}
          {/* <View key={index} style={styles.productRow}> */}
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{(index+1)}</Text> */}
            {/* <Text style={[styles.productCell, { width: '85%' }]}>{product}</Text> */}
            {/* <Text style={[styles.productCell, { width: '25%' }]}>{product.item_code}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.product_unit}</Text> */}
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
            {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.quantity}</Text> */}
            {/* <Text style={[styles.productCell, { width: '10%' }]}>{product.price}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
          {/* </View> */}
        {/* ))} */}
      {/* </View> */}

      <View style={{ marginTop: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            'S.N', 'Product Name', 'Item Code', 'Product Unit',
            'Quantity'
          ].map((header, index) => {
            let width=[5, 40, 25, 15, 15]
            return(
            <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
              {header}
            </Text>
          )})}
        </View>
        {data?.productList?.map((product, index) => (
          <View key={index} style={styles.productRow}>
            <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
            <Text style={[styles.productCell, { width: '40%' }]}>{product.product_name}</Text>
            <Text style={[styles.productCell, { width: '25%' }]}>{product.item_code}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.product_unit}</Text>
            {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
            {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
            <Text style={[styles.productCell, { width: '15%' }]}>{product.quantity}</Text>
            {/* <Text style={[styles.productCell, { width: '10%' }]}>{product.price}</Text>
            <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
          </View>
        ))}
      </View>

      {/* <View style={styles.note}>
        <Text>Notes From Customer: {data.notesFromCustomer}</Text>

        <Text>Total Price: {data.totalPrice}</Text>
      </View> */}
    </Page>
  </Document>
);

export default PDFDocument;