import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function RequestApproval() {
    const { data, loading } = UseGetDashBoardData(`/dashboard/request-approvals`);

  return (
    <div className="w-full md:w-[33%] px-4">
    <div className="flex items-center justify-center" >
      <div className="pl-3">
        <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">Request</p>
        <p className='text-[#333333] text-[25px]  font-semibold '>{data?.total || 0}</p>
        <div className='flex gap-2 items-center justify-evenly'>
        <p className='text-xs 2xl:text-sm'>Pending : <span>{data?.pendding || '0'}</span></p>
       
        <p className='text-xs 2xl:text-sm'>Approval : <span>{data?.approved || '0' }</span></p>
        </div>
       </div>
    </div>
  
       </div>
  )
}

export default RequestApproval