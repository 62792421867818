import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const WarrentyPeriod = () => {
  
  return (
    <SettingsMetaData propertyName={"EquipmentWarrentyPeriod"} viewPropertyName={"Equipment Warrenty Period"}/>
  );
};

export default WarrentyPeriod;
