
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const BillingAddress = () => {
  return (
    <SettingsMetaData propertyName={"BillingAddress_po"} viewPropertyName={"Billing Address"} />
  );
}

export default BillingAddress;
