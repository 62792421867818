import React from 'react'
import { TextMediumSM, TextMediumXS } from '../../../../../Components/Text/MediumText';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function PurchaseOrderSupplier() {
    const { data, loading } = UseGetDashBoardData(``);
  
    const totalRequests = data?.customer_category_wise?.reduce(
     (total, item) => total + (item.count || 0),
     0
   );
 
   return (
       <div className="col-span-2 bg-white max-h-[40vh] p-4 pb-10  rounded-lg shadow-lg overflow-hidden mb-4">
<div className="w-full px-1 py-[2px] rounded-md flex justify-between items-center p-4">
         <TextMediumSM content={"Purchase Order - Shipment Status "} color="text-[#4F4768]" />
         <div className="bg-[#95D2B3] px-2 py-0.5 rounded-md">
         
         <p className='text-[#333333] text-[16px] font-semibold '>{totalRequests || 240}</p>
 </div>
         {/* <TextMediumSM content={` ${totalRequests || 0}`} className="font-bold text-[20px] text-[#333333]" /> */}
       </div>
       <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>
 
       {loading ? (
         <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
           <div className="animate-pulse flex space-x-4 w-full">
             <div className="flex-1 space-y-3 py-1">
               <div className="h-3 bg-slate-200 rounded"></div>
               <div className="space-y-3">
                 <div className="grid grid-cols-3 gap-4">
                   <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                   <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                 </div>
                 <div className="h-3 bg-slate-200 rounded"></div>
                 <div className="grid grid-cols-3 gap-4">
                   <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                   <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       ) : (
        
 
     <div className="w-full h-[96%] overflow-y-scroll px-6">
       <table className="w-full">
       <thead className="sticky top-0 bg-white shadow-sm">

           <tr>
             <th className="text-left px-2 py-1"><TextMediumSM content="PO" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="Shipment Status" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="Mode of Shipment" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="ETD" /></th>
             <th className="text-left px-2 py-1"><TextMediumSM content="ETA" /></th>

             
           </tr>
         </thead>
         <tbody>
            <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Toyota"/></td>
               <td className="px-2 py-1"><TextMediumXS content="50" /></td>
             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Tata"/></td>
               <td className="px-2 py-1"><TextMediumXS content="41" /></td>
             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Tesla"/></td>
               <td className="px-2 py-1"><TextMediumXS content="31" /></td>
             </tr>
             <tr className="odd:bg-white even:bg-slate-50 " >
               <td className="px-2 py-1"><TextMediumXS content="Mahindra"/></td>
               <td className="px-2 py-1"><TextMediumXS content="19" /></td>
             </tr>
           {/* {data && data.map((item, index) => (
             <tr className="odd:bg-white even:bg-slate-50 " key={index}>
               <td className="px-2 py-1"><TextMediumXS content={item?.customerName || "N/A"} /></td>
               <td className="px-2 py-1"><TextMediumXS content={item?.totalSpent || "N/A"} /></td>
             </tr>
           ))} */}
         </tbody>
       </table>
     </div>
   )}
 </div>
);
}

export default PurchaseOrderSupplier