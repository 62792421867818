import { useNavigate } from "react-router-dom";
import Search from "../Icons/Search";
import Plus from "../Icons/Plus";
import Edit from "../Icons/Edit";
import Filter from "../Icons/Filter";
import Delete from "../Icons/Delete";
import { useTranslation } from "react-i18next";
import Import from "../Icons/Import";



export function CreateButton({ handleClick = () => {},label="Add",bg="bg-baseColor-primary",svg=true }) {
  const {t,i18n} = useTranslation();

  return (
    <button
      className={`px-3 h-8 cursor-pointer border-none outline-none text-xs 2xl:text-sm text-white ${bg} rounded-lg flex justify-center items-center`}
      // style={{ border: "1px solid #3D348B" }}
      onClick={handleClick}
    >
      {
        svg && i18n.language !="ar" &&
        <span className="md:mr-2 flex justify-center items-center text-white">
        <Plus/>
      </span>
      }

     <span className="hidden md:block text-xs 2xl:text-sm font-semibold">{t(label)}</span> 
     {
        svg && i18n.language =="ar" &&
        <span className="md:ml-2 flex justify-center items-center text-white">
        <Plus/>
      </span>
      }
    </button>
  );
}

export function FilterButton({ handleClick = () => {},label="Filter",bg="bg-baseColor-primary",svg=true , height="h-10"}) {
  const {t,i18n} = useTranslation();

  return (
    <button
      className={`px-3 h-8 ${height} cursor-pointer border-none text-white outline-none font-medium text-xs 2xl:text-sm ${bg} rounded-lg flex justify-center items-center`}
      // style={{ border: "1px solid #3D348B" }}
      onClick={handleClick}
    >
      {
        svg && i18n.language !="ar" &&
        <span className="md:mr-2 flex justify-center items-center">
        <Filter/>
      </span>
      }

     <span className="hidden md:block text-xs 2xl:text-sm font-semibold">{t(label)}</span> 
     {
        svg && i18n.language =="ar" &&
        <span className="md:ml-2 flex justify-center items-center">
        <Filter/>
      </span>
      }
    </button>
  );
}

export function ImportButton({ handleClick = () => {},label="Import",bg="bg-baseColor-primary",svg=true , height="h-10"}) {
  const {t,i18n} = useTranslation();

  return (
    <button
      className={`px-3 h-8 ${height} cursor-pointer border-none text-white outline-none font-medium text-xs 2xl:text-sm ${bg} rounded-lg flex justify-center items-center`}
      // style={{ border: "1px solid #3D348B" }}
      onClick={handleClick}
    >
      {
        svg && i18n.language !="ar" &&
        <span className="md:mr-2 flex justify-center items-center">
        <Import/>
      </span>
      }

     <span className="hidden md:block text-xs 2xl:text-sm font-semibold">{t(label)}</span> 
     {
        svg && i18n.language =="ar" &&
        <span className="md:ml-2 flex justify-center items-center">
        <Import/>
      </span>
      }
    </button>
  );
}


export function EditButton({height='10', handleClick = () => {} }) {
  const {t,i18n} = useTranslation();

  return (
    <button
      className={`px-[14px] py-[10px] h-${height} cursor-pointer text-white text-xs 2xl:text-sm bg-[#048178] rounded-md inline-flex w-auto flex-shrink-0 items-center justify-center`}
      style={{ border: "1px solid #048178" }}
      onClick={handleClick}
    >
      {i18n.language !="ar" &&
      <span className="mr-2 flex justify-center items-center">
      <Edit/>
      </span>
      }
     <span className=" text-xs 2xl:text-sm">{t("Edit")}</span> 

      {i18n.language =="ar" &&
      <span className="ml-2 flex justify-center items-center">
      <Edit/>
      </span>
      }
    </button>
  );
}

export function BackButton( { link=false }) {
  const {t,i18n} = useTranslation();

  let navigate = useNavigate()
  return (
    <button
      className="px-3 h-8 cursor-pointer text-xs 2xl:text-sm text-textColor-main bg-white rounded-md flex items-center"
      style={{ border: "1px solid #048178" }}
      onClick={ ()=> !link && navigate(-1) }
    >
{
  i18n.language !="ar" && 
  <span className="mr-2 flex justify-center items-center">
  <svg
    width="10"
    height="10"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.898 7.70773H4.42869L9.43946 2.69696C9.83991 2.29651 9.83991 1.63936 9.43946 1.23891C9.34447 1.14372 9.23163 1.0682 9.10742 1.01668C8.9832 0.965151 8.85004 0.938629 8.71557 0.938629C8.58109 0.938629 8.44793 0.965151 8.32372 1.01668C8.1995 1.0682 8.08667 1.14372 7.99167 1.23891L1.22509 8.0055C1.1299 8.10049 1.05438 8.21333 1.00285 8.33754C0.951327 8.46176 0.924805 8.59491 0.924805 8.72939C0.924805 8.86387 0.951327 8.99703 1.00285 9.12124C1.05438 9.24546 1.1299 9.35829 1.22509 9.45328L7.99167 16.2199C8.08674 16.3149 8.19959 16.3903 8.3238 16.4418C8.448 16.4932 8.58113 16.5197 8.71557 16.5197C8.85001 16.5197 8.98313 16.4932 9.10733 16.4418C9.23154 16.3903 9.3444 16.3149 9.43946 16.2199C9.53452 16.1248 9.60993 16.012 9.66138 15.8877C9.71282 15.7635 9.7393 15.6304 9.7393 15.496C9.7393 15.3615 9.71282 15.2284 9.66138 15.1042C9.60993 14.98 9.53452 14.8672 9.43946 14.7721L4.42869 9.76132H15.898C16.4627 9.76132 16.9248 9.29926 16.9248 8.73452C16.9248 8.16979 16.4627 7.70773 15.898 7.70773Z"
      fill="#048178"
    />
  </svg>
</span>
}

      {t("Back")}

      {
  i18n.language =="ar" && 
  <span className="ml-2 flex justify-center items-center">
  <svg
    width="10"
    height="10"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.898 7.70773H4.42869L9.43946 2.69696C9.83991 2.29651 9.83991 1.63936 9.43946 1.23891C9.34447 1.14372 9.23163 1.0682 9.10742 1.01668C8.9832 0.965151 8.85004 0.938629 8.71557 0.938629C8.58109 0.938629 8.44793 0.965151 8.32372 1.01668C8.1995 1.0682 8.08667 1.14372 7.99167 1.23891L1.22509 8.0055C1.1299 8.10049 1.05438 8.21333 1.00285 8.33754C0.951327 8.46176 0.924805 8.59491 0.924805 8.72939C0.924805 8.86387 0.951327 8.99703 1.00285 9.12124C1.05438 9.24546 1.1299 9.35829 1.22509 9.45328L7.99167 16.2199C8.08674 16.3149 8.19959 16.3903 8.3238 16.4418C8.448 16.4932 8.58113 16.5197 8.71557 16.5197C8.85001 16.5197 8.98313 16.4932 9.10733 16.4418C9.23154 16.3903 9.3444 16.3149 9.43946 16.2199C9.53452 16.1248 9.60993 16.012 9.66138 15.8877C9.71282 15.7635 9.7393 15.6304 9.7393 15.496C9.7393 15.3615 9.71282 15.2284 9.66138 15.1042C9.60993 14.98 9.53452 14.8672 9.43946 14.7721L4.42869 9.76132H15.898C16.4627 9.76132 16.9248 9.29926 16.9248 8.73452C16.9248 8.16979 16.4627 7.70773 15.898 7.70773Z"
      fill="#048178"
    />
  </svg>
</span>
}
    </button>
  );
}


export function SubmitButton({isLoading, color, name="Submit",handleClick=()=>{}}) {
  const {t} = useTranslation();

  return (
    <button
      className={`${isLoading?'bg-red-300 cursor-wait':'bg-[#048178]'} px-5 py-[9px] cursor-pointer ${color} outline-none border-none text-white rounded-md text-xs 2xl:text-sm flex items-center mr-3`}
      type="submit"
      // disable={isLoading?true:false}
      onClick={handleClick}
      disabled={isLoading?true:false}
    >
      {t(name)}
    </button>
  );
}

export function CancelButton({handleClick=()=>{},color="bg-[#E6F2F2]",textColor="text-textColor-main"}) {
  const {t} = useTranslation();

  return (
    <button
      className={` px-5 py-[9px] cursor-pointer text-xs 2xl:text-sm  ${textColor} ${color} hover:bg-gray-300
      rounded-md flex items-center`}
      style={{ border: "none" }}
      onClick={handleClick}
      type="button"
    >
      {t("Cancel")}
    </button>
  );
}

export function DeleteButton({handleClick=()=>{},color="red",width=6,height=6}) {
  return (
    <button
      className={`h-[20px] px-1 py-1 text-xs 2xl:text-sm rounded-full bg-transparent cursor-pointer`}
      style={{ border: "none" }}
      onClick={handleClick}
      type="button"
    >
      <Delete color={color} width={width} height={height}/>
    </button>
  );
}

export function SearchButton({handleClick=()=>{}}) {
  return (
    <button
      className="p-[5.5px] outline-none border-none  cursor-pointer bg-[#FFF] text-xs 2xl:text-sm  rounded-md flex items-center"
      // style={{ border: "1px solid #E9E9E9" }}
      onClick={handleClick}
      type="button"
    >
      <Search color="#017a6e" />
    </button>
  );
}
export function SaveButton({handleClick=()=>{}}) {
  const {t} = useTranslation();

  return (
    <button
      className="h-[40px] px-5 py-2 cursor-pointer text-xs 2xl:text-sm  text-mainColor-500  bg-[#048178]  text-white  rounded-md flex items-center"
      style={{ border: "none",}}
      onClick={handleClick}
      type="button"
    >
      {t("Save")}
    </button>
  );
}

export function NextButton({handleClick=()=>{}}) {
  const {t} = useTranslation();

  return (
    <button
      className="h-[40px] px-5 py-2 cursor-pointer text-xs 2xl:text-sm  text-mainColor-500  bg-[#048178]  text-white  rounded-md flex items-center"
      style={{ border: "none",}}
      onClick={handleClick}
      type="button"
    >
      {t("Next")}
    </button>
  );
}

export function Submit({handleClick=()=>{}}) {
  const {t} = useTranslation();

  return (
    <button
      className="h-[40px] px-5 py-2 cursor-pointer text-xs 2xl:text-sm text-mainColor-500  bg-[#048178]  text-white  rounded-md flex items-center"
      style={{ border: "none",}}
      onClick={handleClick}
      type="button"
    >
      {t("Submit")}
    </button>
  );
}
