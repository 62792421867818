import { useFormik } from "formik";
import { NuInput } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useState } from "react";
import * as yup from "yup";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";


function EditProgressModal({ id, percentage, onClose, refresh }) {
  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const toast = useToast()
  const [createNew, setCreateNew] = useState({
    taskCompletion: percentage
  })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            axiosInstance
            .put(`/tasks/${id}/task-completion`, value)
            .then((res) => {
              console.log(res);
              refresh();
              onClose();
              if (res.data?.status === true) {
                // res.data?.data && setData();
                toast.open({
                  type:"success",
                  message:"Success",
                  description: res.data?.msg || "Created Successfully."
                })
              }
              else if(res.data?.status === false && res.data?.error){
                toast.open({
                  type:"error",
                  message:"Error",
                  description: res.data.error || "Network error."
                })
              } 
               else {
                toast.open({
                  type:"warning",
                  message:"Warning",
                  description: res.data?.msg || "Warning."
                })
              }
            })
            .catch((err) => {
              console.log(err);
              toast.open({
                type:"error",
                message:"Error",
                description:  "Network error."
              })
            })
        },
      });
  
  return (
    <div className=" fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-[9999]">
            <div  className="animate-scale-in min-w-[20%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
        <form 
        onSubmit={formik.handleSubmit}
        >
            <NuInput type="number" label='Task Percentage' placeholder='0' formik={formik} name='taskCompletion' width='w-full' />
            <div className=' flex justify-end items-center gap-4 '>
                  <button className=' px-3 py-1 bg-gray-300 rounded-md text-gray-800' onClick={onClose}>Cancel</button>
                  <button className=' px-3 py-1 bg-blue-600 rounded-md text-white ' type="submit">Submit</button>
                </div>
        </form>
        </div>
        </div>
  );
}

export default EditProgressModal;
