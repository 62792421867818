import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const TermsAndConditions = () => {
  return (
    <SettingsMetaData propertyName={"termsAndConditions_SalesOrder"} viewPropertyName={"Terms And Conditions"} />

  )
}

export default TermsAndConditions