// import React from 'react'

// const ViewPdf = ({ data }) => {
  
//   return (
//     <div className="py-2 text-sm md:text-sm lg:text-xs xl:text-sm font-normal bg-white font-helvetica w-[50%]  mx-auto  border rounded-lg ">
//      <div className='h-[calc(100vh-80px)] overflow-y-scroll p-2'>
//       <div className="flex gap-3 items-center">
//        <h3 className="text-[14px] font-normal">Suplier Quotes</h3>
//       <p className="text-sm font-light"> : {data.ref}</p>
//     </div>

//       <div className="grid  items-center mt-2 mb-2">
//         {[
//           { label: 'PR Reference', value: data.prRef },
//           { label: 'RFQ Reference', value: data.rfqRef },
//           { label: 'Quoted Date', value: data.quoteDate || 'N/A'},
//           { label: 'Quotation Received Date', value: data.dateReceived || 'N/A'},
//           { label: 'Supplier', value: data.vendorName || 'N/A'},
//           { label: 'Supplier Address', value: data.supplierAddress || 'N/A'},
//           { label: 'Shipping Address', value: data.deliveryAddress || 'N/A'},
//           { label: 'Billing Address', value: data.billingAddress || 'N/A'},
//           { label: 'Payment Terms', value: data.paymentTerms || 'N/A'},
//           { label: 'Delivery Terms', value: data.deliverTerms || 'N/A'},
//           { label: 'Payment Method', value: data.paymentMethod || 'N/A'},
//           { label: 'Payment Status', value: data.paymentStatus || 'N/A'},
//           { label: 'Status', value: data.status || 'N/A'},
//           { label: 'Quotation Validity', value: data.validity || 'N/A'},
//           { label: 'Lead Time', value: data.leadTime || 'N/A'},
//           { label: 'Reviewed By', value: data.reviewedByName || 'N/A'},
//            ].map((item, index) => (
//           <div key={index} className="flex  text-[12px]">
//             <p className="w-[40%] py-1 font-medium">{item.label}</p>
//             <p className="w-[40%] py-1">{item.value}</p>
//           </div>
//         ))}
//       </div>

//       <div className=" bg-gray-300">
//         <div className="w-full flex justify-between text-left text-[12px]">
//           {['S.N', 'Item Code', 'Product Name', 'Product Unit' ,'Quantity' , 'Price'].map((header, index) => (
//             <p key={index} className="bg-blue-500 text-white py-1 p-2 font-bold "
//              style={{ width: `${[5, 20, 40, 15, 15, 20][index]}%` }}>
//               {header}
//             </p>
//           ))}
//         </div>
//         {data?.quoteProducts?.map((product, index) => (
//           <div key={index} className=" w-full flex justify-between py-1 p-2 text-[12px]">
//             <p className="w-[5%] ">{index + 1}</p>
//             <p className="w-[20%] ">{product.item_code}</p>
//             <p className="w-[40%] ">{product.product_name}</p>
//             <p className="w-[15%] ">{product.product_unit}</p>
//             <p className="w-[15%] ">{product.quantity}</p>
//             <p className="w-[20%] ">{product.price}</p>

//           </div>
//         ))}
//       </div>

//       <div className="flex justify-between p-2 my-4 border-2 border-gray-300 text-[12px]">
//         <p>Notes From Customer:{data.notesFromCustomer}</p>
//         <p>Total Price: {data.totalPrice}</p>

//       </div>
//       </div>
//     </div>
//   );
// }

// export default ViewPdf


import React from 'react';
import CompanyLogo from '../../../../assets/images/companyLogo.png'
import CompanyName from '../../../../assets/images/companyName.png'
import Seal from '../../../../assets/images/seal.png'
import NuDate from '../../../../Components/Utils/NuDate';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
const ViewPdf = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);
  return (
    <div
    ref={containerRef}
    className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border">
     <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className='relative w-full h-full '>
          <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
            <button onClick={() => zoomIn()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">+</button>
            <button onClick={() => zoomOut()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">-</button>
            <button onClick={() => resetTransform()} className='text-left'>Reset</button>
          </div>
      <div className="flex justify-center items-center w-full h-full">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <div className='min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black'>
      <div className="border border-black">
     <div className="flex justify-between items-center border-b border-black">
        <div className='w-full'>
          <div className='w-full flex justify-between items-center p-4'>
               <img src={CompanyName} alt="Loading" className='h-28' />
              <img src={CompanyLogo} alt="Loading" className='h-28 w-28'/>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-3 border-b border-black">
        <div className=' w-full p-3 flex items-center border-r border-black'>Your Ref. :</div>
        <div className=' w-full p-3 text-center text-lg font-semibold border-r border-black'>SUPPLIER QUOTATIONS</div>
        <div className=' w-full'>
          <div className=' w-full h-1/2 border-b border-black px-2 pt-1 flex'>
            <p className=' w-8'>Date </p>
            <p className="text-xs">: {data?.quoteDate ? (
                  <NuDate value={data?.quoteDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
          </div>
          <div className=' w-full h-1/2 px-2 pt-1 flex'>
          <p className=' w-8'>PO# </p>
          <p className="text-xs"> : {data.ref || 'N/A'}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 border-b border-black">
        <div className=' w-full py-2 px-3'>
        <h4 className="font-semibold text-sm">Reference</h4>
        </div>
        <div className=' w-full py-2 px-3'>
        <h4 className="font-semibold text-sm ">Supplier</h4>
        </div>
        <div className=' w-full py-2 px-3'>
        <h4 className="font-semibold text-sm ">SHIP TO</h4>
        </div>
      </div>

      <div className="grid grid-cols-3 border-b border-black">
        <div className=' w-full border-r border-black px-3 py-1'>
        <table className='w-full'>
          <tbody>
            <tr className=' w-full'>
              <td className=' w-[30%] py-[2px]'>Name </td>
              <td className=' w-[70%] py-[2px]'>: {data.vendorName || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-[2px]'>Address </td>
            <td className=' w-[70%] py-[2px]'>: {data.supplierAddress || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-[2px]'>Received Date </td>
            <td className=' w-[70%] py-[2px]'>: {data.dateReceived || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className=' w-full border-r border-black px-3 py-1'>
        <table className='w-full'>
          <tbody>
            <tr className=' w-full'>
              <td className=' w-[30%] py-[2px]'>PR </td>
              <td className=' w-[70%] py-[2px]'>: {data.prRef || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-[2px]'>RFQ </td>
            <td className=' w-[70%] py-[2px]'>: {data.rfqRef || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className=' w-full px-3 py-1'>
        <table className='w-full'>
          <tbody>
            <tr className=' w-full'>
              <td className=' w-[30%] py-[2px]'>Billing Address </td>
              <td className=' w-[70%] py-[2px]'>: {data.billingAddress || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-[2px]'>Delivery Address </td>
            <td className=' w-[70%] py-[2px]'>: {data.deliveryAddress || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200 text-[12px]">
            {['S.N', 'Item Code', 'Product Name', 'Product Unit' ,'Quantity' , 'Price','Total'].map((header, index) => (
              <th key={index} className="border-b border-r last:border-r-0  border-black p-2 text-left font-bold">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.quoteProducts?.map((product, index) => (
            <tr key={index} className="border-y border-black text-[12px]">
              <td className="p-2">{index + 1}</td>
              <td className="border border-black p-2">{product.item_code || 'N/A'}</td>
              <td className="border border-black p-2">{product.product_name|| 'N/A'}</td>
              <td className="border border-black p-2">{product.product_unit|| 0}</td>
              <td className="border border-black p-2">{product.quantity.toFixed(2) || 0}</td>
              <td className="border border-black p-2">{product.price.toFixed(2) || 0}</td>
              <td className="border border-r-0 border-black p-2">{(product.quantity * product.price).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-y border-black text-[12px]">
            <td className=' py-3 border-r border-black  pr-3 ' colSpan={6}>
              <div className=' w-full flex justify-end'>
                <div>
                <p className=' text-xs font-medium'>TOTAL</p>
                <p className=' text-xs font-medium'>NET TOTAL</p>
                </div>
              </div>
            </td>
            <td className='px-2 py-3'>
            <div className=' w-full'>
                <div>
                <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p>
                <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>



      <div className="mt-4 text-[12px] flex justify-between w-full px-3">
        <div className=''>
        <h4 className="font-bold">Terms & Conditions</h4>
        <div className="w-full flex  text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Price
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : SAR
              </p>
            </div> 
            <div className="w-full flex  text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Lead Time
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.leadTime || 'N/A'}
              </p>
            </div> 
            <div className="w-full flex  text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Delivery
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.deliverTerms || 'N/A'}
              </p>
            </div>
            <div className="w-full flex  text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Payment
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.paymentTerms || 'N/A'}
              </p>
            </div> 
            <div className="w-full flex  text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Payment Method
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.paymentMethod || 'N/A'}
              </p>
            </div> 
      
        </div>
        {/* <div className="text-center">
        <p className="text-xs ">Authorized By</p>
          <img src={Seal} alt="Company Seat" className="h-24 mb-2" />
        </div> */}
      </div>

      <div className="mt-4 flex justify-between items-center text-[12px] px-3">
        <div>
          <p className="text-xs ">Note:</p>
        
        <p>{data.notesFromCustomer || 'N/A'}</p>

        </div>
       
      </div>
      </div>
      </div>
      </TransformComponent>
      </div>
      </div>
      // </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default ViewPdf;
