import React, { useState } from 'react'
import SideModal from '../../../../Components/Others/SideModel';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';

function ViewCQIdRevision() {
 const { viewrevisioncqId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);

  const handleOpenCommentsModal = () => {
    setIsCommentsModalOpen(true);
  };

  const handleCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
  };
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={[
            // { name: "Sales",
            // link: "/sales/quotes" },
            {
              name: "Revision",
              link: "/sales/quotes/viewcqrevision",
            },
            {
              name: "View Revision",
            },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={"/sales/quotes/viewcqrevision"}>
           
          </Link>
          <BackButton/>
          {/* <PdfLinkCq
            data={data}
            fileName={`Customer_Quote_${data.ref}_${NuDate({
              value: Date(),
              format: "DD-MMM-YYY",
            })}.pdf`}
          />
          <Link
            to={`/sales/quotes/edit/${data?.id}`}
            state={{ data: data, from: `/sales/quotes/view/${data?.id}` }}
          >
            <EditButton height="8" />
          </Link>
          <button
            className={` cursor-pointer text-white bg-[#048178] rounded-md inline-flex w-auto h-8 px-2 flex-shrink-0 items-center justify-center`}
            onClick={handleOpenCommentsModal}
          >
            Comments
          </button> */}
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-y-scroll p-3">
        <div className="flex justify-between">
          <div className="flex gap-3 items-center">
            <NormalHeading title={"Revision"} /> :{" "}
            <p className="text-xs font-light">{data.revisionCount}</p>
          </div>
         
        </div>

        <div className="w-full flex flex-wrap">
          <div className="w-full grid grid-cols-3 gap-x-8 m-4">
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Customer{" "}
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.customerName || "-"}
              </p>
            </div>

            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Total Price</p>
              <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data?.totalPrice || '-'}</p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Validity
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.validity || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Availability
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.availability || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Quote Date
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.date || "-"}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Lead Time
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.leadTime || "-"}
              </p>
            </div>
            {/* <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Payment Method
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.paymentMethod || "-"}
              </p>
            </div> */}
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Delivery Terms
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.deliveryTerms || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Payment Terms
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.paymentTerms || "-"}
              </p>
            </div>

            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Purchase Requests
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.prRef || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Prepared By{" "}
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.preparedByName || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Reviewed By{" "}
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.reviewedByName || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Verified By{" "}
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.verifiedByName || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Approved By{" "}
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.approvedByName || "-"}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Approval Status
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.status}
              </p>
            </div>
            <div className="w-full flex px-2">
              <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
                Status
              </p>
              <p className="w-1/2 py-3 text-xs pl-5 text-[#606279]">
                {data?.status}
              </p>
            </div>
            {/* <div className='w-full flex px-2'>
              <p className='w-1/2 py-3 text-xs font-medium text-[#111537]'>Comments</p>
              <p className='w-1/2 py-3 text-xs pl-5 text-[#606279]'>{data?.comments || '-'}</p>
            </div> */}
          </div>
        </div>
        <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>

        <div className="w-full bg-white overflow-y-scroll ">
          <div className="w-full py-2 bg-baseColor-primary flex justify-between rounded-lg px-6">
          <p className="w-[5%]  text-left pl-4 text-white text-xs font-medium">S.No </p>

            <p className=" w-[20%] text-left pl-1 text-white text-xs font-medium">
              Item Code
            </p>
            <p className=" w-[40%] text-left text-white text-xs font-medium">
              Customer Quotation Product
            </p>
            <p className=" w-[20%] text-left text-white text-xs font-medium">
              Quantity
            </p>
            <p className=" w-[20%] text-left text-white text-xs font-medium">
              Price
            </p>
          </div>
          {data?.quoteProducts?.map((List, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex  px-6 justify-between odd:bg-white even:bg-slate-100"
              >
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                <p className=" w-[20%] text-left  text-[#606279] text-xs font-light py-1">
                  {List?.item_code}
                </p>
                <p className=" w-[40%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.product_name}
                </p>
                <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.quantity}
                </p>
                <p className=" w-[20%] text-left text-[#606279] text-xs font-light py-1">
                  {List?.price}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-full flex px-2 py-2">
          <div className="w-1/4 flex">
            <p className="w-1/2 py-3 text-xs font-medium text-[#111537]">
              Total Price :
            </p>
            <p className="w-1/2 py-3 text-xs font-medium text-[#606279]">
              {data.totalPrice}
            </p>
          </div>
        </div>
      </div>

      {/* {isCommentsModalOpen && (
        <SideModal
          visiable={isCommentsModalOpen}
          closeModal={handleCloseCommentsModal}
          title="Comments"
          mainId={quotesViewId}
          addurl={"/cq-comments/new-comment"}
          geturl={`/cq-comments/by-CQ/${quotesViewId}`}
        />
      )} */}
    </div>
  )
}

export default ViewCQIdRevision