import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  FilterButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import { NuDate } from "../../../../Components/Utils/Input";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Importdoc from "./Importdoc";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import MultiEditST from "./MultiEditST";
import { GetAccess } from "../../../../Components/Utils/roles";

function ShipmentTrackingIndex() {
  const {value:PortOfDischarge} = useFetchMataData(`/dropdown/dropdown-details/`,'PortOfDischarge')
  const {value:PortOfLoading} = useFetchMataData(`/dropdown/dropdown-details/`,'PortOfLoading')
  const {value:ShippingMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'ShippingMethod')

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    issuedDateFrom:"",
    issuedDateTo:"",
    shipmentDateFrom:"",
    shipmentDateTo:"",
    estimatedArrivalDateFrom:"",
    estimatedArrivalDateTo:"",
    portOfLoading:"",
    portOfDischarge:"",
    actualArrivalDateFrom:"",
    actualArrivalDateTo:"",
    shippingMethod:"",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search:"",
    issuedDateFrom:"",
    issuedDateTo:"",
    shipmentDateFrom:"",
    shipmentDateTo:"",
    estimatedArrivalDateFrom:"",
    estimatedArrivalDateTo:"",
    portOfLoading:"",
    portOfDischarge:"",
    actualArrivalDateFrom:"",
    actualArrivalDateTo:"",
    shippingMethod:"",
  });

  const clearFilter = ()=> {
    setParams({...params, issuedDateFrom:"",
      issuedDateTo:"",
      shipmentDateFrom:"",
      shipmentDateTo:"",
      estimatedArrivalDateFrom:"",
      estimatedArrivalDateTo:"",
      portOfLoading:"",
      portOfDischarge:"",
      actualArrivalDateFrom:"",
      actualArrivalDateTo:"",
      shippingMethod:"",})
      setFilter(false)
      formik.values.issuedDateFrom=""
      formik.values.issuedDateTo=""
      formik.values.shipmentDateFrom="" 
      formik.values.shipmentDateTo="" 
      formik.values.estimatedArrivalDateFrom="" 
      formik.values.estimatedArrivalDateTo="" 
      formik.values.portOfLoading="" 
      formik.values.portOfDischarge="" 
      formik.values.actualArrivalDateFrom="" 
      formik.values.actualArrivalDateTo="" 
      formik.values.shippingMethod="" 

  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,shippingMethod:value.shippingMethod, 
        actualArrivalDateTo:value.actualArrivalDateTo,
        actualArrivalDateFrom:value.actualArrivalDateFrom,
        portOfDischarge:value.portOfDischarge,
        portOfLoading:value.portOfLoading,
        estimatedArrivalDateTo:value.estimatedArrivalDateTo,
        estimatedArrivalDateFrom:value.estimatedArrivalDateFrom,
        shipmentDateTo:value.shipmentDateTo,
        shipmentDateFrom:value.shipmentDateFrom,
        issuedDateTo:value.issuedDateTo,
        issuedDateFrom:value.issuedDateFrom})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        issuedDateFrom:"",
    issuedDateTo:"",
    shipmentDateFrom:"",
    shipmentDateTo:"",
    estimatedArrivalDateFrom:"",
    estimatedArrivalDateTo:"",
    portOfLoading:"",
    portOfDischarge:"",
    actualArrivalDateFrom:"",
    actualArrivalDateTo:"",
    shippingMethod:"",
      });
    }
  })

  const { data, totalCount, getData } = UseGetDataSearch("/shipping/",params);
  console.log(data);

  const defaultHeader = [
    // {
    //   title: "Reference",
    //   width: "5%",
    //   field: "ref",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Purchase request",
      width: "9%",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purchase Order",
      width: "9%",
      field: "poRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Agent",
      width: "9%",
      field: "agentName",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "shippingId",
        removeValue: "Shipment Tracking",
        url: '/shipping/',
        moduleName: "Shipment Tracking",
        from: "/shipping/shipment-tracking",
        editLink: "/shipping/shipment-tracking/edit/",
        viewLink: "/shipping/shipment-tracking/view/",
      },
    },
  ];

  let availableHeaders = [
    // {
    //   title: "Reference",
    //   width: "5%",
    //   field: "ref",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Purchase Request",
      width: "9%",
      isLink: true,
      id: "shippingId",
      link: '/shipping/shipment-tracking/view/',
      field: "prRef",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Purchase Order",
      width: "9%",
      field: "poRef",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Agent",
      width: "9%",
      field: "agentName",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Delivery Note Reference",
    //   width: "9%",
    //   field: "dnRef",
    //   type:"date",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Booking Date",
      width: "9%",
      field: "bookingDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Estimated Delivery Date(ETD)",
      width: "9%",
      field: "ETD",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Estimated Arrival Date(ETA)",
      width: "9%",
      field: "ETA",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Actual Arrival Date",
      width: "9%",
      field: "actualArrivalDate",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping On",
      width: "9%",
      field: "shippedOn",
      type:"date",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Delivery Person Name",
      width: "9%",
      field: "deliveryPersonName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Identity Type",
      width: "9%",
      field: "identityType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Identity Number",
      width: "9%",
      field: "identityNumber",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Type",
      width: "9%",
      field: "vehicleType",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Vehicle Number",
      width: "9%",
      field: "vehicleNumber",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Shipping Method",
      width: "9%",
      field: "shippingMethod",
      textAlign: "center",
      rowAlign: "center",
    },
    
    {
      title: "Bill Number",
      width: "9%",
      field: "shipmentBill",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Insurace Policy Number",
    //   width: "9%",
    //   field: "insuracePolicyNumber",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Insurance Provider",
    //   width: "9%",
    //   field: "insuranceProvider",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Insurance Type",
    //   width: "9%",
    //   field: "insuranceType",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage Start Date",
    //   width: "9%",
    //   field: "coverageStartDate",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage End Date",
    //   width: "9%",
    //   field: "coverageEndDate",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Coverage Amount",
    //   width: "9%",
    //   field: "coverageAmount",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Insurance Premium",
    //   width: "9%",
    //   field: "insurancePremium",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Claim Contact Info",
    //   width: "9%",
    //   field: "claimContactInfo",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    // {
    //   title: "Comments",
    //   width: "9%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
  

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "shippingId",
        removeValue: "Shipment Tracking",
        url: '/shipping/',
        moduleName: "Shipment Tracking",
        from: "/shipping/shipment-tracking",
        editLink: "/shipping/shipment-tracking/edit/",
        viewLink: "/shipping/shipment-tracking/view/",
      },
    },
  ];

  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})

  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };


  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [listofNumbers, setListofNumbers] = useState([])
  const [fullList, setFullList] = useState([])

  const selectOneFunction = (value) =>{ 
    let NewId;
    if(listofNumbers && listofNumbers.some(Item=>Item ===value)){
      NewId = listofNumbers.filter(Temp=> Temp !== value);
      setListofNumbers(NewId)
    }else{
      setListofNumbers([...listofNumbers,value])
    }
  }

  const selectMultipleFunction = (e) =>{ 
console.log(e.target.checked);
if(e.target.checked){
  setListofNumbers(fullList)
}else{
  setListofNumbers([])
}

  }

  useEffect(()=>{
    let tempArr = []
    data && data.forEach(i=>{
      console.log("I",i?.id);
      tempArr.push(i?.id)
    });
    setFullList(tempArr)
  },[data])

  
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Shipping",
             
            },
            {
              name: "Shipment Tracking",
            },
          ]}
        />
        <div className=" flex gap-2">
        <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {/* <CreateButton label="Import" handleClick={handleImportClick} /> */}
          {GetAccess("shipment_tracking_create") && <Link to="/shipping/shipment-tracking/create">
            <CreateButton label="Shipment Tracking" />
          </Link>}
        </div>
      </div>

      <CustomDataTable
      fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeader}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Shipment Tracking List"
        storageName="ShipmentTrackingTable"
        selectOneFunction={selectOneFunction}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        listofNumbers={listofNumbers}
        hasMultiEdit={true}
        selectMultipleFunction={selectMultipleFunction}
        fullList={fullList}
        getById="shippingId"
        editAccess="shipment_tracking_edit"      
        deteleAccess="shipment_tracking_delete" 
      />
      {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Shipment Tracking</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <p className="py-2 px-6 text-m">Issued Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="issuedDateFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="issuedDateTo"
              width="w-full "
            />
             
            </div>
            <p className="py-2 px-6 text-m">Shipment Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shipmentDateFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="shipmentDateTo"
              width="w-full "
            />
             
            </div>
            <p className="py-2 px-6 text-m">Estimated Arrival Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="estimatedArrivalDateFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="estimatedArrivalDateTo"
              width="w-full "
            />
             
            </div>
            <p className="py-2 px-6 text-m">Actual Arrival Date</p>
                    <div className="flex justify-between  w-full ">
                    <NuDate
              label="From "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="actualArrivalDateFrom"
              width="w-full "
            />
                    <NuDate
              label="To "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="actualArrivalDateTo"
              width="w-full "
            />
             
            </div>
            <FormikSelectTwo
              type="text"
              label="Port Of Loading"
              placeholder="Port Of Loading"
              Options={PortOfLoading}
              formik={formik}
              name="portOfLoading"
              width="w-full "
            />
            <FormikSelectTwo
              type="text"
              label="Port Of Discharge"
              placeholder="Port Of Discharge"
              Options={PortOfDischarge}
              formik={formik}
              name="portOfDischarge"
              width="w-full "
            />
              <FormikSelectTwo
              type="text"
              label="Shipping Method"
              placeholder="Shipping Method"
              Options={ShippingMethod}
              formik={formik}
              name="shippingMethod"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
          {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
          {showEdit && (<MultiEditST data={listofNumbers} getData={getData} onClose={()=>setShowEdit(false)}/>)}
          <ModalContainer
                visiable={showDelete}
                title="Warning"
                content={`Are you sure, All selected datas will be removed from Purchase Requests List!`}
                handleClick={() =>{}}
                closeModal={() => setShowDelete(false)}
          />
    </div>
  );
}

export default ShipmentTrackingIndex;
