import React, { useEffect, useState } from 'react'
import { userRole } from './RoleData';
import { NuInput } from '../../../../Components/Utils/Input';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import UseEditData from '../../../../Components/Hooks/useEditData';

function EditRole() {
    const {editRoleId} = useParams()
    const {Oncancel,editData,isLoading} = UseEditData();
    const [roleInfo, setRoleInfo] = useState([]);
    const [createNew, setCreateNew] = useState({
      role:"",
      permissions:[]
    });

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: (value,{resetForm}) => {
        value.permissions = roleInfo;
        editData(`/role-permission/multi-update`,value,resetForm,`/settings/role`)
      }
    })

    const selectRow = (name, id) => {
      let testUser;
      if (roleInfo && roleInfo.some((Userid) => Userid === id)) {
        testUser = roleInfo.filter((roleId) => roleId !== id);
        setRoleInfo(testUser);
      } else {
        setRoleInfo([...roleInfo, id]);
      }
    };

    function GetPermissiom(id){
        axiosInstance.get(`/role-permission/role/${id}`).then(res=>{
            setRoleInfo(res.data?.data?.permissions||[])
        })
    }
    function GetRole(id){
        axiosInstance.get(`/role/${id}`).then(res=>{
            let data = res.data?.data?.roleName;
            setCreateNew({...createNew,role:data})
        })
    }

    useEffect(() => {
        if(editRoleId){
            GetRole(editRoleId)
            GetPermissiom(editRoleId)
        }
    },[editRoleId])

  return (
    <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <Breadcrumb
      items={[
        {
          name: "Settings",
        },
        {
          name: "Roles"
        },
        {
          name:"Edit"
        }
      ]}
    />
    <BackButton/>
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto px-4 py-2 rounded-md overflow-y-scroll">
    <form onSubmit={formik.handleSubmit}>
    <div className=" w-full">
              <label className=" font-semibold text-sm">Role Name:</label>
              <NuInput
                disabled={true}
                name="role"
                type="text"
                formik={formik}
                className={`rounded-lg w-full p-1 outline-none border-2`}
              />
      </div>
      <div className=" w-full">
            {userRole.map((item, index) => {
      return (
        <div key={index}>
          {/* <NuLabel label={item?.name} /> */}
          <p className=" my-2  font-semibold text-sm">{item?.name}</p>
          <div className="px-6 grid gap-3 grid-cols-4">
            {item?.roles.map((role, index) => {
              return (
                <div key={index} className=" w-full bg-slate-100 px-2 py-4 rounded-md text-xs ">
                  <input
                    type="checkbox"
                    className="px-3 mr-2 py-2 accent-main-blue"
                    // name={user.name}
                    // checked={users.some((u) => u?.id === user?.id)}
                    // disabled={DisableFun(role.id)}
                    checked={roleInfo.some((id) => id === role.id)}
                    onChange={() => selectRow(item.name, role.id)}
                  />
                  <span className=" font-medium">{role.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    })}
    </div>
    <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
    </form>
    </div>
    </div>
  )
}

export default EditRole