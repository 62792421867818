import React, { useEffect, useMemo, useState } from 'react'
import DeleteIcon from '../../../../Components/Icons/Delete';
import { NuDate, NuInput, NuTextArea, NuUpload } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { useLocation } from 'react-router-dom';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from 'yup';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import axiosInstance from '../../../../Services/axiosCommon';

function EditCustomerOrder() {
  // const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_CO')

    const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_CO')
    const { data: Item } = UseDropDown('/items/drop-down');
    const { data: user } = UseDropDown('/user/drop-down');
    const { data: customers } = UseDropDown('/customers/drop-down');
    const { data: PR } = UseDropDown('/PR/drop-down');
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(location.state, 'state');
    const [dataa,setData] = useState(data);
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [items, setItems] = useState([]);
    const [remove, setRemove] = useState([]);
    const [Cqlist, setCqlist] = useState(data.products);

    const [newItemData, setNewItemData] = useState({     
      productId: "",
      product_category:"",
      product_name:"",
      product_unit:"",
      quantity:"",
      price:"",       
     });
    const [createNew, setCreateNew] = useState({
        receivedOn: data.receivedOn,
        deadLine: data.deadLine,
        notesFromCus: data.notesFromCus,
        customerId: data.customerId,
        ref: data.ref,
        totalPrice: data.totalPrice,
        verifiedBy: data.verifiedBy,
        reviewedBy: data.reviewedBy,
        approvedBy: data.approvedBy,
        salesPerson: data.salesPerson,
        
        assignee: data.assignee,
         status: data.status,
         cusQuoteId:data.cusQuoteId,
         prId:data.prId,
        removedProducts: [],
        filesToRemove:[]  
    })

    const handleDelete = (productId) => {
       const updatedProductList = dataa?.products.filter(item => item.productId !== productId);
       console.log(updatedProductList,"lll");
        const deletedProduct = dataa?.products.find(item => item.productId === productId);

    if (deletedProduct && deletedProduct.price && deletedProduct.quantity) {
      const priceToRemove = deletedProduct.price || 0;
      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = priceToRemove * quantityToRemove;
  
      const newTotalPrice = updatedProductList.length > 0 
                            ? formik.values.totalPrice - reduction 
                            : 0;
  
      setData(prevState => ({
        ...prevState,
        products: updatedProductList,
       
      }));

      formik.setFieldValue('totalPrice', newTotalPrice);

      setRemove([...remove,productId]);
    }
      // setremove(prevState => ({
      //   ...prevState,
      //   removedProducts: [...prevState.removedProducts, deletedItem]
      // }));
    };  
  
    const handleAdd = () => {
      const newItem = {
        productId: formik.values.productId,
        productName : Item.find((i)=> i.value===formik.values.productId),
        quantity: formik.values.quantity,
        price:formik.values.price
      };
      setItems([...items, newItem]);
      setNewItemData({
      productId: "",
    price:"",
      quantity: ""
      });
      formik.values.productId = '';
      formik.values.quantity ='';
      formik.values.price=''     
    };
  
    const handleDeleteItem = (indexToDelete) => {
      const updatedItems = items.filter((_, index) => index !== indexToDelete);
      setItems(updatedItems);
       
    };
  
    const handleChangeImg = (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };
    const handleImageRemove = () => {
      setImage({
        preview: "",
        raw: "",
      });
      setCreateNew(prevState => 
        ({ ...createNew, image: "" }))
      formik.values.image = ""
    }

    const [error,setError] = useState('')
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        prId: yup.string().required('Purchase Request is requred!'),
        cusQuoteId: yup.string().required('Customer Quote is requred!'),
        salesPerson: yup.string().required('Sales Person is requred!')
      }),
      onSubmit: async (value, { resetForm }) => {
        if (dataa.products.length === 0) {
          setError('Product table is empty');
          return;
        }
        let formData = new FormData();
        const valueWithItems = {
          ...value,
          products: dataa.products,
          removedProducts:remove
        };
        formData.append('json_data', JSON.stringify(valueWithItems));
        formData.append('attachments', []);
  
        editFormData(`/customer-orders/edit-order/${data.id}`, formData, resetForm,from);
      }
    });
  
    const [CustomerQ, setCustomerQ] = useState([])

    function getCustomerQ(num){
      axiosInstance.get(`/customer-orders/customer-quote/drop-down/${num}`).then((response) => {
        let tempData =[]
        if(response.data){
          let x = response.data
  
          x.map((item) => {
          let y = {
            value: item.id,
            label: item.name,
          }
          tempData.push(y)
          })
  
          setCustomerQ(tempData)
        }
      }).catch((error) => {})
     }
  
     useMemo(()=>{
      if(formik.values.prId){
        getCustomerQ(formik.values.prId);
  
      }
     },[formik.values.prId])

    // const getCQ = (cusQuoteId) => {
    //   if (cusQuoteId !== "") {
    //     axiosInstance
    //       .get(`/supplier-quotations/selected-products/drop-down/${cusQuoteId}`)
    //       .then(res => {
    //         let data = res.data?.data?.products;

    //         let tPrice=0.00
    //         for (let i=0;i<data?.length;i++){
    //          tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
    //         }
    //        //  setTotalPrice(tPrice);
    //        formik.setFieldValue('totalPrice', tPrice);
    //       //  formik.setFieldValue('paymentTerms', res?.data?.data?.paymentTerms || '');
    //       //  formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
       
    //         setCqlist(data);
    //       })
    //       .catch(err => {
    //         console.log(err, "err");
    //       });
    //   }
    // };
  
    // useEffect(() => {
    //   if (formik.values.cusQuoteId) {
    //     getCQ(formik.values.cusQuoteId);
    //   }
    // }, [formik.values.cusQuoteId]);

    useEffect(() => {
      const totalPrice = Cqlist.reduce((total, item) => {
        return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
      }, 0);
  
      formik.setFieldValue('totalPrice', totalPrice);
    }, [Cqlist]);

    return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb items={[
            // { name: "Procurement" },
  
            {
              name: "Customer Order",
              link: "sales/customer-order"
            },
  
            { name: "Edit" },
          ]} />
          <div className=" flex gap-2">
            <BackButton />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-hidden ">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
             <FormikSelectTwo
                type="text"
                label="Customer Quote"
                placeholder="Choose"
                Options={CustomerQ}
                formik={formik}
                name="cusQuoteId"
                disabled={true}
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
            <NuDate
                label="Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               {/* <NuDate
                label="Dead Line"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLine"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              
                 {/* <NuInput
             label="Reference"
             type="text"
             formik={formik}
             placeholder="Reference"
             name="ref"
               width="w-full md:w-1/2 lg:w-1/3"
           />          */}
                {/* <NuInput
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCus"
                 width="w-full md:w-1/2 lg:w-1/3"
              /> */}
              {/* <NuInput
                label="Total Price"
                type="text"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                // disabled={true}
              /> */}
              <FormikSelectTwo
                type="text"
                label=" Assignee"
                placeholder=" Assignee"
                Options={user}
                formik={formik}
                name="assignee"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Sales Person"
                Options={user}
                formik={formik}
                name="salesPerson"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
  
              {/* <FormikSelectTwo
                type="text"
                label="Verified By"
                placeholder="Verified By"
                Options={user}
                formik={formik}
                name="verifiedBy"
                  width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Reviewed By"
                placeholder="Reviewed By"
                Options={user}
                formik={formik}
                name="reviewedBy"
                 width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Approved By"
                placeholder="Approved By"
                Options={user}
                formik={formik}
                name="approvedBy"
                 width="w-full md:w-1/2 lg:w-1/3"
              /> */}
               {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
              <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Status"
                Options={status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             
              <NuTextArea
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
               <NuUpload
                label="Attachment"
                width="w-full md:w-1/2 lg:w-1/3"
                onChange={handleChangeImg}
              /> 
  <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
            {/* <div className="w-full flex flex-col md:flex-row gap-y-3 md:gap-y-0 justify-start gap-3 border border-solid">
                <FormikSelectTwo
                     type="text"
                     label="Product Name"
                     placeholder="Product "
                     Options={Item}
                     formik={formik}
                     name="productId"
                     width="w-full md:w-1/2 lg:w-1/2"
                   />        
                    <NuInput
             label="Quantity"
             type="number"
             formik={formik}
             placeholder="Quantity"
             name="quantity"
             width="w-full md:w-1/2 lg:w-1/3"
           /> 
              <button
                  onClick={handleAdd}
                    type="button"
                   className=" p-1 mt-10 m-1 text-center h-10 cursor-pointer bg-[#048178] border border-solid border-white  text-white font-semibold rounded-lg "
                     >
                  Add
                 </button>
                 </div> */}
                 <div className='w-full bg-white overflow-y-scroll mt-5'>
               {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                <p className=" w-[40%] text-left text-white text-xs font-medium">
                Product Name
                </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium">
                Unit
                </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium">
                Quantity
                </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium">
                Price
                </p>
                <p className=" w-[5%] text-center text-white text-xs font-medium">
                Action
                </p>
                </div>
               {dataa?.products.length>0 ?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

                {dataa?.products.map((List, index) => {
                return (
                  <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                    <p className=" w-[40%] text-left text-[#606279] text-xs font-light ">
                    {List?.product_name}
  
                    </p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light ">
                      {List?.product_unit}
                    </p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light ">
                      {List?.quantity}
                    </p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light ">
                      {List?.price}
                    </p>
                   
                   
                   <div className='w-[5%] text-center'> 
                   <button
                      onClick={() => handleDelete(List?.productId)}
                      type="button"
                      className="text-red-500 cursor-pointer  ml-4 border-none"
                    >
                      <DeleteIcon
                       color='red'
                       height={4}
                      />
                    </button>
                    </div>
                  
                  </div>
                );
              })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

                                     </div>
                                     <div className='w-full my-6 flex justify-end items-center gap-4'>
              <TextMediumBase content={"Total Price : "} />
              <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              />
            </div>
                                     <div className="w-full hidden md:block mx-auto my-4 mt-8 py-4">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel() }} />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
            </div>
        </form>
      </div>
    );
  }
  

export default EditCustomerOrder