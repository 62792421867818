import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import { NuInput, NuMobileNumber, NuTextArea, NuUpload } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import useFetchMetaStar from '../../../../Components/Hooks/useFetchMetaStar';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function CreateCustomers() {
  const {value:Industry ,getData:refIndustry} = useFetchMataData(`/dropdown/dropdown-details/`,'Industry_Customer')
  const {value:Desigination,getData:refDesigination} = useFetchMataData(`/dropdown/dropdown-details/`,'Desigination_Customer')
  const { value:customerRating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'customerRating_Customer')
  const {value:customerType ,getData:refcustomerType} = useFetchMataData(`/dropdown/dropdown-details/`,'customerType_Customer')
  const {value:Category ,getData:refCategory} = useFetchMataData(`/dropdown/dropdown-details/`,'category_Customer')

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    customerType: "",
    companyName: "",
    email: "",
    phone: "",
    countryCode: "",
    website: "",
    industry: "",
    billingAddress: "",
    shippingAddress: "",
    crNum: "",
    vatNum: "",
    ratings: "",
    paymentTerm: "",
    creditLimit: "",
    comments: "",
    country: "",
    city: "",
    state: "",
    district: "",
    postalCode: "",
    cpName: "",
    cpEmail: "",
    designation: "",
    cpCountryCode: "",
    cpPhone: "",
    category:""
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      phone: yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")
      .min(9, "Phone number must be at least 9 digits")
      .max(10, "Phone number must be at most 10 digits")
      .required("Phone number is required"),
      email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append('customerImage', image.raw);

      addFormData('/customers/new-customer', formData, resetForm);
    }
  })
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb items={[
          // { name: "Sales" },

          {
            name: "Customers",
            link: "/sales/customers"
          },

          { name: "Create" },

        ]} />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-hidden">
          <div className=" w-full h-full py-2 flex justify-start content-start flex-wrap  overflow-y-auto">
          <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Customer Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>

          <DropDownAddMetaData
                label="Customer Type"
                placeholder="Choose"
                Options={customerType}
                formik={formik}
                name="customerType"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refcustomerType}
                displayProperty={"Customer Type"}
                propertyName={"customerType_Customer"}
              />
           
            <NuInput
              type="text"
              label="Company Name"
              placeholder="Company Name"
              formik={formik}
              name="companyName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuMobileNumber
              nunCode="countryCode"
              label="Phone"
              placeholder="Phone"
              formik={formik}
              name="phone"
              width="w-full md:w-1/2 lg:w-1/3"

            />
            <NuInput
              type="email"
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
              isRequired={true}
            />
           <NuInput
              type="text"
              label="website"
              placeholder="website"
              formik={formik}
              name="website"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
                label="Industry"
                placeholder="Choose"
                Options={Industry}
                formik={formik}
                name="industry"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refIndustry}
                displayProperty={"Industry"}
                propertyName={"Industry_Customer"}
              />
             <DropDownAddMetaData
                label="Desigation"
                placeholder="Choose"
                Options={Desigination}
                formik={formik}
                name="designation"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refDesigination}
                displayProperty={"Desigation"}
                propertyName={"Desigination_Customer"}
              />
              <NuInput
              type="text"
              label="Contact Person Name"
              placeholder="Countact Person Name"
              formik={formik}
              name="cpName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <NuMobileNumber
              nunCode="cpCountryCode"
              label="Contact Person Phone"
              placeholder="Contact Person Phone"
              formik={formik}
              name="cpPhone"
              width="w-full md:w-1/2 lg:w-1/3"

            />
            <NuInput
              type="email"
              label="Contact Person Email"
              placeholder="Countact Person Email"
              formik={formik}
              name="cpEmail"
              width="w-full md:w-1/2 lg:w-1/3"
            />

           
          
           
             <DropDownAddMetaData
                label="Category"
                placeholder="Choose"
                Options={Category}
                formik={formik}
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refCategory}
                displayProperty={"Category"}
                propertyName={"category_Customer"}
              />           
               <NuInput
              type="number"
              label="CRNum"
              placeholder="CRNum"
              formik={formik}
              name="crNum"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Vat Num"
              placeholder="Vat Num"
              formik={formik}
              name="vatNum"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Ratings"
              placeholder="Ratings"
              Options={customerRating}
              formik={formik}
              name="ratings"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Payment Term"
              placeholder="payment Term"
              formik={formik}
              name="paymentTerm"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Credit Limit"
              placeholder="Credit Limit"
              formik={formik}
              name="creditLimit"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Address</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
      <NuTextArea
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              type="text"
              label="Shipping Address"
              placeholder="Shipping Address"
              formik={formik}
              name="shippingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Country"
              placeholder="country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="State"
              placeholder="state"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="District"
              placeholder="district"
              formik={formik}
              name="district"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="City"
              placeholder="city"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="Postal Code"
              placeholder="postalCode"
              formik={formik}
              name="postalCode"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
              {/* <NuInput
              type="text"
              label="Billing Address"
              placeholder="Billing Address"
              formik={formik}
              name="billingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Shipping Address"
              placeholder="Shipping Address"
              formik={formik}
              name="shippingAddress"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateCustomers