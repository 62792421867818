import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const IdType = () => {
  return(
    <SettingsMetaData propertyName={"DeliveryNoteIdType"} viewPropertyName={"Id Type"} />
  )
};

export default IdType;
