
import React from 'react'

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      padding: 10,
      fontSize: 12,
      fontFamily: 'Helvetica',
    },
    headingContainer: {
      flexDirection: 'row',
      alignItems: 'center', 
      marginBottom: 10,
    },
    headingTitle: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    headingText: {
      marginTop: 5,
      fontSize: 14,
    },
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: 10,
    },
    label: {
      width: '50%',
      padding: 5,
      fontSize: 12,
      fontWeight: 'bold',
    },
    value: {
      width: '50%',
      padding: 5,
      fontSize: 12,
    },
    productRow: {
      flexDirection: 'row',
      padding: 5,
    },
    productHeader: {
      backgroundColor: '#1e90ff',
      color: '#fff',
      padding: 5,
      fontSize: 12,
      fontWeight: 'bold',
    },
    productCell: {
      padding: 5,
      fontSize: 12,
    },
    note: {
      marginTop: 20,
      padding: 10,
      borderWidth: 1,
      flexDirection: 'row',
      justifyContent: 'space-between', 
      borderColor: '#ccc',
    },
  });
const DownloadDn = ({data}) => {
    return(
        <Document>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.headingContainer}>
            <Text style={styles.headingTitle}>Delivery Note</Text>
            <Text style={styles.headingText}>: {data.ref}</Text>
          </View>
    
          <View style={styles.section}>
            {[
              { label: 'Sales Order', value: data.soRef },
              { label: 'Customer ', value: data.customerName },
              { label: 'Days Elapsed', value: data.daysElapsed || 'N/A'},
              { label: ' Date ', value: data.date || 'N/A'},
              { label: 'Id Number', value: data.idNumber || 'N/A'},
              { label: ' Id Type ', value: data.idType || 'N/A'},
              { label: 'Mobile', value: data.mobile.display || 'N/A'},
              { label: ' Delivery Person  ', value: data.deliveryPersonName || 'N/A'},
              { label: ' Delivery Vehicle Type ', value: data.deliveryVechicleType || 'N/A'},
              { label: ' WayBill No ', value: data.wayBillNo || 'N/A'},
              { label: ' Delivered On ', value: data.deliveredOn || 'N/A'},
              { label: ' Delivery Status ', value: data.deliveryStatus || 'N/A'},
              { label: ' Comments ', value: data.comments || 'N/A'},
             
                   ].map((item, index) => (
              <View key={index} style={{ flexDirection: 'row' }}>
                <Text style={styles.label}>{item.label}</Text>
                <Text style={styles.value}>{item.value}</Text>
              </View>
            ))}
          </View>
    
          <View style={{ marginTop: 20, backgroundColor: '#D3D3D3' }}>
            <View style={{ flexDirection: 'row' }}>
              {[
                'S.N','Product Name', 'Product Unit', 'Ordered Quantity',
                'Packed Quantity', 'Remaining Quantity'
              ].map((header, index) => {
                let width=[5, 30, 10, 20,20,15 ]
                return(
                <Text key={index} style={[styles.productHeader, { width: `${width[index]}%` }]}>
                  {header}
                </Text>
              )})}
            </View>
            {data?.products?.map((product, index) => (
              <View key={index} style={styles.productRow}>
                <Text style={[styles.productCell, { width: '5%' }]}>{(index+1)}</Text>
                <Text style={[styles.productCell, { width: '30%' }]}>{product.product_name}</Text>
                <Text style={[styles.productCell, { width: '10%' }]}>{product.product_unit}</Text>
                <Text style={[styles.productCell, { width: '20%' }]}>{product.orderedQty}</Text>
                {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.product_category}</Text> */}
                {/* <Text style={[styles.productCell, { width: '20%' }]}>{product.requestedQty}</Text> */}
                <Text style={[styles.productCell, { width: '20%' }]}>{product.packedQty}</Text>
                <Text style={[styles.productCell, { width: '15%' }]}>{product.remainingQty}</Text>
                {/* <Text style={[styles.productCell, { width: '15%' }]}>{product.totalPrice}</Text> */}
              </View>
            ))}
          </View>
    
          {/* <View style={styles.note}> */}
            {/* <Text>Note From Customer: {data.notesFromCus || 'N/A'}</Text> */}
    
            {/* <Text>Total Price: {data.subTotal}</Text> */}
          {/* </View> */}
        </Page>
      </Document>
    )
}

export default DownloadDn