// import React from 'react'

// const ViewPdf =  ({ data }) => {
  
//     return (
//       <div className="py-2 text-sm md:text-sm lg:text-xs xl:text-sm font-normal bg-white font-helvetica w-[50%]  mx-auto  border rounded-lg ">
//       <div className='h-[calc(100vh-80px)] overflow-y-scroll p-2'>
//            <div className="flex gap-3 items-center">
//          <h3 className="text-[14px] font-normal">Customer Order</h3>
//         <p className="text-sm font-light"> : {data.ref}</p>
//       </div>
  
//         <div className="grid  items-center mt-2 mb-2">
//           {[
//            { label: 'Purchase Requests', value: data.prRef },
//            { label: 'Customer Qoute', value: data.cqRef },
//            { label: 'Received On', value: data.receivedOn || 'N/A'},
//            { label: 'Dead Line', value: data.deadLine || 'N/A'},
//            { label: 'Verified By', value: data.verifiedByName || 'N/A'},
//            { label: 'Reviewed By ', value: data.reviewedByName || 'N/A'},
//            { label: 'Approved By', value: data.approvedByName || 'N/A'},
//            { label: 'Sales Person', value: data.salesPersonName || 'N/A'},
//            { label: 'Assignee', value: data.assigneeName || 'N/A'},
//            { label: 'Status', value: data.status || 'N/A'},
//                ].map((item, index) => (
//             <div key={index} className="flex text-[12px] ">
//               <p className="w-[40%] py-1 font-medium">{item.label}</p>
//               <p className="w-[40%] py-1">{item.value}</p>
//             </div>
//           ))}
//         </div>
  
//         <div className=" bg-gray-300">
//           <div className="w-full flex justify-between text-left text-[12px]">
//             {['S.N',  'Item Code','Product Name', 'Product Unit',
//             'Quantity', 'Price'].map((header, index) => (
//               <p key={index} className="bg-blue-500 text-white py-1 p-2 font-bold " style={{ width: `${[5, 20, 30, 10, 15, 20][index]}%` }}>
//                 {header}
//               </p>
//             ))}
//           </div>
//           {data?.products?.map((product, index) => (
//             <div key={index} className=" w-full flex justify-between py-1 p-2 text-[12px]">
//               <p className="w-[5%] ">{index + 1}</p>
//               <p className="w-[20%] ">{product.item_code}</p>
//               <p className="w-[30%] ">{product.product_name}</p>
//               <p className="w-[10%] ">{product.product_unit}</p>
//               <p className="w-[15%] ">{product.quantity}</p>
//               <p className="w-[20%] ">{product.price}</p>
//             </div>
//           ))}
//         </div>
  
//         <div className="flex justify-between p-2 my-4 border-2 border-gray-300 text-[12px]">
//         <p>Note FromCustomer: {data.notesFromCus || 'N/A'}</p>
          
//           <p>Total Price: {data.subTotal}</p>
//         </div>
//         </div>
//       </div>
//     );
// }

// export default ViewPdf

import React from 'react'
import CompanyLogo from '../../../../assets/images/companyLogo.png'
import CompanyName from '../../../../assets/images/companyName.png'
import Seal from '../../../../assets/images/seal.png'
import NuDate from '../../../../Components/Utils/NuDate'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
const ViewPdf = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);
  return (
    <div
    ref={containerRef}
    className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border">
     <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className='relative w-full h-full '>
          <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
            <button onClick={() => zoomIn()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">+</button>
            <button onClick={() => zoomOut()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">-</button>
            <button onClick={() => resetTransform()} className='text-left'>Reset</button>
          </div>
      <div className="flex justify-center items-center w-full h-full">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <div className='min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black'>
      <div className="border border-black">
     <div className="flex justify-between items-center border-b border-black">
        <div className='w-full'>
          <div className='w-full flex justify-between items-center p-4'>
               <img src={CompanyName} alt="Loading" className='h-28' />
              <img src={CompanyLogo} alt="Loading" className='h-28 w-28'/>
          </div>
        </div>
      </div>

      <div className=" grid grid-cols-3 border-b border-black">
        <div className=' w-full p-3 flex items-center border-r border-black'>Your Ref. :</div>
        <div className=' w-full p-3 text-center text-lg font-semibold border-r border-black'>CUSTOMER ORDER</div>
        <div className=' w-full'>
          <div className=' w-full h-1/2 border-b border-black px-2 pt-1 flex'>
            <p className=' w-8'>Date </p>
            <p className="text-xs">: {data?.receivedOn ? (
                  <NuDate value={data?.receivedOn} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
          </div>
          <div className=' w-full h-1/2 px-2 pt-1 flex'>
          <p className=' w-8'>CO# </p>
          <p className="text-xs"> : {data.ref || 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 border-b border-black">
        <div className=' w-full px-3  py-2'>
        <h4 className="font-semibold text-sm">Purchase Requests</h4>
        </div>
        <div className=' w-full px-3  py-2'>
        <h4 className="font-semibold text-sm">Customer Qoute</h4>
        </div>
        <div className=' w-full px-3  py-2'>
        <h4 className="font-semibold text-sm ">CO Details</h4>
        </div>
      </div>

      <div className="grid grid-cols-3 border-b border-black">
      <div className=' w-full px-3 py-1 border-r border-black'>
        <p>{data.prRef || 'N/A'}</p>
        </div>
        <div className=' w-full px-3 py-1 border-r border-black'>
        <p>{data.cqRef || 'N/A'}</p>
        </div>
        <div className=' w-full  px-3 py-1'>
        <table className='w-full'>
          <tbody>
            <tr className=' w-full'>
              <td className=' w-[30%] py-1'>Sales Person </td>
              <td className=' w-[70%] py-1'>: {data.salesPersonName || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-1'>Assignee </td>
            <td className=' w-[70%] py-1'>: {data.assigneeName || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-1'>Dead Line </td>
            <td className=' w-[70%] py-1'>: {data.deadLine && <NuDate value={data.deadLine} format="DD-MMM-YYYY" /> || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>


      <table className="w-full border-collapse ">
        <thead>
          <tr className="bg-gray-200 text-[12px]">
            {['S.N',  'Item Code','Product Name', 'Product Unit',
           'Quantity', 'Price','Total'].map((header, index) => (
              <th key={index} className="border-b border-r last:border-r-0  border-black p-2 text-left font-bold">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.products?.map((product, index) => (
            <tr key={index} className="border-y border-black text-[12px]">
              <td className="p-2">{index + 1}</td>
              <td className="border border-black p-2">{product.item_code || 'N/A'}</td>
              <td className="border border-black p-2">{product.product_name|| 'N/A'}</td>
              <td className="border border-black p-2">{product.product_unit|| 0}</td>
              <td className="border border-black p-2">{product.quantity || 0}</td>
              <td className="border border-black p-2">{product.price || 0}</td>
              <td className="border border-r-0 border-black p-2">{(product.quantity * product.price).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-y border-black text-[12px]">
            <td className=' py-3 border-r border-black  pr-3 ' colSpan={6}>
              <div className=' w-full flex justify-end'>
                <div>
                <p className=' text-xs font-medium'>TOTAL</p>
                <p className=' text-xs font-medium'>NET TOTAL</p>
                </div>
              </div>
            </td>
            <td className='px-2 py-3'>
            <div className=' w-full'>
                <div>
                <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p>
                <p className=' text-xs font-medium'>{data.totalPrice.toFixed(2)}</p>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      {/* <div className="mt-4 text-[12px]">
        <h4 className="font-bold">Terms & Conditions</h4>
        <div className="w-full flex px-2 text-[12px]">
              <p className="w-1/5 py-1 text-xs  font-medium text-[#111537]">
              Price
              </p>
              <p className="w-1/2 py-1 text-xs   text-[#606279]">
                : SAR
              </p>
            </div> 
            <div className="w-full flex px-2 text-[12px]">
              <p className="w-1/5 py-1 text-xs  font-medium text-[#111537]">
              Delivery Period
              </p>
              <p className="w-1/2 py-1 text-xs   text-[#606279]">
                : Immediate
              </p>
            </div> 
            <div className="w-full flex px-2 text-[12px]">
              <p className="w-1/5 py-1 text-xs  font-medium text-[#111537]">
              Delivery
              </p>
              <p className="w-1/2 py-1 text-xs   text-[#606279]">
                : {data.deliveryTerms || 'N/A'}
              </p>
            </div>
            <div className="w-full flex px-2 text-[12px]">
              <p className="w-1/5 py-1 text-xs  font-medium text-[#111537]">
              Payment
              </p>
              <p className="w-1/2 py-1 text-xs   text-[#606279]">
                : {data.paymentTerms || 'N/A'}
              </p>
            </div> 
       
      </div> */}

      <div className="mt-4 px-3 pb-4 flex justify-between items-center text-[12px]">
        <div>
          <p className="text-xs ">Note:</p>
        
        <p>{data.notesFromCus || 'N/A'}</p>

        </div>
        {/* <div className="text-center">
        <p className="text-xs ">Authorized By</p>
          <img src={Seal} alt="Company Seat" className="h-16 mb-2" />
        </div> */}
      </div>
      </div>
      </div>
      </TransformComponent>
      </div>
      </div>
      // </div>
        )}
      </TransformWrapper>
    </div>
  );
}

export default ViewPdf