import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDataTime, NuDate, NuInput, NuTextArea, NuTime } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import UseEditData from '../../../../Components/Hooks/useEditData';
import * as yup from 'yup';
import Dashboard from '../../../../Components/Icons/Dasboard';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import Delete from '../../../../Components/Icons/Delete';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import axiosInstance from '../../../../Services/axiosCommon';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import EditIcon from '../../../../Components/Icons/Edit';
import Select from 'react-select';
import NuAmount from '../../../../Components/Utils/NuAmount';

function QuoteEdit() {
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_CQ')

  const {value:Availablity} = useFetchMataData(`/dropdown/dropdown-details/`,'Availablity_CustomerQuotes')
  const {value:DeliveryTerm} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryTerm_CustomerQuotes')
  const {value:PaymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_CustomerQuotes')
  const {value:PaymentMethod} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentMethod_CustomerQuotes')
  const {value:Validity} = useFetchMataData(`/dropdown/dropdown-details/`,'Validity_CustomerQuotes')
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_customerQuotes')
  const {value:leadTime} = useFetchMataData(`/dropdown/dropdown-details/`,'leadTime_customerQuotes')
  const { data: customers } = UseDropDown('/customers/drop-down');
  const { quotesEditId } = useParams();

  const [image, setImage] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location?.state?.data;
  const from = location?.state?.from;
  console.log(location?.state?.data,"ll");
  
 

  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
//   console.log(location.state, 'state');
//   const [dataa,setData] = useState(data);
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [remove, setRemove] = useState([]);
  const [dataa,setData] = useState(data);
  const [items, setItems] = useState( dataa?.quoteProducts || []);
  console.log("qqqq",dataa?.quoteProducts);
  console.log("wwww",data);
  const [newItemData, setNewItemData] = useState({
    supQuotProductId: "",
            quantity: "",
            price: "",
            comments: ""
   });
  const [createNew, setCreateNew] = useState({
    date: "",
    deliverTerms: "",
    paymentTerms: "",
    paymentMethod: "",
    totalPrice: 0.00,
    prId: "",
    ref: "",
    // ref: "",
    availability: "",
    validity: "",
    specialNotes: "",
    leadTime: "",
    leadTime2: "",
    preparedBy: "",
    reviewedBy: "",
    approvedBy: "",
    remarks: "",
    comments: "",
    products: "",
    discount: "",
    approvalStatus:"",
    status:""

  })
  
  useEffect(()=>{
    // let objectlength=Object.keys(data).length;
    // console.log(objectlength,"length");
    
    // if(objectlength>1){
    //   setCreateNew( {date: data?.date || "",
    //     deliverTerms: data?.deliveryTerms || "", 
    //     paymentTerms: data?.paymentTerms || "",
    //     paymentMethod: data?.paymentMethod || "",
    //     totalPrice: data?.totalPrice || "",
    //     prId: data?.prId || "",
    //     ref: data?.ref || "",
    //     availability: data?.availability || "",
    //     validity: data?.validity || "",
    //     specialNotes: data?.specialNotes || "",
    //     leadTime: data?.leadTime || "",
    //     preparedBy: data?.preparedBy || "",
    //     reviewedBy: data?.reviewedBy || "",
    //     approvedBy: data?.approvedBy || "",
    //     remarks: data?.remarks || "",
    //     comments: data?.comments || "",
    //     status: data?.status || "",
    //     approvalStatus: data?.approvalStatus || "",
    //     discount: data?.discount || "",
    //     product_name:data?.product_name ||"",
    //     price:data?.price || "",
    //     leadTime:data?.leadTime || "",
    //     quantity:data?.quantity ||"",
    //     quoteProducts:[]
    // })
    // }else if(objectlength == 1){
    //   console.log(data,"kk");
      getById(quotesEditId)

    // }
    

  },[data])

  function getById(quotesEditId){
    axiosInstance.get(`/customer-quotations/${quotesEditId}`).then(res=>{
        setCreateNew({
          id:res?.data?.data?.id||"",
          date: res?.data?.data?.date || "",
          deliverTerms: res?.data?.data?.deliveryTerms || "", 
          paymentTerms: res?.data?.data?.paymentTerms || "",
          paymentMethod: res?.data?.data?.paymentMethod || "",
          totalPrice: res?.data?.data?.totalPrice || "",
          prId: res?.data?.data?.prId || "",
          ref: res?.data?.data?.ref || "",
          availability: res?.data?.data?.availability || "",
          validity: res?.data?.data?.validity || "",
          specialNotes: res?.data?.data?.specialNotes || "",
          leadTime: res?.data?.data?.leadTime || "",
          preparedBy: res?.data?.data?.preparedBy || "",
          reviewedBy: res?.data?.data?.reviewedBy || "",
          approvedBy: res?.data?.data?.approvedBy || "",
          remarks: res?.data?.data?.remarks || "",
          comments: res?.data?.data?.comments || "",
          status: res?.data?.data?.status || "",
          approvalStatus: res?.data?.data?.approvalStatus || "",
          discount: res?.data?.data?.discount || "",
          product_name:res?.data?.data?.quoteProducts?.product_name || "",
          quantity:res?.data?.data?.quoteProducts?.quantity || "",
          leadTime:res?.data?.data?.quoteProducts?.leadTime || "",
          price:res?.data?.data?.quoteProducts?.price || "",
        })
       setItems(res?.data?.data?.quoteProducts)

        console.log("ITEM",res?.data?.data?.quoteProducts);
        
    }).catch(err=>{
        console.log("error",err);
        
    })
  }

  const handleDelete = (productId) => {
    const updatedquoteProducts = dataa?.quoteProducts.filter(item => item.productId !== productId);
  
   
    const deletedProduct = dataa?.quoteProducts.find(item => item.productId === productId);
  
    if (deletedProduct && deletedProduct.price && deletedProduct.quantity) {
      const priceToRemove = deletedProduct.price || 0;
      const quantityToRemove = deletedProduct.quantity || 0;
      const reduction = priceToRemove * quantityToRemove;
  
    
      const newTotalPrice = updatedquoteProducts.length > 0 
      ? formik.values.totalPrice - reduction 
      : 0;

      setData(prevState => ({
        ...prevState,
        quoteProducts: updatedquoteProducts,
        totalPrice: newTotalPrice
      }));
  
      formik.setFieldValue('totalPrice', newTotalPrice);
  
      setRemove([...remove, productId]);
    }
  };
  const handleAdd = () => {
    const newItem = {
      supQuotProductId: formik.values.supQuotProductId,
      comments: formik.values.comments,
      price: formik.values.price,
      quantity: formik.values.quantity
    };
    setItems([...items, newItem]);
    // Update local storage
    setNewItemData({
    supQuotProductId: "",
    comments: "",
    price: "",
    quantity: ""
    });
    formik.values.supQuotProductId = '';
    formik.values.quantity ='';
    formik.values.price = '';
    formik.values.comments =''
  };

  const [deletedItem,setDeletedItem] = useState([]);
  const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
  console.log("product",items);

  const handleDeleteItem = (indexToDelete) => {
    const updatedItems = items.filter(item => item.productId !== indexToDelete);
    setItems(updatedItems);
    console.log("dbjhbc",updatedItems);
    const deletedItems = items.find(item => item.productId == indexToDelete);

    console.log("Test delete",deletedItems);
    
    if (deletedItems) {
      const priceToRemove = deletedItems.price || 0;
      const quantityToRemove = deletedItems.quantity || 0;
      const reduction = priceToRemove * quantityToRemove;    
      const newTotalPrice = updatedItems.length > 0 
      ? formik.values.totalPrice - reduction 
      : 0;
      formik.setFieldValue('totalPrice', newTotalPrice);
    setDeletedItem((prevDeletedItems) => [...prevDeletedItems,deletedItems]);
    }
  };
  useEffect(() => {
    let tempData = [];
    deletedItem.forEach((item) => {
      let tempObject = { value: item.productId, label: item.product_name };
      tempData.push(tempObject);
    });
    setDeletedItemDropDown(tempData);
  }, [deletedItem]);
  const handleAddDelPro = () => {
    const selectedProductId = formik.values.productId;
  
    // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
    const selectedProduct = deletedItem.find(
      (item) => item.productId === selectedProductId
    );
  
    if (selectedProduct) {
      // Add the selected product to prList
      // setData((prevState) => ({
      //   ...prevState,
      //   products: [...prevState.products, selectedProduct],
      // }));
      setItems((prevPrList) => [...prevPrList, selectedProduct])
  
      // Optionally, remove the selected product from the dropdown after adding it to prList
      setDeletedItem((prevDeletedItems) =>
        prevDeletedItems.filter((item) => item.productId !== selectedProductId)
      );
    }
  };
  const [error,setError] = useState('')
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({

    }),
    onSubmit: async (value, { resetForm }) => {
      if (items.length === 0) {
        setError('Product table is empty');
        return;
      }
      const valueWithItems = {
        ...value,
        products: items,
        removedProducts:remove
      };

      editFormData(`/customer-quotations/edit-quotation/${value?.id}`, valueWithItems, resetForm, `/sales/quotes`);
    }
  });

  const { data: PR } = UseDropDown('/PR/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');
  const [subQuot,setSubQuot] = useState([]);
const listSubQuotes = useMemo(() => {
  axiosInstance
  .get(`/supplier-quotations/quot-products/drop-down/${formik?.values?.prId}`)
  .then((res) => {
    let list = res.data;
    let array = [];
    list &&
      list.forEach((i) => {
        let num = { value: i?.id, label: i?.productName };
        array.push(num);
      });
    res.data && setSubQuot(array);
  })
  .catch((error) => {
        console.error('Error fetching data:', error);
      });
}, [formik?.values?.prId]);

const [prlist, setPrlist] = useState([]);

const getPr = (prId) => {
  if (prId !== "") {
    axiosInstance
      .get(`/supplier-quotations/selected-products/drop-down/${prId}`)
      .then(res => {
        let data = res?.data?.data?.products;

        let tPrice=0.00
        for (let i=0;i<data?.length;i++){
         tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
        }
       //  setTotalPrice(tPrice);
       formik.setFieldValue('totalPrice', tPrice);
      
        setPrlist(data);
        // setItems(data);
         
        // formik.setFieldValue('paymentTerms', res?.data?.data?.customerId || '');
        // formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
    
      })
      .catch(err => {
        console.log(err, "err");
      });
  }
};

// useEffect(() => {
//   const totalPrice = prlist.reduce((total, item) => {
//     return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
//   }, 0);

//   formik.setFieldValue('totalPrice', totalPrice);
// }, [prlist]);
const getCusByPr = (prId) => {
  if (prId !== "") {
    axiosInstance
      .get(`/PR/customer-name/${prId}`)
      .then(res => {

        formik.setFieldValue('customerName', res?.data?.customerName || '');
      })
      .catch(err => {
        console.log(err, "err");
      });
  }
};

useEffect(() => {
  if (formik.values.prId) {
    getPr(formik.values.prId);
    getCusByPr(formik.values.prId);
  }
}, [formik.values.prId]);


  const updateTotalPrice = () => {
    const totalPrice = [...items].reduce((total, item) => {
      return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
    }, 0);
    formik.setFieldValue('totalPrice', totalPrice);
  };

  const handlePriceChange = (e, index) => {
    const updatedItems = [...items];
    if (updatedItems[index]) {
      updatedItems[index].price = e.target.value;
      setData(updatedItems);
      updateTotalPrice();
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    if (updatedItems[index]) {
      updatedItems[index].quantity = newQuantity;
      setData({
        ...dataa,
        quoteProducts: updatedItems
      });
      updateTotalPrice();
    }
  };

  const handleIncrement = (index) => {
    handleQuantityChange(index, items[index].quantity + 1);
  };

  const handleDecrement = (index) => {
    handleQuantityChange(index, Math.max(1, items[index].quantity - 1));
  };

  const handleInputChange = (event, index) => {
    const value = Math.max(Number(event.target.value));
    handleQuantityChange(index, value);
  };

  const handleLeadTimeChange = (value, index) => {
    const updatedItems = [...items];
    updatedItems[index].leadTime = value.value;
    setItems(updatedItems);
  };

  useEffect(() => {
    if (items.length > 0) {
      setError('');
    }
  }, [items]);

  useEffect(() => {

  }, [items]);

  
  return (
    <div className="w-full h-full py-2 px-4">
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-260px)] bg-white rounded-md overflow-hidden ">
        <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto'>
        
             {/* <FormikSelectTwo
                type="text"
                label="Customer"
                placeholder="Choose"
                Options={customers}
                formik={formik}
                name="customerId"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
            {/* <NuInput
              type="text"
              label="Refrence"
              placeholder="Reffrence"
              formik={formik}
              name="ref"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          {formik.values.prId&&
            <NuInput
              disabled={true}
              type="text"
              label="Customer"
              placeholder="customer"
              formik={formik}
              name="customerName"
              width="w-full md:w-1/2 lg:w-1/3"
            />}
          <NuDate
              label="Quote Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
           
           
             {/* <FormikSelectTwo
              type="text"
              label="Availability"
              placeholder="Choose"
              Options={Availablity}
              formik={formik}
              name="availability"
              width="w-full  md:w-1/2 lg:w-1/3"
            /> */}
            <FormikSelectTwo
              type="text"
              label="Validity"
              placeholder="Choose"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            {/* <FormikSelectTwo
              type="text"
              label="Lead Time"
              placeholder="Choose"
              Options={leadTime}
              formik={formik}
              name="leadTime"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <FormikSelectTwo
                type="text"
                label="Delivery Terms"
                placeholder="Choose"
                Options={DeliveryTerm}
                formik={formik}
                name="deliverTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             <FormikSelectTwo
                type="text"
                label="Payment Terms"
                placeholder="Choose"
                Options={PaymentTerms}
                formik={formik}
                name="paymentTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            
            {/* <FormikSelectTwo
              label="Payment Method"
              placeholder="Choose"
              Options={PaymentMethod}
              formik={formik}
              name="paymentMethod"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            {/* <NuInput
              type="number"
              label="Discount"
              placeholder="discount"
              formik={formik}
              name="discount"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            */}
           {/* <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          /> */}
                      {/* {formik.values.approvalStatus === "Approved" && (
              <FormikSelectTwo
                type="text"
                label="Seal/Signature"
                placeholder="Choose"
                Options={[
                  {value:"seal",label:"Seal"},
                  {value:"signature",label:"Signature"}
                  ]}
                formik={formik}
                name="supportDocType"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            )} */}
             <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={status}
              formik={formik}
              name="status"
              width="w-full  md:w-1/2 lg:w-1/3"
            />
             <NuTextArea
              label="Special Notes"
              placeholder="Special Notes"
              formik={formik}
              name="specialNotes"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
              />
                   <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
                   {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddDelPro}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
        

          <div className='w-full bg-white overflow-y-scroll '>
              <div className='flex py-2 items-center'><p className='text-xs pr-1 text-red-500'>Note :</p><p className='text-[10px] text-slate-600'>CLick the edit icon near "Action" to edit quantity, lead time and price.</p></div>
             
              <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                        <p className=" w-[40%] text-left text-white text-xs font-medium">
                        Product
              </p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">
                Unit 
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
                Qty
              </p>
              {/* <p className="w-[20%] text-center text-white text-xs font-medium">
                  Lead Time
                </p> */}
                <p className="w-[20%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
                <button type="button" onClick={toggleEdit} className="text-center text-white text-xs font-medium">
                  <EditIcon color='white' width={"12"} height={"12"}/>
                </button>
            </div>
           {items?.length>0?
                         <div className="h-[calc(100vh-300px)] overflow-y-scroll">

            {items?.map((List, index) => {
              return (
                <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                  <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className=" w-[40%] text-left text-[#606279] text-xs font-light py-1">
                    {List?.product_name}
                  </p>
                  {/* <p className=" w-[20%] text-center text-[#606279] text-xs font-light py-1">
                    {List?.product_name}
                  </p> */}
                   <p className="w-[10%] text-center text-[#606279] text-xs font-light">
                      {List?.product_unit}
                    </p>
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light flex items-center justify-center space-x-2">
                      {isEditing ? (
                        <>
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="w-16 text-center border text-[#606279] text-xs font-light"
                            name="quantity"
                            value={List.quantity}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </>
                      ) : (
                        <p>{List.quantity}</p>
                      )}
                    </div>
                    {/* <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                     
                        <Select
                          value={{value:List.leadTime,label:List.leadTime}}
                          options={isEditing ? leadTime : []}
                          className="w-full"
                          onChange={(value) => handleLeadTimeChange(value, index)}
                        />
                     
                    </div> */}
                    <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                      {isEditing ? (
                        <input
                          type="number"
                          className="w-full py-1 text-center border text-[#606279] text-xs font-light"
                          name="price"
                          placeholder="Enter the price"
                          value={List.price || ''}
                          onChange={(e) => handlePriceChange(e, index)}
                        />
                      ) : (
                        <p>{List.price}</p>
                      )}
                    </div>
                    <p className=" w-[15%] text-center text-[#606279] text-xs font-light">
                  {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                  
                  </p>
                    <div className='w-[5%] text-center'>
                      <button
                        disabled={isEditing ? false : true}
                        onClick={() => handleDeleteItem(List?.productId)}
                        type="button"
                        className={`text-red-500 ${isEditing && "cursor-pointer"} ml-4 border-none`}
                      >
                        <Delete
                          color='red'
                          height={4}
                        />
                      </button>
                    </div>
                
                </div>
              );
            })}
                        </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

          </div>
          <div className=" w-full my-6  flex justify-end items-center gap-4">
              <TextMediumBase content={"Total Price : "} />
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount value={formik?.values?.totalPrice || 0} />
              </p>
              {/* <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              /> */}
            </div>
           <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
          <div className="md:flex justify-end gap-4">
            <CancelButton  handleClick={()=>{Oncancel(-1)}}/>
            <SubmitButton />
          </div>
        </div>
          </div>
        </div>
        </form>
    </div>
  );
}


export default QuoteEdit;