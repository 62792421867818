import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import AccountType from "./AccountType";
import Category from "./Category";
import PaymentMethod from "./PaymentMethod";
import Status from "./Status";



const Recevables = () =>{
    return(
        <>
        <TextMediumSM content="Recevables" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Category/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <AccountType />
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PaymentMethod/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>
         </div>
         </>
    )
}

export default Recevables;