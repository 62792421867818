import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useState } from "react";
import Input, { NuDate, NuInput, NuMobileNumber, NuSelect, NuTextArea, NuUpload } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import Delete from "../../../../Components/Icons/Delete";
import useEditFormData from "../../../../Components/Hooks/useEditFormData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import Attachement from "../../../../Components/Utils/CustomAttrachmentupload";
const filetype = ["docx","doc","xlsx","xls","pdf","txt","pptx","mp4","mov","wmv","avi","flv","mkv",];

const EditPackingList = () => {
  const {value:PackingListStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'PackingListStatus')
  const {value:ApprovalStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'ApprovalStatus_PackingList')

  
  const { data:user } = UseDropDown('/user/drop-down');
  const { data:deliveryNotes } = UseDropDown('/delivery-notes/drop-down');


    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const location = useLocation();
    const data = location.state.data;
    console.log(data,"gg");
    const from = location.state.from;
    const [dataa,setData] = useState(data);
  const [fileRemoveList, setFileRemoveList] = useState([]);
  const [image, setImage] = useState([]);

    console.log(dataa,"hhh");
    const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);
    const [newItemData, setNewItemData] = useState({
      orderedQty:"",
      packedQty:"",
      productId:"",
      product_name:"",
      product_unit: "",
      remainingQty:""
     });
    const [createNew, setCreateNew] = useState({
      date: data.date,
      dnId: data.dnId,
      approvedBy: data.approvedBy,
      verifiedBy: data.verifiedBy,
      status: data.status,
      products:[],
      
       
    })
    const handleDelete = (productId) => {
      const updatedProducts = dataa?.products.filter(item => item.productId !== productId);
      
      setData(prevState => ({
        ...prevState,
        products: updatedProducts
      }));
      
      setRemove(prevRemove => [...prevRemove, productId]);
    };
    
    const handleAdd = () => {
      const newItem = {
        productId:formik.values.productId,
      product_unit:formik.values.product_unit,
      product_name: formik.values.product_name,
      // productName : Item.find((i)=> i.value===formik.values.productId),
      orderedQty: formik.values.orderedQty,
      packedQty: formik.values.packedQty,
      remainingQty: formik.values.remainingQty
    };
    setItems([...items, newItem]);
    setNewItemData({
      orderedQty:"",
  packedQty: "",
  productId:"",
  product_name: "",
  product_unit: "",
  remainingQty:"",
    });
    formik.values.product_name = '';
    formik.values.product_unit = '';
    formik.values.orderedQty ='';
    formik.values.packedQty = '';
    formik.values.remainingQty =''
    };
  
    const handleDeleteItem = (indexToDelete) => {
      const updatedItems = items.filter((_, index) => index !== indexToDelete);
      setItems(updatedItems);
      // localStorage.setItem("items", JSON.stringify(updatedItems));
    };
  
    const [error,setError] = useState('')
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        
      }),
  
      onSubmit: async (value, { resetForm }) => {
        if (dataa?.products.length === 0) {
          setError('Product table is empty');
          return;
        }
      let formData = new FormData();

        const valueWithItems = {
          ...value,
          products: dataa?.products,
          // removedProducts:remove,
        filesToRemove: fileRemoveList,

        };
        formData.append("json_data", JSON.stringify(valueWithItems));
        if (selectedFile !== null) {
          selectedFile.forEach((file) => {
            formData.append("attachments", file);
          });
        } else {
          formData.append("attachments", []);
        }
        editFormData(`/packing-list/edit-packing-list/${data.id}`, formData, resetForm, from)
      }
    });

    useEffect(() => {
      if (dataa?.products.length > 0) {
        setError('');
      }
    }, [dataa?.products]);

    
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const toast = useToast();

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        toast.open({
          type: "warning",
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          // placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        console.log("fname", fileName);
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    console.log(tempArr, "temp");
    setImage(tempArr);
  }

  useEffect(() => {
    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]"
    ) {
      console.log(data.attachments, "ghjkllgd");
      setFilePathList(data?.attachments);
     
      setRemoveFilePathList(data?.attachments);
    }
  }, [data]);

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList.file !== item);
    let removeFile = filePathList.find((itemList) => itemList.file === item);
    console.log("llll", filterList);
    setFileRemoveList([...fileRemoveList, removeFile.file]);
    setFilePathList(filterList);
  }
  function handleImageClick(img) {
    setSelectedImage(img);
    setIsModalOpen(true);
  }

    const [dnlist, setDnlist] = useState([]);

    const getPr = (dnId) => {
      if (dnId !== "") {
        axiosInstance
          .get(`/packing-list/order-products/${dnId}`)
          .then(res => {
            let data = res?.data?.products;
            setDnlist(data);
          })
          .catch(err => {
            console.log(err, "err");
          });
      }
    };
  
    useEffect(() => {
      if (formik.values.dnId) {
        getPr(formik.values.dnId);
      }
    }, [formik.values.dnId]);

    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const updatedProducts = [...dataa.products];
      const intValue = parseInt(value);
      if (name === 'packedQty' && intValue <= updatedProducts[index].orderedQty) {
        updatedProducts[index][name] = intValue;
        updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
      }
      setData(prevState => ({
        ...prevState,
        products: updatedProducts
      }));
    };
    
  
    const handleIncrement = (index, field) => {
      const updatedProducts = [...dataa.products];
      if (field === 'packedQty' && updatedProducts[index][field] < updatedProducts[index].orderedQty) {
        updatedProducts[index][field] += 1;
        updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
      }
      setData(prevState => ({
        ...prevState,
        products: updatedProducts
      }));
    };
    
  
    const handleDecrement = (index, field) => {
      const updatedProducts = [...dataa.products];
      if (updatedProducts[index][field] > 0) {
        updatedProducts[index][field] -= 1;
        updatedProducts[index].remainingQty = updatedProducts[index].orderedQty - updatedProducts[index].packedQty;
      }
      setData(prevState => ({
        ...prevState,
        products: updatedProducts
      }));
    };
    
   
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
      <Breadcrumb
          items={
            [
            {
              name: "Packing List",
              link: "/store/packing-list"
            },
            {
              name: "Edit"
            }
            ]
          }
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
          <div className="w-full mx-auto h-[calc(100vh-145px)] mt-4 bg-white rounded-md overflow-hidden">
            <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuDate
              label="Date "
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
           
        
          <FormikSelectTwo
                   type="text"
                   label="Delivery"
                   placeholder="Delivery"
                   Options={deliveryNotes}
                   disabled={true}
                   formik={formik}
                   name="dnId"
                   width="w-full md:w-1/2 lg:w-1/3"
                 />
                  <FormikSelectTwo
            type="text"
            label="Approval Status"
            placeholder="Approval Status"
            Options={ApprovalStatus}
           formik={formik}
           name="approvalStatus"
           width="w-full md:w-1/2 lg:w-1/3"
          />
                  <FormikSelectTwo
                   type="text"
                   label="Status"
                   placeholder="Status"
                   Options={PackingListStatus}
                   formik={formik}
                   name="status"
                   width="w-full md:w-1/2 lg:w-1/3"
                 />
                  <div className="md:flex justify-between mt-5 px-3 w-full">
              <div className="w-full flex gap-2 items-center flex-wrap">
                {data?.attachments &&
                  filePathList.map((imgs, idx) => {
                    console.log("img", imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{
                          border: "1px solid gray",
                          borderColor:
                            "rgb(148 163 184 / var(--tw-bg-opacity))",
                        }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs?.file)}
                        >
                          x
                        </div>
                        <div
                          className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                          onClick={() => handleImageClick(imgs)}
                        >
                          {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                          <img src={imgs?.file} alt={imgs?.file} />
                        </div>
                      </div>
                    );
                  })}
                <Attachement
                  handleChange={handleImageChange}
                  preview={image || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}
                />
              </div>
            </div>
          <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
          <div className='w-full bg-white overflow-y-scroll mt-5'>
         {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left pl-2 text-white text-xs font-medium">S.N. </p>

              <p className=" w-[30%] text-left  text-white text-xs font-medium">
              Product
              </p>
              <p className=" w-[10%] text-center text-white text-xs font-medium">
              Unit
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Ordered Quantity
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Packed Quantity
              </p>
              <p className=" w-[20%] text-center text-white text-xs font-medium">
              Remaining Quantity
              </p>
              <p className=" w-[5%] text-center text-white text-xs font-medium">
              Action
              </p>
              </div>
              {dataa?.products?.length>0?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">

              {dataa?.products.map((List, index) => {
                // console.log(List,'lissttt ');
              return (
                <div key={index} className="w-full py-2 flex justify-between px-6">
                  <p className="w-[5%]  text-left pl-2 text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                  <p className=" w-[30%] text-left  text-[#606279] text-xs font-light ">
                    {List?.product_name}
                  </p>
                  <p className=" w-[10%] text-center text-[#606279] text-xs font-light">
                    {List?.product_unit}
                  </p>
                  <p className=" w-[25%] text-center text-[#606279] text-xs font-light">
                    {List?.orderedQty}
                  </p>
                  <div className="w-[20%] text-center text-[#606279] text-xs font-light">
                  <button
  type="button"
  className="border rounded-full p-1"
  onClick={() => handleDecrement(index, 'packedQty')}
>
  -
</button>
<input
  type="number"
  className="w-[20%] text-center border text-[#606279] text-xs font-light"
  name="packedQty"
  value={List.packedQty}
  onChange={(e) => handleInputChange(e, index)}
/>
<button
  type="button"
  className="border rounded-full p-1"
  onClick={() => handleIncrement(index, 'packedQty')}
>
  +
</button>

                  </div>
                  {/* <p className=" w-[20%] text-center text-[#606279] text-xs font-light">
                    {List?.packedQty}
                  </p> */}
                  <p className=" w-[20%] text-center text-[#606279] text-xs font-light">
                    {List?.remainingQty}
                  </p>
                 
                  <div className='w-[5%] text-center'> 
                   <button
                    onClick={() => handleDelete(List?.productId)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <Delete
                     color='red'
                     height={4}
                    />
                  </button>
                  </div>
                </div>
              );
            })}
            </div>:<div className="w-full text-center text-[#93949f]">No Items here</div>}

          </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
          </div>
        </form>
    </div>
  );
}


export default EditPackingList;
