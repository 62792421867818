import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useMemo, useState } from "react";
import Input, { NuDate, NuInput, NuMobileNumber, NuSelect, NuTextArea, NuTime, NuUpload } from "../../../../Components/Utils/Input";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import axiosInstance from "../../../../Services/axiosCommon";
import UseDropDown from "../../../../Components/Hooks/useDropDown";

const CreateAllTasks = () => {
  const { Oncancel, addFormData, isLoading } = useAddFormData();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    taskName: "",
    taskDesc: "",
    priority: "",
    taskStatus: "",
    startDate: "",
    endDate: "",
    completeDate: "",
    noOfDays: "",
    noOfWeeks: "",
    taskTeam: "",
    reminderDate: "",
    reminderTime: "",
    assignedTo: [],
    copyTo: []
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("taskData", JSON.stringify(value));
      formData.append('attachment', image.raw);

      addFormData("/tasks/new-task", formData, resetForm);
    },
  });
  
  const { data: team } = UseDropDown('/teams/drop-down')
  const [assignToMembers,setAssignToMembers] = useState([]);
  const listOfAssignTo = useMemo(()=>{
    axiosInstance
    .get(`/teams/team-members/${formik?.values?.taskTeam}`)
    .then((res) => {
      // console.log("nnn",res?.data);
      let list = res?.data?.data;
      let array = [];
      list &&
        list.forEach((i) => {
          let num = { value: i?.value, label: i?.label };
          array.push(num);
        });
      res.data?.data && setAssignToMembers(array);
    })
    .catch((error) => {
          console.error('Error fetching data:', error);
        });
  },[formik?.values?.taskTeam])
  // console.log("bbb",team);

  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
      <Breadcrumb
          items={
            [{
              name: "My Tasks",
              link: "/tasks/all-tasks"
            },
            {
              name: "Create My Tasks"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="text"
              label="Task Name"
              placeholder="Task Name"
              formik={formik}
              name="taskName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Task Description"
              placeholder="Task Desc"
              formik={formik}
              name="taskDesc"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Priority"
              placeholder="Priority"
              Options={[
                { value: "Shipping", label: "Shipping" },
                { value: "Cargo", label: "Cargo" },
              ]}
              formik={formik}
              name="priority"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Task Status"
              placeholder="taskStatus"
              Options={[
                { value: "Shipping", label: "Shipping" },
                { value: "Cargo", label: "Cargo" },
              ]}
              formik={formik}
              name="taskStatus"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="startDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="endDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Complete Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="completeDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="No Of Days"
              placeholder="No Of Days"
              formik={formik}
              name="noOfDays"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="No Of Weeks"
              placeholder="No Of Weeks"
              formik={formik}
              name="noOfWeeks"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Task Team"
              placeholder="Task Team"
              Options={team}
              formik={formik}
              name="taskTeam"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <MultiSelect
              label="Assigned To"
              placeholder="assignedTo"
              Options={assignToMembers}
              formik={formik}
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <MultiSelect
              label="Copy To"
              placeholder="Copy To"
              Options={assignToMembers}
              formik={formik}
              name="copyTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Reminder Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="reminderDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTime
             label="Reminder Time"
             formik={formik}
             name="reminderTime"
             width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> 

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAllTasks;
