import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axiosCommon';

const UseGetData = (page="", pageCount="", url, search=null) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  async function getData() {
    let params;
    if (search){
      params = {
      page: page,
      per_page: pageCount,
      search: search
    };}
    else if(page == "" && pageCount == ""){
       params = {}
    }
    else{
      params = {
        page: page,
        per_page: pageCount}
    }
    try {
      const res = await axiosInstance.get(url, { params });
      page!=="" && pageCount!==""? setData(res?.data?.data?.data || []) : setData(res?.data?.data || []) ;
      page!=="" && pageCount!=="" ? setTotalCount(res?.data?.data?.total || 0) : setTotalCount(0)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, [url,search]);

  return { data, totalCount , getData };
};

export default UseGetData;
