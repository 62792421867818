import { useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import {
  CreateButton,
  FilterButton,
} from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function AllTaskIndex() {
  const { data, getData } = UseGetData("","","/tasks/member-all-tasks");
  // console.log("jfehk",data);
  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[5%]",
    },
    {
      label: <span>Task Name</span>,
      width: "w-[10%]",
    },
    {
       label: <span>Assign To</span>,
       width: "w-[15%]",
    },
    {
      label: <span>Copy To</span>,
      width: "w-[15%]",
    },
    {
      label: <span>Start Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>End Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>No Of Days</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Progress</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Action</span>,
      width: "w-[5%]",
    },
  ];

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: createNew,
  //   validateOnChange: false,
  //   validateOnBlur: false,
  //   validationSchema: yup.object({
  //     // email: yup.string().required('Email is required!'),
  //   }),
  //   onSubmit: async (value, { resetForm }) => {
    
  //     editData(`/tasks/1/task-completion`, value, resetForm, from)

  //   },
  // });

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Task",
              link:''
            },
            {
              name: "All Task",
            },
          ]}
        />
        <div className=" flex gap-2">
          <SearchBar />
          <FilterButton height="8" />
          <CreateButton label="Import" />
          <Link to="/tasks/all-tasks/create">
            <CreateButton label="All Tasks" />
          </Link>
        </div>
      </div>

      <DataTable
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        headers={headers}
        total={0}
        pageIndex={1}
        setPageIndex={() => {}}
        dataCount={0}
        setDataCount={() => {}}
      >
        {data.length > 0 ? (
          data.map((item, i) => {
            //   let getCurrency;
            //   currencies.find((i) => {
            //     if(i.value == item?.currency){
            //       getCurrency=i.label;
            //     }
            //   })
            return (
              <tr
                className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105"
                key={i}
              >
                <td className="w-[5%] p-2 px-[1px] text-left ">
                  {item?.id || "#"}
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left ">
                  {item?.taskName || "#"}
                </td>
                <td className="w-[15%] p-2 px-[1px] text-left ">
                  {item?.taskName || "#"}
                </td>
                <td className="w-[15%] p-2 px-[1px] text-left ">
                  {item?.taskName || "#"}
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left ">
                  {item?.startDate || "#"}
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left ">
                  {item?.endDate || "#"}
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left ">
                  {item?.noOfDays || "#"}
                </td>
                <td className="w-[10%] p-2 px-[1px] text-left"
                  // onClick={() => handleOpenModal(item?.id,item?.taskCompletion)}
                >
                  <MyCircularProgressbar percentage={item?.taskCompletion} />
                </td>
                <td className="w-[5%] p-2 px-[1px] text-left ">
                  <MoreOptions
                    data={item}
                    fetcher={getData}
                    id={item?.id}
                    removevalue={item?.taskName || "undefined"}
                    url={`/tasks/member-all-tasks`}
                    moduleName={"All Task"}
                    from={`/tasks/my-tasks/`}
                    // editLink={"/tasks/all-tasks/edit/"}
                    viewLink={"/tasks/my-tasks/view/"}
                  />
                </td>
              </tr>
            );
          })
        ) : (
          <tr className=" text-center my-2 font-semibold text-sm">
            No Data Found
          </tr>
        )}
      </DataTable>
    </div>
  );
}

export default AllTaskIndex;

const MyCircularProgressbar = ({ percentage }) => {
  return (
    <div className="w-full flex justify-center items-center cursor-pointer">
       <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={10}
        styles={{
          root: { width: '30px' },
          path: { stroke: '#3498db' },
          text: { fontSize: '30px', fill: '#333', fontWeight: 'bold' }, 
        }}
      />
    </div>
  );
};
