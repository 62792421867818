import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const Validity = () => {
  return (
    <SettingsMetaData propertyName={"Validity_Supplierquotes"} viewPropertyName={"Validity"} />

  )
}

export default Validity