import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function DeliveryNote() {
    const { data, loading } = UseGetDashBoardData(`/dashboard/delivery-notes-approvals`);

  return (
    <div className="w-full md:w-[33%] px-4">
          <div className="grid items-center justify-center">
            <div className="pl-3">
              <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">Delivery Note</p>
             <p className='text-[#333333] text-[25px]  font-semibold '>{data?.total || 0}</p>
             <div className='flex gap-2 items-center justify-evenly'>
             <p className='text-xs 2xl:text-sm'>Pending :<span>{data?.pendding || '0'}</span></p>
             
              <p className=' text-xs 2xl:text-sm'>Verified :<span>{data?.reviewed || '0'}</span></p>
              </div>
             </div>
             
          </div>
         
              </div>
  )
}

export default DeliveryNote