import React from "react";
import ReportTable from "../../../../Components/Others/ReportTable";
import NuDate from "../../../../Components/Utils/NuDate";
import DownloadXlsx from "../xlsx/DownloadXlsx";
import DefaultDownloadLink from "../pdf/DefaultDownloadLink";
import { BackButton, FilterButton } from "../../../../Components/Utils/Buttons";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";

function VendorPOReports() {
  let headers = [
    {
      label: "ID",
      width: "33.3%",
      field: "id",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      label: "Actions",
      width: "33.3%",
      field: "item",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      label: "Data",
      width: "33.3%",
      field: "date",
      type: "amount",
      textAlign: "left",
      rowAlign: "left",
    },
  ];

  const data = [
    {
      id: "1",
      item: "textttt",
      date: 100,
    },
    {
      id: "1",
      item: "textttt",
      date: 100,
    },
    {
      id: "1",
      item: "textttt",
      date: 190,
    },
    {
      id: "1",
      item: "textttt",
      date: 500,
    },
  ];

  return (
    <div className=" w-full h-full px-4 py-2  overflow-hidden">
      <div className=" w-full flex justify-between items-center">
      <Breadcrumb
        items={[
          {
            name: "Reports",
            link: "/reports",
          },
          {
            name: "Vendor PO",
          },
        ]}
      />
        <BackButton />
      </div>
      <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
        <div className=" w-full h-full">
          <div className=" w-full flex justify-between items-center">
            <TextMediumSM content={"Vendor PO Report"} />
            <div className=" flex items-center gap-4">
              <FilterButton height="h-[32px]" />
              <DefaultDownloadLink
                headers={headers}
                data={data}
                fileName={`Vendor PO Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}.pdf`}
              />
              <DownloadXlsx
                header={headers}
                data={data}
                fileName={`Vendor PO  Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}`}
              />
            </div>
          </div>
          <ReportTable
            bodyHeight="max-h-[calc(100vh-245px)]"
            height="h-[calc(100vh-235px)]"
            headers={headers}
            data={data}
          ></ReportTable>
        </div>
      </div>
    </div>
  );
}

export default VendorPOReports;
