import React from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { Pie, PieChart, Cell, Tooltip, ResponsiveContainer, Label } from 'recharts';

function PrFromCustomer() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/pr-from-customer-approvals`);
  const COLORS = [ '#FF4191','#000000'];

  const chartData = [
    { name: 'Pending', value: data?.pendding || 0 },

    { name: 'Verified', value: data?.verified || 0 },
  ];

  // const total = chartData.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className="w-full md:w-[25%]  px-2">
      <div className="flex items-center">
        <div className="pl-1">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-normal">PR From Customer</p>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex h-[96%] justify-center items-center">
            <div className="w-1/2 flex justify-center items-center">
              <ResponsiveContainer width="100%" height={150}>
                <PieChart>
                  <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={20}
                    outerRadius={35}
                    fill="#8884d8"
                    dataKey="value"
                    paddingAngle={5}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    <Label
                      value={`${data?.total}`}
                      position="center"
                      className="text-center"
                      style={{ fontSize: '12px', fontWeight: 'bold' }}
                    />
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className="w-1/2 px-2 flex flex-col justify-center">
              {chartData.map((item, index) => (
                <div key={index} className="flex gap-2 items-center text-xs mb-1">
                  <span
                    className="inline-block w-2 h-2 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  ></span>
                  <p className="w-[60%]">{item.name}</p>
                  <p className="w-[40%]">{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* <p className="text-sm font-semibold mt-1 pb-4">Total: {total}</p> */}
    </div>
  );
}

export default PrFromCustomer;
