import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import CompanySize from "./CompanySize";
import Industry from "./Industry";
import LeadCoversionProbablity from "./LeadConversion";
import LeadSource from "./LeadSource";
import LeadStatus from "./LeadStatus";
import LeadsRating from "./LeadsRating";


const Leads = () =>{
    return(
        <>
        <TextMediumSM content="Leads" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadSource/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadStatus/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Industry/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadCoversionProbablity/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <CompanySize/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <LeadsRating/>
         </div>
         </div>
         </>
    )
}

export default Leads;