
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const DeliveryTerm = () => {
  return (
    <SettingsMetaData propertyName={"DeliveryTerm_CustomerQuotes"} viewPropertyName={"Delivery Term"} />
  );
};

export default DeliveryTerm;
