// import React, { useState } from 'react'
// import FormikSelectTwo from '../../../../../Components/Utils/Select';
// import { TextMediumSM } from '../../../../../Components/Text/MediumText';
// import PieCharts from '../../../../../Components/Charts/PieChart';
// import colorCodes from '../../../../../Components/Utils/ColorCode';
// import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

// function QuotesSubmittedByProcurmentTeamProDash() {
//   const { data, loading } = UseGetDashBoardData(`/dashboard/QuotesSubmittedByProcurement`);
//     // const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
//         const [selectedMonth, setSelectedMonth] = useState("");     
//         const handleMonthChange = (event) => {
//           setSelectedMonth(event.target.value);
//         };
//         const status = {   
//           proTeam:[       
//             {name: data?.customer_request_salesman_wise?.procurementMember, value: data?.customer_request_salesman_wise?.submitted},
           
//           ],
//           teamTotal: 5,
//           priceTotal: 500,
//         }
//   return (
//     <div className='w-[49%] bg-white p-4 rounded-lg drop-shadow-lg h-[40vh]'>
//        <div className="w-full py-[2px] rounded-md flex justify-between">
//          <TextMediumSM content={"Quotes Submited By procurment Team"} color='text-[#4F4768]' />
//          <div className='w-1/6'>
//          <select id="month-select" value={selectedMonth} onChange={handleMonthChange} className='min-w-6 max-w-20 h-5 text-xs border border-slate-200 rounded-sm py-[1px] outline-none'>
//         <option value="" disabled>--Select Term--</option>
//         <option value="Monthly">Monthly</option>
//         <option value="Quarterly">Quarterly</option>
//         <option value="Half yearly">Half yearly</option>
//         <option value="Yearly">Yearly</option>
//       </select>
//          </div>
//       </div>
//       {loading ? (
//         <div className="flex justify-center items-center h-full">
//           <div className="animate-pulse space-y-4 w-full">
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//             <div className="h-4 bg-slate-200 rounded"></div>
//           </div>
//         </div>
//       ) : (
//       <div className='w-full flex'>
//         <div className=" w-1/2 flex justify-center items-center">
//             <PieCharts
//               data={status?.proTeam}
//               label={true}
//               labelValue={status?.priceTotal}
//               COLORS = {colorCodes.slice(0, status?.teamTotal)}
//               height={180}
//             />
//         </div>
//         <div className=" w-1/2 ">
//           {status?.proTeam.map((data,idx)=>{
//             const color = colorCodes[idx];
//             return(
//               <>
//               <div className='w-full flex justify-start gap-2 mt-2 ml-2 text-sm'>
//               <div className={`w-2 h-2 rounded-full mt-1.5`}
//               style={{ backgroundColor: color }}
//               >
//                 </div>
//                 <p>{data?.name}</p>
//                 <p>{data?.value}</p>
//               </div>
//               </>
//             )
//           })}
//         </div>
//       </div>
//       )}
//     </div>
//   )
// }

// export default QuotesSubmittedByProcurmentTeamProDash;

import React, { useState, useEffect } from 'react';
import FormikSelectTwo from '../../../../../Components/Utils/Select';
import { TextMediumSM } from '../../../../../Components/Text/MediumText';
import PieCharts from '../../../../../Components/Charts/PieChart';
import colorCodes from '../../../../../Components/Utils/ColorCode';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function QuotesSubmittedByProcurmentTeamProDash() {
  const [params, setParams] = useState({
    period:"monthly",

  });

  const [selectedPeriod, setSelectedPeriod] = useState( 'Monthly');

  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/QuotesSubmittedByProcurement`,params);
 
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data?.customer_request_salesman_wise) {
      const formattedData = data.customer_request_salesman_wise.map(item => ({
        name: item.procurementMember,
        assign:item.assigned,
        value: item.submitted,
      }));
      setChartData(formattedData);
    }
  }, [data]);

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }

  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4  rounded-lg drop-shadow-lg mb-4">
          <div className="flex justify-between items-center mb-2">
      <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Quotes Submitted By Procurment Team</p>
          <div className="flex items-center">
            <select
              value={selectedPeriod}
              onChange={hanChange}
              className="border rounded-md p-1 text-sm text-[#ACACAC]  font-normal"
            >
              {periods.map((period) => (
                <option key={period.value} value={period.value}>
                {period.option}
              </option>
              ))}
            </select>
          </div>
        </div>
          
          
           <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mt-2"></div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-pulse space-y-4 w-full">
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
            <div className="h-4 bg-slate-200 rounded"></div>
          </div>
        </div>
      ) : (
        <div className='w-full flex'>
          <div className="w-1/2 flex justify-center items-center">
            <PieCharts
              data={chartData}
              label={true}
              labelValue={chartData.reduce((acc, cur) => acc + cur.value, 0)}
              COLORS={colorCodes.slice(0, chartData.length)}
              height={180}
            />
          </div>
          <div className="w-1/2">
            {chartData.map((data, idx) => {
              const color = colorCodes[idx];
              return (
                <div key={idx} className='w-full flex justify-start gap-2 mt-2 ml-2 text-xs 2xl:text-sm'>
                  <div
                    className={`w-2 h-2 rounded-full mt-1.5`}
                    style={{ backgroundColor: color }}
                  ></div>
                  <p>{data?.name}</p>
                  <p>{data?.assign}</p>

                  <p>{data?.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default QuotesSubmittedByProcurmentTeamProDash;
