import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";


const Status = () => {
  return (
    <SettingsMetaData propertyName={"Status_Payable"} viewPropertyName={"Status"} />
  );
};

export default Status;
