import React, { useState } from 'react';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import UseGetDashBoardWithParams from '../../../../../Components/Hooks/useDashboardDataParams';

function Sales() {
  const [params, setParams] = useState({
    period:"monthly",

  });
  
  const { data, loading } = UseGetDashBoardWithParams(`/dashboard/targetBySalesMen`,params);
  const [selectedPeriod, setSelectedPeriod] = useState('Monthly');

  const chartData = data?.targets?.map(target => ({
    name: target.salesman,
    target: target.target,
    achieved: target.price
  })) || [];

  const hanChange=(e)=>{
    setParams({
      period:e.target.value
    })
    setSelectedPeriod(e.target.value)
  }


  const periods = [
    { value: 'monthly', option: 'Monthly' },
    { value: 'quarterly', option: 'Quarterly' },
    { value: 'halfyearly', option: 'Half Yearly' },
    { value: 'yearly', option: 'Yearly' },
  ];

  return (
    <div className="col-span-2 bg-white h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
      <div className="flex justify-between items-center ">
        <p className="text-xs 2xl:text-sm text-[#4F4768] font-medium">Target / Sales</p>
        <div className="flex items-center">
          <select
            value={selectedPeriod}
            onChange={hanChange}
            className="border rounded-md p-1 text-sm text-[#ACACAC] font-normal"
          >
            {periods.map((period) => (
              <option key={period.value} value={period.value}>
              {period.option}
            </option>
            ))}
          </select>
        </div>
      </div>
      {loading ? (
        <div className="p-4 max-w-sm w-full flex flex-col justify-center items-center h-full">
          <div className="animate-pulse flex space-x-4 w-full">
            <div className="flex-1 space-y-3 py-1">
              <div className="h-3 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-3 bg-slate-200 rounded"></div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-3 bg-slate-200 rounded col-span-1"></div>
                  <div className="h-3 bg-slate-200 rounded col-span-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="90%" >
          <BarChart data={chartData} margin={{ top: 0, right: 10, left: -25, bottom: 0 }}>
            <XAxis dataKey="name" fontSize={12} fontWeight={500} />
            <YAxis 
              tick={false} 
              label={{ value: 'Amount', angle: -90, position: 'insideLeft', textAnchor: 'middle', dy: -5 , dx:40 }} // Adjusted dy to move closer
            />
            <Tooltip contentStyle={{ fontSize: '12px' }} />
            <Legend fontSize={8} verticalAlign="top" height={26} iconSize={12} iconType="circle" wrapperStyle={{ fontSize: '12px' }} />
            <Bar dataKey="target" fill="#8884d8" barSize={5} radius={[10, 10, 0, 0]} /> {/* Reduced barSize */}
            <Bar dataKey="achieved" fill="#82ca9d" barSize={5} radius={[10, 10, 0, 0]} /> {/* Reduced barSize */}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default Sales;
