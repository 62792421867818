import React from 'react';
// import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { NormalHeading } from '../../../../Components/Utils/Heading';

const ViewRefunds = () => {
  // const { patientsViewId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  return (
    <div className="w-full h-full py-2 px-4 ">
    <div className="flex justify-between ">
    <Breadcrumb
          items={
            [
              { name: "Payment",
              link: "/payments/refunds" },
            {
              name: "Refunds",
              link: "/payments/refunds"
            },
            {
              name: "View Refunds"
            }]
          }
        />
        <div className=" flex gap-2">
          <Link to={'/payments/refunds'}><BackButton link={true} /></Link>
          <Link to={`/payments/refunds/edit/${data?.refundId}`} state={{ data: data, from: `/payments/refunds/view/${data?.refundId}` }}><EditButton height='8' /></Link>
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden p-3 mt-4'>
      {/* <h2 className='text-base font-normal text-[#111537] p-3'>Cataglogues</h2> */}
      <NormalHeading title={"Refund Detail"} />
      <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col  '>
                <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                          <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>Refund ID</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.refundId || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>refundMethod</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.refundMethod || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>refundAmount</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.refundAmount || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>trackingNo</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.trackingNo || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>customerId</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.customerName || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>Invoice Ref</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.invoiceRef || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>status</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.status}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>Approved By</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.approvedByName || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>Reviewed By</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-[#606279]'>{data?.reviewedByName || '-'}</p>
            </div>
                        <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>

              <p className='w-1/4 py-3 text-xs font-medium text-[#111537]'>Comments</p>
              <p className='w-1/4 py-3 pl-5 text-xs  text-wrap text-[#606279]'>{data?.comments || '-'}</p>
            </div>
          </div>
        </div>
      </div>
</div>
    </div>

  )
};
export default ViewRefunds;