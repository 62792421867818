import React, { useEffect, useState } from "react";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";
import ModalContainer from "../../../../Components/Others/ModalContainer";
import {
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../../Services/axiosCommon";
import { useToast } from "../../../../Components/Others/ToastServices";
import { use } from "i18next";

function ViewMore({
  data,
  fetcher,
  id,
  roleList = [],
  userList = [],
  getData,
}) {
  const toast = useToast();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [createNew, setCreateNew] = useState({
      userId:"",
      roleId:""
  });

  const formik = useFormik({
    initialValues: createNew,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      userId: yup.string().required("User is requred!"),
      roleId: yup.string().required("Role is requred!"),
    }),
    onSubmit: async (value, { resetForm }) => {
      axiosInstance
        .put(`/user-role/${id}`, value)
        .then( async(res) => {
          if (res?.data?.status == true) {
            resetForm();
            getData();
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully.",
            });
            setIsModalEditOpen(false);
          } else {
            toast.open({
              type: "error",
              message: "Error",
              description:
                res.data.error ||
                res.data?.msg ||
                res.data?.message ||
                "Network error.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          toast.open({
            type: "error",
            message: "Error",
            description:
              err.response.data.error ||
              err.response.data.message ||
              err.response.data.msg ||
              "Network error.",
          });
        });
    },
  });




  function onClose() {
    setIsModalEditOpen(false);
    formik.resetForm();
  }


  useEffect(() => {
    setCreateNew({
      userId:data?.userId||"",
      roleId:data?.roleId||""
    });
  },[data])


  function handleDelete(){
    axiosInstance.delete(`/user-role/${id}`).then(res=>{
      if(res?.data?.status){
        getData();
        toast.open({
          type: "success",
          message: "Success",
          description: res.data?.msg || "Created Successfully.",
        });
        setIsModalDeleteOpen(false)
      }else{
        toast.open({
          type: "error",
          message: "Error",
          description:
            res.data.error ||
            res.data?.msg ||
            res.data?.message ||
            "Network error.",
        });
      }
    }).catch(err=>{
      toast.open({
        type: "error",
        message: "Error",
        description:
          err.response.data.error ||
          err.response.data.message ||
          err.response.data.msg ||
          "Network error.",
      });
    })
  }


  return (
    <>
      <div className=" w-full flex  justify-start items-center">
        <div className=" w-full flex justify-start ">
          <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
            <span onClick={() => setIsModalEditOpen(true)} className="cursor-pointer">
              <EditIcon color="skyblue" width={"12"} height={"12"} />
            </span>
          </div>

          <div className=" w-[25%] overflow-hidden flex justify-center items-center ">
            <span onClick={() => setIsModalDeleteOpen(true)} className="cursor-pointer">
              <DeleteIcon height={"3"} width={"3"} color="lightcoral" />
            </span>
          </div>
        </div>
      </div>

      <ModalContainer
        visiable={isModalDeleteOpen}
        title="Warning"
        content={`Are you sure, Role will removed from User Role!`}
        handleClick={() => handleDelete()}
        closeModal={() => setIsModalDeleteOpen(false)}
      />

      {isModalEditOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in relative w-[45%] min-h-[50%] max-h-[96%] bg-white py-2 px-4 rounded-md shadow-lg overflow-y-scroll ">
            <button
              className="absolute top-2 right-2 text-white bg-red-600"
              onClick={() => onClose()}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <p className=" text-sm font-semibold">Edit User Roll</p>
            <form onSubmit={formik.handleSubmit}>
              <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap ">
                <FormikSelectTwo
                  type="text"
                  label="User"
                  placeholder="Choose"
                  Options={userList}
                  formik={formik}
                  name="userId"
                  width="w-full"
                  isRequired={true}
                  disabled={true}
                />
                <FormikSelectTwo
                  type="text"
                  label="Role"
                  placeholder="Choose"
                  Options={roleList}
                  formik={formik}
                  name="roleId"
                  width="w-full"
                  isRequired={true}
                />
              </div>
              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={onClose} />
                  <SubmitButton />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewMore;
