import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const ApprovalStatusDN = () => {
  return (
    <SettingsMetaData propertyName={"approval_status_DN"} viewPropertyName={"Approval Status DN"} />

  )
}

export default ApprovalStatusDN;