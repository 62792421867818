// import React from 'react'

// const ViewPdf = ({ data }) => {
  
//   return (
//     <div className="py-2 text-sm md:text-sm lg:text-xs xl:text-sm font-normal bg-white font-helvetica w-[50%]  mx-auto  border rounded-lg ">
//      <div className='h-[calc(100vh-80px)] overflow-y-scroll p-2'>
//         <div className="flex gap-3 items-center">
//        <h3 className="text-[14px] font-normal">GRN</h3>
//       <p className="text-sm font-light"> : {data.ref}</p>
//     </div>

//       <div className="grid  items-center mt-2 mb-2">
//         {[
//            { label: 'Purchase Order ', value: data.poRef },
//            { label: 'Supplier  ', value: data.vendorName },
//            { label: 'Purchase Type', value: data.purchaseType || 'N/A'},
//            { label: 'Received Date', value: data.receivedDate || 'N/A'},
//            { label: 'Approved By', value: data.approvedByName || 'N/A'},
//            { label: 'Reviewed By ', value: data.reviewedByName || 'N/A'},
//            { label: 'Verified By', value: data.verifiedByName || 'N/A'},
//            { label: 'Status  ', value: data.status || 'N/A'},
//            { label: 'Comments', value: data.comments || 'N/A'},
//             ].map((item, index) => (
//           <div key={index} className="flex text-[12px] ">
//             <p className="w-[40%] py-1 font-medium">{item.label}</p>
//             <p className="w-[40%] py-1">{item.value}</p>
//           </div>
//         ))}
//       </div>

//       <div className=" bg-gray-300">
//         <div className="w-full flex justify-between text-left text-[12px]">
//           {[ 'S.N','Product Name', 'Product Unit',
//                 'Ordered Quantity','Received Quantity','Remaining Quantity'].map((header, index) => (
//             <p key={index} className="bg-blue-500 text-white py-1 p-2 font-bold " 
//             style={{ width: `${[5, 30, 10, 20, 20, 15][index]}%` }}>
//               {header}
//             </p>
//           ))}
//         </div>
//         {data?.products?.map((product, index) => (
//           <div key={index} className=" w-full flex justify-between py-1 p-2 text-[12px]">
//             <p className="w-[5%] ">{index + 1}</p>
//             <p className="w-[30%] ">{product.product_name}</p>
//             <p className="w-[10%] ">{product.product_unit}</p>
//             <p className="w-[20%] ">{product.orderedQty}</p>
//             <p className="w-[20%] ">{product.receivedQty}</p>
//             <p className="w-[15%] ">{product.remainingQty}</p>


//           </div>
//         ))}
//       </div>

//       {/* <div className="flex-row justify-between p-2 my-4 border-2 border-gray-300">
//         <p>Notes From Customer:{data.notesFromCustomer}</p>
//       </div> */}
//       </div>
//     </div>
//   );
// }

// export default ViewPdf

import React from 'react';
import CompanyLogo from '../../../assets/images/companyLogo.png'
import CompanyName from '../../../assets/images/companyName.png'
import Seal from '../../../assets/images/seal.png'
import NuDate from '../../../Components/Utils/NuDate';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
const GRNPdfOthers = ({ data }) => {
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Assuming your content has a max width of 1200px and height equals the container height
      const contentWidth = Math.min(1200, containerWidth);
      const contentHeight = containerHeight;

      setInitialPosition({
        x: (containerWidth - contentWidth) / 2,
        y: (containerHeight - contentHeight) / 2,
      });
    }
  }, []);
  return (
    <div
    ref={containerRef}
    className="py-2 text-xs  font-normal bg-white font-helvetica w-[70%] 2xl:w-[50%] max-h-[calc(100vh-80px)] mx-auto border">
     <TransformWrapper
        initialScale={1}
        initialPositionX={initialPosition.x}
        initialPositionY={initialPosition.y}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          // <div className='w-full overflow-y-scroll'>
          <div className='relative w-full h-full '>
          <div className="w-full p-3 absolute top-0 z-10 flex flex-col justify-start gap-2">
            <button onClick={() => zoomIn()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">+</button>
            <button onClick={() => zoomOut()} className="mr-2 rounded-full border border-black w-6 h-6 text-sm font-semibold">-</button>
            <button onClick={() => resetTransform()} className='text-left'>Reset</button>
          </div>
      <div className="flex justify-center items-center w-full h-full">
      <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
        <div className='min-w-[calc(100vw-856px)] max-w-[1200px] h-full bg-white border border-black'>
      <div className="border border-black">
     <div className="flex justify-between items-center border-b border-black">
        <div className='w-full'>
          <div className='w-full flex justify-between items-center p-4'>
               <img src={CompanyName} alt="Loading" className='h-28' />
              <img src={CompanyLogo} alt="Loading" className='h-28 w-28'/>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-3 border-b border-black">
        <div className=' w-full p-3 flex items-center border-r border-black'>Your Ref. :</div>
        <div className=' w-full p-3 text-center text-lg font-semibold border-r border-black'>GRN</div>
        <div className=' w-full'>
          <div className=' w-full h-1/2 border-b border-black px-2 pt-1 flex'>
            <p className=' w-9'>Date </p>
            <p className="text-xs">: {data?.receivedDate ? (
                  <NuDate value={data?.receivedDate} format="DD-MMM-YYYY" />
                ) : (
                  "N/A"
                )}</p>
          </div>
          <div className=' w-full h-1/2 px-2 pt-1 flex'>
          <p className=' w-9'>GRN# </p>
          <p className="text-xs">  : {data.ref || 'N/A'}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between px-3  py-2 border-b border-black">
        <div className=' w-[50%]'>
        <h4 className="font-semibold text-sm">Supplier</h4>
        </div>
        <div className=' w-[50%]'>
        <h4 className="font-semibold text-sm pl-3">Purchase Order</h4>
        </div>
      </div>

      <div className="flex justify-between border-b border-black">
        <div className=' w-[50%] px-3 py-1 border-r border-black'>
        <p>{data.vendorName || 'N/A'}</p>
        </div>
        <div className=' w-[50%]  px-3 py-1'>
        <table className='w-full'>
          <tbody>
            <tr className=' w-full'>
              <td className=' w-[30%] py-1'>Ref </td>
              <td className=' w-[70%] py-1'>: {data.poRef || 'N/A'}</td>
            </tr>
            <tr className=' w-full'>
            <td className=' w-[30%] py-1'>Type </td>
            <td className=' w-[70%] py-1'>: {data.purchaseType || 'N/A'}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>



      <table className="w-full border-collapse ">
        <thead>
          <tr className="bg-gray-200 text-[12px]">
            {['S.N','Product Name', 'Product Unit',
                'Ordered Quantity','Received Quantity','Remaining Quantity'].map((header, index) => (
              <th key={index} className="border-b border-r last:border-r-0  border-black p-2 text-left font-bold">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody >
          {data?.products?.map((product, index) => (
            <tr key={index} className="border-y border-black text-[12px]">
              <td className=" p-2">{index + 1}</td>
              <td className="border border-black p-2">{product.product_name || 'N/A'}</td>
              <td className="border border-black p-2">{product.product_unit|| 'N/A'}</td>
              <td className="border border-black p-2">{product.orderedQty|| 0}</td>
              <td className="border border-black p-2">{product.receivedQty || 0}</td>
              <td className="border border-r-0 border-black p-2">{product.remainingQty || 0}</td>
              {/* <td className="border border-gray-300 p-2">{(product.orderedQty * product.price).toFixed(2)}</td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* <div className="flex justify-between items-center p-2 border-t border-gray-300">
        <p className="font-medium text-[12px]">TOTAL</p>
        <p className="font-normal text-[12px]">{data.totalPrice.toFixed(2)}</p>
      </div>
      <div className="flex justify-between items-center p-2 border-t border-gray-300 text-[12px]">
        <p className="font-medium">NET TOTAL</p>
        <p className="font-normal">{data.totalPrice.toFixed(2)}</p>
      </div> */}

      {/* <div className="mt-4 text-[12px] flex justify-between w-full px-2">
        <div className=''>
        <h4 className="font-bold">GRN Details</h4>
        <div className="w-full flex px-2 text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Price
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : SAR
              </p>
            </div> 
            <div className="w-full flex px-2 text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Delivery Period
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : Immediate
              </p>
            </div> 
            <div className="w-full flex px-2 text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Delivery
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.deliveryTerms || 'N/A'}
              </p>
            </div>
            <div className="w-full flex px-2 text-[12px]">
              <p className=" py-1 text-xs  font-medium text-[#111537]">
              Payment
              </p>
              <p className=" py-1 text-xs   text-[#606279]">
                : {data.paymentTerms || 'N/A'}
              </p>
            </div> 
       
        </div>*/}
        {/* <div className="text-center">
        <p className="text-xs ">Authorized By</p>
          <img src={Seal} alt="Company Seat" className="h-24 mb-2" />
        </div> */}
      {/* </div>  */}

      <div className="mt-4 flex pb-4 px-3 justify-between items-center text-[12px]">
        <div>
          <p className="text-xs ">Note:</p>
         
        <p>{data.notesFromCus || 'N/A'}</p>

        </div>
       
      </div>
      </div>
      </div>
      </TransformComponent>
      </div>
      </div>
      // </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default GRNPdfOthers;