import React from 'react'
import { Route } from 'react-router-dom'
import { MainReport, ReportMainIndex } from '../Views/Web/Report'
import { AgentsReport, AssetsReport, CustomersReport, EquipmentReport, GRNReport, InvoicesReport, LeadsReports, PayabalesReport, ProductsReport, PurchaseRequestsReport, ReceivablesReport, SalesOrderReport, StocksReport, TasksReport, VehiclesReports, VendorPOReports, VendorsReports } from '../Views/Web/Report/Components';

function ReportRoutes() {
  return (
    <Route path="reports" element={<MainReport/>}>
        <Route index element={<ReportMainIndex/>}/>
        <Route path="agents" element={<AgentsReport/>}/>
        <Route path="assets" element={<AssetsReport/>}/>
        <Route path="customers" element={<CustomersReport/>}/>
        <Route path="equipment" element={<EquipmentReport/>}/>
        <Route path="grn" element={<GRNReport/>}/>
        <Route path="invoices" element={<InvoicesReport/>}/>
        <Route path="leads" element={<LeadsReports/>}/>
        <Route path="payabales" element={<PayabalesReport/>}/>
        <Route path="purchase-requests" element={<PurchaseRequestsReport/>}/>
        <Route path="products" element={<ProductsReport/>}/>
        <Route path="receivables" element={<ReceivablesReport/>}/>
        <Route path="sales-order" element={<SalesOrderReport/>}/>
        <Route path="stocks" element={<StocksReport/>}/>
        <Route path="tasks" element={<TasksReport/>}/>
        <Route path="vendors" element={<VendorsReports/>}/>
        <Route path="vendor-po" element={<VendorPOReports/>}/>
        <Route path="vehicles" element={<VehiclesReports/>}/>
    </Route>
  )
}

export default ReportRoutes;