import React from 'react';
import { TextMediumSM } from '../../../../Components/Text/MediumText';
import BarChartView from '../../../../Components/Charts/BarChart';
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';

export default function ReceivablePayableChart() {
  const { data, loading } = UseGetDashBoardData(`/dashboard/financeStats`);
  const currentYear = new Date().getFullYear();

  console.log("data", data);

  return (
    <div className="col-span-3 bg-white max-h-[40vh] w-full p-4 rounded-lg drop-shadow-lg mb-4">
      <div className="w-full px-1 py-[2px] rounded-md flex justify-between">
        <TextMediumSM content={"Invoice"} color="text-[#4F4768]" />
        <p className="text-center text-xs text-[#304463]">Year {currentYear}</p>
     
      </div>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full "></div>
      
      <BarChartView data={data} />
    </div>
  );
}

