import React, { useState } from "react";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import { BackButton, FilterButton } from "../../../../Components/Utils/Buttons";
import DefaultDownloadLink from "../pdf/DefaultDownloadLink";
import DownloadXlsx from "../xlsx/DownloadXlsx";
import NuDate from "../../../../Components/Utils/NuDate";
import ReportTable from "../../../../Components/Others/ReportTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";

function AssetsReport() {
  const [params, setParams] = useState({
    page: 1,
    per_page: 100,
    search: "",
    searchBy: ""
  });
  const { data, totalCount, getData } = UseGetDataSearch( "/assets/", params );
  let headers = [
    {
      title: "ID",
      width: "5%",
      field: "stockId",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Category",
      width: "10%",
      field: "category",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Sub Category",
      width: "10%",
      field: "subCategory",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Adjusted Qty",
      width: "10%",
      field: "adjustedQty",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Barcode",
      width: "10%",
      field: "barcode",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Batch No",
      width: "10%",
      field: "batchNo",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Brand",
      width: "10%",
      field: "brand",
      textAlign: "left",
      rowAlign: "left",
    },
  ];



  return (
    <div className=" w-full h-full px-4 py-2  overflow-hidden">
      <div className=" w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Reports",
              link: "/reports",
            },
            {
              name: "Assets",
            },
          ]}
        />
        <BackButton />
      </div>
      <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
        <div className=" w-full h-full">
          <div className=" w-full flex justify-between items-center">
            <TextMediumSM content={"Assets Report"} />
            <div className=" flex items-center gap-4">
              <FilterButton height="h-[32px]" />
              <DefaultDownloadLink
                headers={headers}
                data={data}
                fileName={`Assets Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}.pdf`}
              />
              <DownloadXlsx
                header={headers}
                data={data}
                fileName={`Assets Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}`}
              />
            </div>
          </div>
          <ReportTable
            bodyHeight="max-h-[calc(100vh-245px)]"
            height="h-[calc(100vh-235px)]"
            headers={headers}
            data={data}
          ></ReportTable>
        </div>
      </div>
    </div>
  );
}

export default AssetsReport;
