import { Route, useLocation, useNavigate } from "react-router-dom";
import MainPayments from "../Views/Web/Payment/MainPayment";
import RefundsMain from "../Views/Web/Payment/Refunds/MainRefunds";
import RefundsIndex from "../Views/Web/Payment/Refunds";
import CreateRefunds from "../Views/Web/Payment/Refunds/CreateRefunds";
import EditRefunds from "../Views/Web/Payment/Refunds/EditRefunds";
import ViewRefunds from "../Views/Web/Payment/Refunds/ViewRefunds";
import PayablesMain from "../Views/Web/Payment/Payables/MainPayables";
import PayablesIndex from "../Views/Web/Payment/Payables";
import CreatePayables from "../Views/Web/Payment/Payables/CreatePayables";
import EditPayables from "../Views/Web/Payment/Payables/EditPayables";
import ViewPayables from "../Views/Web/Payment/Payables/ViewPayables";
import ReceivablesMain from "../Views/Web/Payment/Receivables/MainReceivables";
import ReceivablesIndex from "../Views/Web/Payment/Receivables";
import CreateReceivables from "../Views/Web/Payment/Receivables/CreateReceivables";
import EditReceivables from "../Views/Web/Payment/Receivables/EditReceivables";
import ViewReceivables from "../Views/Web/Payment/Receivables/ViewReceivables";
import AddPayableAmount from "../Views/Web/Payment/Payables/AddPayableAmount";
import EditPayableAmount from "../Views/Web/Payment/Payables/EditPayableAmount";
import AddReceivableAmount from "../Views/Web/Payment/Receivables/AddReceivableAmount";
import EditReceivableAmount from "../Views/Web/Payment/Receivables/EditReceivableAmount";
import { Wrapper } from "../Components/Utils/roles";
import { useEffect } from "react";


function PaymentRoutes(){
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (
        location.pathname === "/payments" ||
        location.pathname === "/payments/") {
        navigate("/page-not-found")
      }
    },[location])
    return(

        <Route path="payments" element={<MainPayments/>}>
            <Route path="payables" element={<PayablesMain/>}>
                <Route index element={< Wrapper accessKey={"payables_view"}  Component={PayablesIndex} />}/>
                <Route path="create" element={< Wrapper accessKey={"payables_create"}  Component={CreatePayables} />}/>
                <Route path="edit/:payablesEditId" element={< Wrapper accessKey={"payables_edit"}  Component={EditPayables} />}/>
                <Route path="view/:payablesViewId" element={< Wrapper accessKey={"payables_view"}  Component={ViewPayables} />}/>
                <Route path="add/:amountPayableId" element={< Wrapper accessKey={"payables_create"}  Component={AddPayableAmount} />}/>
                <Route path="edit/amount/:amountPayableEditId" element={< Wrapper accessKey={"payables_edit"}  Component={EditPayableAmount} />}/>
            </Route>
            <Route path="receivables" element={<ReceivablesMain/>}>
                <Route index element={< Wrapper accessKey={"receivables_view"}  Component={ReceivablesIndex} />}/>
                <Route path="create" element={< Wrapper accessKey={"receivables_create"}  Component={CreateReceivables} />}/>
                <Route path="edit/:receivablesEditId" element={< Wrapper accessKey={"receivables_edit"}  Component={EditReceivables} />}/>
                <Route path="view/:receivablesViewId" element={< Wrapper accessKey={"receivables_view"}  Component={ViewReceivables} />}/>
                <Route path="add/:amountReceivableId" element={< Wrapper accessKey={"receivables_create"}  Component={AddReceivableAmount} />}/>
                <Route path="edit/amount/:amountReceivableEditId" element={< Wrapper accessKey={"receivables_edit"}  Component={EditReceivableAmount} />}/>

            </Route>
            <Route path="refunds" element={<RefundsMain/>}> 
                <Route index element={< Wrapper accessKey={"refunds_view"}  Component={RefundsIndex} />}/>
                <Route path="create" element={< Wrapper accessKey={"refunds_create"}  Component={CreateRefunds} />}/>
                <Route path="edit/:refundsEditId" element={< Wrapper accessKey={"refunds_edit"}  Component={EditRefunds} />}/>
                <Route path="view/:refundsViewId" element={< Wrapper accessKey={"refunds_view"}  Component={ViewRefunds} />}/>
            </Route>
        </Route>
    )
}

export default PaymentRoutes;