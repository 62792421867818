import { useFormik } from "formik";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import * as yup from "yup";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import { useEffect, useMemo, useState } from "react";
import Input, { NuDate, NuInput, NuMobileNumber, NuSelect, NuTextArea, NuTime, NuUpload } from "../../../../Components/Utils/Input";
import UseEditData from "../../../../Components/Hooks/useEditData";
import { useLocation } from "react-router-dom";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MultiSelect from "../../../../Components/Utils/MultiSelect";
import axiosInstance from "../../../../Services/axiosCommon";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

const EditAllTasks = () => {
  const {value:TaskStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'TaskStatus')

  const { Oncancel, editData, contextHolder, isLoading } = UseEditData();
  const location = useLocation();
  const data = location.state.data;
  const from = location.state.from;
  const [image, setImage] = useState({ preview: "", raw: "" });

  const [createNew, setCreateNew] = useState({
    taskName: data?.taskName || "",
    taskDesc: data?.taskDesc || "",
    priority: data?.priority || "",
    taskStatus: data?.taskStatus || "",
    startDate: data?.startDate || "",
    endDate: data?.endDate || "",
    completeDate: data?.completeDate || "",
    noOfDays: data?.noOfDays || "",
    noOfWeeks: data?.noOfWeeks || "",
    taskTeam: data?.taskTeam || "",
    reminderDate: data?.reminderDate || "",
    reminderTime: data?.reminderTime || "",
    assignedTo: data?.assignedTo?.map((id)=>{
      return id?.id
    }) || [],
    copyTo: data?.copyTo?.map((id)=>{
         return id?.id
    }) || []
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("taskData", JSON.stringify(value));
      formData.append('attachment', image.raw);

      editData(`/tasks/edit-task/${data.id}`, formData, resetForm, from)

    },
  });

  const { data: team } = UseDropDown('/teams/drop-down')
  const [assignToMembers,setAssignToMembers] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/teams/${formik?.values?.taskTeam}`)
      .then((res) => {
        let list = res?.data?.data?.memberList;
        let array = [];
        list &&
          list.forEach((i) => {
            let num = { value: i?.userId, label: i?.name };
            array.push(num);
          });
        setAssignToMembers(array);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formik?.values?.taskTeam]);

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  return (
    <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
        <Breadcrumb
          items={
            [{
              name: "My Tasks",
              link: "/tasks/all-tasks"
            },
            {
              name: "Edit My Tasks"
            }]
          }
        />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
        <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInput
              type="text"
              label="Task Name"
              placeholder="Task Name"
              formik={formik}
              name="taskName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="text"
              label="Task Description"
              placeholder="Task Desc"
              formik={formik}
              name="taskDesc"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Priority"
              placeholder="Priority"
              Options={[
                { value: "Shipping", label: "Shipping" },
                { value: "Cargo", label: "Cargo" },
              ]}
              formik={formik}
              name="priority"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Task Status"
              placeholder="taskStatus"
              Options={TaskStatus}
              formik={formik}
              name="taskStatus"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="startDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="endDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Complete Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="completeDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="No Of Days"
              placeholder="No Of Days"
              formik={formik}
              name="noOfDays"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInput
              type="number"
              label="No Of Weeks"
              placeholder="No Of Weeks"
              formik={formik}
              name="noOfWeeks"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              label="Task Team"
              placeholder="Task Team"
              Options={team}
              formik={formik}
              name="taskTeam"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <MultiSelect
              label="Assigned To"
              placeholder="assignedTo"
              Options={assignToMembers}
              formik={formik}
              name="assignedTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <MultiSelect
              label="Copy To"
              placeholder="Copy To"
              Options={assignToMembers}
              formik={formik}
              name="copyTo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDate
              label="Reminder Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="reminderDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTime
             label="Reminder Time"
             formik={formik}
             name="reminderTime"
             width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuUpload
              label="Attachment"
              width="w-full md:w-1/2 lg:w-1/3"
              onChange={handleChangeImg}
            /> 

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAllTasks;
