import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import socketIOClient from "socket.io-client";
import DecryptData from "../../../Components/Utils/Decrypt";
import axiosInstance from "../../../Services/axiosCommon";
import Breadcrumb from "../../../Components/Utils/Breadcrumb";
import SearchBar from "../../../Components/Utils/SearchBar";
import {
  CancelButton,
  CreateButton,
  SearchButton,
  SubmitButton,
} from "../../../Components/Utils/Buttons";
import FormikSelectTwo from "../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputCheck, NuInput } from "../../../Components/Utils/Input";
import CustomImgUpload from "../../../Components/Utils/CustomImgUpload";
import { useToast } from "../../../Components/Others/ToastServices";

// const socket = socketIOClient(window.SOCKET_SERVER_URL);

function ChatIndex() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserIds,setSelectedUserIds] = useState([]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const toast = useToast();
  const [createNew, setCreateNew] = useState({
    name: "",
    members: "",
  });
  console.log("dddd",data);
  const userDetails = DecryptData("sLiAtEdReSuEdArT") || "";
  const [selectedUser, setSelectedUser] = useState({id:null,type:""});

   async function getUsers() {
    await axiosInstance
      .get(`/groups/`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [members,setMembers] = useState([])
  async function getMembers() {
    await axiosInstance
      .get(`/user/drop-down`)
      .then(async (res) => {
        console.log("find", res?.data);
        (await res?.data) && setMembers(res?.data);
        // setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const connectSocket = () => {
  //   socket.connect();

  //   const id = userDetails?.id; // Adjust this based on your actual structure
  //   const userEmail = userDetails?.email;
  //   // console.log('details',id,userEmail);

  //   const joinData = {
  //     userId: id,
  //     username: userEmail,
  //   };
  //   console.log("details", joinData);
  //   socket.emit("join_room", joinData);
  // };
  // const disConnectSocket = () => {
  //   const id = userDetails?.id; // Adjust this based on your actual structure
  //   const userEmail = userDetails?.email;

  //   const disconnectData = {
  //     id: id,
  //     username: userEmail,
  //   };
  //   socket.emit("leave_room", disconnectData);
  //   socket.disconnect();
  // };

  // useEffect(() => {
  //   getMembers();
  //   getUsers();
  //   connectSocket();
  //   return () => {
  //     disConnectSocket();
  //   };
  // }, []);

  function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      axiosInstance
        .get(`/customer/search-customers/${searchQuery}`)
        .then((res) => {
          res.data?.data && setData(res.data?.data);
          console.log("search", res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      getUsers();
    }
  }

  useEffect(() => {
    if (searchQuery == "") {
      getUsers();
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   // console.log("test");
  //   socket.on("unread_message", (data) => {
  //     console.log("new ..... message", data, 'selectedUser',selectedUser, selectedUser.id!==data.sender_id);
  //     // Update the state based on the received data
  //     if (selectedUser.id!==data.sender_id){
  //       setData((prevData) =>
  //       prevData.map((user) =>
  //         user.id === data.sender_id
  //           ? { ...user, unread: (user.unread || 0) + 1 }
  //           : user
  //       )
  //     );
  //   };
  //   });
  //   socket.on("error", (error) => {
  //     console.error("Socket error:", error);
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   const handleUnreadMessage = (data) => {
  //     console.log("new ..... message", data, 'selectedUser', selectedUser, selectedUser.id !== data.sender_id);
  //     if (selectedUser.id !== data.sender_id) {
  //       setData((prevData) =>
  //         prevData.map((user) =>
  //           user.id === data.sender_id
  //             ? { ...user, unread: (user.unread || 0) + 1 }
  //             : user
  //         )
  //       );
  //     }
  //   };
  
  //   const handleError = (error) => {
  //     console.error("Socket error:", error);
  //   };
  
  //   socket.on("unread_message", handleUnreadMessage);
  //   socket.on("error", handleError);
  
  //   return () => {
  //     socket.off("unread_message", handleUnreadMessage);
  //     socket.off("error", handleError);
  //   };
  // }, [socket, selectedUser]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {      
      console.log("ffff", value);
      let formData = new FormData();
      const valueData = {
        ...value,
        members: selectedUserIds,
      }
      formData.append("json_data", JSON.stringify(valueData));
      formData.append('groupLogo', image.raw);
      await axiosInstance.post('/groups/new-group',formData)
      .then((res)=>{
        if (res.data?.status === true) {
          toast.open({
            type:"success",
            message:"Success",
            description: res.data?.msg || "Created Successfully."
          })
          getUsers(); 
          resetForm();
          setSelectedUsers([]);
          setSelectedUserIds([]);
          setImage({ preview: "", raw: "" });
          closeFilterDialog();
        }
        else if(res.data?.status === false && res.data?.error){
          toast.open({
            type:"error",
            message:"Error",
            description: res.data.error || "Network error."
          })
        } 
        else {
          toast.open({
            type:"warning",
            message:"Warning",
            description: res.data?.msg || "Warning."
          })
        }
      }).catch((err)=>{
        console.log(err);
      })
    },
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleDeleteImg = () => {
    setImage({
      preview: "",
      raw: "",
    });
    setCreateNew(prevState => ({ ...createNew, companyLogo: "" }))
    formik.values.companyLogo = ''
  }


  const handleUserSelect = (user) => {
    if (!selectedUsers.some(selectedUser => selectedUser.id === user.id)) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
      setSelectedUserIds((prevSelectedUserIds) => [...prevSelectedUserIds, user.id]);
    }
  };

  const handleUserDelete = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.filter(user => user.id !== id)
    );
  };

  const [searchQueryFront, setSearchQueryFront] = useState("");
  const filteredMembers = members.filter((member) =>
    member.name.toLowerCase().includes(searchQueryFront.toLowerCase())
  );
  console.log("uuuu",filteredMembers);

  return (
    <div className=" w-full h-[100%] p-4 overflow-y-scroll">
      <div className="md:flex justify-between items-center w-full">
        <Breadcrumb
          items={[
            {
              name: "Chat",
            },
          ]}
        />
        <div className=" flex justify-between items-center">
          <div className=" pb-2 w-full flex justify-between items-center ">
            <div className="flex justify-end items-center gap-1">
              <CreateButton
                label="New Group"
                handleClick={() => setShowFilterDialog(true)}
              />
              <form className="flex items-center mx-0" onSubmit={SearchData}>
                <SearchBar
                  handleChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
              {/* {NotesCreateAccess && <Link to="/mainNotes/CreateNotes"><CreateButton /></Link>} */}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[calc(100vh-156px)] bg-white flex rounded-md">
        <div className="w-1/3 h-full border-r border-slate-200 overflow-y-scroll">
          {data &&
            data.map((item, idx) => {
              const isSelected = selectedUser.id === item.id && selectedUser.type === item.type;
              return (
                <Link
                    key={idx}
                    // className='bg-purple-400 hover:text-white  px-2 py-1 rounded-md text-white text-xs font-medium'
                  to={`/chat/view/chatProfile/${item?.id}`}
                  state={{ data: userDetails, details: item ,getFun:getUsers.toString()}}
                  onClick={() => {
                    setSelectedUser({id:item.id,type:item?.type})
                    setData((prevData) =>
                      prevData.map((user) =>
                        user.id === item.id
                          ? { ...user, unread: 0 }
                          : user
                      ))
                    }
                  }
                >
                  <div
                    className={`${isSelected ? "bg-teal-100" : ""} p-3`}
                  >
                    <div className={`w-full flex `}>
                      <div className="w-11/12">
                        <div className="w-full flex justify-start gap-2">
                          <div className="w-8 h-8 bg-baseColor-secondary rounded-full overflow-hidden mt-0.5 flex items-center justify-center ">
                            {item.image ? (
                              <img
                                src={item.image}
                                width="50px"
                                alt={`${item.image}`}
                                className="max-w-full max-h-full"
                              />
                            ) : (
                              <h1 className="font-semibold text-teal-100 text-lg">
                                {item?.name?.[0]}
                              </h1>
                            )}
                          </div>
                          <div className="flex mt-1.5 flex-col">
                            <p className="text-base font-semibold text-[#4F4768]">
                              {item?.name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex justify-end gap-x-2 ">
                        <div className="flex gap-2 items-center">
                          {item?.unread > 0 ? (
                            <div className="px-[6px] py-0.5 text-gray-700 text-xs bg-green-400 rounded-full">
                              {item?.unread}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <div className="w-auto bg-white rounded-md flex justify-center items-center">                       
                          Message
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
        <div className="w-8/12 h-full">
          <Outlet />
        </div>
      </div>
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center " >
          <div className="animate-scale-in w-[70%] h-[82vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Create New Group</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex w-full">
                <div className="w-1/2 p-4 ">
                  <NuInput
                    type="text"
                    label="Group Name"
                    placeholder="Group Name"
                    formik={formik}
                    name="name"
                    width="w-full"
                    px="0"
                  />
                  <p className={`text-xs 2xl:text-sm text-black pb-2`}>
                    Select Users        
                  </p>
                  <div className=" w-full h-[34px] relative rounded-md p-1 flex justify-between bg-white items-center" style={{border:'0.5px solid #EAEBED'}}>
                    <input type="text" onChange={(e) => setSearchQueryFront(e.target.value)} placeholder="Search here" className="placeholder-gray-400 w-[90%] text-sm px-2 outline-none border-none" />
                    <SearchButton/>
                  </div>
                  <div className="w-full h-52 rounded-md mt-2 border border-slate-200 overflow-y-scroll">
                    {filteredMembers?.map((user,index)=>{
                      return(
                      <span 
                        key={index}
                        onClick={() => handleUserSelect(user)} 
                        className={`m-1 flex justify-between items-center text-xs ${
                          selectedUsers.some(selectedUser => selectedUser.id === user.id)
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-[#B3EBD7] cursor-pointer'
                        } rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50`}>
                      {user?.name}
                    </span>
                    )})}
                  </div>
                </div>
                <div className="w-1/2 p-4">
                  <CustomImgUpload
                   borderStyle='dashed'
                   handleChange={handleChangeImg}
                   label={'Group Logo'}
                   preview={image.preview || createNew.companyLogo || ""}
                   removeFunction={handleDeleteImg}
                  />
                    <div className="w-full h-64 rounded-md border border-slate-200 mt-4 overflow-y-scroll">
                    {selectedUsers.length > 0 ? (
                    selectedUsers.map((user, index) => (
                      <div key={index} className="m-1  flex justify-between items-center text-xs bg-[#B3EBD7] rounded text-main-title py-2 px-2 hover:bg-baseColor-secondary hover:bg-opacity-50">
                    {user?.name}
                    <span
                onClick={() => {
                  handleUserDelete(user?.id)
                }}
                className=" text-blue-900 cursor-pointer"
              >
                x
              </span>
                  </div>
                    ))
                  ) : (
                    <p className="text-center py-10">No User Is Selected</p>
                  )}
                    </div>
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Create Group" />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatIndex;
