import React from 'react'
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';

function MarketingSales() {
    const { data, loading } = UseGetDashBoardData(`/dashboard/leadsStatsNew`);

    // Destructuring the data
    const totalLeads = data?.total_leads || 0;
    const leadsPercentage = data?.leads_percentage_compare_last_month || 0;
    const newCustomers = data?.new_customers || 0;
    const newCustomersPercentage = data?.new_cus_percentage_compare_last_month || 0;

    const leadsIsNegative = leadsPercentage < 0;
    const newCustomersIsNegative = newCustomersPercentage < 0;

    return (
        <div className="bg-white p-3 pb-10 rounded-lg drop-shadow-lg h-[25vh] w-full">
            <p className='text-[#4F4768] text-sm font-normal'>Marketing</p>
            <div className="h-[96%] flex justify-between mt-2">
                {/* Leads Column */}
                <div className="w-1/2 py-1">
                    <p className='text-xs'>Leads</p>
                    <p className='text-lg font-semibold px-4'>{totalLeads}</p>
                    <div className='flex flex-wrap items-center text-xs mt-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                            <path d={`M10 ${leadsIsNegative ? '17L10 5' : '5L10 17'}`} stroke={leadsIsNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d={`M${leadsIsNegative ? '15.8337 10.0001L10.0003 15.8334L4.16699 10.0001' : '4.16699 10.0001L10.0003 4.16675L15.8337 10.0001'}`} stroke={leadsIsNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className={`text-${leadsIsNegative ? '[#FF0000]' : '[#048178]'} ml-1`}>{leadsPercentage}</span>
                        <span className="ml-1 text-xs text-[#707070]">from last month</span>
                    </div>
                </div>
                {/* Divider */}
                <div className='border-r border-slate-200/60 mr-1'></div>
                {/* New Customers Column */}
                <div className="w-1/2 py-1">
                    <p className='text-xs'>New Customers</p>
                    <p className='text-lg font-semibold px-4'>{newCustomers}</p>
                    <div className='flex flex-wrap items-center text-xs mt-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                            <path d={`M10 ${newCustomersIsNegative ? '17L10 5' : '5L10 17'}`} stroke={newCustomersIsNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d={`M${newCustomersIsNegative ? '15.8337 10.0001L10.0003 15.8334L4.16699 10.0001' : '4.16699 10.0001L10.0003 4.16675L15.8337 10.0001'}`} stroke={newCustomersIsNegative ? "#FF0000" : "#048178"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className={`text-${newCustomersIsNegative ? '[#FF0000]' : '[#048178]'} ml-1`}>{newCustomersPercentage}</span>
                        <span className="ml-1 text-xs text-[#707070]">from last month</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketingSales;
