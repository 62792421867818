import { Route, useLocation, useNavigate } from "react-router-dom";
import { AllTaskIndex, AllTasksMain, CreateAllTasks, EditAllTasks, ViewAllTask } from "../Views/Web/Task/MyTasks";
import MainTask from "../Views/Web/Task/TaskMain";
import { CreateMyTasks, EditMyTasks, MyTaskIndex, MyTasksMain, ViewMyTask } from "../Views/Web/Task/AllTasks";
import { useEffect } from "react";
import { Wrapper } from "../Components/Utils/roles";



function TaskRoutes(){
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (
        location.pathname === "/tasks" ||
        location.pathname === "/tasks/") {
        navigate("/page-not-found")
      }
    },[location])
    return (
        <Route path="tasks" element={<MainTask/>}>
            <Route path="all-tasks" element={<AllTasksMain/>} >
                <Route index element={<Wrapper accessKey={true}  Component={AllTaskIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={true}  Component={CreateAllTasks} />}/>
                <Route path="edit/:allTaskEditId" element={<Wrapper accessKey={true}  Component={EditAllTasks} />}/>
                <Route path="view/:allTaskViewId" element={<Wrapper accessKey={true}  Component={ViewAllTask} />}/>
            </Route>
            <Route path="my-tasks" element={<MyTasksMain/>} >
            <Route index element={<Wrapper accessKey={true}  Component={MyTaskIndex} />}/>
                <Route path="create" element={<Wrapper accessKey={true}  Component={CreateMyTasks} />}/>
                <Route path="edit/:myTaskEditId" element={<Wrapper accessKey={true}  Component={EditMyTasks} />}/>
                <Route path="view/:myTaskViewId" element={<Wrapper accessKey={true}  Component={ViewMyTask} />}/>
            </Route>
        </Route>
    )
}

export default TaskRoutes;