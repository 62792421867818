import { useEffect, useState } from "react";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { CancelButton, CreateButton, FilterButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import DataTable from "../../../../Components/Others/DataTable";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import MoreOptions from "../../../../Components/Utils/MoreOptions";
import CustomDataTable from "../../../../Components/Others/CustomDataTable";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import FormikSelectTwo from "../../../../Components/Utils/Select";
import { useFormik } from "formik";
import * as yup from 'yup';
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";
import { GetAccess } from "../../../../Components/Utils/roles";

function RefundsIndex(){
  const {value:status} = useFetchMataData(`/dropdown/dropdown-details/`,'Refund_Status')
  const {value:Method} = useFetchMataData(`/dropdown/dropdown-details/`,'Refund_Method')
  const { data:users} = UseDropDown ("/user/drop-down");

    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [filter, setFilter] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterProps, setFilterProps] = useState({
      refundMethod:"",
      approvedBy:"",
      status:""
    });
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search:"",
      refundMethod:"",
      approvedBy:"",
      status:""
    });
    const clearFilter = ()=> {
      setParams({...params,  refundMethod:"",
      approvedBy:"",
      status:""})
      formik.values.refundMethod=""
      formik.values.approvedBy=""
      formik.values.status=""

        setFilter(false)
    }
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterProps,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params,approvedBy:value.approvedBy, status:value.status,refundMethod:value.refundMethod})
        closeFilterDialog();
        setFilter(true)
        setFilterProps({
          refundMethod:"",
          approvedBy:"",
          status:""
        });
      }
    })
  
    const { data, totalCount, getData } = UseGetDataSearch( '/refunds/',params);
    console.log(data,'dddd');
  
    const defaultHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "refundId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Customer",
        width: "10%",
        isLink: true,
        id: "refundId",
        link: '/payments/refunds/view/',
        field: "customerName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Tracking No",
        width: "10%",
       
        field: "trackingNo",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Refund Method",
        width: "10%",
        field: "refundMethod",
        textAlign: "center",
        rowAlign: "center",
      },
     
      {
        title: "Refund Amount",
        width: "10%",
        field: "refundAmount",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Refund",
        width: "10%",
        field: "invoiceRef",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"refundId",
          removeValue:"trackingNo",
          url:`/refunds/`,
          moduleName:"Refunds",
          from:`/payments/refunds`,
          editLink:"/payments/refunds/edit/",
          viewLink:"/payments/refunds/view/"
        }
      },
    ];
    const availableHeaders = [
      {
        title: "ID",
        width: "5%",
        field: "refundId",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Customer",
        width: "10%",
        isLink: true,
        id: "refundId",
        link: '/payments/refunds/view/',
        field: "customerName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Refund Method",
        width: "10%",
        field: "refundMethod",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Tracking No",
        width: "10%",
        field: "trackingNo",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Refund Amount",
        width: "10%",
        field: "refundAmount",
        textAlign: "center",
        rowAlign: "center",
      },
      
      {
        title: "Status",
        width: "10%",
        field: "status",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Approved By Name",
        width: "10%",
        field: "approvedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      // {
      //   title: "Comments",
      //   width: "10%",
      //   field: "comments",
      //   textAlign: "center",
      //   rowAlign: "center",
      // },
      {
        title: "Reason",
        width: "10%",
        field: "reason",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Reviewed By Name",
        width: "10%",
        field: "reviewedByName",
        textAlign: "center",
        rowAlign: "center",
      },
      {
        title: "Action",
        width: "5%",
        field: "action",
        textAlign: "center",
        rowAlign: "center",
        contents:{
          // fetcher:getData,
          id:"refundId",
          removeValue:"trackingNo",
          url:`/refunds/`,
          moduleName:"Refunds",
          from:`/payments/refunds`,
          editLink:"/payments/refunds/edit/",
          viewLink:"/payments/refunds/view/"
        }
      },
    ];
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])

     const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <Breadcrumb
         items={
          [
            { name: "Payment" },
          {
            name: "Refunds",
          }]
         }
        />
      <div className=' flex gap-2'>
      <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />   <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
       <CreateButton label='Import' />
       {GetAccess("refunds_create") && <Link to="/payments/refunds/create"><CreateButton label='Refunds'/></Link> }
      </div>
      </div>
  
      <CustomDataTable
      fetcher={getData}
        data={data} 
        availableHeaders={availableHeaders} 
        defaultHeader={defaultHeaders}
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-145px)]'
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title='Refunds List'
        storageName="RefundTable"
        editAccess="refunds_edit"      
        deteleAccess="refunds_delete" 
        />
          {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Refund</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
              label="Approved By"
              placeholder="Choose"
              Options={users}
              formik={formik}
              name="approvedBy"
              width="w-full "
            />
                     <FormikSelectTwo
              label="Refund Method"
              placeholder="Choose"
              Options={Method}
              formik={formik}
              name="refundMethod"
              width="w-full "
            />
             <FormikSelectTwo
                type="text"
                label="Status"
                placeholder="Choose"
                Options={status}
                formik={formik}
                name="holderType"
                width="w-full "
              />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
    </div>
    )
}

export default RefundsIndex;