import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { NuDate } from '../../../../Components/Utils/Input';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import { Link } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import * as yup from "yup";

function ViewCqRevision() {
  const { value: Availablity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Availablity_CustomerQuotes"
  );
  const { value: DeliveryTerm } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "DeliveryTerm_CustomerQuotes"
  );
  const { value: PaymentTerms } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentTerms_CustomerQuotes"
  );
  const { value: PaymentMethod } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "PaymentMethod_CustomerQuotes"
  );
  const { value: Validity } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Validity_CustomerQuotes"
  );
  const { value: status } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "Status_customerQuotes"
  );
  const { value: leadTime } = useFetchMataData(
    `/dropdown/dropdown-details/`,
    "leadTime_customerQuotes"
  );

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    status: "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    paymentTerms: "",
    deliveryTerms: "",
    fromDate: "",
    toDate: "",
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    status: "",
    paymentMethod: "",
    leadTime: "",
    validity: "",
    availability: "",
    paymentTerms: "",
    deliveryTerms: "",
    cqId:1

  

  });
  const clearFilter = () => {
    setParams({
      ...params,
      status: "",
      paymentMethod: "",
      leadTime: "",
      validity: "",
      availability: "",
      paymentTerms: "",
      deliveryTerms: "",
      fromDate: "",
      toDate: "",
    });
    formik.values.status = "";
    formik.values.paymentMethod = "";
    formik.values.leadTime = "";
    formik.values.validity = "";
    formik.values.availability = "";
    formik.values.deliveryTerms = "";
    formik.values.fromDate = "";
    formik.values.toDate = "";
    formik.values.paymentTerms = "";

    setFilter(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff", value);
      setParams({
        ...params,
        paymentTerms: value.paymentTerms,
        toDate: value.toDate,
        fromDate: value.fromDate,
        deliveryTerms: value.deliveryTerms,
        availability: value.availability,
        validity: value.validity,
        leadTime: value.leadTime,
        paymentMethod: value.paymentMethod,
      });
      closeFilterDialog();
      setFilter(true);
      setFilterProps({
        status: "",
        paymentMethod: "",
        leadTime: "",
        validity: "",
        availability: "",
        paymentTerms: "",
        deliveryTerms: "",
        fromDate: "",
        toDate: "",
      });
    },
  });

  const { data, totalCount, getData } = UseGetDataSearch(
    "/customer-quotations/revisions",
    params
  );

  const defaultHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "id",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Reference",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "PR Reference",
      width: "10%",
      field: "prRef",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Total Price",
      width: "10%",
      field: "totalPrice",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "left",
      rowAlign: "left",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "left",
      rowAlign: "left",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/revision/`,
        moduleName: "Revision",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/viewcqcreate/",
        viewLink: "/sales/quotes/viewCQIdRevision/",
      },
    },
  ];
  const availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "id",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Reference ",
      width: "10%",
      isLink: true,
      id: "id",
      link: "/sales/quotes/view/",
      field: "ref",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Remarks",
    //   width: "10%",
    //   field: "remarks",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Total Price",
      width: "10%",
      field: "totalPrice",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Status",
      width: "10%",
      field: "status",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Approved By Name",
      width: "10%",
      field: "approvedByName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Availability",
      width: "10%",
      field: "availability",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Delivery Terms",
      width: "10%",
      field: "deliveryTerms",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Quote Date",
      width: "10%",
      field: "date",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Lead Time",
      width: "10%",
      field: "leadTime",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Payment Method",
    //   width: "10%",
    //   field: "paymentMethod",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Payment Terms",
      width: "10%",
      field: "paymentTerms",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Pr Id",
      width: "10%",
      field: "prId",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Prepared By Name",
      width: "10%",
      field: "preparedByName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Reviewed By Name",
      width: "10%",
      field: "reviewedByName",
      textAlign: "left",
      rowAlign: "left",
    },
    // {
    //   title: "Special Notes",
    //   width: "10%",
    //   field: "specialNotes",
    //   textAlign: "left",
    //   rowAlign: "left",
    // },
    {
      title: "Validity",
      width: "10%",
      field: "validity",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "left",
      rowAlign: "left",
      contents: {
        // fetcher:getData,
        id: "id",
        removeValue: "ref",
        url: `/customer-quotations/revision/`,
        moduleName: "Revision",
        from: `/sales/quotes`,
        editLink: "/sales/quotes/viewcqcreate/",
        viewLink: "/sales/quotes/viewCQIdRevision/",
      },
    },
  ];

  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount });
  }, [page, pageCount]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
      <Breadcrumb
          items={[
            {
              name: "Customer Quotation",
              // link: "/sales/quotes/view",
            },
            {
              name: "Revision",
            },
          ]}
        />
       
        <div className=" flex gap-2">
          <SearchBar
            handleChange={(e) =>
              setParams({ ...params, search: e.target.value })
            }
          />
          <FilterButton handleClick={() => setShowFilterDialog(true)} />
          {filter && (
            <FilterButton handleClick={() => clearFilter()} label="Clear" />
          )}
  <BackButton />
          {/* <CreateButton label="Import" />
          <Link to="/sales/quotes/create">
            <CreateButton label="Quotes" />
          </Link> */}
        </div>
      </div>
      <CustomDataTable
        fetcher={getData}
        data={data}
        availableHeaders={availableHeaders}
        defaultHeader={defaultHeaders}
        bodyHeight="max-h-[calc(100vh-260px)]"
        height="h-[calc(100vh-145px)]"
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
        title="Revision List"
        storageName="RevisionTable"
      />
      {showFilterDialog && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
          <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
            <h2 className="text-xl mb-4">Filter Revision</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4">
                <p className="py-2 px-6 text-m">Date</p>
                <div className="flex justify-between  w-full ">
                  <NuDate
                    label="From "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="fromDate"
                    width="w-full "
                  />
                  <NuDate
                    label="To "
                    placeholder="dd/mm/yyyy"
                    formik={formik}
                    name="toDate"
                    width="w-full "
                  />
                </div>
                <FormikSelectTwo
                  type="text"
                  label="Deliver Terms"
                  placeholder="Choose"
                  Options={DeliveryTerm}
                  formik={formik}
                  name="deliverTerms"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Payment Terms"
                  placeholder="Choose"
                  Options={PaymentTerms}
                  formik={formik}
                  name="paymentTerms"
                  width="w-full "
                />

                {/* <FormikSelectTwo
                  label="Payment Method"
                  placeholder="Choose"
                  Options={PaymentMethod}
                  formik={formik}
                  name="paymentMethod"
                  width="w-full "
                /> */}
                <FormikSelectTwo
                  type="text"
                  label="Availability"
                  placeholder="Choose"
                  Options={Availablity}
                  formik={formik}
                  name="availability"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Validity"
                  placeholder="Choose"
                  Options={Validity}
                  formik={formik}
                  name="validity"
                  width="w-full "
                />
                <FormikSelectTwo
                  type="text"
                  label="Lead Time"
                  placeholder="Choose"
                  Options={leadTime}
                  formik={formik}
                  name="leadTime"
                  width="w-full "
                />
                {/* <NuDataTime
              label="Lead Time"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="leadTime"
              width="w-full "
            /> */}

                <FormikSelectTwo
                  type="text"
                  label="Status"
                  placeholder="Choose"
                  Options={status}
                  formik={formik}
                  name="status"
                  width="w-full"
                />
              </div>
              <div className="flex justify-end gap-2">
                <CancelButton handleClick={closeFilterDialog} />
                <SubmitButton name="Apply" />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default ViewCqRevision