import React from 'react'
// import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import UseGetDashBoardData from '../../../../../Components/Hooks/useDashboardData';
import GRN from './GRN';
import DeliveryNote from './DeliveryNote';
import RequestApproval from './RequestApproval';

function Approvals() {
    
    return (
      <div className="col-span-4 bg-white max-h-[25vh] p-4   rounded-lg shadow-lg overflow-hidden ">
       <p className="text-sm font-medium text-[#4F4768] pl-2">Approvals</p>
       <div className='flex'>
  
        <GRN/>
        <div className='border-r border-[1px] border-slate-200/60 border-solid'/>
  
      <DeliveryNote/>
        <div className='border-r border-[1px] border-slate-200/60 border-solid'/>
  
        <RequestApproval/>
        
        </div>
      </div>
    );
  }
  

export default Approvals