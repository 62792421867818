import React, { useState } from "react";
import ReportTable from "../../../../Components/Others/ReportTable";
import NuDate from "../../../../Components/Utils/NuDate";
import DownloadXlsx from "../xlsx/DownloadXlsx";
import DefaultDownloadLink from "../pdf/DefaultDownloadLink";
import { BackButton, FilterButton } from "../../../../Components/Utils/Buttons";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";

function GRNReport() {
  const [params, setParams] = useState({
    page: 1,
    per_page: 100,
    search: "",
    searchBy: ""
  });
  const { data, totalCount, getData } = UseGetDataSearch( "/grn/", params );
  let headers = [
    {
      title: "ID",
      width: "5%",
      field: "grnId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Item",
      width: "10%",
      field: "itemName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Purchase Type",
      width: "10%",
      field: "purchaseType",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Order Ref",
      width: "10%",
      field: "orderRef",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Vendor Id",
      width: "10%",
      field: "vendorName",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Unit",
      width: "10%",
      field: "unit",
      textAlign: "left",
      rowAlign: "left",
    },
  ];



  return (
    <div className=" w-full h-full px-4 py-2  overflow-hidden">
      <div className=" w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Reports",
              link: "/reports",
            },
            {
              name: "GRN",
            },
          ]}
        />
        <BackButton />
      </div>

      <div className=" w-[100%] h-[calc(100vh-130px)] mt-2 bg-white rounded-lg  overflow-y-auto p-3">
        <div className=" w-full h-full">
          <div className=" w-full flex justify-between items-center">
            <TextMediumSM content={"GRN Report"} />
            <div className=" flex items-center gap-4">
              <FilterButton height="h-[32px]" />
              <DefaultDownloadLink
                headers={headers}
                data={data}
                fileName={`GRN Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}.pdf`}
              />
              <DownloadXlsx
                header={headers}
                data={data}
                fileName={`GRN Report ${NuDate({
                  value: Date(),
                  format: "DD-MMM-YYY",
                })}`}
              />
            </div>
          </div>
          <ReportTable
            bodyHeight="max-h-[calc(100vh-245px)]"
            height="h-[calc(100vh-235px)]"
            headers={headers}
            data={data}
          ></ReportTable>
        </div>
      </div>
    </div>
  );
}

export default GRNReport;
