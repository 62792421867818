import React, { useEffect, useMemo, useState } from 'react'
import DeleteIcon from '../../../../Components/Icons/Delete';
import { NuDate, NuInput, NuTextArea, NuUpload } from '../../../../Components/Utils/Input';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import * as yup from 'yup';
import { TextMediumBase } from '../../../../Components/Text/MediumText';
import axiosInstance from '../../../../Services/axiosCommon';

function CreateCustomerOrder() {
  const currentDate = new Date().toISOString().split('T')[0];
    const { data: Item } = UseDropDown('/items/drop-down');
  const { data: user } = UseDropDown('/user/drop-down');
  const { data: customers } = UseDropDown('/customers/drop-down');
  const { data: PO } = UseDropDown('/PO/drop-down');
  const { data: PR } = UseDropDown('/PR/drop-down');
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [items, setItems] = useState([]);
    console.log(items,"ii");
    const [newItemData, setNewItemData] = useState({
       productId: "",
       quantity: "",
     });
  
    const [createNew, setCreateNew] = useState({
          receivedOn: currentDate || "",
        deadLine:"",
        notesFromCus:"",
        customerId:"",
        ref:"",
        totalPrice:0.00,
        salesPerson:"",
        assignee:"",
        products:"",
        cusQuoteId:"",
        prId:"",       
    })
    const [error,setError] = useState('')
    const handleAdd = () => {
      const newItem = {
        productId: formik.values.productId,
        productName : Item.find((i)=> i.value===formik.values.productId),
        // comments: formik.values.comments,
        // price: formik.values.price,
        quantity: formik.values.quantity
      };
      setItems([...items, newItem]);
   formik.values.totalPrice = parseFloat(formik.values.totalPrice) + (parseFloat(newItem.price) * parseFloat(newItem.orderedQty));

      // Update local storage
      setNewItemData({
      productId: "",
      quantity: ""
      });
      formik.values.productId = '';
      formik.values.quantity ='';
     };
  
    const handleDeleteItem = (indexToDelete) => {
      const updatedItems = Cqlist.filter((_, index) => index !== indexToDelete);
      setCqlist(updatedItems);
     const removedItemPrice = parseFloat(Cqlist[indexToDelete].price || 0);
    const removedItemQuantity = parseFloat(Cqlist[indexToDelete].quantity || 0);
    const newTotalPrice = formik.values.totalPrice - (removedItemPrice * removedItemQuantity);
      formik.setFieldValue('totalPrice', newTotalPrice);
    console.log("Updated totalPrice:", newTotalPrice);
      // localStorage.setItem("items", JSON.stringify(updatedItems));
    };
  
    const handleChangeImg = (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };
    const handleImageRemove = () => {
      setImage({
        preview: "",
        raw: "",
      });
    }
  
    const formik = useFormik
      ({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          prId: yup.string().required('Purchase Request is requred!'),
          cusQuoteId: yup.string().required('Customer Quote is requred!'),
          ref: yup.string().required('Reffrence Number is requred!'),
          salesPerson: yup.string().required('Sales Person is requred!')
        }),
        onSubmit: async (value, { resetForm }) => {
          if (Cqlist.length === 0) {
            setError('Product table is empty');
            return;
          }
          let formData = new FormData();
          const valueWithItems = {
            ...value,
            products: Cqlist,
          };
          formData.append('json_data', JSON.stringify(valueWithItems));
          formData.append('attachments', image.raw);
  
          addFormData('/customer-orders/new-order', formData,resetForm);
  
        }
      });

      const [CustomerQ, setCustomerQ] = useState([])

      function getCustomerQ(num){
        axiosInstance.get(`/customer-orders/customer-quote/drop-down/${num}`).then((response) => {
          let tempData =[]
          if(response.data){
            let x = response.data
    
            x.map((item) => {
            let y = {
              value: item.id,
              label: item.name,
            }
            tempData.push(y)
            })
    
            setCustomerQ(tempData)
          }
        }).catch((error) => {})
       }
    
       useMemo(()=>{
        if(formik.values.prId){
          getCustomerQ(formik.values.prId);
            }
       },[formik.values.prId])   
     
      const [Cqlist, setCqlist] = useState([]);

      const getCQ = (cusQuoteId) => {
        if (cusQuoteId !== "") {
          axiosInstance
            .get(`/customer-orders/customer-quote-product/drop-down/${cusQuoteId}`)
            .then(res => {
              let data = res.data;
              setCqlist(data);

              let tPrice=0.00
              for (let i=0;i<data?.length;i++){
               tPrice = parseFloat(tPrice) + (parseFloat(data[i].price) * parseFloat(data[i].quantity));
              }
             //  setTotalPrice(tPrice);
             formik.setFieldValue('totalPrice', tPrice);
            //  formik.setFieldValue('paymentTerms', res?.data?.data?.paymentTerms || '');
            //  formik.setFieldValue('deliveryTerms', res?.data?.data?.deliveryTerms || '');
            
            })
            .catch(err => {
              console.log(err, "err");
            });
        }
      };
    
      useEffect(() => {
        if (formik.values.cusQuoteId) {
          getCQ(formik.values.cusQuoteId);
        }
      }, [formik.values.cusQuoteId]);

      useEffect(() => {
        const totalPrice = Cqlist.reduce((total, item) => {
          return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
        }, 0);
    
        formik.setFieldValue('totalPrice', totalPrice);
      }, [Cqlist]);

      useEffect(() => {
        if (Cqlist.length > 0) {
          setError('');
        }
      }, [Cqlist]);

   return (
      <div className="w-full h-full py-2 px-4 ">
      <div className="flex justify-between ">
         <Breadcrumb items={[
           
            {
              name: "Customer Order",
              link: "/sales/customer-order"
            },
  
            { name: "Create" },
          ]} />
          <BackButton />
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md mt-4 overflow-hidden ">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <FormikSelectTwo
                type="text"
                label="Purchase Request"
                placeholder="Purchase Request "
                Options={PR}
                formik={formik}
                name="prId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
             <FormikSelectTwo
                type="text"
                label="Customer Quote"
                placeholder="Choose"
                Options={CustomerQ}
                formik={formik}
                name="cusQuoteId"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />
                 <NuInput
             label="Customer PO Reference"
             type="text"
             formik={formik}
             placeholder="Reference"
             name="ref"
             width="w-full md:w-1/2 lg:w-1/3"
             isRequired={true}
           />   
             <NuDate
                label="Received On"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="receivedOn"
                width="w-full md:w-1/2 lg:w-1/3"
                // value={currentDate}
              />
               {/* <NuDate
                label="Dead Line"
                placeholder="dd/mm/yyyy"
                formik={formik}
                name="deadLine"
                width="w-full md:w-1/2 lg:w-1/3"
              /> */}
                <FormikSelectTwo
                type="text"
                label=" Assignee"
                placeholder=" Choose"
                Options={user}
                formik={formik}
                name="assignee"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              <FormikSelectTwo
                type="text"
                label="Sales Person"
                placeholder="Choose"
                Options={user}
                formik={formik}
                name="salesPerson"
                width="w-full md:w-1/2 lg:w-1/3"
                isRequired={true}
              />              
               <NuTextArea
                label="Notes From Customer"
                type="text"
                formik={formik}
                placeholder="Notes From Customer"
                name="notesFromCus"
                width="w-full md:w-1/2 lg:w-1/3"
              />
                <NuUpload
                label="Attachment"
                width="w-full md:w-1/2 lg:w-1/3"
                onChange={handleChangeImg}
              />           
            <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
              <div className='w-full bg-white overflow-y-scroll mt-5'>
               {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

             <p className=" w-[40%] text-left text-white text-xs font-medium"> Product Name </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium"> Unit </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium"> Quantity </p>
                <p className=" w-[20%] text-center text-white text-xs font-medium"> Price </p>
                <p className=" w-[5%] text-center text-white text-xs font-medium"> Action </p>
                </div>
                {Cqlist.length > 0 ?
              <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                
                {Cqlist.map((List, index) => {

                return (
                  <div key={index} className="w-full py-3 flex justify-between px-6  odd:bg-white even:bg-slate-50">
                   <p className="w-[5%]  text-left  text-[#606279] text-xs font-light py-1">
                                    {index + 1}
                                </p>
                    <p className=" w-[40%] text-left text-[#606279] text-xs font-light ">{List?.productName} </p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light">{List?.unit}</p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light">{List?.quantity}</p>
                    <p className=" w-[20%] text-center text-[#606279] text-xs font-light">{List?.price} </p>                  
                   
                    <div className='w-[5%] text-center'> 
                     <button
                      onClick={() => handleDeleteItem(index)}
                      type="button"
                      className="text-red-500 cursor-pointer  ml-4 border-none"
                    >
                      <DeleteIcon
                       color='red'
                       height={4}
                      />
                    </button>
                    </div>
                  </div>
                );
              })}
                          </div>
                          :<div className="w-full text-center text-[#93949f]">No Items here</div>}

            </div>
            <div className='w-full my-6 flex justify-end items-center gap-4'>
              <TextMediumBase content={"Total Price : "} />
              <NuInput
                label=""
                type="number"
                formik={formik}
                placeholder="Total Price"
                name="totalPrice"
                width="w-full md:w-1/2 lg:w-1/3"
                disabled={true}
              />
            </div>
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel(-1) }} />
                <SubmitButton isLoading={isLoading}/>
              </div>
            </div>
            </div>
            </div>
        </form>
      </div>
  
    )
  }

export default CreateCustomerOrder