import React from "react";

export  function TextXS({
  color = "gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align,
  otherStyles = ""
}) {
  return (
    <p
      className={`text-[10px] 2xl:text-xs text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      } ${otherStyles}`}
    >
      {content}
    </p>
  );
}

export  function TextSM({
    color = "text-gray-800",
    truncate,
    wrap = "text-wrap",
    whitespace = "whitespace-normal",
    content,
    align,
    otherStyle=""
  }) {
    return (
      <p
        className={` text-xs 2xl:text-sm ${align} font-normal ${color} ${wrap} ${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyle}`}
      >
        {content}
      </p>
    );
  }

  export  function TextBase({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-sm 2xl:text-base text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-base 2xl:text-xl text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }

  export  function TextXXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left",
    otherStyles = ""
  }) {
    return (
      <p
        className={` text-xl 2xl:text-2xl text-${align} font-normal text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        } ${otherStyles}`}
      >
        {content}
      </p>
    );
  }
