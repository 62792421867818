import i18next from "i18next";
import Languagedetector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next';

import enTranslation from "./locales/enTranslation.json";
import frTranslation from "./locales/frTranslation.json";
import arTranslation from "./locales/arTranslation.json";


i18next.use(Languagedetector).use(initReactI18next).init({
    resources:{
        en:{
            translation:enTranslation
        },
        fr:{
            translation:frTranslation
        },
        ar:{
            translation:arTranslation
        }

    },
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false // React already escapes HTML by default
      },
});