import React, { useState } from 'react'
import * as yup from 'yup';
import { CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
import DropDownAddSubCategory from '../../../../Components/Utils/selectAddSubCategory';
import DropDownAdd from '../../../../Components/Utils/selectAdd';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
import { useFormik } from 'formik';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import axiosInstance from '../../../../Services/axiosCommon';
import { useToast } from '../../../../Components/Others/ToastServices';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { NuDate } from '../../../../Components/Utils/Input';

function MultiEditGRN({onClose,data,getData}) {
  const toast = useToast();

  const {value:GrnStatus} = useFetchMataData(`/dropdown/dropdown-details/`,'GrnStatus')
  const [createNew, setCreateNew] = useState({
    ids: "",
    receivedDate:"",
    status: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // Add your validation schema here
    }),
    onSubmit: async (value, { resetForm }) => {
      value.ids = data;
      axiosInstance

      .put(`/grn/multi-edit`, value)
        .then(res => {
          if (res.data?.status === true) {
            toast.open({
              type: "success",
              message: "Success",
              description: res.data?.msg || "Created Successfully."
            });
            getData();
            onClose();
          } else if (res.data?.status === false && res.data?.error) {
            toast.open({
              type: "error",
              message: "Error",
              description: res.data.error || "Network error."
            });
          } else {
            toast.open({
              type: "warning",
              message: "Warning",
              description: res.data?.msg || "Warning."
            });
          }
        })
        .catch(err => {
          console.error(err);
          toast.open({
            type: "error",
            message: "Error",
            description: "Network error."
          });
        });
    }
  });

  const { category: itemsCategory, subCategory: itemsSubCategory, getCategory, fetchSubCategory } = UseCatSubCatDropDown('/category/ItemCategory', "ItemCategory", formik?.values?.category);

  function closeFilterDialog() {
    formik.resetForm();
    onClose();
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9998] flex justify-center items-center">
      <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] relative bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
        <div className='w-full flex justify-between items-center bg-white'>
          <h2 className="text-base font-medium mb-4">Multiple Edit</h2>
          <button
            className="absolute top-2 right-2 text-white bg-red-600"
            onClick={onClose}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
          <NuDate
            label="Received Date "
            placeholder="dd/mm/yyyy"
            formik={formik}
            name="receivedDate"
            width="w-full "
            />
          <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Status"
              Options={GrnStatus}
              formik={formik}
              name="status"
              width="w-full "
            />
          </div>
          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name='Submit' />
          </div>
        </form>
      </div>
    </div>
  );
}

export default MultiEditGRN