import React from 'react'
import UseGetDashBoardData from '../../../../Components/Hooks/useDashboardData';
import { FaCheckCircle, FaClipboardCheck, FaHourglassHalf } from 'react-icons/fa';

function GRNApproval() {
    // const { data, loading } = UseGetDashBoardData('/dashboard/grn-approvals');
//max-h-40 md:max-h-48 lg:max-h-60
const { data, loading } = UseGetDashBoardData('');

    return (
      
      <div className="flex-1 flex-wrap p-2  overflow-y-scroll ">
        <div className="flex items-center justify-between mb-2">
          <p className="text-[#4F4768] text-xs 2xl:text-sm font-semibold">GRN </p>
          <p className="text-[#333333] text-sm float-end font-semibold">0</p>

        {/* <p className="text-[#333333] text-sm float-end font-semibold">{data?.total || 0}</p> */}
      </div>
        <div className="grid gap-2 justify-between items-center ">
        <div className="flex items-center text-[12px]">
            <FaHourglassHalf className="text-yellow-500 mr-1" />
            <span>Pending : 0</span>

            {/* <span>Pending {data?.pendding||0}</span> */}
          </div>
          <div className="flex items-center text-[12px]">
          <FaClipboardCheck className="text-purple-500 mr-1" />
          <span>Verified : 0</span>

            {/* <span>Verified {data?.verified||0}</span> */}
          </div>
         
        </div>
      </div>
    )
  }
  
export default GRNApproval