import React, { useEffect, useState } from 'react';
import { CancelButton, CreateButton, FilterButton, ImportButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useSearchParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import Breadcrumb from '../../../../Components/Utils/Breadcrumb';
import MoreOptions from '../../../../Components/Utils/MoreOptions';
import CustomDataTable from '../../../../Components/Others/CustomDataTable';
import Card from '../../../../Components/Icons/card';
import List from '../../../../Components/Icons/list';
import Select from 'react-select'
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import SupplierCard from './SupplierCard';
import Pagination from '../../../../Components/Utils/Pagenation';
import FormikSelectTwo from '../../../../Components/Utils/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { NuInput } from '../../../../Components/Utils/Input';
import useFetchMetaStar from '../../../../Components/Hooks/useFetchMetaStar';
import { CountryMIN } from '../../../../assets/CountryMini';
import Importdoc from './Importdoc';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import { GetAccess, GetRole } from '../../../../Components/Utils/roles';

function SuppliersIndex() {
  const { value:vendorRating } = useFetchMetaStar(`/dropdown/dropdown-details/`,'vendorRating_supplier')
  const { value:vendorStatus } = useFetchMataData(`/dropdown/dropdown-details/`,'status_supplier')
  const {value:vendorType } = useFetchMataData(`/dropdown/dropdown-details/`,'SupplierType')
  const {value:supplierCat } = useFetchMataData(`/dropdown/dropdown-details/`,'SupplierCategory')
 
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  // const [search,setSearch] = useState("");
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const [searchBy, setSearchBy] = useState("all");
  const [filter, setFilter] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filterProps, setFilterProps] = useState({
    country:"",
    vendorStatus:"",
    performanceRating:"",
    vendorType:"",
    category:""
  });
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
    searchBy: "",
    country:"",
    vendorStatus:"",
    performanceRating:"",
    category:"",
    vendorType:""
  });
  const clearFilter = ()=> {
    setParams({...params, country:"",
    vendorStatus:"",
    performanceRating:"",category:"",vendorType:""})
    formik.values.performanceRating=""
    formik.values.vendorStatus=""
    formik.values.country=""
    formik.values.vendorType=""
    formik.values.category=""

      setFilter(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterProps,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("ffff",value);
      setParams({...params,performanceRating:value.performanceRating, vendorStatus:value.vendorStatus,country:value.country,category:value.category,vendorType:value.vendorType})
      closeFilterDialog();
      setFilter(true)
      setFilterProps({
        country:"",
        vendorStatus:"",
        performanceRating:"",
        vendorType:"",
        category:""
      });
    }
  })

  // console.log();
  const { data, totalCount, getData, credit, advance } = UseGetDataSearch('/vendors/search', params );
  // console.log(data, 'dddd');

  const defaultHeader = [
    {
      title: "ID",
      width: "5%",
      field: "vendorId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "vendorId",
      link: '/procurement/suppliers/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },

    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "vendorId",
        removeValue: "Vendors",
        url: "/vendors/",
        moduleName: "Suppliers",
        from: "/procurement/suppliers",
        editLink: "/procurement/suppliers/edit/",
        viewLink: "/procurement/suppliers/view/",
      },
    },
  ];

  let availableHeaders = [
    {
      title: "ID",
      width: "5%",
      field: "vendorId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "10%",
      isLink: true,
      id: "vendorId",
      link: '/procurement/suppliers/view/',
      field: "companyName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Email",
      width: "10%",
      field: "email",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Phone",
      width: "10%",
      field: "phone?.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Website",
      width: "10%",
      field: "website",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Designation",
      width: "10%",
      field: "designation",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Building No",
      width: "10%",
      field: "buildingNo",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person Email",
      width: "10%",
      field: "contactPersonEmail",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Contact Person Mobile",
      width: "10%",
      field: "contactPersonMobile.display",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Tax",
      width: "10%",
      field: "taxId",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "StreetName",
      width: "10%",
      field: "streetName",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Country",
      width: "10%",
      field: "country",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "City",
      width: "10%",
      field: "city",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "State",
      width: "10%",
      field: "state",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "District",
      width: "10%",
      field: "district",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Postal Code",
      width: "10%",
      field: "postalCode",
      textAlign: "center",
      rowAlign: "center",
    },
    // {
    //   title: "Comments",
    //   width: "10%",
    //   field: "comments",
    //   textAlign: "center",
    //   rowAlign: "center",
    // },
    {
      title: "Action",
      width: "5%",
      field: "action",
      textAlign: "center",
      rowAlign: "center",
      contents: {
        // fetcher: getData,
        id: "vendorId",
        removeValue: "Vendors",
        url: "/vendors/",
        moduleName: "Suppliers",
        from: "/procurement/suppliers",
        editLink: "/procurement/suppliers/edit/",
        viewLink: "/procurement/suppliers/view/",
      },
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '33px',
      height: '33px',
      fontSize: '12px',
      minWidth: '100px',
      width: '100px', 
      borderRadius: '8px',
      border: "none" 
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '4px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
  };

  let searchList = [
    { value: "all", label: "All" },
    { value: "services", label: "Services" },
    { value: "products", label: "Products" },
  ];

  function handleSearch(e) {
    setSearchBy(e.value);
    setParams({...params,searchBy:e.value})
  }

  useEffect(() => {
    setParams({...params,page:page,per_page:pageCount})
    localStorage.setItem("viewMode", viewMode);
  }, [page,pageCount,viewMode]);

  const closeFilterDialog = () => {
    setShowFilterDialog(false);
  };
  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportClick = () => {
    setShowImportModal(true);
  };

  const closeModal = () => {
    setShowImportModal(false);
  };
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <Breadcrumb
          items={[
            {
              name: "Supplier",
            },

          ]}
        />
        <div className=' flex gap-2'>
        {/* <form
                className="flex items-center gap-3 mx-0 text-xs"
                onSubmit={SearchData}
        > */}
        <Select
            options={searchList}
            value={searchList.find((i) => i.value == searchBy) || ""}
            styles={customStyles}
            className={`text-xs capitalize 2xl:text-sm font-medium `}
            onChange={handleSearch}
          />
 <SearchBar
              handleChange={(e) =>{
                setParams({ ...params, search: e.target.value, page:1 });
                setPage(1)
              }}
            />  {/* </form> */}
          <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
          <FilterButton  handleClick={()=>setShowFilterDialog(true)} />
          {filter && <FilterButton  handleClick={()=>clearFilter()} label='Clear'/>}
        
          {GetRole() &&  <ImportButton handleClick={handleImportClick} />}
          {GetAccess("suppliers_create") && <Link to="/procurement/suppliers/create/"><CreateButton label='Supplier' /></Link>}
        </div>
      </div>

      {viewMode === "list" ? (
        <CustomDataTable
        fetcher={getData}
          data={data}
          availableHeaders={availableHeaders}
          defaultHeader={defaultHeader}
          bodyHeight="max-h-[calc(100vh-260px)]"
          height="h-[calc(100vh-145px)]"
          total={totalCount}
          pageIndex={params.page}
          setPageIndex={setPage}
          dataCount={params.per_page}
          setDataCount={setPageCount}
          storageName='SupplierTable'
          editAccess="suppliers_edit"      
          deteleAccess="suppliers_delete" 
        />
      ) : (
        <>
        <div className='w-full h-[calc(100vh-145px)] rounded-md bg-white p-3 mt-4 relative overflow-y-scroll'>
          <div className='flex gap-10 '>
        {/* {data && data.map((item, idx) => ( */}
         <p className='text-xs font-semibold text-[#111537] text-left'>Suppliers : {totalCount}</p>
         <p className='text-xs font-semibold text-[#111537]'>Credit : {credit}</p>
         <p className='text-xs font-semibold text-[#111537]'>Advance : {advance}</p>
        

        {/* ))} */}
        </div>
          <div className='w-full flex  flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4'>
            {data && data.map((item, idx) => (
            
              <SupplierCard fetcher={getData} item={item} key={idx} />
            ))}
          </div>
        </div>
        <div className="w-full h-[40px] rounded-b-md bg-white">
          <Pagination
            total={totalCount}
            pageIndex={page}
            setPageIndex={setPage}
            dataCount={pageCount}
            setDataCount={setPageCount}
          />
        </div>
        </>
      )}
        {showFilterDialog && (
             
             
             <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-[9999] flex justify-center items-center">
               <div className="animate-scale-in min-w-[40%] max-w-[80%] max-h-[80vh] bg-white py-4 px-6 rounded-md shadow-lg overflow-y-scroll">
                 <h2 className="text-xl mb-4">Filter Supplier</h2>
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <FormikSelectTwo
                type="text"
                label="Type"
                placeholder="Choose"
                Options={vendorType}
                formik={formik}
                name="vendorType"
                  width="w-full "

              />  <FormikSelectTwo
              type="text"
              label="Category"
              placeholder="Choose"
              Options={supplierCat}
              formik={formik}
              name="category"
                width="w-full "

            />
                    <FormikSelectTwo
              Options={vendorRating}
              label="Performance Ratings"
              placeholder="performanceRatings"
              formik={formik}
              name="performanceRating"
              width="w-full "
            />
            <FormikSelectTwo
              Options={CountryMIN}
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full"
            />
                       
                <FormikSelectTwo
              Options={vendorStatus}
              label="Status"
              placeholder="Status"
              formik={formik}
              name="vendorStatus"
              width="w-full "
            />
                    </div>
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               </div>
             </div>
           )}
        {showImportModal && (<Importdoc show={showImportModal} onClose={closeModal} getData={getData} />)}
  </div>
  )
}
export default SuppliersIndex