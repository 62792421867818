import { useContext, createContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CircleLeftArrow from "../../Components/Icons/CircleLeftArrow";
import CircleRightArrow from "../../Components/Icons/CircleRightArrow";
import { useTranslation } from "react-i18next";
import Settings from "../../Components/Icons/Settings";

const SidebarContext = createContext();

export default function SettingsSidebar({ children }) {
  const [expanded, setExpanded] = useState(true);
  const {t,i18n} = useTranslation();

  


  function getExpand() {
    let locExp = localStorage.getItem("settingsExpand");
    if (locExp) {
      setExpanded(JSON.parse(locExp));
    } else {
      localStorage.setItem("settingsExpand", true);
      setExpanded(true);
    }
  }

  useEffect(() => {
    getExpand();
  }, []);

  function toggleExpanded(e) {
    setExpanded(e);
    localStorage.setItem("settingsExpand", e);
  }

  return (
    <aside className="h-full z-[999]">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
        <div className={`overflow-hidden font-medium text-xl text-baseColor-primary text-center  transition-all ${
              expanded ? "w-32" : "w-0"
            }`}>
              nutrade
          </div>
          {/* <img
            src="https://img.logoipsum.com/243.svg"
            className={`overflow-hidden transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
            alt=""
          /> */}
          <button
            onClick={() => toggleExpanded(!expanded)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <CircleLeftArrow /> : <CircleRightArrow />}
          </button>
        </div>

        <div className=" w-full flex py-1 xl:py-2 px-6 font-medium  my-1 items-center overflow-hidden transition-all text-xs 2xl:text-sm" dir={i18n.language == "ar" ? "rtl" : ""}>
          {expanded ? "Settings :" : <Settings color="black"/>}
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 overflow-y-scroll" dir={i18n.language == "ar" ? "rtl" : ""}>{children}</ul>
        </SidebarContext.Provider>
      </nav>
    </aside>
  );
}

export function SettingsSidebarItem({ icon, text, active, alert, link }) {
  const { expanded } = useContext(SidebarContext);
  const {t,i18n} = useTranslation();


  return (
    <li
      className={`
        relative flex items-center py-1 xl:py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${
          active==true
            ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
            : "hover:bg-indigo-50 text-gray-600"
        }
    `}
    >
      <Link to={link}>
        <span className=" w-4  text-xs 2xl:text-sm">{icon}</span>
      </Link>

      <span
        className={`overflow-hidden transition-all text-xs 2xl:text-sm ${
          expanded ?`w-28 xl:w-32 ${i18n.language == "ar" ? "mr-3":"ml-3"} `  : "w-0"
        }`}
      >
        <Link to={link}>{text}</Link>
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
            expanded ? "" : "top-2"
          }`}
        />
      )}


{!expanded && (
        <div
          className={`
          absolute left-full min-w-40 rounded-md px-2 py-2 ml-4
          bg-indigo-100 text-indigo-800
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0  text-xs
          ${i18n.language == "ar" ? "text-right":"text-center"}
      `}
        >
          {`${text} Settings`}
        </div>
      )}
    </li>
  );
}
