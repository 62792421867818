import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../Utils/Pagenation";
import { TextMediumSM } from "../Text/MediumText";
import ModalContainer from "./ModalContainer";
import MoreOptions from "../Utils/MoreOptions";
import NuDate from "../Utils/NuDate";
import NuAmount from "../Utils/NuAmount";
import { Link } from "react-router-dom";

// Table component

const DynamicTable = ({ fetcher, data, headers, bodyHeight, hasMultiEdit,hasMultiDelete,listofNumbers,selectOne=()=>{},selectMultiple=()=>{},fullList,getById,DeteleAccess,EditAccess}) => {
  const { t, i18n } = useTranslation();


  const RenderCell = ({ header, row }) => {  
    if (header?.isLink) {
      return (
        <Link to={header.link+row[header.id]} state={{data:row}}>
          {getField(row, header?.field, header?.type)}
        </Link>
      );
    } else {
      if (header?.field === "action" && header?.title === "Action" && header?.contents) {
        return (
          <MoreOptions
            data={row}
            fetcher={fetcher}
            id={row[header.contents?.id] || ""}
            removevalue={row[header.contents?.removeValue] || "undefined"}
            url={header.contents?.url}
            moduleName={header.contents?.moduleName}
            from={header.contents?.from}
            editLink={header.contents?.editLink}
            viewLink={header.contents?.viewLink}
            DeteleAccess={DeteleAccess}
            EditAccess={EditAccess}
            EmailRequired={header.contents?.EmailRequired}
            isEmailSent={row.isEmailSent}
            emailEnable={row.emailEnable}

          />
        );
      } else if (header?.isCondition) {
        return row[header.field] ? 'Yes' : 'No';
      } else {
        return getField(row, header?.field, header?.type);
      }
    }
  };


  function getField(row,field,typeValue){
    let splitValue = field.split(".");
    let value = ""
    if(splitValue.length >= 2){
      if(splitValue.length == 3){
        value = row[splitValue[0]][splitValue[1]][splitValue[2]]
      }else {
        value = row[splitValue[0]][splitValue[1]]
      }
    }else{
        value = row[splitValue[0]]
    }

    if(typeValue === "date-time"){
      return  (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY hh:mm a" />  )||""
    }else if(typeValue === "date"){
      return (value !=="" && value !=="None" &&<NuDate value={value} format="DD-MMM-YYYY" />  )||""
    }else if(typeValue === "time"){
      return (value !=="" && value !=="None" &&<NuDate value={value} format="hh:mm a" />  )||""
    }else if(typeValue === "amount"){
      return <NuAmount value={value}/> ||""
    }else{
      return value
    }
  }

  function getHeaderId(row){
    return row[getById]||0
  }

  
  

  return (
    <div className="w-full h-[90%] overflow-hidden">
      <div className="w-full relative">
        <div className=" w-full sticky top-0" >

          <table className="w-full" dir={i18n.language == "ar" ? "rtl" : ""}>
            <thead>
              <tr className="text-xs   text-[#4F4768]">
                {
                  (hasMultiEdit||hasMultiDelete) && <th className="w-[1%]">
                    <input type="checkbox" 
                     onChange={(e)=>selectMultiple(e)}
                     checked={ fullList.length>0 && listofNumbers.length === fullList.length}
                    />
                  </th>
                }
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className="py-2 px-[8px] truncate text-[12px] 2xl:text-sm font-medium"
                    style={{ width: header.width, textAlign: header.rowAlign }}
                  >
                    {t(header.title) || ""}
                  </th>
                ))}
              </tr>

            </thead>

          </table>
        <div className="w-full h-[1px] opacity-30 bg-[#C4C2C2]  mx-auto"></div>

        </div>
        <div className={`${bodyHeight} overflow-y-scroll z-10`}>
          <table
            className="w-full"
            dir={i18n.language == "ar" ? "rtl" : ""}
          >
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  className=" hover:text-baseColor-primary hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] hover:scale-y-105 text-[#606279]"
                  key={rowIndex}
                >
                  {
                  (hasMultiEdit||hasMultiDelete) && <td className="w-[1%] pl-2">
                    <input type="checkbox" 
                      checked={listofNumbers.some(id=>id===getHeaderId(row))}
                      onChange={()=>selectOne(getHeaderId(row))}
                    />
                  </td>
                }
                  {headers.map((header, colIndex) => {
                    return (
                      <td
                        className="py-3 px-[8px]  text-xs text-center "
                        key={colIndex}
                        style={{
                          width: header.width,
                          textAlign: header.textAlign,
                        }}
                      > 
                        {RenderCell({header,row})}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Example usage
const CustomDataTable = ({
  fetcher,
  data,
  availableHeaders,
  defaultHeader,
  total,
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  height = "h-[calc(100vh-145px)]",
  bodyHeight = "max-h-[calc(100vh-200px)]",
  title = "",
  storageName = "",
  hasMultiEdit=false,
  hasMultiDelete=false,
  selectMultipleFunction=()=>{},
  selectOneFunction=()=>{},
  listofNumbers=[],
  setShowEdit=()=>{},
  showEdit=false,
  setShowDelete=()=>{},
  showDelete=false,
  fullList=[],
  getById="",
  showFieldFalse,
  editAccess="",
  deteleAccess=""
  
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();


  // Retrieve headers from local storage or set default headers
  const storedHeaders = JSON.parse(localStorage.getItem(storageName));
  const defaultHeaders = storedHeaders ||defaultHeader|| availableHeaders;

  // State to track table headers and remaining headers
  const [headersState, setHeadersState] = useState(defaultHeaders);

  useEffect(() => {
    localStorage.setItem(storageName, JSON.stringify(headersState));
  }, [headersState]);

  const toggleHeader = (header) => {
    if (headersState.some((h) => h.field === header.field)) {
      // Remove the header from headersState
      setHeadersState((prevHeaders) =>
        prevHeaders.filter((h) => h.field !== header.field)
      );
    } else {
      // Find the index of the toggled header in availableHeaders
      const headerIndex = availableHeaders.findIndex(
        (h) => h.field === header.field
      );
      // Insert the header into headersState at the same index
      setHeadersState((prevHeaders) => [
        ...prevHeaders.slice(0, headerIndex),
        header,
        ...prevHeaders.slice(headerIndex),
      ]);
    }
  };
  
  

  return (
    <div className={`w-full ${height} rounded-md bg-white p-3 mt-4 relative`}>
      <div className=" flex justify-between items-center ">
        <TextMediumSM content={title} color="text-[#4F4768]"/>
        <div className=" flex gap-3 items-center">
         {hasMultiEdit && listofNumbers.length>0 && <button className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md " onClick={()=>setShowEdit(!showEdit)}>Multi Edit</button>}
         {hasMultiDelete && listofNumbers.length>0 && <button className=" bg-[#048178] px-3 py-[3px] text-white text-xs font-medium rounded-md " onClick={()=>setShowDelete(!showDelete)}>Delete</button>}
        </div>
        {showFieldFalse == false ? 
         null
        :
        <div
        className=" rounded-lg  bg-slate-300  px-3 py-1 text-xs  cursor-pointer text-[#4F4768]"
        onClick={() => setIsModalOpen(true)}
      >
        {t('Show Feilds')} 
      </div>
       } 
      </div>

      <DynamicTable
        fetcher={fetcher}
        data={data}
        headers={headersState}
        bodyHeight={bodyHeight}
        hasMultiEdit={hasMultiEdit}
        hasMultiDelete={hasMultiDelete}
        listofNumbers={listofNumbers}
        selectOne={selectOneFunction}
        selectMultiple={selectMultipleFunction}
        fullList={fullList}
        getById={getById}
        EditAccess={editAccess}
        DeteleAccess={deteleAccess}
      />
      <div className=" w-full h-[40px] text-[#4f4768]">
        <Pagination
          total={total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
      <ModalContainer
        visiable={isModalOpen}
        title="Show Tables Headers"
        content={
          <div>
            {availableHeaders.map((header, index) => (
              <div key={index} className=" flex justify-start items-center gap-3 py-1" dir={i18n.language == "ar" ? "rtl" : ""}>
                <input
                  type="checkbox"
                  id={`header-${index}`}
                  checked={headersState.some((h) => h.field === header.field)}
                  onChange={() => toggleHeader(header)}
                />

                <label className=" font-medium" htmlFor={`header-${index}`}>{t(header.title)}</label>
              </div>
            ))}
          </div>
        }
        handleClick={() => setIsModalOpen(false)}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default CustomDataTable;
