import { Link } from "react-router-dom";
import Breadcrumb from "../../../../Components/Utils/Breadcrumb";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import axiosInstance from "../../../../Services/axiosCommon";
import { useEffect, useState } from "react";
import { TextMediumSM } from "../../../../Components/Text/MediumText";
import EditIcon from "../../../../Components/Icons/Edit";
import DeleteIcon from "../../../../Components/Icons/Delete";

const MainRolesSettings = () => {
  const [roles, setRoles] = useState([]);
  function GetRole() {
    axiosInstance.get(`/role/`).then((res) => {
      console.log(res.data.data);
      let role = res.data.data;
      res.data.data && setRoles(role);
    });
  }

  useEffect(() => {
    GetRole();
  }, []);

  return (
    <div className="w-full h-full px-4 py-2">
      <div className="w-full flex justify-between items-center">
        <Breadcrumb
          items={[
            {
              name: "Settings",
            },
            {
              name: "Roles",
            },
          ]}
        />
        <div className=" flex gap-2 items-center">
          <Link to="/settings/role/create">
            <CreateButton label="Role" />
          </Link>
          <BackButton />
        </div>
      </div>
      <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto px-4 py-2 rounded-md overflow-y-scroll">
        <div className=" w-full  grid gap-4 grid-cols-4 2xl:grid-cols-5">
          {roles &&
            roles.map((role, indx) => {
              return (
                <div
                  className=" w-full bg-slate-100 rounded-lg p-3 flex justify-between items-center"
                  key={indx}
                >
                  <TextMediumSM content={role?.roleName || ""} />

                  <div className=" flex gap-2 items-center">
                    <Link to={`/settings/role/edit/${role?.roleId}`}>
                      <EditIcon color="#999999" />
                    </Link>
                    <button>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MainRolesSettings;
