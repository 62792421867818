import CostSavingByOrdersProDash from "./Components/CostSavingByOrders";
import CostSavingByProcurmentTeamProdash from "./Components/CostSavingByProcurmentTeam";
import OrdersByRegionProDash from "./Components/OrdersByRegion";
import OrdersByValueProDash from "./Components/OrdersByValue";
import OrdersRecordProDash from "./Components/OrdersRecord";
import POToSuppliersProDash from "./Components/POToSuppliers";
import PurchaseOrderToSupplierProcuDash from "./Components/PurchaseOrderToSupplier";
import QuotesSubTotalProcuDash from "./Components/QuotesSubTotal";
import QuotesSubmittedByProcurmentTeamProDash from "./Components/QuotesSubmittedByProcurmentTeam";
import RequestFromCustomerProcuDash from "./Components/RequestFromCustomer";
import SuppliersProcuDash from "./Components/SuppliersProcuDash";
import SuppliersWithLeastLeadTimeProDash from "./Components/SuppliersWithLeastLeadTime";
import TopProductsBySalesVolumeProDash from "./Components/TopProductsBySalesVolume";
import TopProductsByValueProDash from "./Components/TopProductsByValue";


function ProcurmentDashboard() {
    return (
     <div className="w-[100%] h-[calc(100vh-90px)] py-2">
      <div className="w-full grid grid-cols-4 gap-4 ">
          <SuppliersProcuDash/>
          <RequestFromCustomerProcuDash/>
          <QuotesSubTotalProcuDash/>
          <PurchaseOrderToSupplierProcuDash/>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 mt-4">
         <QuotesSubmittedByProcurmentTeamProDash/>
         <OrdersRecordProDash/>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
         <OrdersByRegionProDash/>
         <TopProductsBySalesVolumeProDash/>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
         <TopProductsByValueProDash/>
         <SuppliersWithLeastLeadTimeProDash/>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
         <CostSavingByOrdersProDash/>
         <CostSavingByProcurmentTeamProdash/>
      </div>
      <div className="w-full grid grid-cols-4 gap-4 ">
         <OrdersByValueProDash/>
         <POToSuppliersProDash/>
      </div>
     </div>
    )
}

export default ProcurmentDashboard;

