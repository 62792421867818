import { TextMediumSM } from "../../../../../Components/Text/MediumText"
import ApprovalStatus from "./ApprovalStatus";
import PurchaseType from "./DeliveryStatus";
import Status from "./Status";



const GRN = () =>{
    return(
        <>
        <TextMediumSM content="GRN" />
        <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <PurchaseType/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <ApprovalStatus/>
         </div>
         <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
            <Status/>
         </div>

         </div>
         </>
    )
}

export default GRN;