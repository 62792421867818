import { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';

function UseFetchCurrency(url) {
    const [value,setValue] = useState([])
    function getData(){
        axiosInstance.get(url).then(res => {
            // console.log('daataa',res.data?.data);
            let data = res.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.id, label: item.name };
                tempData.push(tempObject);
            });
            res.data && setValue(tempData);
        }).catch(err => {
            console.log(err, "err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {value, getData}||[]
}

export default UseFetchCurrency