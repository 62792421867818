import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const MaintenanceType = () => {
  return (
    <SettingsMetaData propertyName={"equipments_mainType"} viewPropertyName={"Maintenance Type"} />

  )
}

export default MaintenanceType