import { useFormik } from "formik";
import SettingsMetaData from "../../../../../Components/Hooks/useSettingsMetaData";

const VesselName = () => {
  return(
    <SettingsMetaData propertyName={"VesselName"} viewPropertyName={"Vessel Name"}/>
  )
};

export default VesselName;
